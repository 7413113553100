import React from 'react';
import {
  oneOfType, string, number, bool, node, shape,
} from 'prop-types';
import { itemToValue } from 'oc-core-components/src/Autocomplete/utils';
import cn from 'classnames';
import { createMarkup } from 'helpers/utils';

import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';

import { Consumer } from './RadioGroupContext';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './RadioGroup.module.scss';

const styles = stylesProxy(stylesObj, 'RadioGroup');

const RadioButton = ({
  id,
  disabled,
  children,
  item,
  className,
}) => (
  <Consumer>
    {
      ({
        selected,
        onChange,
        disabledGroup,
        name,
      }) => {
        const isChecked = itemToValue(selected) === itemToValue(item);
        const isCorrect = item.isCorrect;

        return (
          <>
            <div
              className={
                cn(styles.container, className, {
                  [styles.checked]: isChecked,
                  [styles.disabled]: disabled || disabledGroup,
                  [styles.correct]: isCorrect,
                })
              }
            >
              <Row noGutters className="u-w-100 u-align-items-center">
                <Col xs='auto'>
                  <div className={styles.radio}>
                    <input
                      type="radio"
                      disabled={disabled || disabledGroup}
                      id={id}
                      checked={isChecked}
                      value={id}
                      name={name}
                      onChange={e => onChange && onChange(item, e)}
                      className={styles.input}
                    />
                  </div>
                </Col>
                <Col className="u-min-w-0">
                  <label
                    htmlFor={id}
                    className={styles.label}
                    dangerouslySetInnerHTML={createMarkup(children)}
                  />
                </Col>
                {
                  isCorrect && (
                    <Col xs="auto">
                      <Icon icon="feather:check" size={19} className={styles.check}/>
                    </Col>
                  )
                }
              </Row>


            </div>
          </>
        );
      }
    }
  </Consumer>
);

RadioButton.propTypes = {
  id: oneOfType([string, number]).isRequired,
  item: shape(),
  disabled: bool,
  children: node,
};

RadioButton.defaultProps = {
  disabled: false,
};

export default RadioButton;
