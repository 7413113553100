import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { ModelTypePropType, uidType } from 'types';
import {
  node, number, string, array, oneOfType,
} from 'prop-types';
import RoundThumbCard from 'components/pages/ProductBrowse/RoundThumbCard';
import { useTranslation } from 'config/i18n';
import { isNil } from 'helpers/utils';
import LvlBadge from 'hw_components/src/_HWv2/Badge/components/LvlBadge/LvlBadge';
import FollowUserButton from 'oc-core-components/src/FollowUserButton';
import { getUserProfileLink } from 'common/user/utils';
import { selectV2ModelByIdProp } from 'v2/redux/selectors';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ContributorCard.module.scss';

const styles = stylesProxy(stylesObj, 'ContributorCard');

const ContributorCard = ({
  src,
  name,
  uploadsCount,
  level,
  id,
  modelType,
  className,
  ...restProps
}) => {
  const { t } = useTranslation();
  const user = useSelector(state => selectV2ModelByIdProp.user(state, { userId: id }));

  const uploads = !isNil(uploadsCount) ? t('uploads_number', { count: uploadsCount }) : undefined;
  const profileLink = getUserProfileLink(id);

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <RoundThumbCard
        src={src}
        alt={name}
        title={name}
        subtitle={uploads}
        withAvatar
        href={profileLink}
        user={user}
      />

      {
        level && (
          <div className="u-mt-xxs">
            <LvlBadge lvl={level} />
          </div>
        )
      }

      <FollowUserButton
        modelId={id}
        modelType={modelType}
        className={styles.btn}
      />
    </div>
  );
};

ContributorCard.propTypes = {
  modelType: ModelTypePropType,
  id: uidType,
  src: oneOfType([array, string]),
  uploadsCount: number,
  name: node,
  level: number,
};

ContributorCard.defaultProps = {
  uploadsCount: 0,
};

export default ContributorCard;
