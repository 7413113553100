import { getSafeQuery } from 'helpers/utils';
import { postCourses } from 'common/api';

export const getCourses = (query, schooId, { request = postCourses, params, ...config } = {}) => request({
  code: getSafeQuery(query),
  school_id: schooId,
  ...params,
}, config);

export default {};
