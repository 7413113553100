import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';
import types from 'v2/redux/school/types';

import { storeDefaultTerm } from 'v2/redux/school/actions';
import { getDefaultTermYear } from 'common/api';
import { selectV2DefaultTermByIdProp } from 'v2/redux/school/selectors';

function* fetchDefaultTermGenerator({ payload: { schoolId } }) {
  if (schoolId) {
    const defaultTerm = yield select(state => selectV2DefaultTermByIdProp(state, { schoolId }));

    if (!defaultTerm) {
      const res = yield call(getDefaultTermYear, schoolId);
      if (res?.data) {
        yield put(storeDefaultTerm(schoolId, res.data));
      }
    }
  }
}

function* schoolSaga() {
  yield takeLatest(types.FETCH_DEFAULT_TERM.SELF, fetchDefaultTermGenerator);
}

export default schoolSaga;
