import React from 'react';
import { node, bool, string } from 'prop-types';
import { tagType } from 'types';
import Tag from 'oc-core-components/src/Tag';
import cn from 'classnames';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Tabs.module.scss';

const styles = stylesProxy(stylesObj, 'Tabs');

const SimpleTab = (props) => {
  const {
    className,
    innerClassName,
    children,
    isLabel,
    tag,
    isActive,
    small,
    component: Component,
    ...restProps
  } = props;

  return (
    <Component
      className={cn(styles.item, { [styles['is-active']]: isActive, [styles.sm]: small }, className)}

      {...restProps}
    >
      <span className={cn(styles[isLabel ? 'label' : 'link'], innerClassName)}>
        {children}
      </span>
      {
        tag && (
          <Tag className={styles.tag}>{tag}</Tag>
        )
      }
    </Component>
  );
};

SimpleTab.propTypes = {
  className: string,
  component: tagType,
  isLabel: bool,
  children: node,
  tag: string,
  isActive: bool,
  small: bool,
};

SimpleTab.defaultProps = {
  component: 'li',
};

export default SimpleTab;
