import { createAction } from 'redux-actions';
import getEnv from 'shared/helpers/utils/getEnv';
import {
  RECEIVE_COURSE_DATA,
  POST_RATE_COURSE,
  POST_RATE_COURSE_SUCCESS,
  POST_RATE_COURSE_ERROR,
  RESET_POST_RATE_COURSE_ERROR,
  GET_COURSE_SUGGESTIONS,
  GET_COURSE_SUGGESTIONS_SUCCESS,
  GET_COURSE_SUGGESTIONS_ERROR,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  REMOVE_COURSE,
  REMOVE_COURSE_SUCCESS,
  REMOVE_COURSE_ERROR,
  RESET_REMOVE_COURSE_ERROR,
  RESET_REMOVE_COURSE_SUCCESS,
} from './constants';
import { getCourseById, getRemoveAllFromCourse, postData } from '../api';

const env = getEnv();

const receiveCourseData = createAction(RECEIVE_COURSE_DATA, payload => payload);
const postRateCourse = createAction(POST_RATE_COURSE, payload => payload);
const postRateCourseSuccess = createAction(POST_RATE_COURSE_SUCCESS, (params, res) => ({ params, res }));
const postRateCourseError = createAction(POST_RATE_COURSE_ERROR, (params, res) => ({ params, res }));
const resetPostRateCourseError = createAction(RESET_POST_RATE_COURSE_ERROR);
const getCourseSuggestions = createAction(GET_COURSE_SUGGESTIONS, payload => payload);
const getCourseSuggestionsSuccess = createAction(GET_COURSE_SUGGESTIONS_SUCCESS, (params, res) => ({ params, res }));
const getCourseSuggestionsError = createAction(GET_COURSE_SUGGESTIONS_ERROR, (params, res) => ({ params, res }));
const getCourses = createAction(GET_COURSES);
const getCoursesSuccess = createAction(GET_COURSES_SUCCESS, payload => payload);
const getCoursesError = createAction(GET_COURSES_ERROR, payload => payload);
const getCourse = createAction(GET_COURSE, payload => payload);
const getCourseSuccess = createAction(GET_COURSE_SUCCESS, payload => payload);
const getCourseError = createAction(GET_COURSE_ERROR, payload => payload);
const removeCourse = createAction(REMOVE_COURSE, payload => payload);
const removeCourseSuccess = createAction(REMOVE_COURSE_SUCCESS, payload => payload);
const removeCourseError = createAction(REMOVE_COURSE_ERROR, payload => payload);
const resetRemoveCourseError = createAction(RESET_REMOVE_COURSE_ERROR);
const resetRemoveCourseSuccess = createAction(RESET_REMOVE_COURSE_SUCCESS);

function rateCourse(params) {
  return function action(dispatch) {
    dispatch(postRateCourse(params));
    const request = postData('/course_rates/create_rate', { course_rate: params });
    return request.then(
      ({ data }) => {
        if (!data?.success) {
          return dispatch(postRateCourseError(params, { error: 'rate course error' }));
        }
        return dispatch(postRateCourseSuccess(params, data));
      },
      () => dispatch(postRateCourseError(params, { error: 'rate course error' })),
    );
  };
}

function retrieveCourseSuggestions(inputValue, inputName, schoolId, departmentId) {
  // TODO: extract this somewhere else
  let endpoint = '/elastic_search/course';
  if (env.NODE_ENV === 'production') endpoint = `https://assets.oneclass.com${endpoint}`;
  return function action(dispatch) {
    const params = { code: inputValue, school_id: schoolId };
    if (departmentId) params.department_id = departmentId;
    dispatch(getCourseSuggestions(params));
    const request = postData(endpoint, params);
    return request.then(
      ({ data: { hits: { hits } } }) => dispatch(getCourseSuggestionsSuccess({ inputName, ...params }, hits)),
      () => dispatch(getCourseSuggestionsError({ inputName, ...params }, { error: 'get course suggestions error' })),
    );
  };
}

function retrieveCourseById(courseId, authToken) {
  return function action(dispatch) {
    dispatch(getCourse(courseId));

    // IF authToken PROCEED
    // ELSE retrieveNewAuthToken THEN PROCEED

    return getCourseById({ courseId, authToken }).then(({ data }) => {
      const course = data?.data && data.data.find(dataItem => (dataItem?.id?.toString() === courseId));
      if (course) {
        dispatch(getCourseSuccess([data]));
      } else {
        dispatch(getCourseError({ error: 'retrieve course by id error' }));
      }
    }, () => {
      dispatch(getCourseError({ error: 'retrieve course by id error' }));
    });
  };
}

function removeUserCourse(pageId, courseId) {
  return function action(dispatch) {
    dispatch(removeCourse({ pageId, courseId }));
    // For testing without actual removal...
    // return window.setTimeout(() => {
    //   dispatch(removeCourseSuccess({ pageId, courseId }));
    // }, 2000);
    return getRemoveAllFromCourse(courseId).then(({ data }) => {
      if (data) {
        dispatch(removeCourseSuccess({ pageId, courseId }));
      } else {
        dispatch(removeCourseError({ pageId, courseId }));
      }
    }, () => {
      dispatch(removeCourseError({ pageId, courseId }));
    });
  };
}

export {
  receiveCourseData,
  postRateCourse,
  postRateCourseSuccess,
  postRateCourseError,
  rateCourse,
  resetPostRateCourseError,
  retrieveCourseSuggestions,
  getCourseSuggestions,
  getCourseSuggestionsSuccess,
  getCourseSuggestionsError,
  getCourses,
  getCoursesSuccess,
  getCoursesError,
  retrieveCourseById,
  removeUserCourse,
  resetRemoveCourseError,
  resetRemoveCourseSuccess,
};
