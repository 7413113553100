
import MobileDetect from 'mobile-detect';
import config from 'shared/config';
import { isObject } from 'shared/helpers/utils';

const {
  container,
  breakpoints,
} = config;

// Detect device type based on user agent
export const mobileParser = ({ headers = {} } = {}) => {
  const ua = headers['user-agent'] || headers['User-Agent'];
  const md = new MobileDetect(ua);

  return {
    phone: !!md.phone(),
    tablet: !!md.tablet(),
    mobile: !!md.mobile(),
    desktop: !md.mobile(),
  };
};

const reducer = (threshold = 0) => (acc, [key, value]) => (
  {
    ...acc,
    [key]: breakpoints.includes(value) ? container[value] + threshold : value,
  }
);

export const transformQuery = (query) => {
  if (!isObject(query)) return query;

  const {
    minHeight,
    minDeviceHeight,
    minWidth,
    minDeviceWidth,

    maxHeight,
    maxDeviceHeight,
    maxWidth,
    maxDeviceWidth,

    ...restQuery
  } = query;

  const minMediaProps = Object.entries({
    minHeight,
    minDeviceHeight,
    minWidth,
    minDeviceWidth,
  }).reduce(reducer(), {});

  const maxMediaProps = Object.entries({
    maxHeight,
    maxDeviceHeight,
    maxWidth,
    maxDeviceWidth,
  }).reduce(reducer(-1), {});

  return {
    ...minMediaProps,
    ...maxMediaProps,
    ...restQuery,
  };
};

export default {};
