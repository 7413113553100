import React from 'react';
import useMediaQuery from './useMediaQuery';

const hocFactory = useHook => Component => (props) => {
  const mq = useHook();

  return (
    <Component {...props} matches={mq} />
  );
};

const createMaxWidthMQ = bp => (query, ...rest) => useMediaQuery({
  maxWidth: bp,
  ...query,
}, ...rest);

const createMinWidthMQ = bp => (query, ...rest) => useMediaQuery({
  minWidth: bp,
  ...query,
}, ...rest);


export const usePhone = createMaxWidthMQ('sm');
export const useTablet = createMaxWidthMQ('md');
export const useMobile = createMaxWidthMQ('lg');
export const useDesktop = createMinWidthMQ('lg');

export const useSmScreen = createMinWidthMQ('sm');
export const useMdScreen = createMinWidthMQ('md');
export const useLgScreen = createMinWidthMQ('lg');
export const useXlScreen = createMinWidthMQ('xl');
export const useXxlScreen = createMinWidthMQ('xxl');

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 'lg' });
  return isDesktop ? children : null;
};

export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 'md', maxWidth: 'lg' });
  return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 'md' });
  return isMobile ? children : null;
};

export const withMobileMQ = hocFactory(useMobile);
export const withTabletMQ = hocFactory(useTablet);
export const withDesktopMQ = hocFactory(useDesktop);
