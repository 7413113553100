import React from "react";
import { arrayOf, string, func, bool } from "prop-types";
import { breadcrumbType, tagType } from "types";
import cn from "classnames";

import { formatRound, isFunction, isNil } from "helpers/utils";
import Link from "oc-core-components/src/Link";

// eslint-disable-next-line import/order
import { stylesProxy } from "helpers/css";
import stylesObj from "./Breadcrumbs.module.scss";

const styles = stylesProxy(stylesObj, "Breadcrumbs");

const Item = ({
  label,
  link,
  count,
  onItemClick,
  linkTag: LinkTag,
  dataAttributes,
  className,
  linkClassName,
  defaultStyles,
}) => (
  <div
    data-test-id={`js-test_breadcrumb_${label}`}
    className={cn(styles.item, className)}
  >
    <LinkTag
      className={cn(
        { [styles.link]: link, [styles.default]: defaultStyles },
        linkClassName
      )}
      href={link}
      {...dataAttributes}
      onClick={onItemClick}
    >
      {label}
    </LinkTag>
    {!isNil(count) && (
      <span className={styles.count}>{` ${formatRound(count, true)}`}</span>
    )}
  </div>
);

const Breadcrumbs = ({
  items,
  linkTag,
  onItemClick,
  lastIsClickable,
  itemClassName,
  linkClassName,
  className,
  defaultLinkStyles,
  ...restProps
}) => (
  <div className={cn(styles.container, className)} {...restProps}>
    {items.map(({ uid, link, ...item }, indx) => {
      const isLast = indx === items.length - 1;
      const linkAvailable = !isLast || lastIsClickable;

      return (
        <Item
          onItemClick={
            linkAvailable && isFunction(onItemClick)
              ? (e) => onItemClick(e, { link, ...item })
              : null
          }
          linkTag={linkTag}
          key={uid || `${item.label}--${item.link || ""}--${indx}`}
          link={linkAvailable ? link : null}
          className={itemClassName}
          linkClassName={linkClassName}
          defaultStyles={defaultLinkStyles}
          {...item}
        />
      );
    })}
  </div>
);

Breadcrumbs.propTypes = {
  items: arrayOf(breadcrumbType),
  className: string,
  onItemClick: func,
  linkTag: tagType,
  lastIsClickable: bool,
  itemClassName: string,
  linkClassName: string,
  defaultLinkStyles: bool,
};

Breadcrumbs.defaultProps = {
  items: [],
  linkTag: Link,
};

export default Breadcrumbs;
