import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getImage } from 'helpers/utils';
import cn from 'classnames';
// import NavbarMenu from 'shared/oc-core-components/src/components/Navbar/NavbarMenu';
import {
  MENU_ITEMS_CREATE,
} from 'shared/oc-core-components/src/components/Navbar/constants';
import { useTranslation } from 'config/i18n';

import NavbarMenuIcon from 'shared/oc-core-components/src/components/Navbar/NavbarMenuIcon';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import PointsBadge from 'hw_components/src/_HWv2/Badge/components/PointsBadge';

import {
  getV2WillEarnPoints,
} from 'v2/redux/meta/selectors';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './TutorWelcomeModal.module.scss';

const styles = stylesProxy(stylesObj, 'TutorWelcomeModal');

const WELCOME_IMG = getImage('/pages/welcome/welcome.svg');

// Simplified copy from NavbarMenu
const Menu = ({
  items,
  onItemClick,
}) => {
  const { t } = useTranslation('navbar');

  return (
    <ul className={styles['navbar__menu__item-container']}>
      {
        (items?.length > 0) ? (items?.map((item, indx) => {
          if (!item.points) return;

          return (
            <li key={item?.key || item?.id || indx}>
              <Button
                color="none"
                className={cn(styles.navbar__menu__item, { [styles['navbar__menu__item--last-child']]: indx === items.length - 1 })}
                href={item.url}
                onClick={
                  onItemClick ? e => onItemClick(e, item?.data) : undefined
                }
              >
                {item.icon && (
                  <NavbarMenuIcon
                    fill={item.iconFill}
                    icon={item.icon}
                    stroke={item.iconStroke}
                  />
                )}

                <div className={styles.navbar__menu__item__text}>
                  <div
                    className={cn(
                      styles.navbar__menu__item__text__title,
                      item.description
                      && styles[
                      'navbar__menu__item__text__title--has-description'
                      ],
                    )}
                  >
                    {t(item.title)}

                    {
                      item.points && (
                        <PointsBadge
                          pointsCount={item.points}
                          color="orange"
                          size="xs"
                          className="u-ml-3xs"
                          addPlusSign
                        />
                      )
                    }
                  </div>

                  {item.description && (
                    <p
                      className={
                        styles.navbar__menu__item__text__description
                      }
                    >
                      {t(item.description, {
                        // count: shortenLargeNumber(count),
                        count: 0,
                      })}
                    </p>
                  )}
                </div>

                <Icon
                  className="u-ml-xs"
                  icon={'feather:chevron-right'}
                  size={22}
                  svgClassName={styles['navbar__menu__item__icon-right']}
                />
              </Button>
            </li>
          );
        })) : null
      }
    </ul>
  );
};

const TutorWelcomeModal = () => {
  const title = 'Welcome to our contributor community!';
  const subtitle = 'Upload your first study document or answer your first question to get started.';
  const willEarnPoints = useSelector(getV2WillEarnPoints);

  const menuItemsCreateChanger = useMemo(
    () => MENU_ITEMS_CREATE({ askQuestionUrl: undefined, isLoggedIn: true, willEarnPoints }),
    [willEarnPoints],
  );

  return (
    <div className={styles.container}>
      <img src={WELCOME_IMG} alt={'Welcome popupt image'} />

      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>

      <Menu
        items={menuItemsCreateChanger}
      />
    </div>
  );
};

TutorWelcomeModal.propTypes = {};

export default TutorWelcomeModal;
