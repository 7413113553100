import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2CurrentUser } from 'v2/redux/meta/selectors';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
// import { getV2sCSpotsLeft } from 'v2/redux/meta/selectors';
import InviteSignupPopup from 'oc_components/src/pages/Invite/InviteSignupPopup';

// eslint-disable-next-line import/order
import { stylesProxy } from 'shared/helpers/css';
import stylesObj from 'oc_components/src/pages/Invite/InviteSignupPopup/InviteSignupPopup.module.scss';

const styles = stylesProxy(stylesObj, 'InviteSignupPopup');

const usePreloadInviteSignupPopup = ({ enabled } = {}) => {
  const currentUser = useSelector(getV2CurrentUser) || {};

  const displayBanner = currentUser?.showRefPopup;

  // TODO: replace selector
  // const displayBanner = useSelector(getV2sCSpotsLeft);
  // const displayBanner = true;
  const { inline } = useModal();

  useEffect(() => {
    if (!enabled) return;
    if (!displayBanner) return;

    inline({
      content: () => (
        <InviteSignupPopup />
      ),
      mobileFullScreen: true,
      wrapperProps: {
        className: styles.modal_wrapper,
      },
    });
  }, [displayBanner, inline, enabled]);
};

export default usePreloadInviteSignupPopup;
