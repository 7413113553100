// [TODO:] Combine sass variables with json, so we have only one source of truth
// https://itnext.io/sharing-variables-between-js-and-sass-using-webpack-sass-loader-713f51fa7fa0


export const containerWidths = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1140,
  xxl: 1200,
};

export default {
  // Container max widths
  container: containerWidths,

  // container sizes
  breakpoints: Object.keys(containerWidths),
  // margin/padding sizes
  spacing: ['4xs', '3xs', 'xxs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl'],
  // asset sizes
  sizes: ['4xs', '3xs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  // Color names
  theme: [
    'primary',
    'primary-dk',
    'primary-lt',
    'primary-alt',
    'primary-alt-lt',
    'primary-ltr',
    'primary-ltst',
    'primary-accent',
    'dark-dkr',
    'dark-dk',
    'dark',
    'dark-lt',
    'dark-ltr',
    'light-dk',
    'light',
    'light-lt',
    'alert',
    'alert-lt',
    'success',
    'social-fb',
    'accent',
    'accent-alt',
    'accent-alt-lt',
    // v2
    'secondary',
    'secondary-dk',
    'secondary-alt',
    'secondary-alt-lt',
    'secondary-lt',
    'dark-alt',
    'blue',
    'coral',
    'cascade',
    'bright-green',
  ],
  inputSizes: ['xs', 'sm', 'md', 'lg'],
  inputVariants: ['primary', 'primary-dark', 'dark', 'primary-ltst'],
  modalSizes: ['md', 'sm', 'lg', 'xlg'],

  gradients: ['primary', 'warm', 'cold'],
};
