
import React from 'react';
import { string, node } from 'prop-types';
import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Dropdown.module.scss';

const styles = stylesProxy(stylesObj, 'Dropdown');

const DropdownItemGroup = ({
  tag: Tag,
  label,
  children,
  className,
  ...props
}) => (
  <Tag className={cn(styles.group, className)} {...props}>
    <div className={styles.group__label}>{label}</div>
    {children}
  </Tag>
);

DropdownItemGroup.propTypes = {
  tag: string,
  children: node,
  label: node,
};

DropdownItemGroup.defaultProps = {
  tag: 'div',
};

export default DropdownItemGroup;
