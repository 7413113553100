import React, { Component } from 'react';
import {
  string, func, oneOf, bool, oneOfType, number, shape,
} from 'prop-types';
import { tagType } from 'types';
import cn from 'classnames';
import config from 'config';

import { warnOnce, renderNode } from 'helpers/utils';

import FormField from 'oc-core-components/src/FormField';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';
import Link from 'oc-core-components/src/Link';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './TextInput.module.scss';
import ampStylesObj from './TextInput.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'TextInput');

const isNotaNumber = new RegExp('\\D', 'g');
const iconPositions = ['last', 'first'];
const {
  inputSizes: sizes,
  inputVariants: variants,
  theme,
} = config;

@FormField()

class TextInput extends Component {
  static propTypes = {
    // status: oneOf(Object.values(STATUS)), // Validation Status
    inputClassName: string, // inner input class names
    fieldContainerClassName: string,
    className: string, // wrapper class names
    error: bool, // Validation error status
    success: bool, // Validation success status
    refCallback: oneOfType([func, shape()]), // ref callback
    type: string, // Input Type
    cssSize: oneOf(sizes), // Input size
    variant: oneOf(variants), // Input color variant
    disabled: bool, // Input disabled attribute
    readonly: bool, // Input disabled attribute
    onFocus: func, // Input event handlers
    onBlur: func, // Input event handlers
    iconPosition: oneOf(iconPositions), // Icon Position
    successIcon: string, // Success Icon name
    errorIcon: string, // Error Icon name
    icon: string, // Icon name
    iconSize: oneOfType([string, number]),
    iconClassName: string,
    iconColor: oneOf(theme),
    noFocus: bool, // disable focus styles
    onIconClick: func, // icon click event handler,
    iconTag: tagType,
    'icon-aria-label': string, // Icon ARIA label
    sideLinkLabel: string,
    onSideLinkClick: func,
    containerStyle: shape(),
    disableState: bool,
    placeholder: string,
    roundBorders: bool,
    // innerRef: oneOfType([func, string]),
  };

  static defaultProps = {
    type: 'text',
    cssSize: sizes[0],
    variant: variants[0],
    successIcon: 'feather:check',
    errorIcon: 'feather:x',
    iconPosition: iconPositions[0],
    iconSize: 'xxs',
  };

  state = {
    isFocus: false,
  }

  onBlurHandler = (e) => {
    this.setState({
      isFocus: false,
    });
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  }

  onFocusHandler = (e) => {
    this.setState({
      isFocus: true,
    });
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  // getInputRef = (ref) => {
  //   if (isFunction(this.props.refCallback)) {
  //     this.props.refCallback(ref);
  //   }
  //   this.inputRef = ref;
  // }

  render() {
    /* eslint-disable prefer-const */
    let {
      inputClassName,
      fieldContainerClassName,
      className,
      variant,
      type,
      disabled,
      readonly,
      cssSize,
      onFocus,
      errorIcon,
      successIcon,
      onBlur,
      iconPosition,
      iconStroke,
      icon,
      iconSize,
      error,
      success,
      iconClassName,
      noFocus,
      onIconClick,
      iconTag,
      sideLinkLabel,
      onSideLinkClick,
      disableState,
      // status,
      // className,
      refCallback,
      placeholder,
      'icon-aria-label': iconAriaLabel,
      containerStyle,
      roundBorders,
      tag: customTag,
      styles,
      renderBeforeField,
      rowClassName,
      ...props
    } = this.props;
    /* eslint-enable prefer-const */

    // const checkInput = ['radio', 'checkbox'].indexOf(type) > -1;
    const textareaInput = type === 'textarea';
    const selectInput = type === 'select';

    const Tag = customTag || (selectInput || textareaInput ? type : 'input');

    if (props.size && isNotaNumber.test(props.size)) {
      warnOnce('Please use the prop "cssSize" instead of the "size" to css input sizing.');
      cssSize = props.size;
      delete props.size;
    }

    if (Tag === 'input') {
      props.type = type;
    }

    // Validation status
    if (error && success) {
      warnOnce('Incorrect validation status (SUCCESS and ERROR at the same time)');
      success = false;
    }

    if(!disableState) {
      if (error) {
        icon = errorIcon;
      } else if (success) {
        icon = successIcon;
      }
    }

    const inputWrapperClasses = cn(
      className,
      styles.field,
      // status,
      {
        [styles.error]: error && !disableState,
        [styles.success]: success && !disableState,
        [styles['is-disabled']]: disabled,
        [styles['is-readonly']]: readonly,
        [styles['is-focused']]: this.state.isFocus && !noFocus,
        [styles[`variant--${variant}`]]: !!variant,
        [styles.round]: roundBorders,
      },
    );

    const inputClasses = cn(
      inputClassName,
      styles.input,
      {
        [styles.textarea]: textareaInput,
        [styles['with-icon']]: !!icon,
        [styles[`size--${cssSize}`]]: !!cssSize,
      },
    );

    const iconClasses = cn(
      styles['icon-container'],
      styles[`icon-container--${iconPosition}`],
      {
        [styles.textarea__icon]: textareaInput,
      },
    );

    const alignment = type === 'textarea' ? 'start' : 'center';

    return (
      <div className={inputWrapperClasses} style={containerStyle}>
        <Row noGutters className={rowClassName}>
          <Col className={cn(styles['input-container'], fieldContainerClassName)}>
            {
              renderBeforeField && renderNode(renderBeforeField)
            }

            <Tag
              disabled={disabled}
              readOnly={readonly}
              onFocus={this.onFocusHandler}
              onBlur={this.onBlurHandler}
              ref={refCallback}
              className={inputClasses}
              placeholder={placeholder}
              {...props}
            />
            {/*
                In order to assign yellow background color to the entire container
                including the icon column, when the input is autofilled by the browser,
                we have to add an additional empty div. See css rules for more details
            */}
            <div className={styles['autofill-dummy']} />
          </Col>

          {
            sideLinkLabel && (
              <Col xs="auto" className={styles['side-link']}>
                <Link
                  aria-label={sideLinkLabel}
                  onClick={onSideLinkClick}
                  type="button"
                  className={styles['side-link__button']}
                >
                  {sideLinkLabel}
                </Link>
              </Col>
            )
          }
          {icon && (
            <Col xs="auto" className={cn(iconClasses, `u-align-items-${alignment}`)}>
              <Icon
                aria-label={iconAriaLabel || placeholder}
                className={cn(styles.icon, iconClassName)}
                size={iconSize}
                icon={icon}
                onClick={onIconClick}
                tag={iconTag}
                stroke={iconStroke}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}


export default withAmpStyles(TextInput, s, stylesObj, ampStylesObj);
