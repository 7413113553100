import { createAction } from 'redux-actions';

import { receivePageList, receivePageData } from 'common/page/actions';
import {
  getPathFromUrl,
  modelsDataToObject,
} from 'helpers/utils';
import buildQueryString from 'helpers/utils/buildQueryString';
import urljoin from 'url-join';
import { storeModels, catchError } from 'v2/redux/actions';

import {
  getAPIData,
  postData,
  getRealDocumentViewCreate,
  getPrevNextDocumentData,
} from '../api';
import {
  RECEIVE_DOCUMENT_DATA,
  POST_REPORT_DOCUMENT,
  POST_REPORT_DOCUMENT_SUCCESS,
  POST_REPORT_DOCUMENT_ERROR,
  RESET_POST_REPORT_DOCUMENT_ERROR,
  POST_REQUEST_NOTES,
  POST_REQUEST_NOTES_SUCCESS,
  POST_REQUEST_NOTES_ERROR,
  RESET_POST_REQUEST_NOTES_ERROR,
  POST_VOTE,
  POST_VOTE_SUCCESS,
  POST_VOTE_ERROR,
  POST_NOTES_REQUEST,
  POST_NOTES_REQUEST_ERROR,
  POST_NOTES_REQUEST_SUCCESS,
  GET_REAL_DOCUMENT_VIEW,
  GET_REAL_DOCUMENT_VIEW_SUCCESS,
  GET_PREV_NEXT_DATA,
  GET_PREV_NEXT_DATA_SUCCESS,
  GET_PREV_NEXT_DATA_ERROR,
  HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON,
  HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF,
  LOAD_MORE,
  LOAD_MORE_SUCCESS,
  LOAD_MORE_ERROR,
} from './constants';


const highlightUnlockDocumentFormOn = createAction(HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON);
const highlightUnlockDocumentFormOff = createAction(HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF);
function highlightUnlockDocumentForm() {
  return function action(dispatch) {
    dispatch(highlightUnlockDocumentFormOn());

    setTimeout(() => dispatch(highlightUnlockDocumentFormOff()), 2000);
  };
}

const receiveDocumentData = res => ({ type: RECEIVE_DOCUMENT_DATA, res });

const postReportDocument = params => ({ type: POST_REPORT_DOCUMENT, params });

const postReportDocumentSuccess = params => ({ type: POST_REPORT_DOCUMENT_SUCCESS, params });

const postReportDocumentError = (params, res) => ({
  type: POST_REPORT_DOCUMENT_ERROR,
  params,
  res,
});

const resetReportDocumentError = () => ({ type: RESET_POST_REPORT_DOCUMENT_ERROR });

const postRequestNotes = params => ({ type: POST_REQUEST_NOTES, params });

const postRequestNotesSuccess = (params, res) => ({
  type: POST_REQUEST_NOTES_SUCCESS,
  params,
  res,
});

const postRequestNotesError = (params, res) => ({
  type: POST_REQUEST_NOTES_ERROR,
  params,
  res,
});

const resetRequestNotesError = () => ({ type: RESET_POST_REQUEST_NOTES_ERROR });

const postVote = params => ({ type: POST_VOTE, params });

const postVoteSuccess = params => ({ type: POST_VOTE_SUCCESS, params });

const postVoteError = params => ({ type: POST_VOTE_ERROR, params });

const postNotesRequest = params => ({ type: POST_NOTES_REQUEST, params });

const postNotesRequestSuccess = params => ({ type: POST_NOTES_REQUEST_SUCCESS, params });

const postNotesRequestError = params => ({ type: POST_NOTES_REQUEST_ERROR, params });

function reportDocument(params) {
  return function action(dispatch) {
    dispatch(postReportDocument(params));
    const request = postData(`/api/documents/${params.documentId}/report`, { report: params.values });

    return request.then(
      () => dispatch(postReportDocumentSuccess({ documentId: params.documentId })),
      () => dispatch(postReportDocumentError(params, { error: 'report document error' })),
    );
  };
}

function requestNotes(documentId, ownerId, params) {
  const details = params;
  details.document_id = documentId;
  details.note_taker_id = ownerId;
  return function action(dispatch) {
    dispatch(postRequestNotes(details));
    const request = postData('/api/documents/create_request_note', details);

    return request.then(
      () => dispatch(postRequestNotesSuccess(details, { success: true })),
      () => dispatch(postRequestNotesError(details, { error: 'request notes error' })),
    );
  };
}

function vote(documentId, voteValue) {
  const details = { target_id: documentId, target_type: 'Document', vote: voteValue };
  return function action(dispatch) {
    dispatch(postVote(details));
    const request = postData('/api/votes.json', details);
    return request.then(
      () => dispatch(postVoteSuccess(details)),
      () => dispatch(postVoteError(details)),
    );
  };
}

function notesRequest(details) {
  return function action(dispatch) {
    dispatch(postNotesRequest(details));
    const request = postData('/api/notes_requests', details);
    return request.then(
      ({ data }) => {
        if (data.success) {
          return dispatch(postNotesRequestSuccess(details));
        }
        return dispatch(postNotesRequestError(details));
      },
      () => dispatch(postNotesRequestError(details)),
    );
  };
}

function reportDocumentAndDownVote(documentId, { reason, additional_details }) {
  const details = {
    target_id: documentId,
    target_type: 'Document',
    vote: false,
    report: {
      issue: reason,
      message: additional_details,
    },
  };
  return function action(dispatch) {
    dispatch(postReportDocument({ documentId }));
    const request = postData('/api/votes/create_with_report.json', details);
    return request.then(
      () => dispatch(postReportDocumentSuccess({ documentId })),
      () => dispatch(postReportDocumentError({ documentId })),
    );
  };
}

const getRealDocumentView = params => ({ type: GET_REAL_DOCUMENT_VIEW, params });

const getRealDocumentViewSuccess = params => ({ type: GET_REAL_DOCUMENT_VIEW_SUCCESS, params });

const createRealDocumentView = documentId => function action(dispatch) {
  dispatch(getRealDocumentView(documentId));
  const request = getRealDocumentViewCreate(documentId);

  return request.then(
    () => dispatch(getRealDocumentViewSuccess(documentId)),
    (err) => {
      dispatch(catchError(err, { documentId }));
    },
  );
};

const getPrevNextData = params => ({ type: GET_PREV_NEXT_DATA, params });

const getPrevNextDataSuccess = (res, params) => ({ type: GET_PREV_NEXT_DATA_SUCCESS, res, params });

const getPrevNextDataError = params => ({ type: GET_PREV_NEXT_DATA_ERROR, params });

const retrievePrevNextDocumentData = documentId => function action(dispatch) {
  dispatch(getPrevNextData(documentId));
  const request = getPrevNextDocumentData(documentId);
  return request.then(
    ({ data }) => {
      if (data.success) {
        return dispatch(getPrevNextDataSuccess(data, documentId));
      }
      return dispatch(getPrevNextDataError(documentId));
    },
    () => dispatch(getPrevNextDataError(documentId)),
  );
};

const loadMore = params => ({ type: LOAD_MORE, params });

const loadMoreSuccess = params => ({ type: LOAD_MORE_SUCCESS, params });

const loadMoreError = (params, error) => ({ type: LOAD_MORE_ERROR, params, error });

function loadMorePageListItems({
  listUid,
  page,
  url,
  override,
  routerQuery = {},
}) {
  return function action(dispatch) {
    const actionParams = {
      listUid,
      page,
      url,
      // Merging strategy,
      // If true, override the exisiting ids array,
      // otherwise concatenate with the new one
      override,
      routerQuery,
    };
    // This is dispatched for debugging, should include whatever params could help
    dispatch(loadMore(actionParams));
    // Ensure we have all the params we require for success
    if (!listUid || !page || !url) {
      return dispatch(loadMoreError(actionParams, { error: 'loadMoreItems action missing action param error' }));
    }
    // strip querystring from url & build fresh query from router query with section load_more
    let requestUrl = getPathFromUrl(url);
    const query = {};

    if (!override) {
      query.section = 'load_more';
    }

    // TODO: this should be a passed in list of keys, not hardcoded checks
    if (routerQuery?.page) query.page = routerQuery.page;
    if (routerQuery?.order) query.order = routerQuery.order;
    if (routerQuery?.search) query.search = routerQuery.search;
    if (routerQuery?.q) query.q = routerQuery.q;

    requestUrl = `${requestUrl}${buildQueryString(query)}`;

    // Ensure we have a request url
    if (!requestUrl) return dispatch(loadMoreError(actionParams, { error: 'loadMoreItems action requestUrl error' }));
    const request = getAPIData({ url: urljoin('api', requestUrl) });
    return request.then(
      ({ data }) => {
        let newPageProps = {};

        if (data && data.pages) newPageProps = data.pages;
        // If we have new pageProps for this pageSubState
        if (newPageProps[actionParams.page]) {
          const pageSubState = newPageProps[actionParams.page];
          // Sends object types to appropriate states
          // TODO: next line could be removed after storeModels testing
          dispatch(receivePageData(actionParams.page, pageSubState.data));
          dispatch(storeModels(modelsDataToObject(pageSubState.data)));
          // Check if we received new pageLists
          const newPageLists = pageSubState?.pageProps?.pageLists;

          if (newPageLists) {
            // Check if we have a uid item in the pageLists
            const newListItem = newPageLists.find(list => list.uid === listUid);
            // If we have the uid in the new lists,
            // send it to the reducer and merge in new data IDs
            if (newListItem) {
              dispatch(receivePageList(
                {
                  pageSubState: actionParams.page,
                  listUid,
                  override,
                },
                newListItem,
              ));
            }
          }
        }
        return dispatch(loadMoreSuccess(actionParams));
      },
      () => dispatch(loadMoreError(actionParams, { error: 'loadMoreItems action uncaught error' })),
    );
  };
}

export {
  highlightUnlockDocumentForm,
  receiveDocumentData,
  reportDocument,
  resetReportDocumentError,
  requestNotes,
  resetRequestNotesError,
  vote,
  notesRequest,
  reportDocumentAndDownVote,
  createRealDocumentView,
  retrievePrevNextDocumentData,
  loadMorePageListItems,
};
