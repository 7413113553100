import { handleModelActions } from 'v2/redux/utils/handleActions';
import { HW_ANSWER_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const hwAnswerReducer = handleModelActions(HW_ANSWER_MODEL_TYPE, {}, initialState);

export { initialState };

export default hwAnswerReducer;
