import React from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './FormHint.module.scss';

const styles = stylesProxy(stylesObj, 'FormHint');

const FormHint = (props) => {
  const {
    href,
    className,
    ...restProps
  } = props;

  const Tag = href ? 'a' : 'button';


  return (
    <Tag className={cn(styles.hint, className)} href={href} type={!href && 'button'} {...restProps} />
  );
};

FormHint.propTypes = {
  href: string,
};

export default FormHint;
