const RECEIVE_COUNTRY_DATA = 'common/country/RECEIVE_COUNTRY_DATA';
const GET_COUNTRIES = 'common/country/GET_COUNTRIES';
const GET_COUNTRIES_SUCCESS = 'common/country/GET_COUNTRIES_SUCCESS';
const GET_COUNTRIES_ERROR = 'common/country/GET_COUNTRIES_ERROR';

export {
  RECEIVE_COUNTRY_DATA,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
};
