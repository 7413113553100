import merge from 'lodash/merge';
import mergeable from 'redux-merge-reducers';

import {
  RECEIVE_PROFESSOR_DATA,
  GET_PROFESSOR,
  GET_PROFESSOR_SUCCESS,
  GET_PROFESSOR_ERROR,
  GET_PROFESSOR_SUGGESTIONS,
  GET_PROFESSOR_SUGGESTIONS_SUCCESS,
  GET_PROFESSOR_SUGGESTIONS_ERROR,
  GET_PROFESSORS_BY_COURSE,
  GET_PROFESSORS_BY_COURSE_SUCCESS,
  GET_PROFESSORS_BY_COURSE_ERROR,
  RESET_GET_PROFESSOR_SUGGESTIONS_ERROR,
} from './constants';

const initialState = {
  data: {},
  loadingProfessor: false,
  loadingProfessorsByCourse: false,
  retrievingProfessorSuggestions: false,
  retrieveProfessorSuggestionSuccess: false,
  retrieveProfessorSuggestionError: false,
  requestFormProfessorOptions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROFESSOR_DATA:
      return merge({}, state, {
        data: action.res,
      });
    case GET_PROFESSOR_SUGGESTIONS:
      return Object.assign({}, state, {
        retrievingProfessorSuggestions: true,
        retrieveProfessorSuggestionSuccess: false,
      });
    case GET_PROFESSOR_SUGGESTIONS_SUCCESS: {
      const fieldNameSuggestions = [];
      action.res.forEach(({ value, data }) => {
        fieldNameSuggestions.push({
          value,
          name: data,
        });
      });
      return Object.assign({}, state, {
        retrievingProfessorSuggestions: false,
        retrieveProfessorSuggestionSuccess: {
          [action.params.inputName]: fieldNameSuggestions,
        },
      });
    }
    case GET_PROFESSOR_SUGGESTIONS_ERROR:
      return Object.assign({}, state, {
        retrievingProfessorSuggestions: false,
        retrieveProfessorSuggestionError: 'An error occured in GET_PROFESSOR_SUGGESTIONS',
      });
    case RESET_GET_PROFESSOR_SUGGESTIONS_ERROR:
      return Object.assign({}, state, {
        retrieveProfessorSuggestionError: false,
      });
    case GET_PROFESSOR:
      return Object.assign({}, state, {
        loadingProfessor: true,
      });
    case GET_PROFESSOR_SUCCESS:
      return Object.assign({}, state, {
        loadingProfessor: false,
      });
    case GET_PROFESSOR_ERROR:
      return Object.assign({}, state, {
        loadingProfessor: false,
      });
    case GET_PROFESSORS_BY_COURSE:
      return Object.assign({}, state, {
        loadingProfessorsByCourse: true,
        requestFormProfessorOptions: [],
      });
    case GET_PROFESSORS_BY_COURSE_SUCCESS:
      return Object.assign({}, state, {
        loadingProfessorsByCourse: false,
        requestFormProfessorOptions: action.res,
      });
    case GET_PROFESSORS_BY_COURSE_ERROR:
      return Object.assign({}, state, {
        loadingProfessorsByCourse: false,
        requestFormProfessorOptions: [],
      });
    default:
      return state;
  }
};

export default mergeable(reducer);
