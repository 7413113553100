import React from 'react';
import {
  oneOfType, number, string, bool, shape, arrayOf,
} from 'prop-types';
import cn from 'classnames';
import { tagType } from 'types';

import { isObject } from 'helpers/utils';

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
const stringOrNumberProp = oneOfType([number, string]);

const columnProps = oneOfType([
  bool,
  number,
  string,
  shape({
    size: oneOfType([bool, number, string]),
    order: stringOrNumberProp,
    offset: stringOrNumberProp,
  }),
]);

const propTypes = {
  tag: tagType,
  xs: columnProps,
  sm: columnProps,
  md: columnProps,
  lg: columnProps,
  xl: columnProps,
  xxl: columnProps,
  className: string,
  widths: arrayOf(string),
};

const defaultProps = {
  tag: 'div',
  widths: colWidths,
};

const getColumnSizeClass = (isXs, colWidth, colSize) => {
  if (colSize === true || colSize === '') {
    return isXs ? 'l-col' : `l-col-${colWidth}`;
  } if (colSize === 'auto') {
    return isXs ? 'l-col-auto' : `l-col-${colWidth}-auto`;
  }

  return isXs ? `l-col-${colSize}` : `l-col-${colWidth}-${colSize}`;
};

const Col = (props) => {
  const {
    className,
    widths,
    tag: Tag,
    ...attributes
  } = props;
  const colClasses = [];

  widths.forEach((colWidth, i) => {
    let columnProp = props[colWidth];

    if (!i && columnProp === undefined) {
      columnProp = true;
    }

    delete attributes[colWidth];

    if (!columnProp && columnProp !== '') {
      return;
    }

    const isXs = !i;
    let colClass;

    if (isObject(columnProp)) {
      const colSizeInterfix = isXs ? '-' : `-${colWidth}-`;
      colClass = getColumnSizeClass(isXs, colWidth, columnProp.size);

      colClasses.push(cn({
        [colClass]: columnProp.size || columnProp.size === '',
        [`order${colSizeInterfix}${columnProp.order}`]: columnProp.order || columnProp.order === 0,
        [`offset${colSizeInterfix}${columnProp.offset}`]: columnProp.offset || columnProp.offset === 0,
      }));
    } else {
      colClass = getColumnSizeClass(isXs, colWidth, columnProp);
      colClasses.push(colClass);
    }
  });

  const classes = cn(
    className,
    'l-col-base',
    colClasses,
  );

  return (
    <Tag {...attributes} className={classes} />
  );
};

Col.propTypes = propTypes;
Col.defaultProps = defaultProps;

export default Col;
