import cn from 'classnames';
import { stylesProxy } from 'helpers/css';
import withAmpStyles from 'helpers/withAmpStyles';
import Icon from 'oc-core-components/src/Icon';
import {
  bool, func, number, string,
} from 'prop-types';
import React, { useCallback } from 'react';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Navbar');

const MenuButton = ({
  active,
  className,
  count,
  icon,
  iconStroke,
  on,
  onClick,
  onClickOutside,
  styles,
  title,
  iconClassName,
  'aria-label': ariaLabel,
  isActiveLabel,
  label,
  useHover,
}) => {
  const onMouseLeave = useCallback(() => {
    setTimeout(() => {
      onClickOutside();
    }, 350);
  }, [onClickOutside]);

  return (
    <button
      className={cn(
        styles.navbar__menu__button,
        active && styles['navbar__menu__button--active'],
        className,
      )}
      onClick={useHover ? undefined : onClick}
      onMouseEnter={useHover ? onClick : undefined}
      onMouseLeave={useHover ? onMouseLeave : undefined}
      on={on}
      title={title}
      type="button"
      aria-label={ariaLabel}
    >
      {count > 0 && (
      <span className={styles['navbar__button-badge']}>{count}</span>
      )}

      <Icon className={cn(styles.navbar__menu__button__icon, iconClassName)} icon={icon} size={22} stroke={iconStroke} />
      {
        label ? (
          <div
            className={
              cn(styles['navbar__menu__btn_label'], {
                [styles['navbar__menu__btn_label--active']]: isActiveLabel,
              })
            }
          >
            {label}
          </div>
        ) : null
      }
    </button>
  );
};

MenuButton.defaultProps = {
  count: 0,
};

MenuButton.propTypes = {
  active: bool,
  className: string,
  icon: string,
  iconStroke: string,
  count: number,
  on: string,
  onClick: func,
  title: string,
};

export default withAmpStyles(MenuButton, s, stylesObj, ampStylesObj);
