import urljoin from 'url-join';

import {
  ROUTES,
  ROUTES_BASE,
  NOTES_URL_PREFIXES,
  COUNTRY_SUFFIXES,
  COPYRIGHT,
  SOCIAL_LINKS,
} from 'helpers/constants';


export const MOBILE_LOGGEDIN_FOOTER = [
  { label: 'About', url: ROUTES.about },
  { label: 'Career', url: ROUTES.jobs },
  { label: 'Press', url: ROUTES.press },
  { label: 'Academic integrity', url: ROUTES.academicIntegrity },
  { label: 'Become a contributor', url: ROUTES.tutors },
  { label: 'Help', url: ROUTES.support },
  { label: 'FAQ', url: ROUTES.faq },
  { label: 'Canada', url: ROUTES.canada },
  { label: 'United States', url: ROUTES.unitedStates },
  { label: 'Australia', url: ROUTES.australia },
  { label: 'New Zealand', url: ROUTES.newZealand },
  { label: 'Blog', url: ROUTES.blog },
];

export const MOBILE_FOOTER = [
  { label: 'Sign up', url: ROUTES.signup },
  { label: 'Log in', url: ROUTES.login },
  ...MOBILE_LOGGEDIN_FOOTER,
];


export const FOOTER_MENUS = [
  {
    name: 'About',
    items: [
      { label: 'Company', url: ROUTES.about },
      { label: 'Career', url: ROUTES.jobs },
      { label: 'Press', url: ROUTES.press },
      { label: 'Academic integrity', url: ROUTES.academicIntegrity },
      { label: 'Become a contributor', url: ROUTES.tutors },
    ],
  },

  {
    name: 'Resources',
    items: [
      { // TODO: footer_end_url_for_user
        label: NOTES_URL_PREFIXES.classNotes.label,
        url: ROUTES.classNotes,
      },
      {
        label: NOTES_URL_PREFIXES.textbookNotes.label,
        url: ROUTES.textbookNotes,
      },
      {
        label: NOTES_URL_PREFIXES.studyGuides.label,
        url: ROUTES.studyGuides,
      },
      {
        label: 'Homework Help',
        url: ROUTES.homeworkHelp,
      },
      {
        label: 'Booster Classes',
        url: ROUTES.boosterClass,
      },
    ],
  },

  {
    name: 'Browse',
    items: [
      { label: 'Canada', url: urljoin(ROUTES_BASE.classNotes, COUNTRY_SUFFIXES.ca) },
      { label: 'United States', url: urljoin(ROUTES_BASE.classNotes, COUNTRY_SUFFIXES.us) },
      { label: 'Australia', url: urljoin(ROUTES_BASE.classNotes, COUNTRY_SUFFIXES.au) },
      { label: 'New Zealand', url: urljoin(ROUTES_BASE.classNotes, COUNTRY_SUFFIXES.nz) },
      { label: 'Blog', url: ROUTES.blog },
      { label: 'Schools', url: ROUTES.schools },
    ],
  },
  {
    name: 'Connect',
    items: [
      { label: 'Sign up', url: ROUTES.signup },
      { label: 'Log in', url: ROUTES.login },
      { label: 'Help & Support', url: ROUTES.support },
      { label: 'FAQ', url: ROUTES.faq },
      { label: 'Contact', url: ROUTES.contact },
    ],
  },
];

export const FOOTER_BOTTOM = [
  { label: 'Privacy', url: ROUTES.privacy },
  { label: 'Terms', url: ROUTES.terms },
  { label: COPYRIGHT },
];

export const SMALL_FOOTER = [
  { label: 'About', url: ROUTES.about },
  { label: 'Help', url: ROUTES.support },
  { label: 'FAQ', url: ROUTES.faq },
  { label: 'Career', url: ROUTES.jobs },
  { label: 'Blog', url: ROUTES.blog },
  { label: 'Contact', url: ROUTES.contact },
  { label: 'Privacy', url: ROUTES.privacy },
  { label: 'Terms', url: ROUTES.terms },
  { label: COPYRIGHT },
];

export const SOCIAL = [
  {
    icon: 'oc:facebook',
    url: SOCIAL_LINKS.facebook,
    ariaLabel: 'Facebook',
  },
  {
    icon: 'oc:twitter',
    url: SOCIAL_LINKS.twitter,
    ariaLabel: 'Twitter',
  },
  // { // TODO: update, when https://github.com/feathericons/feather/pull/114 will be merged
  //   icon: 'oc:google-plus',
  //   url: SOCIAL_LINKS.gplus,
  //   ariaLabel: 'Google Plus',
  // },
  {
    icon: 'oc:youtube',
    url: SOCIAL_LINKS.youtube,
    ariaLabel: 'Youtube',
  },
  // {
  //   icon: 'oc:instagram',
  //   url: SOCIAL_LINKS.instagram,
  //   ariaLabel: 'Instagram',
  // },
];
