import React, { createContext, Component, useContext } from 'react';
import NotificationSystem from 'react-notification-system';
import { node, func, shape } from 'prop-types';
import { ERROR_MESSAGE } from 'helpers/notificationMessages';
import { dummyFunc } from 'helpers/utils';
import { withContext } from 'helpers/hoc';

// TODO: Refactor styles
const style = {
  Containers: {
    DefaultStyle: {
      zIndex: 100000,
      padding: 0,
    },
  },
  // TODO: addclass support
  NotificationItem: { // Override the notification item
    DefaultStyle: { // Applied to every notification, regardless of the notification level
      padding: '14px 32px',
      textAlign: 'center',
      fontSize: '16px',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: '3px',
      borderTop: 'none',
    },
    success: {
      border: '1px solid #43d6a9',
      backgroundColor: '#f6fbf9',
    },
    error: {
      border: '1px solid #CE2F2C',
      backgroundColor: '#fdf7f7',
    },
  },
};

export const NotificationContext = createContext({
  addNotification: dummyFunc,
  addErrorNotification: dummyFunc,
  addSuccessNotification: dummyFunc,
  addWarningNotification: dummyFunc,
});

export const NotificationContextType = shape({
  addNotification: func,
  addErrorNotification: func,
  addSuccessNotification: func,
  addWarningNotification: func,
});

export const withNotifications = withContext(NotificationContext, 'notificationContext');

export const useNotifications = () => useContext(NotificationContext);

export default class NotificationProvider extends Component {
  static propTypes = {
    children: node,
  }

  constructor() {
    super();
    this.system = React.createRef();
  }

  /* eslint-disable react/sort-comp */
  addNotification = ({
    text,
    type,
    autoDismiss,
    ...config
  }) => {
    if (this.system) {
      this.system.current.addNotification({
        message: text,
        level: type,
        position: 'tc',
        autoDismiss: (autoDismiss !== undefined) ? autoDismiss : 3,
        dismissible: 'click',
        ...config,
      });
    }
  }

  addErrorNotification = (text = ERROR_MESSAGE) => this.addNotification({ text, type: 'error' })

  addSuccessNotification = text => this.addNotification({ text, type: 'success' })

  addWarningNotification = text => this.addNotification({ text, type: 'warning' })

  state = {
    value: {
      addNotification: this.addNotification,
      addErrorNotification: this.addErrorNotification,
      addSuccessNotification: this.addSuccessNotification,
      addWarningNotification: this.addWarningNotification,
    },
  }
  /* eslint-enable react/sort-comp */

  render() {
    const { children } = this.props;

    return (
      <NotificationContext.Provider
        value={this.state.value}
      >
        <NotificationSystem
          ref={this.system}
          style={style}
        />
        {children}
      </NotificationContext.Provider>
    );
  }
}
