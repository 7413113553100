import React from 'react';
import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './MenuButtonV2.module.scss';

const styles = stylesProxy(stylesObj, 'MenuButtonV2');


const MenuButtonV2 = ({
  className,
  count,
  icon,
  iconStroke,
  iconProps,
  children,
  ...restProps
}) => (
  <div className={cn(styles.container, className, { [styles['with-icon']]: !!icon })} {...restProps}>
    {count > 0 && (
    <span className={styles.count}>{count}</span>
    )}
    {
      icon ? (
        <Icon icon={icon} stroke={iconStroke} size={22} {...iconProps} />
      ) : (
        <>
          {children}
        </>
      )
    }
  </div>
);

export default MenuButtonV2;
