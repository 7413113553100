import React from 'react';
import Icon from 'oc-core-components/src/Icon';
import { string } from 'prop-types';
import cn from 'classnames';
import LazyImage from 'oc-core-components/src/LazyImage';
import Col from 'oc-core-components/src/Col';
import Row from 'oc-core-components/src/Row';
import Link from 'oc-core-components/src/Link';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './TextbookSelectOption.module.scss';

const styles = stylesProxy(stylesObj, 'TextbookSelectOption');

const TextbookSelectOption = ({
  label,
  edition,
  author,
  isbn,
  thumb,
  thumb2,
  highlighted,
  url,
  year,
}) => {
  const authorLabel = author ? `by ${author}` : undefined;
  const isbnLabel = isbn ? `ISBN: ${isbn}` : undefined;
  const editionlabel = edition ? `${edition} Edition, ${year || ''}` : undefined;

  const info = [authorLabel, isbnLabel, editionlabel].filter(v => !!v).join(', ');

  return (
    <Link href={url} className={cn(styles.container, { [styles.highlighted]: highlighted })}>
      <Row xsGap="xxs" className="u-align-items-center">
        {
          thumb && (
            <Col xs="auto">
              <LazyImage
                width={32}
                height={38}
                src={thumb}
                className={styles.thumb}
                alt={label}
              />
            </Col>
          )
        }


        <Col className="u-min-w-0">
          {
            label && (
              <div className={styles.label}>
                {label}
              </div>
            )
          }

          {
            info && (
              <div className={styles.info}>
                {info}
              </div>
            )
          }
        </Col>

      </Row>
    </Link>
  );
};

TextbookSelectOption.propTypes = {
  label: string,
  info: string,
  meta: string,
  icon: string,
};

export default TextbookSelectOption;
