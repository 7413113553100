import React from 'react';
import { func } from 'prop-types';
import ErrorComponent from 'components/Error';

import { captureException } from 'helpers/sentry';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    return { error, errorInfo };
  }

  throwError = (error, errorInfo) => {
    this.setState({ error, errorInfo });

    console.error('ErrorBoundary', error, errorInfo);
    captureException(error, { errorInfo });
  }

  componentDidCatch(error, errorInfo) {
    this.throwError(error, errorInfo);
  }


  render() {
    const { errorRender, children } = this.props;
    const { error, errorInfo } = this.state;

    if (error) {
      // You can render any custom fallback UI
      return errorRender(error, errorInfo);
    }

    return children({ error, errorInfo }, this.throwError);
  }
}

ErrorBoundary.propTypes = {
  errorRender: func,
  children: func.isRequired,
};

ErrorBoundary.defaultProps = {
  errorRender: ErrorComponent,
};

const withErrorBoundary = (errorProps => WrappedComponent => props => (
  <ErrorBoundary
    {...errorProps}
  >
    {
      (error, throwError) => (
        <WrappedComponent
          throwError={throwError}
          {...props}
        />
      )
    }
  </ErrorBoundary>
));


export {
  withErrorBoundary,
};
export default ErrorBoundary;
