import { handleModelActions } from 'v2/redux/utils/handleActions';
import { VIDEO_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const watcherReducer = handleModelActions(VIDEO_MODEL_TYPE, {}, initialState);

export { initialState };

export default watcherReducer;
