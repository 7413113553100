import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2CurrentUser } from 'v2/redux/meta/selectors';
import { shape, arrayOf } from 'prop-types';
import DeferredAutocomplete from 'oc-core-components/src/DeferredAutocomplete';
import { getUserProfileLink } from 'common/user/utils';
import { uidType } from 'types';
// import { getProfessors } from './utils';
import { getImage } from 'shared/helpers/utils';
import { getInitSearchUsers, getUsers } from 'shared/common/api';
import LazyImage from 'oc-core-components/src/LazyImage';
import Link from 'oc-core-components/src/Link';
import useImgFallback from 'helpers/hooks/useImgFallback';
import LvlBadge from 'hw_components/src/_HWv2/Badge/components/LvlBadge';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';

// const getUsers = val => new Promise(() => null);

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './UserAutocomplete.module.scss';

const styles = stylesProxy(stylesObj, 'UserAutocomplete');


const AVATAR_FALLBACK = getImage('/pages/invite_signup/avatar-placeholder@2x.png');

const FallbackImage = ({ src, alt, ...rest }) => {
  const { imgSrc, onImgError } = useImgFallback({ src, fallback: AVATAR_FALLBACK });

  return (
    <LazyImage
      src={imgSrc}
      sources={Array.isArray(imgSrc) ? imgSrc : undefined}
      onError={onImgError}
      disableLazy
      alt={`${alt} OC user avatar`}
      // width
      {...rest}
    />
  );
};

const closeIcon = 'feather:x';

export const defaultRenderItem = ({ id, avatar, label, level, onCloseClick, ...rest }) => {
  const userLink = getUserProfileLink(id);
  const Tag = userLink ? Link : 'div';

  return (
    <Tag className={styles.row} href={userLink}>
      <FallbackImage src={avatar} className={styles.avatar} alt={label}/>
      <span className={styles.label}>{label}</span>
      <LvlBadge lvl={level} />

      {
        onCloseClick ? (
          <Col className={styles.user_tag_close_col} onClick={onCloseClick}>
            <Icon
              icon={closeIcon}
              className={styles.user_tag_close}
              size={12}
              // onClick={onCloseClick}
            />
          </Col>
        ) : null
      }
    </Tag>
  );
};

const normalizeRawData = ({ data, currentUserId, excludedIds = [] }) => {
  const {
    hits: { hits: rawHits } = {},
  } = data || {};

  let users = rawHits?.map(({
    _source: { avatar_png, nickname, id, level }
  }) => ({
    avatar: avatar_png || AVATAR_FALLBACK,
    label: nickname,
    value: id.toString(),
    level,
    // url,
  }));

  // users = users?.filter(u => u.value !== `${currentUserId}`);
  users = users?.filter(u => ![...excludedIds, `${currentUserId}`].includes(u.value));

  return users;
};



// Copy-Pasted almost AS IS from ProfessorAutocomplete
const UserAutocomplete = (props = {}) => {
  const [suggestions, setSuggestions] = useState([]);
  const currentUser = useSelector(getV2CurrentUser) || {};
  const { id: currentUserId, init_tagged_ids: initTaggedIds } = currentUser;
  const { excludedIds } = props;


  useEffect(() => {
    async function getInitUsers() {
      const { data } = await getInitSearchUsers();
      setSuggestions(normalizeRawData({ data, currentUserId, excludedIds }));
    }

    if (initTaggedIds) {
      getInitUsers();
    }
  }, [currentUserId, initTaggedIds, excludedIds]);

  const inputChangeHandler = useCallback(val => getUsers({ code: val })
    .then(({ data }) => normalizeRawData({ data, currentUserId, excludedIds })), [currentUserId, excludedIds]);

  const {
    items: customItems,
    countryId,
    renderItem = defaultRenderItem,
    ...restProps
  } = props;

  return (
    <DeferredAutocomplete
      promise={inputChangeHandler}
      // items={suggestions}
      defaultItems={suggestions}
      disableFiltering
      showMenuOnEmptyFocus
      renderItem={renderItem}
      {...restProps}
    />
  );
};

UserAutocomplete.propTypes = {
  countryId: uidType,
  items: arrayOf(shape()),
};

export default UserAutocomplete;
