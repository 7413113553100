import React from 'react';
import { node, string, func, oneOf } from 'prop-types';
import cn from 'classnames';
import FormHint from 'oc-core-components/src/FormHint';

import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';



// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Label.module.scss';

const styles = stylesProxy(stylesObj, 'Label');


const Label = ({
  htmlFor,
  hint,
  onHintClick,
  size,
  children,
  className,
  ...props
}) => (
  <label htmlFor={htmlFor} className={cn(className, styles.label, { [styles[size]]: size })} {...props}>
    {
      hint ? (
        <Row noGutters className="u-justify-content-between">
          <Col xs="auto">
            <strong>
              {children}
            </strong>
          </Col>

          <Col xs="auto">
            <FormHint onClick={onHintClick}>{hint}</FormHint>
          </Col>
        </Row>
      ) : children
    }

  </label>
);

Label.propTypes = {
  children: node,
  hint: node,
  onHintClick: func,
  htmlFor: string,
  size: oneOf(['xs', 'sm', 'lg']),
};

export default Label;
