import React, { useCallback } from 'react';

import ClickOutside from 'react-click-outside-component';
import { useDispatch, useSelector } from 'react-redux';
import { getV2IsStudyModeOn } from 'v2/redux/page/selectors';
import { setStudyModeTooltipOpen, setStudyModeTooltipClosed } from 'v2/redux/page/actions';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './StudyModeOverlay.module.scss';

const styles = stylesProxy(stylesObj, 'StudyModeOverlay');

export const StudyModeOverlay = () => {
  const dispatch = useDispatch();
  const isStudyModeOn = useSelector(getV2IsStudyModeOn);
  const onClickOutside = useCallback((e) => {
    // have to use timeout - otherwise clicks on StudyMode switch won't work
    setTimeout(() => {
      dispatch(setStudyModeTooltipClosed());
    }, 0);
  }, [dispatch]);

  const onClick = useCallback(
    () => {
      // setTimeout(() => {
      dispatch(setStudyModeTooltipOpen());
      // }, 0);
    },
    [dispatch],
  );

  return isStudyModeOn ? (
    <ClickOutside
      onClick={onClick}
      onClickOutside={onClickOutside}
      className={styles.wrapper}
    />
  ) : null;
};

export default StudyModeOverlay;
