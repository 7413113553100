import React from 'react';
import { connect } from 'react-redux';

import Link from 'oc-core-components/src/Link';

import { getV2ImpersonatingUserId } from 'v2/redux/meta/selectors';
import { number } from 'prop-types';
import { ROUTES } from 'helpers/constants';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ImpersonatingUserContainer.module.scss';

const styles = stylesProxy(stylesObj, 'ImpersonatingUserContainer');

export const ImpersonatingUserContainer = ({
  impersonatingUserId,
}) => {
  return impersonatingUserId ? (
    <div className={styles.wrapper}>
      {impersonatingUserId}
      {' '}
      <Link className={styles.link} href={ROUTES.stopImpersonatingUrl}>
        Stop Impersonating
      </Link>
    </div>
  ) : null;
};

ImpersonatingUserContainer.propTypes = {
  impersonatingUserId: number,
};


const mapStateToProps = state => ({
  impersonatingUserId: getV2ImpersonatingUserId(state),
});

export default connect(mapStateToProps)(ImpersonatingUserContainer);
