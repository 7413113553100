const RECEIVE_DEPARTMENT_DATA = 'common/department/RECEIVE_DEPARTMENT_DATA';
const GET_DEPARTMENTS = 'common/department/GET_DEPARTMENTS';
const GET_DEPARTMENTS_SUCCESS = 'common/department/GET_DEPARTMENTS_SUCCESS';
const GET_DEPARTMENTS_ERROR = 'common/department/GET_DEPARTMENTS_ERROR';
const GET_DEPARTMENT = 'common/department/GET_DEPARTMENT';
const GET_DEPARTMENT_SUCCESS = 'common/department/GET_DEPARTMENT_SUCCESS';
const GET_DEPARTMENT_ERROR = 'common/department/GET_DEPARTMENT_ERROR';

export {
  RECEIVE_DEPARTMENT_DATA,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_DEPARTMENT,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
};
