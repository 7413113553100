import React from 'react';
import { string } from 'prop-types';
import { Tabs as ReactTabs } from 'react-tabs';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Tabs.module.scss';

const styles = stylesProxy(stylesObj, 'Tabs');

const propTypes = {
  className: string,
  disabledTabClassName: string,
  selectedTabClassName: string,
};

// const defaultProps = {
//   disabledTabClassName: styles['is-disabled'],
//   selectedTabClassName: styles['is-active'],
// };

const Tabs = (props) => {
  const {
    className,
    ...restProps
  } = props;

  return (
    <ReactTabs className={className} {...restProps} />
  );
};

Tabs.propTypes = propTypes;
// Tabs.defaultProps = defaultProps;


export default Tabs;
