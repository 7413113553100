import React from 'react';
import { bool, string } from 'prop-types';
import cn from 'classnames';
import { TabList as ReactTabList } from 'react-tabs';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Tabs.module.scss';

const styles = stylesProxy(stylesObj, 'Tabs');

const propTypes = {
  className: string,
  fullWidth: bool,
  underlined: bool,
  small: bool,
};

const defaultProps = {
  fullWidth: false,
  underlined: false,
};

const TabList = (props) => {
  const {
    className,
    fullWidth,
    underlined,
    small,
    ...restProps
  } = props;

  const classes = cn(
    className,
    styles['tabs-list'],
    {
      [styles.full]: fullWidth,
      [styles.underlined]: underlined,
      [styles.sm]: small,
    },
  );

  return (
    <ReactTabList className={classes} {...restProps} />
  );
};

TabList.defaultProps = defaultProps;
TabList.propTypes = propTypes;
TabList.tabsRole = 'TabList';

export default TabList;
