import { useTranslation } from 'config/i18n';
import { useSelector } from 'react-redux';
import { selectV2ModelByIdProp } from 'v2/redux/selectors';
import { categoriesByKind } from 'oc_components/src/pages/Search/utils';

export default function useSearchBannerTitle(filters) {
  const { t } = useTranslation('search');

  const {
    kind,
    courseId,
    schoolId,
    departmentId,
    countryId,
    professorId,
  } = filters || {};

  const category = categoriesByKind[kind];
  const categoryI18n = category?.labelI18n;

  const kindLabel = categoryI18n ? t(categoryI18n) : t('search:study_materials');


  const country = useSelector(state => selectV2ModelByIdProp.country(state, { countryId }));
  const school = useSelector(state => selectV2ModelByIdProp.school(state, { schoolId }));
  const department = useSelector(state => selectV2ModelByIdProp.department(state, { departmentId }));
  const course = useSelector(state => selectV2ModelByIdProp.course(state, { courseId }));
  const professor = useSelector(state => selectV2ModelByIdProp.professor(state, { professorId }));

  const i18nData = t('umbrella_search_title', {
    returnObjects: true,
    kind: kindLabel,
    country: country?.name,
    schoolName: school?.name,
    schoolShortName: school?.shortenedName || school?.name,
    state: school?.stateName || school?.location,
    department: department?.name,
    professorName: professor?.name,
    courseCode: course?.code,
  });

  // if (!filters || !kindLabel) return {};

  if (!school && !course) {
    if (country) {
      return i18nData.with_country;
    }

    return i18nData.default[kind || 'default'];
  }

  if (school && course && professor) {
    return i18nData.with_professor;
  }

  if (school && course) {
    return i18nData.with_course;
  }

  if (school && !course && !department) {
    return i18nData.with_school;
  }


  if (school && department) {
    return i18nData.with_department;
  }

  return {};
}
