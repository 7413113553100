import React from 'react';
import { string } from 'prop-types';
import { IOS_APP_URL } from 'helpers/constants';
import { Trans } from 'config/i18n';

import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';
import Button from 'oc-core-components/src/Button';

import Link from 'oc-core-components/src/Link';
import { useInView } from 'react-intersection-observer';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './AppBanner.module.scss';

const styles = stylesProxy(stylesObj, 'AppBanner');


const AppBanner = ({
  className,
  ...restProps
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <>
      <div ref={ref} />
      {
        inView ? (
          <Link className={cn(styles.banner, className)} {...restProps}>
            <Icon
              className="u-mb-xxs"
              icon="logo:big"
              stroke="none"
              width={90}
            />

            <div className={styles.text}>
              <Trans i18nKey="common:app_banner.text">
                get the OneClass app<br />for a better experience.
              </Trans>
            </div>

            <Button
              className={styles.button}
              color="apple-dark"
              icon="oc:apple-logo"
              iconSize={18}
              iconStroke="none"
            >
              <div>
                <Trans i18nKey="common:app_banner.button">
                  Download on the
                  <span className={styles.button__text}>App Store</span>
                </Trans>
              </div>
            </Button>

          </Link>
        ) : null
      }
    </>
  )
};

AppBanner.propTypes = {
  href: string,
};

AppBanner.defaultProps = {
  href: IOS_APP_URL,
};

export default AppBanner;
