// NOTE: This require will be replaced with `@sentry/browser` when
// client side thanks to the webpack config in next.config.js
import getEnv from 'shared/helpers/utils/getEnv';

const env = getEnv();

const Sentry = require('@sentry/node');

const isProd = env.IS_PROD;
const isFeature = env.IS_FEATURE;
const isLocal = env.IS_LOCAL;

let environment = (isProd && !isFeature && !isLocal) ? 'production' : 'feature';
if (!isProd && !isFeature) {
  environment = 'development';
}

const DSN = 'https://af78b90b2d0e46ef87ac5ab3359b649d@sentry.io/1455349';

const logDetails = (err, ctx) => {
  console.log('Error details', { err, ctx });
  console.error('Exception was sent to Sentry', err);
};

const sentryOptions = {
  dsn: DSN,
  release: env.SENTRY_RELEASE,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  environment,
  integrations(integrations) {
    return (isProd ? integrations : []);
  },
};

// // When we're developing locally
// if (!isProd) {
//   /* eslint-disable-next-line global-require */
//   const sentryTestkit = require('sentry-testkit');
//   const { sentryTransport } = sentryTestkit();

//   // Don't actually send the errors to Sentry
//   sentryOptions.transport = sentryTransport;
// }

const initSentry = opts => Sentry.init({ ...sentryOptions, ...opts });

// console.log('INITED ==========================');
const prodCaptureException = (err, ctx) => {
  logDetails(err, ctx);

  const scope = new Sentry.Scope();

  if (err.message) {
    // De-duplication currently doesn't work correctly for SSR / browser errors
    // so we force deduplication by error message if it is present
    scope.setFingerprint([err.message]);
  }

  const errStatusCode = err && err.statusCode;
  const ctxtatusCode = ctx && ctx.statusCode;

  const statusCode = ctxtatusCode || errStatusCode;

  if (statusCode) {
    scope.setTag('statusCode', statusCode);
  }


  if (ctx) {
    const {
      req, res, errorInfo, query, pathname,
    } = ctx;

    if (res && res.statusCode) {
      scope.setTag('statusCode', res.statusCode);
    }

    if (typeof window !== 'undefined') {
      scope.setTag('ssr', false);

      scope.setContext('queryParams', {
        pathname,
        query,
      });

      // On client-side we use js-cookie package to fetch it
      // const sessionId = Cookie.get('sid');
      // if (sessionId) {
      //   scope.setUser({ id: sessionId });
      // }
    } else {
      scope.setTag('ssr', true);
      scope.setContext('req', {
        url: req.url,
        method: req.method,
        headers: req.headers,
        params: req.params,
        query: req.query,
      });

      // On server-side we take session cookie directly from request
      if (req && req.cookies && req.cookies.sid) {
        scope.setUser({ id: req.cookies.sid });
      }
    }

    if (errorInfo) {
      Object.keys(errorInfo).forEach(key => scope.setContext(key, errorInfo[key]));
    }

    console.log(scope, sentryOptions);
  }

  // console.log('SEND ===================', err, statusCode);
  return Sentry.captureException(err, scope);
};

const devCaptureException = (err, ctx) => {
  logDetails(err, ctx);
};

const captureException = isProd ? prodCaptureException : devCaptureException;
// const captureException = prodCaptureException;


export {
  Sentry,
  captureException,
  initSentry,
};
