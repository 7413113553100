import React from 'react';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import { ROUTES } from 'shared/helpers/constants';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import useAskQuestionModal from 'shared/helpers/hooks/useAskQuestionModal';
import useDocumentUploadHandler from 'helpers/hooks/useDocumentUploadHandler';
import { getV2IsLoggedIn } from 'v2/redux/meta/selectors';
import { useSelector } from 'react-redux';

import { stylesProxy } from 'helpers/css';
import { shape, string } from 'prop-types';
import { useMobile, useTablet } from 'oc-core-components/src/MediaQuery';
import { useTranslation } from 'config/i18n';
import stylesObj from './PromoLinks.module.scss';

const styles = stylesProxy(stylesObj, 'PromoLinks');


const PromoLinks = ({
  linksText,
  linksDirection,
  xxlGap,
  className,
}) => {
  const { t } = useTranslation('dashboard');
  const onAskQuestionClick = useAskQuestionModal();
  const isMobile = useMobile();
  const uploadProps = useDocumentUploadHandler();
  const isLoggedIn = useSelector(getV2IsLoggedIn);

  const content = [
    {
      uid: 'ask_question',
      icon: 'oc:ask',
      stroke: 'none',
      text: linksText.question,
      // subtext: t('answers_from_community'),
      color: 'question',
      onClick: onAskQuestionClick,
      col: { xs: 12, md: 4 },
      direction: linksDirection.question,
    },
    {
      uid: 'answer_question',
      icon: 'oc:conversation',
      stroke: 'none',
      text: linksText.answer,
      color: 'answer',
      href: isLoggedIn ? ROUTES.hwAnswers : ROUTES.login,
      col: { xs: 6, md: 4 },
      direction: linksDirection.answer,
    },
    {
      uid: 'your_questions',
      icon: 'sf:ask',
      stroke: 'none',
      text: linksText.yourQuestions,
      color: 'upload',
      href: isLoggedIn ? ROUTES.hwYourQuestions : ROUTES.login,
      col: { xs: 6, md: 4 },
      direction: linksDirection.yourQuestions,
    },
    {
      uid: 'upload',
      icon: 'oc:upload',
      stroke: 'none',
      text: linksText.upload,
      color: 'upload',
      col: { xs: 6, md: 4 },
      direction: linksDirection.upload,
      ...uploadProps,
    },
  ];

  return (
    <Row xsGap="xs" lgGap="m" xxlGap={xxlGap} className={className}>
      {content.map(({
        uid, text, href, color, icon, stroke, col, onClick, direction, subtext,
      }, indx) => {
        const directionClassName = direction !== 'default' ? styles[direction] : undefined;

        return text && (
          <Col key={uid} {...col} className={cn({ 'u-mt-xs u-mt-0-md': indx > 0 })}>
            <Link
              onClick={onClick}
              href={href}
              className={cn(
                styles.item,
                color && styles[color],
                {
                  [styles.mobile]: isMobile && indx > 0,
                  [directionClassName]: !isMobile && direction !== 'default',
                },
              )}
            >
              <div className={styles['icon-wrapper']}>
                <Icon
                  className={styles.icon}
                  icon={icon}
                  stroke={stroke}
                  size={18}
                />
              </div>
              <div className={styles.text}>
                {text}
                {subtext && <span className={styles.subtext}>{subtext}</span>}
              </div>
            </Link>
          </Col>
        );
      })
    }
    </Row>
  );
};

PromoLinks.defaultProps = {
  linksText: {
    question: 'Ask a question for free',
    answer: 'Answer questions',
    upload: 'Upload documents',
  },
  linksDirection: {
    question: 'default',
    answer: 'default',
    upload: 'default',
  },
  xxlGap: 'm',
};

PromoLinks.propTypes = {
  linksText: shape(),
  linksDirection: shape(),
  xxlGap: string,
};

export default PromoLinks;
