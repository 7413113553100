const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
const digits = '0123456789'.split('');

const startWithValues = [
  ...alphabet,
  ...digits,
];

export {
  startWithValues,
};
