
import createAction from 'v2/redux/utils/createAction';
import types from './types';

export const startLoading = createAction(types.LOADING.START, () => true, ({ id, error }) => ({ id, error }));
export const stopLoading = createAction(types.LOADING.STOP, () => false, ({ id, error }) => ({ id, error }));

export const resetLoading = createAction(types.LOADING.RESET, ({ id, error }) => ({ id, error }));

export const createLoadingActions = id => ({
  start: props => startLoading({ id, ...props }),
  stop: props => stopLoading({ id, ...props }),
  reset: props => resetLoading({ id, ...props }),
});
