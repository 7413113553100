import { handleModelActions } from 'v2/redux/utils/handleActions';
// import { logErrorDev } from 'helpers/utils';
// import types from './types';

import { DOWNLOAD_TOKEN_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const downloadTokenReducer = handleModelActions(
  DOWNLOAD_TOKEN_MODEL_TYPE,
  {

  },
  initialState,
);

export { initialState };

export default downloadTokenReducer;
