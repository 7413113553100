import {
  object, string, mixed, number as yupNumber, ref, boolean as yupBoolean,
} from 'yup';

import {
  INCORRECT_EMAIL, INCORRECT_USERNAME, REQUIRED_FIELD, SMALL_PASSWORD, INCORRECT_PHONE, INCORRECT_REFERRAL_ID,
} from 'helpers/validationMessages';

const rePhoneNumber = /^([\+][0-9]{1,3}([ \.\-])?)?([\(]{1}[0-9]{3}[\)])?([0-9A-Z \.\-]{1,32})((x|ext|extension)?[0-9]{1,4}?)$/;

export const phone = string().test(
  'phone',
  INCORRECT_PHONE,
  value => !value || rePhoneNumber.test(value),
);

export const reUsername = /^[a-z0-9-]*$/;

export const nickname = string().test(
  'nickname',
  INCORRECT_USERNAME,
  value => !value || reUsername.test(value),
).max(35, 'Maximum 35 characters');

export const referralId = string().test(
  'referral_id',
  INCORRECT_REFERRAL_ID,
  value => !value || reUsername.test(value),
).max(35, 'Maximum 35 characters');


export const requiredMap = obj => Object.keys(obj).reduce((acc, key) => {
  acc[key] = obj[key].required(REQUIRED_FIELD);
  return acc;
}, {});

// return a schema with mandatory fields
export const requiredShape = schema => object().shape(
  requiredMap(schema),
);

export const required = schema => schema.required(REQUIRED_FIELD);

export const autocomplete = object({
  label: string().nullable(),
  value: mixed(),
}).nullable();

export const email = string().email(INCORRECT_EMAIL);

// TODO: remove
export const number = yupNumber();

export const rating = number.min(1).max(5);

// TODO: remove
export const text = string();

export const password = string().min(6, SMALL_PASSWORD);

// TODO: remove
export const bool = yupBoolean();

export const stripe = mixed();
export const stripeRequired = mixed().required('Your card info is incomplete.');
// export const stripe = Yup.object({
//   empty: bool.when,
//   complete: bool,
// });
