import { createAction } from 'redux-actions';
import { loadingDocumentUnlockRequest } from 'v2/redux/page/documentShow/actions';
import { trackUnlockDocumentRequestComplete } from 'helpers/tracks';


import { postData } from '../api';
import {
  POST_ONE_TIME_PURCHASE,
  POST_ONE_TIME_PURCHASE_SUCCESS,
  POST_ONE_TIME_PURCHASE_ERROR,
  RESET_ONE_TIME_PURCHASE_ERROR,
  POST_PURCHASE,
  POST_PURCHASE_SUCCESS,
  POST_PURCHASE_ERROR,
  RESET_PURCHASE_ERROR,
  POST_CREATE_DOWNLOAD,
  POST_CREATE_DOWNLOAD_SUCCESS,
  POST_CREATE_DOWNLOAD_ERROR,
  RESET_CREATE_DOWNLOAD_ERROR,
} from './constants';

const postOneTimePurchase = createAction(POST_ONE_TIME_PURCHASE, payload => payload);
const postOneTimePurchaseSuccess = createAction(POST_ONE_TIME_PURCHASE_SUCCESS, (params, res) => ({ params, res }));
const postOneTimePurchaseError = createAction(POST_ONE_TIME_PURCHASE_ERROR, (params, error) => ({ params, error }));
const resetOneTimePurchaseError = createAction(RESET_ONE_TIME_PURCHASE_ERROR);
const postPurchase = createAction(POST_PURCHASE, payload => payload);
const postPurchaseSuccess = createAction(POST_PURCHASE_SUCCESS, (params, res) => ({ params, res }));
const postPurchaseError = createAction(POST_PURCHASE_ERROR, (params, error) => ({ params, error }));
const resetPurchaseError = createAction(RESET_PURCHASE_ERROR);
const postCreateDownload = createAction(POST_CREATE_DOWNLOAD, payload => payload);
const postCreateDownloadSuccess = createAction(POST_CREATE_DOWNLOAD_SUCCESS, (params, res) => ({ params, res }));
const postCreateDownloadError = createAction(POST_CREATE_DOWNLOAD_ERROR, (params, error) => ({ params, error }));
const resetCreateDownloadError = createAction(RESET_CREATE_DOWNLOAD_ERROR);

function performOneTimePurchase(params) {
  return function action(dispatch) {
    dispatch(loadingDocumentUnlockRequest.start());
    dispatch(postOneTimePurchase(params));


    const request = postData('/api/downloads', params);
    return request.then(
      ({ data }) => {
        dispatch(loadingDocumentUnlockRequest.stop());
        if (!data.success) {
          trackUnlockDocumentRequestComplete({
            allow: false,
          });

          return dispatch(postOneTimePurchaseError(params, { error: data.msg }));
        }

        trackUnlockDocumentRequestComplete({
          allow: true,
          single: true,
        });

        return dispatch(postOneTimePurchaseSuccess(params, { res: data }));
      },
      () => {
        dispatch(postOneTimePurchaseError(params, { error: 'A one time purchase error occurred' }));
        dispatch(loadingDocumentUnlockRequest.stop());
      },
    );
  };
}

function performPurchase(documentId) {
  return function action(dispatch) {
    dispatch(loadingDocumentUnlockRequest.start());
    const params = { documentId };
    dispatch(postPurchase(params));
    const request = postData('/api/document_unlocks.json', { document_id: documentId });
    return request.then(
      ({ data }) => {
        dispatch(loadingDocumentUnlockRequest.stop());
        if (!data.id) {
          trackUnlockDocumentRequestComplete({
            allow: false,
            single: false,
          });

          return dispatch(postPurchaseError(params, { error: data.msg }));
        }
        trackUnlockDocumentRequestComplete({
          allow: true,
          single: false,
        });
        return dispatch(postPurchaseSuccess(params, { res: data }));
      },
      () => {
        dispatch(postPurchaseError(params, { error: 'A purchase error occurred' }));
        dispatch(loadingDocumentUnlockRequest.stop());
      },
    );
  };
}

function createDownload(documentId) {
  return function action(dispatch) {
    const params = { documentId };
    dispatch(postCreateDownload(params));
    const request = postData('/api/downloads', { document_id: documentId });
    return request.then(
      ({ data }) => {
        if (!data?.success) {
          return dispatch(postCreateDownloadError(params, { error: data.msg || 'A download error occurred' }));
        }
        return dispatch(postCreateDownloadSuccess(params, { res: data }));
      },
      () => dispatch(postCreateDownloadError(params, { error: 'A download error occurred' })),
    );
  };
}

export {
  performOneTimePurchase,
  performPurchase,
  createDownload,
  resetPurchaseError,
  resetOneTimePurchaseError,
  resetCreateDownloadError,
};
