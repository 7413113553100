import React from 'react';
import { numberString } from 'types';
import { string, shape } from 'prop-types';

const LazyImage = ({
  src,
  width,
  height,
  alt,
  ampSrcSet,
  ampProps,

  children,
  className,
  ampLayout,
}) => (
  <amp-img
    alt={alt}
    src={src}
    srcset={ampSrcSet}
    width={width}
    height={height}
    layout={ampLayout}
    className={className}
    {...ampProps}
  >
    {children}
  </amp-img>
);

LazyImage.propTypes = {
  ampLayout: string,
  src: string,
  width: numberString,
  height: numberString,
  alt: string,
  ampSrcSet: string,
  ampProps: shape(),
};

LazyImage.defaultProps = {
  ampLayout: 'responsive',
};

export default LazyImage;
