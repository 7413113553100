import { takeEvery, put } from 'redux-saga/effects';
import { receiveDocumentData } from 'common/document/actions';
import {
  receiveNotification,
  clearNotification,
} from 'common/notification/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { receiveDownloadTokenData } from 'common/downloadToken/actions';
import { getDataByType } from 'common/utils';
import { resetPurchaseError } from './actions';
import {
  POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS,
  POST_PURCHASE_DOWNLOAD_TOKEN_ERROR,
} from './constants';

function* dispatchErrorNotification({ res: { error } }) {
  if (error) {
    yield put(receiveNotification({
      type: 'error',
      text: error,
    }));
    yield put(clearNotification());
  }
  yield put(resetPurchaseError());
}

function* updateDocument({ payload:{
  params: {
    documentId,
  },
  res: {
    /* eslint-disable camelcase */
    available_download_tokens_count,
    /* eslint-enable camelcase */
  },
}}) {
  yield put(receiveDocumentData({
    [documentId]: {
      attributes: {
        availableDownloadTokensCount: available_download_tokens_count,
      },
    },
  }));
}

function* storeDownloadTokenData({ res }) {
  const downloadTokens = getDataByType('downloadToken', res);
  if (downloadTokens) yield put(receiveDownloadTokenData(downloadTokens));
}

function* downloadTokenSaga() {
  yield takeEvery(POST_PURCHASE_DOWNLOAD_TOKEN_ERROR, dispatchErrorNotification);
  yield takeEvery(POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS, updateDocument);
  yield takeEvery(RECEIVE_PAGE_DATA, storeDownloadTokenData);
}

export default downloadTokenSaga;
