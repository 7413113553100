import React, { useCallback } from 'react';
import { bool, shape } from 'prop-types';
import RecentSearchContainer from 'components/pages/Search/RecentSearchContainer';
import SearchForm from 'oc_components/src/pages/SearchNew/SearchForm';
import useInitialData from 'oc_components/src/pages/SearchNew/SearchGroup/useInitialData';
import MobileSearchPopover from 'oc_components/src/pages/SearchNew/MobileSearchPopover';
import { getFieldsByKind } from 'oc_components/src/pages/SearchNew/SearchGroup/fields';
import SearchBanner from 'oc_components/src/pages/SearchNew/SearchBanner';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import { useSelector } from 'react-redux';
import LoadingBar from 'oc-core-components/src/LoadingBar';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import useSearch from 'oc_components/src/pages/Search/useSearch';
import useSearchBannerTitle from 'oc_components/src/pages/Search/useSearchBannerTitle';
import { isEmpty } from 'helpers/utils';

const SearchBannerContainer = ({
  withRedirect,
  redirectParams,
  withTitles,
  searchData,
  hideRecentSearches,
  standalone,
  onClearButtonClick,
  submitText,
  submitProps,
  ...restProps
}) => {
  const {
    filters,
    setFilter,

    showUserCategories,
    userCategories,
    activeUserCategory,
    onUserCategoryChange,
    sideFiltersData,
    fallbackTopics,
  } = useSearch({ standalone, ...searchData });

  const titleData = useSearchBannerTitle(filters);

  const {
    kind,
  } = filters || {};

  const categoryValue = activeUserCategory?.value;


  const isMobile = useMobile();
  const fieldTypes = getFieldsByKind(kind, categoryValue);

  const userSubjects = sideFiltersData?.topics?.items || fallbackTopics;
  const currentSubject = userSubjects?.find(({ value }) => filters?.subject === value);

  const initialData = useInitialData({
    ...filters, category: categoryValue, currentSubject,
  });
  const submitHandler = useCallback(
    values => setFilter(values, null, withRedirect, redirectParams),
    [withRedirect, setFilter, redirectParams],
  );

  const currentPage = useSelector(getV2CurrentPage);

  const content = (
    <div>
      <SearchForm
        // debug
        standalone={standalone}
        kind={kind}
        onSubmit={submitHandler}
        filters={fieldTypes}
        initialData={initialData}
        onClearButtonClick={onClearButtonClick}
        submitText={submitText}
        submitProps={submitProps}

        showUserCategories={showUserCategories}
        userCategories={userCategories}
        activeUserCategory={activeUserCategory}
        onUserCategoryChange={onUserCategoryChange}
        userSubjects={userSubjects}
      />

      {
        !hideRecentSearches && (
          <RecentSearchContainer className="u-d-none u-d-flex-lg" />
        )
      }
    </div>
  );

  return (

    <SearchBanner
      compact={!withTitles || !titleData || isEmpty(titleData)}
      {...(withTitles && titleData)}
      {...restProps}
    >
      <>
        {
          isMobile ? (
            <MobileSearchPopover
              toggle={content}
              onSubmit={submitHandler}
              filters={fieldTypes}
              initialData={initialData}
              className="u-d-block u-w-100"
              toggleClassName="u-d-block u-w-100"
              kind={kind}

              userCategories={userCategories}
              activeUserCategory={activeUserCategory}
              onUserCategoryChange={onUserCategoryChange}
              userSubjects={userSubjects}
            />
          ) : content
        }
      </>

      <LoadingBar bottom posOutside scope={currentPage} size={4} />
    </SearchBanner>

  );
};

SearchBannerContainer.propTypes = {
  withRedirect: bool,
  withTitles: bool,
  searchData: shape(),
  hideRecentSearches: bool,
  standalone: bool,
  redirectParams: shape(),
};

export default SearchBannerContainer;
