import typesCreator from 'v2/redux/utils/typesCreator';

export default typesCreator()(
  'CATCH_ERROR',
  'FETCH_API_EVERY',
  'FETCH_API_LATEST',
  'STORE_MODELS',
  'STORE_RELATIONSHIPS',
  'STORE_ROUTE_DATA',
  'STORE_MODEL_ATTRIBUTES',
  'SAVE_SEARCH_ATTEMPT',
  'TRACK_CLICK',
);
