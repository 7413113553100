const RECEIVE_COURSE_DATA = 'common/course/RECEIVE_COURSE_DATA';
const POST_RATE_COURSE = 'common/course/POST_RATE_COURSE';
const POST_RATE_COURSE_SUCCESS = 'common/course/POST_RATE_COURSE_SUCCESS';
const POST_RATE_COURSE_ERROR = 'common/course/POST_RATE_COURSE_ERROR';
const RESET_POST_RATE_COURSE_ERROR = 'common/course/RESET_POST_RATE_COURSE_ERROR';
const GET_COURSE_SUGGESTIONS = 'common/course/GET_COURSE_SUGGESTIONS';
const GET_COURSE_SUGGESTIONS_SUCCESS = 'common/course/GET_COURSE_SUGGESTIONS_SUCCESS';
const GET_COURSE_SUGGESTIONS_ERROR = 'common/course/GET_COURSE_SUGGESTIONS_ERROR';
const GET_COURSES = 'common/course/GET_COURSES';
const GET_COURSES_SUCCESS = 'common/course/GET_COURSES_SUCCESS';
const GET_COURSES_ERROR = 'common/course/GET_COURSES_ERROR';
const GET_COURSE = 'common/course/GET_COURSE';
const GET_COURSE_SUCCESS = 'common/course/GET_COURSE_SUCCESS';
const GET_COURSE_ERROR = 'common/course/GET_COURSE_ERROR';
const REMOVE_COURSE = 'common/course/REMOVE_COURSE';
const REMOVE_COURSE_SUCCESS = 'common/course/REMOVE_COURSE_SUCCESS';
const REMOVE_COURSE_ERROR = 'common/course/REMOVE_COURSE_ERROR';
const RESET_REMOVE_COURSE_ERROR = 'common/course/RESET_REMOVE_COURSE_ERROR';
const RESET_REMOVE_COURSE_SUCCESS = 'common/course/RESET_REMOVE_COURSE_SUCCESS';

const emptyCourseOption = {
  value: '',
  label: 'Choose Course',
};

export {
  RECEIVE_COURSE_DATA,
  POST_RATE_COURSE,
  POST_RATE_COURSE_SUCCESS,
  POST_RATE_COURSE_ERROR,
  RESET_POST_RATE_COURSE_ERROR,
  GET_COURSE_SUGGESTIONS,
  GET_COURSE_SUGGESTIONS_SUCCESS,
  GET_COURSE_SUGGESTIONS_ERROR,
  emptyCourseOption,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  REMOVE_COURSE,
  REMOVE_COURSE_SUCCESS,
  REMOVE_COURSE_ERROR,
  RESET_REMOVE_COURSE_ERROR,
  RESET_REMOVE_COURSE_SUCCESS,
};
