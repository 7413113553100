import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { useTranslation } from 'config/i18n';
import { ROUTES } from 'helpers/constants';
import { stylesProxy } from 'helpers/css';
import buildUrl from 'helpers/utils/buildUrl';
import { useAmp } from 'next/amp';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import Link from 'oc-core-components/src/Link';
import Loader from 'oc-core-components/src/Loader';
import { string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearchOverlay } from 'v2/redux/page/actions';
import { getV2IsSearchOverlayOpen } from 'v2/redux/page/selectors';
import NavbarSearchContainer from '../NavbarSearchContainer';
import { getCategoryIcon, getCategoryLabels } from '../utils';
import stylesObj from './NavbarSearchOverlay.module.scss';
import NavbarSearchOverlayAmp from './NavbarSearchOverlayAmp';
import NavbarSearchOverlayInput from './NavbarSearchOverlayInput';

const styles = stylesProxy(stylesObj, 'NavbarSearchOverlay');

const NavbarSearchOverlay = ({ id, placeholder, type }) => {
  const isAmp = useAmp();
  const dispatch = useDispatch();
  const containerRef = useRef();
  const isSearchOverlayOpen = useSelector(getV2IsSearchOverlayOpen);
  const { t } = useTranslation('navbar');

  useEffect(() => {
    if (containerRef.current) {
      if (isSearchOverlayOpen) {
        disableBodyScroll(containerRef.current);
      } else {
        enableBodyScroll(containerRef.current);
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isSearchOverlayOpen]);

  const renderItem = (item, { value }) => {
    const categoryLabel = getCategoryLabels(item.data?.category);
    const url = item.data?.url || buildUrl(ROUTES.search, { query: value });

    return (
      <li key={item.data?.obj_index}>
        <Link className={styles.item} href={url}>
          <div className={styles.item__icon}>
            <Icon icon={getCategoryIcon(item.data?.category)} size={18} />
          </div>
          <div className={styles.item__label}>{item.value}</div>
          <div className={styles.item__kind}>
            {categoryLabel && t(categoryLabel)}
          </div>
        </Link>
      </li>
    );
  };

  if (isAmp) {
    return <NavbarSearchOverlayAmp id={id} />;
  }

  if (!isSearchOverlayOpen) {
    return null;
  }

  return (
    <NavbarSearchContainer allowBlur type={type}>
      {({
        inputValue,
        loading,
        onStateChange,
        onKeyDown,
        query,
        suggestions,
      }) => (
        <div className={styles.container}>
          <header className={styles.header}>
            <div className={styles.header__autocomplete}>
              <NavbarSearchOverlayInput
                initialValue={query}
                onKeyDown={onKeyDown}
                onStateChange={onStateChange}
                placeholder={placeholder}
                value={inputValue}
              />
            </div>
            <Button
              className={styles.header__cancel}
              color="none"
              onClick={() => dispatch(closeSearchOverlay())}
              size="sm"
            >
              Cancel
            </Button>
          </header>

          <ul className={styles['item-container']} ref={containerRef}>
            {suggestions.map((suggestion) =>
              renderItem(suggestion, { value: inputValue })
            )}
            {loading && (
              <li>
                <Loader iconSize={18} />
              </li>
            )}
          </ul>
        </div>
      )}
    </NavbarSearchContainer>
  );
};

NavbarSearchOverlay.propTypes = {
  id: string,
  placeholder: string,
  type: string,
};

export default NavbarSearchOverlay;
