import { getSchoolById, getSchoolsByCountry, getFreeTrialSchools } from 'common/api';
import { createAction } from 'redux-actions';

import {
  RECEIVE_SCHOOL_DATA,
  GET_SCHOOLS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_ERROR,
  GET_SCHOOL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
  GET_FREE_TRIAL_SCHOOLS,
  GET_FREE_TRIAL_SCHOOLS_SUCCESS,
  GET_FREE_TRIAL_SCHOOLS_ERROR,
} from './constants';

const receiveSchoolData = res => ({ type: RECEIVE_SCHOOL_DATA, res });

const getSchools = params => ({ type: GET_SCHOOLS, params });

const getSchoolsSuccess = res => ({ type: GET_SCHOOLS_SUCCESS, res });

const getSchoolsError = res => ({ type: GET_SCHOOLS_ERROR, res });

const getSchool = params => ({ type: GET_SCHOOL, params });

const getSchoolSuccess = res => ({ type: GET_SCHOOL_SUCCESS, res });

const getSchoolError = res => ({ type: GET_SCHOOL_ERROR, res });

const fetchFreeTrialSchools = createAction(GET_FREE_TRIAL_SCHOOLS);

const fetchFreeTrialSchoolsSuccess = createAction(GET_FREE_TRIAL_SCHOOLS_SUCCESS);

const fetchFreeTrialSchoolsError = createAction(GET_FREE_TRIAL_SCHOOLS_ERROR);

function retrieveSchoolsByCountry({ countryId, materialType, authToken }) {
  return function action(dispatch) {
    dispatch(getSchools({ countryId }));

    // IF authToken PROCEED
    // ELSE retrieveNewAuthToken THEN PROCEED

    const request = getSchoolsByCountry({ countryId, materialType, authToken });
    return request.then(
      ({ data }) => dispatch(getSchoolsSuccess([data])),
      () => dispatch(getSchoolsError({ error: 'retrieve schools by country error' })),
    );
  };
}

function retrieveSchoolById(Id, authToken) {
  const schoolId = Id ? Id.toString() : null;
  return function action(dispatch) {
    if (!schoolId) return dispatch(getSchoolError({ error: 'retrieve school by id error' }));

    dispatch(getSchool(schoolId));
    // IF authToken PROCEED
    // ELSE retrieveNewAuthToken THEN PROCEED
    return getSchoolById({ schoolId, authToken }).then(({ data }) => {
      const school = data?.data && data.data.find(dataItem => (dataItem?.id?.toString() === schoolId));
      if (school) {
        dispatch(getSchoolSuccess([data]));
      } else {
        dispatch(getSchoolError({ error: 'retrieve school by id error' }));
      }
    }, () => {
      dispatch(getSchoolError({ error: 'retrieve school by id error' }));
    });
  };
}

function retrieveFreeTrialSchools() {
  return function action(dispatch) {
    dispatch(fetchFreeTrialSchools());

    const request = getFreeTrialSchools();

    return request.then(
      res => dispatch(fetchFreeTrialSchoolsSuccess(res.data)),
      () => dispatch(fetchFreeTrialSchoolsError({ error: 'Something went wrong' })),
    );
  };
}


export {
  receiveSchoolData,
  retrieveSchoolsByCountry,
  retrieveSchoolById,
  retrieveFreeTrialSchools,
};
