const RECEIVE_SCHOOL_DATA = 'common/school/RECEIVE_SCHOOL_DATA';
const GET_SCHOOLS = 'common/school/GET_SCHOOLS';
const GET_SCHOOLS_SUCCESS = 'common/school/GET_SCHOOLS_SUCCESS';
const GET_SCHOOLS_ERROR = 'common/school/GET_SCHOOLS_ERROR';
const GET_SCHOOL = 'common/school/GET_SCHOOL';
const GET_SCHOOL_SUCCESS = 'common/school/GET_SCHOOL_SUCCESS';
const GET_SCHOOL_ERROR = 'common/school/GET_SCHOOL_ERROR';

export const GET_FREE_TRIAL_SCHOOLS = 'common/school/GET_FREE_TRIAL_SCHOOLS';
export const GET_FREE_TRIAL_SCHOOLS_SUCCESS = 'common/school/GET_FREE_TRIAL_SCHOOLS_SUCCESS';
export const GET_FREE_TRIAL_SCHOOLS_ERROR = 'common/school/GET_FREE_TRIAL_SCHOOLS_ERROR';

export {
  RECEIVE_SCHOOL_DATA,
  GET_SCHOOLS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_ERROR,
  GET_SCHOOL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
};
