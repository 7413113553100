import { handleModelActions } from 'v2/redux/utils/handleActions';
import { NOTIFICATION_MODEL_TYPE } from 'v2/redux/constants';
import { isNil } from 'helpers/utils';
// import {idlogErrorDev } from 'helpers/utils';
import types from './types';

const initialState = {
  data: {},
  page: 0,
  notifications: [],
  allLoaded: false,
};

const notificationReducer = handleModelActions(
  NOTIFICATION_MODEL_TYPE,
  {
    [types.STORE_NOTIFICATIONS]: (state, { payload }) => {
      const {
        notifications,
        page,
      } = payload || {};


      if (Array.isArray(notifications) && notifications?.length > 0) {
        notifications.forEach((item) => {
          const { id } = item;

          if (!isNil(id)) {
            state.data[id] = item;
            state.notifications.push(id);
          }
        });

        state.page = page;
      }
    },
    [types.UPDATE_ALL_LOADED]: (state, { payload }) => {
      state.allLoaded = !!payload;
    },
  },
  initialState,
);

export { initialState };

export default notificationReducer;
