import { takeEvery, put } from 'redux-saga/effects';
import {
  receiveCourseData,
  resetRemoveCourseError,
  resetRemoveCourseSuccess,
} from 'common/course/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { getDataByType } from 'common/utils';
import { warnOnce } from 'helpers/utils';
import {
  GET_COURSE_SUCCESS,
  GET_COURSES_SUCCESS,
  REMOVE_COURSE_ERROR,
  REMOVE_COURSE_SUCCESS,
} from './constants';

// TODO: remove res when all actions are updated with redux-actions
function* storeCourseData({ res, payload }) {
  if (res) warnOnce('DEPRECATED: "storeCourseData" replace res with payload when using redux-actions', true);
  const courses = getDataByType('course', payload || res);
  if (courses) yield put(receiveCourseData(courses));
}

function* resetRemoveError() {
  yield put(resetRemoveCourseError());
}

function* resetRemoveSuccess() {
  yield put(resetRemoveCourseSuccess());
}

function* courseSaga() {
  yield takeEvery([RECEIVE_PAGE_DATA, GET_COURSE_SUCCESS, GET_COURSES_SUCCESS], storeCourseData);
  yield takeEvery(REMOVE_COURSE_ERROR, resetRemoveError);
  yield takeEvery(REMOVE_COURSE_SUCCESS, resetRemoveSuccess);
}

export default courseSaga;
