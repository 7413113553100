import React, { Component, useCallback } from 'react';
import { tagType } from 'types';
// do not add dymanic import,
// since we are using a Downshift's static field (stateChangeTypes) here
import Downshift from 'downshift';
import { Portal } from 'react-portal';
import { isBrowser, isFunction, renderNode } from 'helpers/utils';

import {
  func,
  string,
  bool,
  oneOfType,
  shape,
  node,
} from 'prop-types';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Button from 'oc-core-components/src/Button';
import FormField from 'oc-core-components/src/FormField';

import Input from './Input';
import Menu from './Menu';


import {
  filterItem as defaultFitlerItem,
  filterItems,
  itemToString as defaultItemToString,
  itemsPropTypes,
  highlightMatches,
} from './utils';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Autocomplete.module.scss';

const styles = stylesProxy(stylesObj, 'Autocomplete');

const isSimpleSelect = props => props.select && !props.withSearch;

const defaultLabelText = 'Autocomplete Input';

const defaultRenderToItem = (item, { itemToString, inputValue }) => (
  <span
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: highlightMatches(itemToString(item), inputValue),
    }}
  />
);

const defaultRenderCancelButton = ({
  onClick,
}) => (
  <Button color="none" className={styles.cancel} onClick={onClick}>Cancel</Button>
);

const defaultRenderClearButton = ({
  onClick,
  className,
}) => (
  <Link onClick={onClick} className={cn(styles.clear, className)}>
    <Icon icon="oc:x-circle" stroke="none" size={16} />
  </Link>
);

const MultiselectBadge = ({
  item,
  onCloseClick,
}) => (
  <div className={styles.multiselect_badge} key={item.label}>
    {item.label}

    {
        defaultRenderClearButton({
          onClick: onCloseClick,
          className: styles.multiselect_badge__clear,
        })
      }
  </div>
);

const MultiSelectPseudoInput = ({
  items,
  resetItems,
  removeItem,
  autocompleteProps: {
    isOpen,
    openMenu,
    closeMenu,
  },
}) => {
  const onInputClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    return isOpen ? closeMenu() : openMenu();
  }, [isOpen, closeMenu, openMenu]);

  const onItemsReset = useCallback((e) => { e.stopPropagation(); e.preventDefault(); resetItems(); }, [resetItems]);

  return (
    <Row className={styles.multiselect_pseudo_input} onClick={onInputClick}>
      <Col className={styles.multiselect_input_row}>
        {
          items.map(item => (
            <MultiselectBadge
              key={item.label}
              item={item}
              onCloseClick={(e) => { e.preventDefault(); e.stopPropagation(); removeItem(item); }}
            />
          ))
        }
      </Col>
      <Col xs="auto">
        {
          defaultRenderClearButton({ onClick: onItemsReset })
        }
      </Col>
    </Row>
  );
};


@FormField()
class Autocomplete extends Component {
  static propTypes = {
    clearOnMouseUp: bool,
    selectOnMouseUp: bool,
    cssSize: string, // Input size
    disabled: bool,
    readonly: bool,
    error: bool,
    errorMessage: string,
    filterItem: func,
    handleStateChange: func,
    hideNotFound: bool,
    id: string,
    inputClassName: string,
    inputProps: shape(),
    inputWrapperClassName: string,
    inputFieldClassName: string,
    itemClassName: string,
    items: itemsPropTypes.isRequired,
    itemToString: func,
    label: oneOfType([string, func]),
    menuClassName: string,
    name: string,
    notFoundText: oneOfType([string, func]),
    onBlur: func,
    onFocus: func,
    onStateChange: func,
    optionsClassName: string,
    placeholder: string,
    renderItem: func,
    renderMenuFooter: func,
    renderCancelButton: func,
    renderClearButton: func,
    resetOptionText: node,
    select: bool,
    showResetOption: bool,
    withSearch: bool,
    disableFiltering: bool,
    optionProps: oneOfType([func, shape()]),
    iconName: string,
    iconClassName: string,
    disableLabel: bool,
    menuPortalRef: shape(),
    menuPortalSelector: string,
    menuWrapper: tagType,
    disableMenuStyles: bool,
    onEnterSelect: func,
    showClearButton: bool,
  }

  static defaultProps = {
    notFoundText: inputValue => `No results match "${inputValue}"`,
    itemToString: defaultItemToString,
    filterItem: defaultFitlerItem,
    renderCancelButton: defaultRenderCancelButton,
    renderClearButton: defaultRenderClearButton,
    resetOptionText: 'Clear',
    renderItem: defaultRenderToItem,
    items: [],
    inputProps: {},
    cancelButton: 'Cancel',
  }

  // constructor(props) {
  //   super(props);
  //   this.downshift = React.createRef();
  // }

  state = {
    focused: false,
    // showClear: false,
    itemsToShow: [],
    inputValue: this.props.initialInputValue || this.props.itemToString(this.props.selectedItem),
    // only for isMultiSelect mode
    selectedItems: [],
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      itemsToShow: prevItemsToShow,
      ...restPrevState
    } = prevState;

    return {
      itemsToShow: (isSimpleSelect(nextProps) || nextProps.disableFiltering)
        ? nextProps.items
        : filterItems(prevState.inputValue, nextProps.items, nextProps.filterItem),
      ...restPrevState,
    };
  }

  // this stateReducer required to keep menu in isOpen state
  // when user click on menuItem (to select multiple items in a row)
  // see https://codesandbox.io/s/W6gyJ30kn?file=/multi-downshift.js
  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          isOpen: true,
        };
      default:
        return changes;
    }
  };

  // only for isMultiSelect mode
  removeItem = (item, cb) => {
    const newItems = this.state.selectedItems.filter(i => i?.value !== item?.value);

    this.setState(
      ({ selectedItems }) => ({
        selectedItems: newItems,
      }),
      () => cb({ item, newItems }),
    );
  }

  // only for isMultiSelect mode
  addSelectedItem = (item, cb) => {
    const newItems = [...this.state.selectedItems, item];

    this.setState(
      ({ selectedItems }) => ({
        selectedItems: newItems,
      }),
      () => {
        cb({ item, newItems });
      },
    );
  }

  // only for isMultiSelect mode
  resetSelectedItems = (item, cb) => {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [],
      }),
      () => {
        cb({ item, newItems: [] });
      },
    );
  }

  componentDidUpdate(prevProps) {
    const { initialInputValue, inputValue } = this.props;

    if (prevProps.initialInputValue !== initialInputValue) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({ inputValue: initialInputValue || '' });
    }

    // if external inputValue prop is changed, update the internal one
    // if (inputValue === '' && prevProps.inputValue) {
    //   /* eslint-disable-next-line react/no-did-update-set-state */
    //   this.setState({ inputValue: '' });
    // }
  }


  onInputValueChange = (e) => {
    this.setState({ inputValue: this.props.inputValue || e.target.value });
  }

  onFocus = (e, autocompleteProps) => {
    const { onFocus, showMenuOnEmptyFocus } = this.props;

    const {
      openMenu,
      inputValue,
    } = autocompleteProps;

    this.setState({ focused: true /* showClear: true */ });

    if (showMenuOnEmptyFocus && !inputValue) {
      openMenu();
    }

    if (onFocus) onFocus(e);
  }

  onBlur = (e) => {
    const { onBlur } = this.props;

    this.setState({ focused: false });

    if (onBlur) onBlur(e);
  }

  handleStateChange = (changes, downshiftState) => {
    const {
      items,
      onStateChange,
      filterItem,
      select,
      withSearch,
      clearOnMouseUp,
      selectOnMouseUp,
      disableFiltering,
      onEnterSelect,
      onSelect,
      isMultiSelect,
    } = this.props;


    if (Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
      if (changes.isOpen) {
        this.setState({
          itemsToShow: (isSimpleSelect(this.props) || disableFiltering) ? items : filterItems(downshiftState.inputValue, items, filterItem),
        });
      }
    }

    if (Object.prototype.hasOwnProperty.call(changes, 'inputValue')) {
      const isMouseUp = changes.type === Downshift.stateChangeTypes.mouseUp;
      const filteredItems = disableFiltering ? items : filterItems(changes.inputValue, items, filterItem);
      const changeInputValue = !(clearOnMouseUp || selectOnMouseUp) && !select && !withSearch && isMouseUp;/* && filteredItems.length === 0 */

      if (isMouseUp && this.state.inputValue === '' && this.props.selectedItem) {
        downshiftState.clearSelection();
      } else {
        this.setState(prevState => ({
          itemsToShow: filteredItems,
          inputValue: changeInputValue
            ? prevState.inputValue : changes.inputValue,
        }));


        if (changeInputValue) {
          downshiftState.selectItem({ label: this.state.inputValue, value: this.state.inputValue });
        } else if (selectOnMouseUp && filteredItems.length && isMouseUp) {
          downshiftState.selectItem(filteredItems[0]);
        }
      }
    }

    if (Object.prototype.hasOwnProperty.call(changes, 'selectedItem')) {
      const isEnterPressed = changes.type === Downshift.stateChangeTypes.keyDownEnter;
      // console.log(changes, isFunction(onSelect));

      // if(showClear) {
      //   this.setState({ showClear: false, })
      // }

      if (isFunction(onSelect)) {
        if (!isMultiSelect) {
          onSelect(changes.selectedItem);
        } else if (this.state.selectedItems.find(i => i.value === changes.selectedItem?.value)) {
          this.removeItem(changes.selectedItem, ({ item, newItems }) => onSelect({ item, newItems }));
        } else {
          this.addSelectedItem(changes.selectedItem, ({ item, newItems }) => onSelect({ item, newItems }));
        }
      }

      if (isEnterPressed && isFunction(onEnterSelect)) {
        onEnterSelect(changes.selectedItem);
      }
    }


    if (typeof onStateChange === 'function') {
      onStateChange(changes, downshiftState);
    }
  }

  onCancel = (autocompleteProps) => {
    const { onBlur, onCancel, resetFields } = this.props;

    autocompleteProps.clearSelection();

    // this.setState({ showClear: false })
    const onCancelProps = resetFields?.reduce((acc, current) => {
      acc[current] = null;
      return acc;
    }, {}) || {};

    if (onBlur) {
      onBlur();
    }

    if (onCancel) {
      onCancel(onCancelProps);
    }
  }

  render() {
    /* eslint-disable prefer-const */
    let {
      id,
      error,
      errorMessage,
      items,
      cssSize,
      select,
      withSearch,
      placeholder,
      notFoundText,
      inputClassName,
      inputWrapperClassName,
      inputFieldClassName,
      optionsClassName,
      disabled,
      readonly,
      onStateChange,
      name,
      onFocus,
      onBlur,
      label,
      'aria-label': ariaLabel,
      handleStateChange: customHandleStateChange,
      inputProps,
      filterItem,
      itemToString,
      renderItem,
      hideNotFound,
      clearOnMouseUp,
      showResetOption,
      resetOptionText,
      menuClassName,
      itemClassName,
      initialInputValue,
      showCancelButton,
      // showClearOnFocus,
      showClearButton,
      renderCancelButton,
      optionProps,
      renderMenuFooter,
      iconName,
      iconClassName,
      disableLabel,
      children,
      className,
      focusClassName,
      menuPortalRef,
      menuPortalSelector,
      disableMenuStyles,
      menuWrapper,
      selectedItemClassName,
      highlightedItemClassName,
      onEnterSelect,
      renderClearButton,
      showEmptyMenu,
      renderAfterMenu,
      showMenuOnEmptyFocus,
      menuAbove,
      isMultiSelect,
      onEnter,
      ...restProps
    } = this.props;
    /* eslint-enable prefer-const */


    if (!select) {
      withSearch = false;
    }

    const {
      itemsToShow,
      inputValue,
      focused,
      // showClear,
    } = this.state;

    const {
      onIconClick,
      ...restInputProps
    } = inputProps;

    const input = autocompleteProps => ((isMultiSelect && this.state.selectedItems.length > 0) ? (
      <MultiSelectPseudoInput
        items={this.state.selectedItems}
        resetItems={() => this.resetSelectedItems(null, (item, newItems) => this.props.onSelect({ item, newItems }))}
        removeItem={item => this.removeItem(item, (i, newItems) => this.props.onSelect({ item: i, newItems }))}
        autocompleteProps={autocompleteProps}
      />
    ) : (
      <Input
        autocompleteProps={autocompleteProps}
        select={select}
        cssSize={cssSize}
        placeholder={placeholder}
        inputClassName={inputClassName}
        className={inputWrapperClassName}
        inputFieldClassName={inputFieldClassName}
        iconName={iconName}
        iconClassName={iconClassName}
        disabled={disabled}
        readonly={readonly}
        onChange={this.onInputValueChange}
        noFocus
        error={error}
        value={inputValue}
        // errorMessage={errorMessage}
        name={name}

        onFocus={e => this.onFocus(e, autocompleteProps)}
        onBlur={e => this.onBlur(e, autocompleteProps)}
        items={itemsToShow}
        aria-label={ariaLabel}
        onIconClick={e => (isFunction(onIconClick) ? onIconClick(e, autocompleteProps) : null)}
        onEnter={onEnter}
        {...restInputProps}
        // label={label}
      />
    ));

    const menuContainerEl = menuPortalRef?.current || (isBrowser && menuPortalSelector && document.querySelector(menuPortalSelector));

    return (
      <Downshift
        itemToString={itemToString}
        onStateChange={this.handleStateChange}
        id={id}
        // ref={this.downshift}
        stateReducer={isMultiSelect ? this.stateReducer : undefined}
        {...restProps}
      >
        {(autocompleteProps) => {
          const {
            isOpen,
            getLabelProps,
          } = autocompleteProps;

          // 'not found' text parameter can be either a (string -> string) function or just a string
          const notFound = typeof notFoundText === 'function' ? notFoundText(inputValue) : notFoundText;

          const ddIsVisible = isOpen && !disabled && !readonly && (
            (itemsToShow?.length > 0) || (notFound && !hideNotFound) || withSearch || showResetOption || showEmptyMenu
          );

          const renderMenu = () => (
            <Menu
              items={itemsToShow}
              withSearch={withSearch}
              autocompleteProps={autocompleteProps}
              notFoundText={notFound}
              renderItem={renderItem}
              renderFooter={renderMenuFooter}
              renderAfter={renderAfterMenu}
              hideNotFound={hideNotFound}
              optionsClassName={optionsClassName}
              itemClassName={itemClassName}
              showResetOption={showResetOption}
              resetOptionText={resetOptionText}
              className={menuClassName}
              optionProps={optionProps}
              inputValue={inputValue}
              wrapper={menuWrapper}
              noDefaultStyles={disableMenuStyles}
              selectedClassName={selectedItemClassName}
              highlihghtedClassName={highlightedItemClassName}
              menuAbove={menuAbove}
              selectedItems={this.state.selectedItems}
            />
          );

          return (
            <div className={cn(styles.container, className, focused && focusClassName)}>
              {
                !disableLabel && (
                  <label className="u-show-for-sr" {...getLabelProps()}>
                    {ariaLabel || placeholder || defaultLabelText}
                  </label>
                )
              }


              {
                (showCancelButton && isFunction(renderCancelButton)) || (showClearButton && isFunction(renderClearButton)) ? (
                  <Row className="u-align-items-center">
                    <Col>
                      {input(autocompleteProps)}
                    </Col>
                    <Col xs="auto">
                      {
                        showCancelButton && renderCancelButton({
                          onClick: () => {
                            this.onCancel(autocompleteProps);
                          },
                        })
                      }


                      {
                        showClearButton && (inputValue || this.props.selectedItem) && renderClearButton({
                          onClick: () => {
                            this.onCancel(autocompleteProps);
                          },
                        })
                      }


                      {/* {
                        (showClearOnFocus && showClear && inputValue) && renderClearButton({
                          onClick: () => {
                            this.onCancel(autocompleteProps);
                          },
                        })
                      } */}
                    </Col>
                  </Row>
                ) : input(autocompleteProps)
              }

              {ddIsVisible
                ? (
                  menuContainerEl ? (
                    <Portal node={menuContainerEl}>
                      {renderMenu()}
                    </Portal>
                  ) : renderMenu()
                )
                : null}

              {
                  renderNode(children, { autocompleteProps, isFocused: focused })
                }
            </div>
          );
        }}
      </Downshift>
    );
  }
}

export default Autocomplete;
