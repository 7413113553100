import { handleModelActions } from 'v2/redux/utils/handleActions';

import { LIVE_TUTORIAL_SECTION_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const liveTutorialSectionReducer = handleModelActions(
  LIVE_TUTORIAL_SECTION_MODEL_TYPE,
  {

  },
  initialState,
);

export { initialState };

export default liveTutorialSectionReducer;
