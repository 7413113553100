import React from 'react';
import { uidType } from 'types';
import Col from 'oc-core-components/src/Col';
import Row from 'oc-core-components/src/Row';


import BoosterClassCardContainer from 'components/pages/BoosterClass/BoosterClassCard';
import { arrayOf } from 'prop-types';

const PopularBoosterClasses = ({
  itemIds,
}) => {
  if (!itemIds?.length) return null;

  return (
    <Row>
      {
        itemIds?.map(itemId => (
          <Col xs="12" lg className="u-mb-m u-mb-0-lg" key={itemId}>
            <BoosterClassCardContainer
              boosterClassId={itemId}
            />
          </Col>
        ))
      }
    </Row>
  );
};


PopularBoosterClasses.propTypes = {
  itemIds: arrayOf(uidType),
};

export default PopularBoosterClasses;
