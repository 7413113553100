import React, { useMemo } from 'react';
import { getImage } from 'shared/helpers/utils';
import Tooltip from 'oc-core-components/src/TooltipPopper';
import LazyImage from 'oc-core-components/src/LazyImage';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './AmbassadorBadge.module.scss';

const styles = stylesProxy(stylesObj, 'AmbassadorBadge');


const AMBASSADOR_BADGE_IMG = getImage('/pages/profile/ambassador-badge.svg');

const AmbassadorBadge = ({
  top,
  right,
  bottom,
  left,

  iconWrapperSize,
  iconSize = 24,
  mobileIconWrapperSize,
  mobileIconSize = 18,
  className,
}) => {
  const isMobile = useMobile();
  const width = isMobile ? mobileIconWrapperSize : iconWrapperSize;
  const height = isMobile ? mobileIconWrapperSize : iconWrapperSize;
  const imgWidth = isMobile ? mobileIconSize : iconSize;
  const imgHeight = isMobile ? mobileIconSize : iconSize;

  const styleProps = useMemo(() => ({
    bottom, right, top, left, width, height, borderRadius: width, minWidth: width,
  }), [bottom, right, top, left, height, width]);
  const imgStyleProps = useMemo(() => ({
    width: imgWidth,
    minWidth: imgWidth,
    height: imgHeight,
  }), [imgWidth, imgHeight]);


  return (
    <div
      className={cn(styles.ambassador_badge_container, className)}
      style={styleProps}
    >
      <Tooltip
        dark
        placement="bottom"
        widthAuto
        disableOnMobile
        paddingSmall
        // targetClassName={styles.questionmark}
        target={(
          <LazyImage
            src={AMBASSADOR_BADGE_IMG}
            alt="Ambassador badge icon"
            disableLazy
            width={imgWidth}
            height={imgHeight}
            style={imgStyleProps}
          />
        )}
      >
        Ambassador
      </Tooltip>
    </div>
  );
};

AmbassadorBadge.propTypes = {};

export default AmbassadorBadge;
