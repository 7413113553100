import { createSelector } from 'reselect';
import { getSub } from 'common/notification/selectors';

export const selectNotificationsIds = state => getSub(state).notifications;
export const selectNotificationsData = state => getSub(state).data;
export const selectNotificationsAllLoaded = state => getSub(state).allLoaded;
export const selectNotificationLastPage = state => getSub(state).page;


export const selectNotifications = createSelector(
  [
    selectNotificationsIds,
    selectNotificationsData,
  ],
  (ids, data) => {
    try {
      if (Array.isArray(ids)) {
        return ids.map(id => data[id]);
      }

      return undefined;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
);
