import React, { useCallback } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import {
  ModalContainer, Modal, ModalOverlay,
} from 'components/pages/HomeworkHelp/_HWv2/SubjectModalWrapper';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import Button from 'oc-core-components/src/Button';
import { useTranslation, Trans } from 'config/i18n';
import AmazonGiftCard from 'hw_components/src/_HWv2/sidebar/ChallengeWidget/components/AmazonGiftCard';
import { ROUTES } from 'shared/helpers/constants';
import { postTrackSCEvents } from 'shared/common/api';
import { getV2TopBanner } from 'v2/redux/meta/selectors';
import { useMobile } from 'oc-core-components/src/MediaQuery';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SummerChallengeModal.module.scss';

const styles = stylesProxy(stylesObj, 'SummerChallengeModal');

export const ModalClose = ({ className, ...restProps }) => (
  <div className={styles.close_wrapper}>
    <Icon
      icon="feather:x"
      tag="button"
      role="button"
      aria-label="close popup"
      className={className}
      size={16}
      {...restProps}
    />
  </div>
);

const DEFAULT_SPOTS_LEFT = 957;

const SummerChallengeModal = () => {
  const modal = useModal();
  const { t } = useTranslation();
  const isMobile = useMobile();

  const { close } = modal;
  const bannerData = useSelector(getV2TopBanner);
  const { spotsLeft = DEFAULT_SPOTS_LEFT } = bannerData || {};

  const texts = t('summer_challenge', { returnObjects: true, count: spotsLeft });

  // const summerChallgePopupTexts = {
  //   "join_our": "Join our",
  //   "challenge_period": "1-MONTH",
  //   "summer_challenge": "SUMMER CHALLENGE",
  //   "and_pick_up": "and pick up",
  //   "new_subject": "A NEW SUBJECT!",
  //   "description": "Get a <1></1> Amazon gift card when you successfully complete it.",
  //   "how_it_works": "How it works",
  //   "bullet_1": "You will need to have an active <1></1> to begin the challenge.",
  //   "bullet_2": "Sign-in everyday to view a question or document or answer a question.",
  //   "bullet_3": "Share your study achievements with friends (by Facebook or twitter)",
  //   "bullet_4": "Complete one daily study task 30 days in a row. You can skip a day (max 3 skips) in case you cannot complete the daily task.",
  //   "spots_left": "{{count}} Spot left",
  //   "spots_left_plural": "{{count}} Spots left",
  //   "iam_in": "Interesting! I’m in",
  //   "not_for_now": "Not for now"
  // };
  const priceText = '$40.00';
  const priceTextMobile = '$40';
  const gradePlusText = 'Grade+ subscription';
  const gradePlusHref = ROUTES.gradePlusPurchaseSC;

  const handleOnClick = useCallback((event) => {
    postTrackSCEvents({ event });

    window.location.assign(gradePlusHref);
  }, [gradePlusHref]);

  const handleGradePlusClick = useCallback(() => {
    handleOnClick('grade_plus_link');
  }, [handleOnClick]);

  const handleIamInClick = useCallback(() => {
    handleOnClick('imin');
  }, [handleOnClick]);

  const handleNotForNow = useCallback(() => {
    postTrackSCEvents({ event: 'not_for_now' });
    close();
  }, [close]);


  // const spotsLeftText = t('summer_challenge.spots_left', { count: spotsLeft });

  return (
    <ModalContainer className={styles.modal__container}>
      <ModalOverlay className={styles.modal__overlay} onClick={handleNotForNow} />
      <div>
        <Modal
          // headerClassName={styles.header}
          // header={<ModalClose onClick={modal.close} />}
          className={cn(styles.wrapper, styles.modal__inner)}
          contentClassName={styles.content}
          // renderBefore={isMobile && renderMobileHeader}
        >
          <div className={styles.header}>
            <ModalClose className={styles.close} onClick={handleNotForNow} />

            <div className={styles.event_info}>
              <div className={styles.title}>
                <div className={styles.title__mark}>Spend $40</div>
                <div>Get $40 back</div>
                <div className={styles.title__mark}>in 30 days</div>
              </div>

              <div className={styles.subtitle}>
                That’s 1 month of free
                <br />
                unlimited access!
              </div>
            </div>
          </div>

          <div className={styles.card_wrapper}>
            <AmazonGiftCard className={styles.card} />
          </div>

          <div className={styles.footer}>
            <div className={styles.how_it_works}>
              <div className={styles.hiw_title}>{texts.how_it_works}</div>
              <ol className={styles.hiw_list}>
                <li className={styles.hiw_list__item}>
                  <Trans i18nKey="summer_challenge.bullet_1">
                    Get a monthly or yearly <Link onClick={handleGradePlusClick} className={styles.sub}>{{ gradePlusText }}</Link>
                  </Trans>
                </li>
                <li className={styles.hiw_list__item}>{texts.bullet_2}</li>
              </ol>
            </div>

            <div className={styles.hint}>
              {texts.hint}
            </div>

            <div className={styles.spots_left_wrapper}>
              <Trans i18nKey="summer_challenge.spots_left" count={spotsLeft}>
                <span className={styles.spots_left}>{{ spotsLeft }}</span> Spots left
              </Trans>
            </div>

            <Button
              className={styles.iam_in}
              size="lg"
              // href={gradePlusHref}
              onClick={handleIamInClick}
            >
              {texts.iam_in}
            </Button>

            <Link onClick={handleNotForNow} className={styles.not_for_now}>
              {texts.not_for_now}
            </Link>

          </div>
        </Modal>
      </div>
    </ModalContainer>
  );
};

SummerChallengeModal.propTypes = {};

export default SummerChallengeModal;
