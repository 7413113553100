
import React from 'react';
import { string, bool } from 'prop-types';

import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Dropdown.module.scss';

const styles = stylesProxy(stylesObj, 'Dropdown');

const DropdownItem = ({
  tag,
  bold,
  selected,
  highlighted,
  disabled,
  link,
  type,
  className,
  highlightedClassName,
  selectedClassName,
  noHover,
  ...props
}) => {
  const Tag = link ? 'a' : tag;
  const itemType = Tag === 'button' ? 'button' : type;

  return (
    <Tag
      className={
        cn(styles.item, {
          [styles.selected]: selected,
          [styles.bold]: bold,
          [styles.highlighted]: highlighted,
          [styles.disabled]: disabled,
          [styles['no-hover']]: noHover,
        },
        className,
        highlighted && highlightedClassName,
        selected && selectedClassName)
      }
      type={itemType}
      href={link}
      {...props}
    />
  );
};

DropdownItem.propTypes = {
  tag: string,
  selected: bool,
  highlighted: bool,
  disabled: bool,
  link: string,
  type: string,
};

DropdownItem.defaultProps = {
  tag: 'button',
};

export default DropdownItem;
