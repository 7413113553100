import { useCallback, useState } from 'react';
import { getImage } from 'shared/helpers/utils';

const PROCESSING_PLACEHOLDER_SRC = getImage('/pages/upload/thumbnail.png');
const errorSrc = PROCESSING_PLACEHOLDER_SRC;

// @example
//
// const { imgSrc, onImgError } = useImgFallback({ src: newThumbUrl || thumbnailUrl, fallback: getImage('/pages/upload/thumbnail.png') })
//
const useImgFallback = ({ src, fallback = errorSrc }) => {
  const [imgSrc, setImgSrc] = useState(src || fallback);
  const onImgError = useCallback(() => {
    if (imgSrc !== fallback) {
      setImgSrc(fallback);
    }
  }, [imgSrc, fallback]);

  return ({
    imgSrc,
    onImgError,
  });
};

export default useImgFallback;
