import React from 'react';
import { stylesProxy } from 'helpers/css';
import Link from 'oc-core-components/src/Link';
import Col from 'oc-core-components/src/Col';
import { string, node } from 'prop-types';
import stylesObj from './HomeSection.module.scss';

const styles = stylesProxy(stylesObj, 'HomeSection');

const HomeSection = ({
  children,
  title,
  subtitle,
  linkText,
  href,
  rightElement,
}) => (
  <div className="u-mt-2xl u-mt-3xl-xl">
    <div className={styles.top}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>

      <div>

        {linkText && (
        <Link
          href={href}
          className={styles.link}
        >
          {linkText}
        </Link>
        )}

        {rightElement && <>{rightElement}</> }

      </div>

    </div>

    {children}
  </div>
);

HomeSection.propTypes = {
  children: node,
  title: string,
  subtitle: string,
  linkText: string,
  href: string,
  rightElement: node,
};

export default HomeSection;
