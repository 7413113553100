import React from 'react';
import Icon from 'oc-core-components/src/Icon';

import Badge from 'hw_components/src/_HWv2/Badge';
import cn from 'classnames';

import { oneOf } from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './PointsBadge.module.scss';

const styles = stylesProxy(stylesObj, 'PointsBadge');

const SIZES = {
  xs: 'xs',
  sm: 'sm',
  // md: 'md',
};
const SIZES_ARRAY = Object.keys(SIZES);

const COLORS = {
  orange: 'orange',
  'grey-orange': 'grey-orange',
  grey: 'grey',
};
const COLORS_ARRAY = Object.keys(COLORS);

const getSizeProps = ({ size }) => ({
  [SIZES.xs]: {
    className: styles[`container--${size}`],
    iconSize: 10,
  },
  [SIZES.sm]: {
    className: styles[`container--${size}`],
    iconSize: 10,
  },
})[size];

const getColorProps = ({ color }) => ({
  [COLORS.orange]: {
    className: styles[`container--${color}`],
    icon: 'oc:coin-orange',
  },
  [COLORS['grey-orange']]: {
    className: styles[`container--${color}`],
    icon: 'oc:coin-orange',
  },
  [COLORS.grey]: {
    className: styles[`container--${color}`],
    icon: 'oc:coin-orange',
  },
})[color];

const PointsBadge = ({
  color,
  size,

  iconSize,
  iconClassName,
  iconStroke,
  pointsCount,
  addPlusSign,
  className,
}) => {
  const sizeProps = getSizeProps({ size });
  const colorProps = getColorProps({ color });

  return (
    <Badge
      className={cn(styles.container, sizeProps?.className, colorProps?.className, className)}
    >
      <div className="u-d-flex u-align-items-center">
        <Icon
          className={iconClassName}
          icon={colorProps?.icon}
          size={iconSize || sizeProps.iconSize}
          stroke={iconStroke}
        />

        <span className={styles.badge_points}>{addPlusSign ? '+' : ''}{pointsCount}</span>
      </div>
    </Badge>
  );
};

PointsBadge.propTypes = {
  color: oneOf(COLORS_ARRAY),
  size: oneOf(SIZES_ARRAY),
};

PointsBadge.defaultProps = {
  color: COLORS.orange,
  size: SIZES.sm,
  iconStroke: 'none',
  addPlusSign: false,
};

export default PointsBadge;
