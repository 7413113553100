
import { withFormik, yupToFormErrors } from 'formik';
import WithDebug from 'oc-core-components/src/WithDebug';
import { IS_DEV, isFunction } from 'helpers/utils';
import { withNotifications as withNotificationContext } from 'oc-core-components/src/NotificationProvider';

const getDefaultInitalData = (defaultInitialData, props) => {
  if(isFunction(defaultInitialData)) {
    return defaultInitialData(props);
  }

  return defaultInitialData;
} 

const defautlMapPropsToDebugData =({
  defaultInitialData,
  initialData,
  values,
  errors
}) => ({
  values,
  errors,
  initialData: { ...defaultInitialData, ...initialData },
});

// Default formik Enhancer
const formikWithDebug = (displayName, schema, {
  mapPropsToDebugData = defautlMapPropsToDebugData,
  defaultInitialData,
  withNotifications = true,
  ...restParams
} = {}) => {
  
  const formikEnhancer = withFormik({
    displayName,
    handleSubmit: (values, { props, setSubmitting }) => {
      try {
        if (typeof props.handleSubmit === 'function') {
          props.handleSubmit(values);
        }
      } catch (e) {
        if (withNotifications) {
          props?.notificationContext?.addErrorNotification();
        }
        setSubmitting(false);
        console.error(e);
      }
    },
    mapPropsToValues: (props) => {
      const defaultData = getDefaultInitalData(defaultInitialData, props);

      return { ...defaultData, ...props.initialData };
    },
    validationSchema: schema,
    // validate: (values, props) => {
    //   return schema
    //     .validate(values, { context: props.context || {} })
    //     .catch((err) => {
    //       throw yupToFormErrors(err);
    //     });
    // },
    ...restParams,
  });

  const resHOC = Component => (withNotifications ? withNotificationContext(formikEnhancer(Component)) : formikEnhancer(Component));


  if (!IS_DEV) return resHOC;

  return Component => resHOC(WithDebug(Component, {
    mapPropsToData: props => {
      const defaultData = getDefaultInitalData(defaultInitialData, props);

      return mapPropsToDebugData({ defaultInitialData: defaultData, ...props })
    },
  }));
};


export default formikWithDebug;
