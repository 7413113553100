import { createLoadingActions } from 'v2/redux/loading/actions';

import createAction from 'v2/redux/utils/createAction';
// import createActions from 'v2/redux/utils/createActions';
import types from './types';
import pageTypes from './page/types';

export const catchError = createAction(types.CATCH_ERROR, payload => payload, (payload, meta) => meta);

export const storeModels = createAction(types.STORE_MODELS, payload => ({ models: payload }), (payload, m = 'deepMerge') => ({ mergingStrategy: m }));
export const storeRelationships = createAction(types.STORE_RELATIONSHIPS);
export const storeV2RouteData = createAction(types.STORE_ROUTE_DATA);
export const saveSearchAttempt = createAction(types.SAVE_SEARCH_ATTEMPT);
export const trackClick = createAction(types.TRACK_CLICK);
export const updateChartData = createAction(pageTypes.UPDATE_CHART_DATA);
export const updateAvatar = createAction(pageTypes.UPDATE_AVATAR);

export const storeModelAttributes = createAction(
  types.STORE_MODEL_ATTRIBUTES,
  payload => payload,
  (payload, m = 'merge') => ({ mergingStrategy: m }),
);

// export const updateModelActions = createActions(
//   types.UPDATE_MODEL, {
//     SELF: [payload => payload, (payload, m = 'merge') => ({ mergingStrategy: m })],
//     ERROR: [payload => payload, (payload, m = 'merge') => ({ mergingStrategy: m })],
//   },
// );

const fetchV2RouteDataFactory = type => createAction(
  type,
  ({ loadingId, mergingStrategy, ...payload }) => payload,
  ({ loadingId, progressBar, mergingStrategy }) => ({
    actions: {
      callback: storeV2RouteData,
      loading: loadingId ? createLoadingActions(loadingId) : null,
    },
    progressBar,
    mergingStrategy,
  }),
);

export const fetchV2RouteDataLatest = fetchV2RouteDataFactory(types.FETCH_API_LATEST.SELF);
export const fetchV2RouteDataEvery = fetchV2RouteDataFactory(types.FETCH_API_EVERY.SELF);

export default {};
