import { createAction } from 'redux-actions';
import { IS_ONECLASS_PROD } from 'helpers/utils';

const createActionWrapper = (type, ...restArgs) => {
  if (!IS_ONECLASS_PROD) {
    if (!type) {
      console.error('Action type is not defined', type);
    } else if (typeof type !== 'string') {
      console.error('Incorrect action type', type);
    }
  }

  return createAction(type, ...restArgs);
};

export default createActionWrapper;
