import merge from 'lodash/merge';
import mergeable from 'redux-merge-reducers';

import {
  RECEIVE_SCHOOL_DATA,
  GET_SCHOOLS,
  GET_SCHOOLS_SUCCESS,
  GET_SCHOOLS_ERROR,
  GET_SCHOOL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_ERROR,
  GET_FREE_TRIAL_SCHOOLS_SUCCESS,
} from './constants';

const initialState = {
  data: {},
  loadingSchools: false,
  loadingSchool: false,
  requestedSchoolsByCountryId: [],
  freeTrialSchoolsIds: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SCHOOL_DATA:
      return merge({}, state, {
        data: action.res,
      });
    case GET_SCHOOLS: {
      let countryId = action?.params?.countryId;
      countryId = countryId && countryId.toString();
      const requestedSchoolsByCountryId = state.requestedSchoolsByCountryId.slice();

      // Store this countryId to prevent unnecessary requests
      if (countryId) {
        if (!requestedSchoolsByCountryId.includes(countryId)) {
          requestedSchoolsByCountryId.push(countryId);
        }
      }

      return Object.assign({}, state, {
        loadingSchools: true,
        requestedSchoolsByCountryId,
      });
    }
    case GET_SCHOOLS_SUCCESS:
      return Object.assign({}, state, {
        loadingSchools: false,
      });
    case GET_SCHOOLS_ERROR:
      return Object.assign({}, state, {
        loadingSchools: false,
      });
    case GET_SCHOOL:
      return Object.assign({}, state, {
        loadingSchool: true,
      });
    case GET_SCHOOL_SUCCESS:
      return Object.assign({}, state, {
        loadingSchool: false,
      });
    case GET_SCHOOL_ERROR:
      return Object.assign({}, state, {
        loadingSchool: false,
      });
    case GET_FREE_TRIAL_SCHOOLS_SUCCESS:
      return Object.assign({}, state, {
        freeTrialSchoolsIds: action.payload,
      });
    default:
      return state;
  }
};

export default mergeable(reducer);
