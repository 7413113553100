import shortid from 'shortid';
import normalize from 'json-api-normalizer';
import Cookies from 'universal-cookie';
import camelCase from 'lodash/camelCase';
import mapValues from 'lodash/mapValues';
import snakeCase from 'lodash/snakeCase';

import getEnv from 'shared/helpers/utils/getEnv';


import { uniqueIdsPaths as documentUniqueIdsPaths } from 'shared/common/document/utils';
import { uniqueIdsPaths as schoolUniqueIdsPaths, preprocess as schoolPreprocess } from 'shared/common/school/utils';
import { uniqueIdsPaths as liveTutorialSessionUniqueIdsPaths } from 'shared/v2/redux/liveTutorialSession/utils';
import { uniqueIdsPaths as boosterClassSkillUniqueIdsPaths } from 'shared/v2/redux/boosterClassSkill/utils';

import { path, assocPath } from 'ramda';
import { array, string } from 'yup';

import urljoin from 'url-join';

import getData from 'shared/v2/redux/utils/getData';

import { containerWidths } from 'shared/config';

// TODO: fix dependency cycle
import {
  HEADER_ID,
  ADWORDS_CONVERSION_LABEL,
  GA_DEV_ID,
  GA_PROD_ID,
  GTM_PROD_ID,
  GTM_FEATURE_ID,
  INTERCOM_DEV_APP_ID,
  INTERCOM_PROD_APP_ID,
  DOCUMENT_TYPE,
  GA_SUBSCRIBED_PROD_ID,
  GA_SUBSCRIBED_DEV_ID,
  URL_SUFFIX,
  ROUTES,
  COUNTRY_SUFFIXES,
  FACEBOOK_DEV_APP_ID,
  FACEBOOK_PROD_APP_ID,
  FACEBOOK_PROD_PIXEL_ID,
  FACEBOOK_DEV_PIXEL_ID,
} from './constants';

const getDataOpts = {
  mergingStrategy: 'deepMerge',
  withUniqueIds: {
    school: {
      paths: schoolUniqueIdsPaths,
      preprocess: schoolPreprocess,
    },
    document: {
      paths: documentUniqueIdsPaths,
    },
    liveTutorialSession: {
      paths: liveTutorialSessionUniqueIdsPaths,
    },
    boosterClassSkill: {
      paths: boosterClassSkillUniqueIdsPaths,
    },
  },
};

export const cookies = new Cookies();
export const getUniversalCookies = (serverCookies) => {
  let universalCookies = cookies;
  if (serverCookies) {
    universalCookies = new Cookies(serverCookies);
  }

  return universalCookies;
};

export const isBrowser = typeof window !== 'undefined';

export const normalizeData = data => normalize(data);

const warned = {};

export const isEmpty = obj => Object.keys(obj).length === 0;
export const isObject = obj => (obj !== null) && (typeof obj === 'object');

// Checks if `value` is `null` or `undefined`.
export const isNil = val => val == null;

export const isFunction = obj => obj instanceof Function;

export const isNumberString = obj => ['number', 'string'].includes(typeof obj);

const env = getEnv();

export const { IS_DEV } = env;
export const { IS_PROD } = env;
export const { IS_FEATURE } = env;
export const { IS_TEST } = env;
export const { IS_LOCAL } = env;
export const { IS_ONECLASS_PROD } = env;
export const { HH_SHOW_REPRESENTATIVE_PATH } = env;
export const API_PORT = env.RAILS_PORT;
export const { NODE_PORT } = env;
export const { DEBUG_RENDER } = env;
export const { ASSETS_URL } = env;
export const { ASSETS_PREFIX } = env;
export const { ELASTIC_SEARCH_URL } = env;


export const IMAGES_PATH = `${ASSETS_URL}/static/assets/images`;

// eslint-disable-next-line prefer-destructuring

export const RESTREAM_PLAYER_TOKEN = (IS_ONECLASS_PROD ? '0c0f8879518dcc39e48c4c160610bed8' : '9b191d1639d9304470ad0968c1037501');
export const RESTREAM_CHAT_TOKEN = (IS_ONECLASS_PROD ? 'fae90d56-29be-4558-9cd6-50a3a16a2753' : '33e9bb30-ef94-449d-bda9-851e3f43fccf');

// export const getGoogleAnalyticsId = () => (IS_ONECLASS_PROD ? GA_PROD_ID : GA_DEV_ID);
export const getGoogleAnalyticsId = () => (IS_PROD ? GA_PROD_ID : GA_DEV_ID);

export const getGTMId = () => {
  // if(IS_FEATURE) {
  //   return GTM_FEATURE_ID;
  // }

  if (IS_PROD) {
    return GTM_PROD_ID;
  }

  return '1';
};

export const getFacebookPixelId = () => (IS_ONECLASS_PROD ? FACEBOOK_PROD_PIXEL_ID : FACEBOOK_DEV_PIXEL_ID);

export const getFacebookApiId = () => ((IS_DEV || IS_FEATURE || IS_LOCAL) ? FACEBOOK_DEV_APP_ID : FACEBOOK_PROD_APP_ID);

export const getGASubcribedId = () => (IS_ONECLASS_PROD ? GA_SUBSCRIBED_PROD_ID : GA_SUBSCRIBED_DEV_ID);

export const getAdwordsConversionId = () => getGASubcribedId().concat(ADWORDS_CONVERSION_LABEL);

export const getIntercomId = () => (IS_PROD ? INTERCOM_PROD_APP_ID : INTERCOM_DEV_APP_ID);

export const getImage = imagePath => (imagePath ? urljoin(IMAGES_PATH, imagePath) : null);

export const addDebugData = (Component) => {
  if (DEBUG_RENDER) {
    Component.whyDidYouRender = true;
  }
};

export const warnOnce = (message, devOnly = false) => {
  if (!warned[message] && (!devOnly || IS_DEV)) {
    /* istanbul ignore else */
    if (typeof console !== 'undefined') {
      console.warn(message); // eslint-disable-line no-console
      // console.trace();
    }
    warned[message] = true;
  }
};

const createLogger = (logger, cb, devOnly) => (...args) => {
  if (!(devOnly && IS_ONECLASS_PROD)) {
    logger(...args);

    if (isFunction(cb)) {
      cb(...args);
    }
  }
};

/* eslint-disable no-console */
export const log = createLogger(console.log);

export const logError = createLogger(console.error);

export const logWarning = createLogger(console.warn);

export const logDev = createLogger(console.log, null, true);

export const logErrorDev = createLogger(console.error, null, true);

export const logWarningDev = createLogger(console.warn, null, true);
/* eslint-enable no-console */

export const generateIdObject = (item) => {
  const uid = shortid.generate();
  if (isObject(item)) {
    return { uid, ...item };
  }

  return {
    uid,
    data: item,
  };
};

export const getMaterialTypeFromPath = (asPath) => {
  let result = 'all-materials';
  DOCUMENT_TYPE.options.forEach(({ uid }) => {
    if (asPath.includes(uid)) result = uid;
  });
  return result;
};

// Map an object list and generate a unique id for each element
export const generateIDs = list => list.map(generateIdObject);

export const deepGenerateIDs = (v) => {
  if (!isObject(v)) {
    return v;
  }

  if (Array.isArray(v)) {
    return generateIDs(v.map(deepGenerateIDs));
  }

  const reducer = (acc, [key, value]) => ({ ...acc, [key]: deepGenerateIDs(value) });

  return Object.entries(v).reduce(reducer, {});
};


export const generateIDsByPaths = (obj, paths = []) => {
  const pathsSchema = array().of(string());
  if (!pathsSchema.isValid(paths)) {
    logError('Incorrect paths for: ', obj);
    return obj;
  }

  const pathsArrays = paths.map(p => p.split('/'));

  let result = obj;

  pathsArrays.forEach((p) => {
    const value = path(p, obj);
    if (value) {
      result = assocPath(p, deepGenerateIDs(value), result);
    } else {
      // logError('path not found: ', p, obj);
    }
  });

  return result;
};

/**
 * Returns a new object with the key/value pairs from `obj` that are not in the array `omitKeys`.
 */
export const omit = (obj, omitKeys) => {
  const result = {};
  if (isObject(obj)) {
    Object.keys(obj).forEach((key) => {
      if (omitKeys.indexOf(key) === -1) {
        result[key] = obj[key];
      }
    });
  }
  return result;
};

export const keyCodes = {
  esc: 27,
  space: 32,
  tab: 9,
  up: 38,
  down: 40,
  enter: 13,
};

export const genComponentData = (uid, type, data, meta) => ({
  uid,
  type,
  data,
  ...meta,
});

export const optimizedResize = () => {
  let callbacks = [];
  let running = false;

  const runCallbacks = () => {
    callbacks.forEach(callback => callback());
    running = false;
  };


  const resize = () => {
    if (!running) {
      running = true;
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else if (isBrowser) {
        setTimeout(runCallbacks, 66);
      }
    }
  };

  const addCallback = (callback) => {
    if (callbacks) {
      callbacks.push(callback);
    }
  };

  return {
    add: (callback) => {
      if (isBrowser) {
        if (!callbacks.length) {
          window.addEventListener('resize', resize);
        }
        addCallback(callback);
      }
    },

    clear: () => {
      if (isBrowser) {
        window.removeEventListener('resize', resize);
        callbacks = [];
      }
    },
  };
};

// Scrolling
export const scrollToTop = () => {
  if (isBrowser) window.scrollTo(0, 0);
};

export const getScrollTop = () => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  );
};

// Format numbers with commas
export const numberWithCommas = (x) => {
  if (!Number.isNaN(parseFloat(x))) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  return x;
};

export const stringToNumber = (str) => {
  if (typeof str === 'number' || typeof str !== 'string') return str;

  // remove fromating and transfrom to number
  const res = Number.parseFloat(str.replace(',', ''));

  if (Number.isNaN(res)) {
    warnOnce(`stringToNumber: incorrect input string: ${str}`);
    return str;
  }

  return res;
};

export const getSafeQuery = (q) => {
  try {
    return encodeURIComponent(q.trim().replace(/\s\s+/g, ' ')).replace('&', '');
  } catch (_error) {
    console.error(_error);
    return q;
  }
};


// TODO: review and removes; happens only on localhost
export const removeDoubleSlashFromUrl = url => url.replace(/([^:]\/)\/+/g, '$1');


// convert to Number if a value is a number string;
// Otherwise, return the original value
export const convertToNumber = val => (Number.isNaN(Number(val)) ? val : Number(val));

export const getPathFromUrl = url => url && url.split(/[?#]/)[0];


export const removeHashFromUrl = url => url && url.split(/[#]/)[0];

export const getApiPathFromUrl = url => urljoin('api', url);

export const isApiUrl = url => url && url.search(/^\/api/g) === 0;

export const getDocTypeLabelFromDocsType = (docsType) => {
  let result = 'Study Guides';
  if (docsType === '/class-notes') result = 'Class Notes';
  if (docsType === '/textbook-notes') result = 'Textbook Notes';
  return result;
};


export const buildUrlParams = (types = [], query = {}, defaultMap = {}) => {
  try {
    const filteredQuery = types.reduce((acc, type) => {
      const queryVal = convertToNumber(query[type]);
      return (queryVal !== undefined) ? { ...acc, [type]: queryVal } : acc;
    }, {});

    return {
      ...defaultMap,
      ...filteredQuery,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {};
  }
};

export const getInitialState = (query = {}) => {
  let { initialState } = query;
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable prefer-destructuring */
  if (!initialState && isBrowser) {
    initialState = window.__NEXT_DATA__ && window.__NEXT_DATA__.query && window.__NEXT_DATA__.query.initialState;
  }
  /* eslint-disable prefer-destructuring */
  /* eslint-enable no-underscore-dangle */

  return initialState;
};

export const dummyFunc = () => {};

export const createMarkup = markup => ({ __html: markup });
// TODO: consider library functions for this
export const sanitizeMarkup = markup => (markup ? markup.replace(/<\/?a[^>]*>/g, '') : markup);

// Check if all keys exist in the object
export const checkObjectFields = (keys, obj = {}, forbiddenValues = []) => {
  if (!Array.isArray(keys) || !isObject(obj)) {
    logWarning('Incorrect arguments: ', keys, obj);
    return false;
  }

  return !keys.some(key => !Object.prototype.hasOwnProperty.call(obj, key) || forbiddenValues.includes(obj[key]));
};

export const removeEmptySections = (sections) => {
  Object.keys(sections).forEach((sectionId) => {
    const currentEntity = sections[sectionId];
    if (currentEntity.type === 'title') return;

    const { props } = sections[sectionId];

    if (props) {
      Object.keys(props).forEach(key => (props[key] === undefined || props[key] === null
          || (Array.isArray(props[key]) && props[key].length === 0))
        && delete props[key]);

      if (isEmpty(props)) {
        sections[sectionId].props = undefined;
      }
    }
  });

  return sections;
};

// Sort Object key-value pairs alphabetically by keys
export const sortObjectKeys = (obj = {}) => Object.entries(obj)
  // Transform to pairs and sort elements alphabetically by keys
  .sort(([key1 = ''], [key2 = '']) => key1.toLowerCase().localeCompare(key2.toLowerCase()))
  // Transform to object
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});


// https://oneclass.atlassian.net/browse/OS-2798
export const formatRound = (num, withFormatting) => {
  if (!num || Number.isNaN(num)) return num;

  const N = num.toString();

  const digits = N.length > 5 ? 2 : 1;

  // return (parseInt(N.substr(0, digits), 10)) * (10 ** (N.length - digits));

  const rounded = (parseInt(N.substr(0, digits), 10)) * (10 ** (N.length - digits));

  return withFormatting ? numberWithCommas(rounded) : rounded;
};

const SI_SYMBOL = ['', ' thousand', ' million', 'G', 'T', 'P', 'E'];
const SI_SYMBOL_SHORTENED = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

const getShortenLargeNumberArgs = (argsArr = []) => {
  try {
    if (isObject(argsArr[0])) {
      return argsArr[0];
    }

    const [digits, useShortened, plus, disableRound] = argsArr;

    return {
      digits, useShortened, plus, disableRound,
    };
  } catch (e) {
    console.err(e);
    return {};
  }
};

export const shortenLargeNumber = (n, ...params) => {
  const {
    digits = 1,
    useShortened = false,
    plus = false,
    disableRound,
  } = getShortenLargeNumberArgs(params);

  const number = disableRound ? n : formatRound(n, false);

  // what tier? (determines SI symbol)
  const tier = Math.log10(number) / 3 | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  const suffix = useShortened ? SI_SYMBOL_SHORTENED[tier] : SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  const res = Number.isInteger(scaled) ? scaled : scaled.toFixed(digits);

  // format number and add suffix
  return res + suffix + (plus ? '+' : '');
};

export const removeTranslProps = (props = {}) => {
  if (!isObject(props)) return props;

  const {
    t,
    tReady,
    i18n,
    i18nOptions,
    lng,
    defaultNS,
    reportNS,
    ...restProps
  } = props;

  return restProps;
};

export const withSuffix = (url, suffix = URL_SUFFIX) => {
  if (typeof url === 'string') {
    return `${url}${suffix}`;
  }

  warnOnce(`Incorrect url type: ${url}`);

  return url;
};

export const getBaseRoute = (countryCode) => {
  const countryUrl = COUNTRY_SUFFIXES[countryCode];

  if (countryUrl) {
    return urljoin(ROUTES.allMaterials, countryUrl);
  }

  return withSuffix(ROUTES.allMaterials);
};

/**
 * Escape a regular expression string.
 *
 * @param  {string} str
 * @return {string}
 */
export const escapeString = str => str.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

export const triggerEvent = (el, type) => {
  if (isBrowser) {
    const e = document.createEvent('HTMLEvents');
    e.initEvent(type, false, true);
    el.dispatchEvent(e);
  }
};


/**
 * Generate Picture sources based on a fallback src string
 * @param {string} src - image src
 * @param {array} exts - a list of file extensions; the last is used for the fallback img, and excluded when generating sources for defaultFolder
 * @param {array} media - a list of media queries sizes for which we need to generate source, e.g. ['sm', 'md']
 * @param {object} folders - an object that maps mq sizes to the folder names, where images are stored on server
 * @param {object} mediaQueries - an object that maps mq breakpoints to the corresponding page widths in pixels
 * @param {array} folderNames - a set of all possible folder names
 * @param {string} defaultFolder - a default folder name to generate sources without media queries if more than one extension are provided
 * @example
 *    generateSources({
 *      src: 'http://new-docs-thumbs.oneclass.com.s3.amazonaws.com/doc_thumbnails/new_mobile/18421-class-notes-ca-wlu-bu111-lecture.jpg',
 *      exts: ['webp', 'jpg'],
 *      media: ['md'],
 *      folders: {
 *        md: 'mobile',
 *      },
 *      defaultFolder: 'new_mobile',
 *   })
 *   // return
 *   // [
 *   //   {
 *   //     "srcSet": "http://new-docs-thumbs.oneclass.com.s3.amazonaws.com/doc_thumbnails/mobile/18421-class-notes-ca-wlu-bu111-lecture.wepb",
 *   //     "media": "(max-width: 768px)"
 *   //   },
 *   //   {
 *   //     "srcSet": "http://new-docs-thumbs.oneclass.com.s3.amazonaws.com/doc_thumbnails/mobile/18421-class-notes-ca-wlu-bu111-lecture.jpg",
 *   //     "media": "(max-width: 768px)"
 *   //   },
 *   //   {
 *   //     "srcSet": "http://new-docs-thumbs.oneclass.com.s3.amazonaws.com/doc_thumbnails/mobile/18421-class-notes-ca-wlu-bu111-lecture.wepb"
 *   //   }
 *   // ]
 */

export const generateSources = ({
  src,
  exts = ['webp', 'jpg'],
  media: customMedia,
  folders,
  mediaQueries = containerWidths,
  folderNames,
  defaultFolder,
} = {}) => {
  try {
    if (typeof src !== 'string' || !Array.isArray(exts)) {
      console.error('Incorrect picture params:', src, exts);
      return null;
    }

    const generateMedia = !!(defaultFolder && folders);
    let imgPath;
    let filename;
    const sources = [];

    if (generateMedia) {
      const media = customMedia || Object.keys(folders);
      const fNames = folderNames || Object.values(folders);
      const foldersRegexp = [defaultFolder, ...fNames].join('|');

      const regexp = new RegExp(`^(.*)(\\/(${foldersRegexp})\\/)(.*)(\\..*)$`);
      /**
       * src: http://domain.com/path-to-folder/folder-name/filename.ext
       * matches:
       *  0: http://domain.com/path-to-folder/folder-name/filename.ext
       *  1: http://domain.com/path-to-folder
       *  2: folder-name
       *  3: /folder-name/
       *  4: filename
       *  5: .ext
      */

      const matches = src.trim().match(regexp);

      if (!matches || matches.length !== 6) {
        console.error('Incorrect image source:', src);
        return null;
      }

      imgPath = matches[1];
      filename = matches[4];

      // Generate sources for media queries
      if (Array.isArray(media)) {
        media.forEach((mq) => {
          if (mediaQueries[mq] && folders[mq]) {
            exts.forEach((ext) => {
              sources.push({
                srcSet: `${imgPath}/${folders[mq]}/${filename}.${ext}`,
                media: `(max-width: ${mediaQueries[mq]}px)`,
              });
            });
          }
        });
      }
    } else {
      const regexp = new RegExp('^(.*)(\\..*)$');
      /**
       * src: http://domain.com/path-to-folder/folder-name/filename.ext
       * matches:
       *  0: http://domain.com/path-to-folder/folder-name/filename.ext
       *  1: http://domain.com/path-to-folder/folder-name/filename
       *  2: .ext
      */

      const matches = src.trim().match(regexp);
      if (!matches || matches.length !== 3) {
        console.error('Incorrect image source:', src);
        return null;
      }

      imgPath = matches[1];
      filename = matches[4];
    }

    const sourcePath = generateMedia ? `${imgPath}/${defaultFolder}/${filename}` : imgPath;

    // Generate default source without the last extension, since it is used as a picture fallback in the img tag
    if (exts.length > 1) {
      for (let i = 0; i < (exts.length - 1); i++) {
        sources.push({
          srcSet: `${sourcePath}.${exts[i]}`,
          type: `image/${exts[i]}`,
        });
      }
    }

    return sources;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const replaceFolderName = (src, newFolder, folders) => {
  if (typeof src !== 'string' || !Array.isArray(folders)) {
    console.error('Incorrect replaceFolderName params:', src, folders);
    return src;
  }

  const foldersRegexp = folders.join('|');
  const regexp = new RegExp(`^(.*)(\\/(${foldersRegexp})\\/)(.*)$`);
  /**
   * src: http://domain.com/path-to-folder/folder-name/filename.ext
   * matches:
   *  0: http://domain.com/path-to-folder/folder-name/filename.ext
   *  1: http://domain.com/path-to-folder
   *  2: folder-name
   *  3: /folder-name/
   *  4: filename.ext
  */

  const matches = src.trim().match(regexp);

  if (!matches || matches.length !== 5) {
    return src;
  }

  return urljoin(matches[1], newFolder, matches[4]);
};

export const dataId = (name, tag = '') => `${tag}[data-test-id="${name}"]`;

export const reactWrapperDebug = reactWrapper => console.log(reactWrapper.debug());

export const toggleBodyClass = (toggle, className, includeHtml = true) => {
  const elements = includeHtml ? 'html, body' : 'body';

  if (isBrowser) {
    const nodes = document.querySelectorAll(elements);

    const func = toggle ? 'add' : 'remove';

    for (let i = 0; i < nodes.length; i++) {
      nodes[i].classList[func](className);
    }
  }
};

export const createListItem = (label, url, props) => ({
  label, url, ...props,
});

export const tryGetJson = async resp => new Promise((resolve) => {
  if (resp) {
    resp.json().then(json => resolve(json)).catch(() => resolve(null));
  } else {
    resolve(null);
  }
});

export const getDynamicImportProps = ({ ssr = false, loading, ...rest } = {}) => ({
  ssr,
  loading: (props) => {
    if (props.error) {
      console.error(props.error);
    }

    if (isFunction(loading)) {
      return loading(props);
    }

    return null;
  },
  ...rest,
});


export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  }

  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

export function* cycleThrough(arr) {
  let index = 0;
  while (true) {
    yield arr[index];
    index = (index + 1) % arr.length;
  }
}

// TODO: Do not move from this file.
// For some reason, imports are broken if this function is defined in lib/utils (e.g. Blog Index)
// Require more investigations
export const modelsDataToObject = data => (data ? mapValues(getData(data, getDataOpts), v => ({ data: v })) : undefined);

export const getMetaFromResponse = (resp) => {
  try {
    if (!isObject(resp)) return undefined;

    return (resp && resp.pages && resp.pages.meta);
  } catch (e) {
    console.error(e);
  }
};

// TODO: add page name validation
export const getModelsFromResponse = (page, resp) => {
  try {
    if (!isObject(resp) || !page) {
      if (!page) throw new Error('Page id is not defined');
      return undefined;
    }

    const pages = resp && resp.pages;

    if (pages) {
      return modelsDataToObject(pages[page] && pages[page].data);
    }

    return undefined;
  } catch (e) {
    console.error(e);
  }
};

// TODO: add page name validation
export const getPagePropsFromResponse = (page, resp) => {
  try {
    if (!isObject(resp) || !page) {
      if (!page) throw new Error('Page id is not defined');
      return undefined;
    }

    const pages = resp && resp.pages;

    if (pages) {
      return pages[page] && pages[page].pageProps;
    }

    return undefined;
  } catch (e) {
    console.error(e);
  }
};

// TODO: Add response structure validation
export const getDataFromResponse = (resp) => {
  const pageNames = Object.keys(omit(resp && resp.pages, 'meta'));

  const result = { meta: getMetaFromResponse(resp), pages: {} };


  pageNames.forEach((page) => {
    result.pages[page] = {
      pageProps: getPagePropsFromResponse(page, resp),
      models: getModelsFromResponse(page, resp),
    };
  });

  return result;
};

export const getRandomInt = (min, max) => Math.round(Math.random() * (max - min) + min);

export const getWPResizedUrl = (url, size) => {
  if (!url) return undefined;
  if (!size) return url;

  return `${url.replace('https://', 'https://i1.wp.com/')}?resize=${encodeURIComponent(size.join(','))}&ssl=1`;
};


export const getUrlExtension = url => (url ? url.split(/\#|\?/)[0].split('.').pop().trim() : url);

export const getSourcesFromUrl = (url, exts) => {
  try {
    if (!url) return null;
    const sources = [];

    if (url) {
      const urlExt = getUrlExtension(url);

      if (urlExt) {
        exts.forEach((ext) => {
          const extRegexp = new RegExp(`.(${urlExt})`);
          const newUrl = url.replace(extRegexp, `.${ext}`);
          if (newUrl) {
            sources.push({
              srcSet: newUrl,
              type: `image/${ext}`,
            });
          }
        });
      }
    }

    return sources;
  } catch (e) {
    console.error(e);
    return null;
  }
};


// // alt to lodash/pickBy
// export const keysThatMatch = (pattern) => {
//   return (data) => {
//     return Object.keys(data).filter((key) => {
//       return key.match(pattern);
//     }).reduce((obj, curKey) => {
//       obj[curKey] = data[curKey];
//       return obj;
//     });
//   };
// };

const isoDateRegExp = new RegExp(/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/);

export const isISODate = str => isoDateRegExp.test(str);

export const areArrays = (...arrays) => arrays.reduce((acc, arr) => acc && Array.isArray(arr), true);

// Wrap each found tag in a wrapper (note: all nested tags are wrapped too)
export const wrapTables = (html, className = 'table', tag = 'div') => {
  if (!html) return;

  const temp = html.replace(/<\s*table[^>]*>/ig, `<${tag} class="${className}">$&`);
  const result = temp.replace(/<\/\s*table>/ig, `$&</${tag}>`);
  // eslint-disable-next-line consistent-return
  return result;
};

export const getIframeHost = () => {
  if (IS_DEV || IS_LOCAL) {
    return 'http://localhost:3003';
  } if (IS_FEATURE) {
    return 'https://ls-feature.oneclass.com';
  } if (IS_PROD) {
    return 'https://assets.oneclass.com';
  }
};


export const renderNode = (render, props) => (isFunction(render) ? render(props) : render);


export function keysToSnakeCase(obj, deep = true) {
  if (!obj) return obj;

  return Object.entries(obj).reduce((acc, [key, value]) => ({
    ...acc,
    [snakeCase(key)]: (deep && isObject(value) && !Array.isArray(value)) ? keysToSnakeCase(value, deep) : value,
  }), {});
}


export const goHistoryBack = (defaultLink) => {
  if (isBrowser) {
    if (window.history.length > 1) {
      window.history.back();
    } else if (defaultLink) {
      window.location.assign(defaultLink);
    }
  }
};

// Transform number to ordinal string: 1 -> 1st
export const ordinal = (n) => {
  try {
    const num = parseInt(n, 10);

    if (!Number.isNaN(num)) {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = num % 100;
      return num + (s[(v - 20) % 10] || s[v] || s[0]);
    }

    return n;
  } catch (e) {
    console.error(e);
    return n;
  }
};

export const pluralize = (singular, plural, n = 0) => {
  const isPlural = ![1].includes(n);

  return (isPlural ? plural : singular);
};
