import { getProfessorById, getAPIData } from '../api';
import {
  RECEIVE_PROFESSOR_DATA,
  GET_PROFESSOR_SUGGESTIONS,
  GET_PROFESSOR_SUGGESTIONS_SUCCESS,
  GET_PROFESSOR_SUGGESTIONS_ERROR,
  RESET_GET_PROFESSOR_SUGGESTIONS_ERROR,
  GET_PROFESSOR,
  GET_PROFESSOR_SUCCESS,
  GET_PROFESSOR_ERROR,
  GET_PROFESSORS_BY_COURSE,
  GET_PROFESSORS_BY_COURSE_SUCCESS,
  GET_PROFESSORS_BY_COURSE_ERROR,
} from './constants';

const receiveProfessorData = res => ({ type: RECEIVE_PROFESSOR_DATA, res });

const getProfessorSuggestions = params => ({ type: GET_PROFESSOR_SUGGESTIONS, params });

const getProfessorSuggestionsSuccess = (params, res) => ({
  type: GET_PROFESSOR_SUGGESTIONS_SUCCESS,
  params,
  res,
});

const getProfessorSuggestionsError = (params, res) => ({
  type: GET_PROFESSOR_SUGGESTIONS_ERROR,
  params,
  res,
});

const resetGetProfessorSuggestionsError = () => ({ type: RESET_GET_PROFESSOR_SUGGESTIONS_ERROR });

const getProfessor = params => ({ type: GET_PROFESSOR, params });

const getProfessorSuccess = res => ({ type: GET_PROFESSOR_SUCCESS, res });

const getProfessorError = (params, res) => ({
  type: GET_PROFESSOR_ERROR,
  params,
  res,
});

const getProfessorsByCourse = params => ({ type: GET_PROFESSORS_BY_COURSE, params });

const getProfessorsByCourseSuccess = res => ({ type: GET_PROFESSORS_BY_COURSE_SUCCESS, res });

const getProfessorsByCourseError = (params, res) => ({
  type: GET_PROFESSORS_BY_COURSE_ERROR,
  params,
  res,
});

function retrieveProfessorSuggestions(schoolId, inputValue, inputName) {
  return function action(dispatch) {
    const params = { schoolId, inputValue, inputName };
    dispatch(getProfessorSuggestions(params));
    const request = getAPIData({ url: `professors/auto_prof_name?school_id=${schoolId}&query=${inputValue}` });
    return request.then(
      ({ data: { suggestions } }) => {
        if (!suggestions) {
          return dispatch(getProfessorSuggestionsError(params, { error: 'get professor suggestions error' }));
        }
        return dispatch(getProfessorSuggestionsSuccess(params, suggestions));
      },
      () => dispatch(getProfessorSuggestionsError(params, { error: 'get professor suggestions error' })),
    );
  };
}

function retrieveProfessorById(professorId, authToken) {
  return function action(dispatch) {
    dispatch(getProfessor(professorId));

    // IF authToken PROCEED
    // ELSE retrieveNewAuthToken THEN PROCEED

    return getProfessorById({ professorId, authToken }).then(({ data }) => {
      const professor = data?.data && data.data.find(dataItem => (dataItem?.id?.toString() === professorId));
      if (professor) {
        dispatch(getProfessorSuccess([data]));
      } else {
        dispatch(getProfessorError({ error: 'retrieve professor by id error' }));
      }
    }, () => {
      dispatch(getProfessorError({ error: 'retrieve professor by id error' }));
    });
  };
}

function retrieveProfessorsByCourse(courseId) {
  return function action(dispatch) {
    const params = { courseId };
    dispatch(getProfessorsByCourse(params));
    const request = getAPIData({ url: `users/get_prof_list?course_id=${courseId}` });
    return request.then(
      ({ data: { items_list: items } = {} }) => dispatch(getProfessorsByCourseSuccess(items)),
      () => dispatch(getProfessorsByCourseError(params, { error: 'retrieve professors by course error' })),
    );
  };
}

export {
  receiveProfessorData,
  retrieveProfessorSuggestions,
  resetGetProfessorSuggestionsError,
  retrieveProfessorsByCourse,
  retrieveProfessorById,
};
