import { fork, all } from 'redux-saga/effects';

// common sagas
import countrySaga from 'common/country/sagas';
import courseSaga from 'common/course/sagas';
import departmentSaga from 'common/department/sagas';
import documentSaga from 'common/document/sagas';
import pageSaga from 'common/page/sagas';
import professorSaga from 'common/professor/sagas';
import downloadSaga from 'common/download/sagas';
import downloadTokenSaga from 'common/downloadToken/sagas';
import schoolSaga from 'common/school/sagas';
import userSaga from 'common/user/sagas';

// V2 SAGAS

import pageSagaV2 from 'v2/redux/page/sagas';
import schoolSagaV2 from 'v2/redux/school/sagas';
import takex from 'v2/redux/utils/sagas/takex';
import trackingSaga from 'v2/redux/trackingSaga';
import fetchApiSaga from 'v2/redux/utils/sagas/fetchAPI';
import saveSearchAttemptSaga from 'v2/redux/utils/sagas/saveSearchAttempt';
import questionSagaV2 from 'v2/redux/question/sagas';
import boosterClassSaga from 'v2/redux/boosterClass/sagas';
import notificationSaga from 'v2/redux/notification/sagas';

// utils
import { captureException } from 'helpers/sentry';

function* handleErrors() {
  while (true) {
    const action = yield takex(/_ERROR$/);

    // action.payload is an Error occured in saga
    console.error(action.payload);
    captureException(action.payload, { errorInfo: { action } });
  }
}
export default function* rootSaga() {
  yield all([
    fork(countrySaga),
    fork(courseSaga),
    fork(departmentSaga),
    fork(documentSaga),
    fork(pageSaga),
    fork(professorSaga),
    fork(downloadTokenSaga),
    fork(downloadSaga),
    fork(schoolSaga),
    fork(userSaga),
    fork(pageSagaV2),
    fork(schoolSagaV2),
    fork(handleErrors),
    fork(trackingSaga),
    fork(questionSagaV2),
    fork(fetchApiSaga),
    fork(saveSearchAttemptSaga),
    fork(boosterClassSaga),
    fork(notificationSaga),
  ]);
}
