import React from 'react';
import cn from 'classnames';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Badge.module.scss';

const styles = stylesProxy(stylesObj, 'Badge');

// abstract component which should be used by concrete components:
// like LvlBadge, PointsBadge etc.
const Badge = ({
  className,
  children,
}) => (
  <span className={cn(styles.container, className)}>
    {children}
  </span>
);

Badge.propTypes = {};

export default Badge;
