const POST_ONE_TIME_PURCHASE = 'common/download/POST_ONE_TIME_PURCHASE';
const POST_ONE_TIME_PURCHASE_SUCCESS = 'common/download/POST_ONE_TIME_PURCHASE_SUCCESS';
const POST_ONE_TIME_PURCHASE_ERROR = 'common/download/POST_ONE_TIME_PURCHASE_ERROR';
const RESET_ONE_TIME_PURCHASE_ERROR = 'common/download/RESET_ONE_TIME_PURCHASE_ERROR';
const POST_PURCHASE = 'common/download/POST_PURCHASE';
const POST_PURCHASE_SUCCESS = 'common/download/POST_PURCHASE_SUCCESS';
const POST_PURCHASE_ERROR = 'common/download/POST_PURCHASE_ERROR';
const RESET_PURCHASE_ERROR = 'common/download/RESET_PURCHASE_ERROR';
const POST_CREATE_DOWNLOAD = 'common/download/POST_CREATE_DOWNLOAD';
const POST_CREATE_DOWNLOAD_SUCCESS = 'common/download/POST_CREATE_DOWNLOAD_SUCCESS';
const POST_CREATE_DOWNLOAD_ERROR = 'common/download/POST_CREATE_DOWNLOAD_ERROR';
const RESET_CREATE_DOWNLOAD_ERROR = 'common/download/RESET_CREATE_DOWNLOAD_ERROR';

export {
  POST_ONE_TIME_PURCHASE,
  POST_ONE_TIME_PURCHASE_SUCCESS,
  POST_ONE_TIME_PURCHASE_ERROR,
  RESET_ONE_TIME_PURCHASE_ERROR,
  POST_PURCHASE,
  POST_PURCHASE_SUCCESS,
  POST_PURCHASE_ERROR,
  RESET_PURCHASE_ERROR,
  POST_CREATE_DOWNLOAD,
  POST_CREATE_DOWNLOAD_SUCCESS,
  POST_CREATE_DOWNLOAD_ERROR,
  RESET_CREATE_DOWNLOAD_ERROR,
};
