import { path, assocPath } from 'ramda';
import { checkObjectFields, logWarning } from 'shared/helpers/utils';

const reducersMap = {
  'overview/degreesAwarded': {
    required: ['label'],
    transform: ([label, availability]) => ({ label, disabled: availability !== 'available' }),
  },
  'overview/demographics': {
    required: ['label', 'value', 'color'],
    transform: ([label, value, color]) => ({ label, value, color }),
  },
  'overview/enrollment': {
    required: ['students'],
    transform: ([students, undergrads]) => ({ students, undergrads }),
  },
  'overview/faculty': {
    transform: ([total, instructional, research]) => ({ total, instructional, research }),
  },
  'overview/nearestCities': {
    required: ['label'],
    transform: ([label, distance]) => ({ label, distance }),
  },
  'campus/clubs': {
    required: ['name'],
    transform: ([name, url]) => ({ name, url }),
  },
  'campus/residences': {
    required: ['name', 'address'],
    transform: ([name, address]) => ({ name, address }),
  },
  'campus/studyAreas': {
    required: ['label', 'text'],
    transform: ([label, text]) => ({ label, text }),
  },
  'campus/notableLocations': {
    required: ['label', 'text', 'image'],
    transform: ([label, text, image]) => ({ label, text, image }),
  },
  'studentServices/list': {
    required: ['title', 'content'],
    transform: ([title, content]) => ({ title, content }),
  },
  'freshmanAdmissionRequirements/highSchoolPreparation': {
    required: ['label', 'value'],
    transform: ([label, value]) => ({ label, value }),
  },
};

export const uniqueIdsPaths = [
  'statsCounters',
  'academics',
  'blogArticles',
  'campus',
  'overview',
  'studentServices',
  'athletics',
  'studyResources',
  'reviews',
  'studentServices',

  // COLLEGE DATA paths
  'freshmanAdmissionRequirements',
  'applyingForAdmission',
  'selectionOfStudents',
  'profileOfFallAdmission',
  'tuitionAndExpenses',
  'applyingForFinancialAid',
  'profileOfFinancialAid',
  'financialAidPrograms',
  'academicsOverview',
  'undergraduateEducation',
  'cirriculumAndGraduationRequirements',
  'facultyAndInstruction',
  'advancedPlacement',
  'academicResources',
  'academicSupportServices',
  'graduateProfessionalSchoolEducation',
  'locationAndSetting',
  'housing',
  'security',
  'personalSupportServices',
  'sportsAndRecreation',
  'studentActivities',
  'studentBody',
  'undergraduateRetentionAndGraduation',
];

export const preprocess = (attributes = {}) => {
  let result = attributes;

  Object.entries(reducersMap).forEach(([p, { required = [], transform }]) => {
    const pathArr = p.split('/');
    const fieldValue = path(pathArr, result);

    if (fieldValue && Array.isArray(fieldValue)) {
      // Transform lists items from arrays to objects, and check
      // if required fields exist in each item, and values are not empty strings
      const newFieldValue = fieldValue
        .filter((item) => {
          const isArray = Array.isArray(item);
          // check if all array items are also arrays
          if (!isArray) {
            logWarning('incorrect list item: ', item, fieldValue);
          }
          return isArray && item.length > 0;
        })
        .map(transform).filter(item => checkObjectFields(required, item, ['', null, undefined]));

      result = assocPath(pathArr, newFieldValue, result);
    }
  });

  return result;
};

export default {};
