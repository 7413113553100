import React, { memo, useMemo } from 'react';

import {
  string, node, bool, shape, func, oneOfType,
} from 'prop-types';
import { LinkListType } from 'types';
import cn from 'classnames';
import Footer from 'oc-core-components/src/Footer';
import Sticky from 'oc-core-components/src/Sticky';

// import Crisp from 'oc-core-components/src/Crisp';
import {
  isFunction, renderNode,
} from 'helpers/utils';

import { HEADER_ID } from 'helpers/constants';
import BottomNavbar from 'oc-core-components/src/Toolbar';
import DefaultNavbarBannerContainer from 'oc-core-components/src/DefaultNavbarBannerContainer';
import PromoCodeModal from 'oc-core-components/src/PromoCodeModal';
import AddCoursesModal from 'oc-core-components/src/AddCoursesModal';

import ThankYouModal from 'components/DocumentShow/ThankYouModal';
import { useMobile } from 'oc-core-components/src/MediaQuery';

import UploadCourseModal from 'oc-core-components/src/UploadCourseModal';

import {
  FOOTER_MENUS,
  FOOTER_BOTTOM,
  MOBILE_FOOTER,
  MOBILE_LOGGEDIN_FOOTER,
  SMALL_FOOTER,
  SOCIAL,
} from 'oc-core-components/src/Footer/constants';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Page.module.scss';

const styles = stylesProxy(stylesObj, 'Page');


const defaultNavbarBeforeContent = () => <DefaultNavbarBannerContainer />;

const defaultRenderBottomToolbar = props => (
  <BottomNavbar {...props} />
);

const defaultRenderFooter = ({
  isLoggedIn,
  footerBanner,
  ...restProps
}) => (
  <Footer
    fullNav={FOOTER_MENUS}
    smallNav={SMALL_FOOTER}
    bottom={FOOTER_BOTTOM}
    social={SOCIAL}
    mobile={isLoggedIn ? MOBILE_LOGGEDIN_FOOTER : MOBILE_FOOTER}
    full={!isLoggedIn}
    banner={footerBanner}
    {...restProps}
  />
);

const Page = ({
  children,
  className,
  isLoggedIn,
  navbarTabs,
  onTabClick,
  selectedTab,
  light,
  navbarContent,
  navbarBeforeContent,
  navbarAfterContent,
  hideMobileSubnav,
  navbarProps: customNavbarProps,
  disableChat,
  pageApiId,
  getNavbarProps,
  renderNavbar,
  displayCrisp,
  hideBottomNavbar,
  hideCrisp,
  toolbarHandlers,
  renderBottomToolbar,
  renderFooter,
  renderBeforeFooter,
  contentClassName,
  renderBeforeToolbar,
  footerBanner,
  authLinkParams,
  loginLinkParams,
  signupLinkParams,
  disableStickyNavbar,
  colored,
  footerProps,
}) => {
  // const {
  //   onInstallButtonClick,
  //   showInstallPromotion,
  // } = usePwaBanner();

  const authProps = {
    authLinkParams,
    loginLinkParams,
    signupLinkParams,
  };

  const isMobile = useMobile();

  const classNames = cn(
    className,
    styles.container,
    {
      [styles.light]: light,
      [styles.colored]: colored,
      // [styles['with-toolbar']]: !hideBottomNavbar,
    },
  );

  const navbarProps = useMemo(() => (isFunction(getNavbarProps) ? getNavbarProps() : customNavbarProps), [getNavbarProps, customNavbarProps]);

  const {
    stickyProps,
    signupFormProps,
    ...restNavbarProps
  } = navbarProps;

  const navbar = isFunction(renderNavbar) ? renderNavbar(restNavbarProps) : renderNavbar;

  // const navbar = isNil(renderNavbar) ? (
  //   <NavbarContainer
  //     isLoggedIn={isLoggedIn}
  //     tabs={navbarTabs}
  //     onTabClick={onTabClick}
  //     selectedTab={selectedTab}
  //     afterContent={navbarAfterContent}
  //     beforeContent={navbarBeforeContent}
  //     hideMobileSubnav={hideMobileSubnav}
  //     showCancelButton
  //     {...authProps}

  //     {...restNavbarProps}
  //   >
  //     {
  //       isFunction(navbarContent) ? navbarContent() : navbarContent
  //     }
  //   </NavbarContainer>
  // ) : (
  //   isFunction(renderNavbar) ? renderNavbar(restNavbarProps) : renderNavbar
  // );

  return (
    <div className={classNames}>
      {/* <Crisp /> */}
      {/* {
        !hideCrisp && (!IS_DEV && (displayCrisp || (!disableChat && isLoggedIn))) && (
          <Crisp />
        )
      } */}
      {/* <AuthenticationModal name={AUTHENTICATION_MODAL} formProps={signupFormProps} size="sm" /> */}

      <UploadCourseModal />
      <AddCoursesModal
        pageApiId={pageApiId}
      />

      <PromoCodeModal />
      {/* <WechatCheckoutModal /> */}
      <ThankYouModal />

      {
        disableStickyNavbar ? (
          <div id={HEADER_ID}>
            {navbar}
          </div>
        ) : (
          <Sticky
            id={HEADER_ID}
            {...stickyProps}
          >
            {navbar}
          </Sticky>
        )
      }


      <main className={cn(styles.content, contentClassName)}>
        {children}
      </main>
      {/* { */}
      {/*  showInstallPromotion && ( */}
      {/*    <button onClick={onInstallButtonClick}>Install PWA</button> */}
      {/*  ) */}
      {/* } */}

      { renderNode(renderBeforeFooter) }
      { isFunction(renderFooter) && renderFooter({ isLoggedIn, footerBanner, ...footerProps }) }

      {
        (!hideBottomNavbar && isMobile && isFunction(renderBottomToolbar))
          && renderBottomToolbar({
            renderBefore: renderBeforeToolbar,
            toolbarHandlers,
            ...authProps,
          })
      }
    </div>
  );
};

Page.propTypes = {
  light: string,
  children: node,
  header: node,
  footer: node,
  isLoggedIn: bool,
  navbarTabs: LinkListType,
  onTabClick: func,
  selectedTab: string,
  className: string,
  navbarContent: oneOfType([func, node]),
  navbarBeforeContent: oneOfType([func, node]),
  navbarAfterContent: oneOfType([func, node]),
  navbarProps: shape(),
  disableChat: bool,
  hideMobileSubnav: bool,
  displayCrisp: bool,
  hideBottomNavbar: bool,
  pageApiId: string,
  getNavbarProps: func,
  renderNavbar: oneOfType([func, node]),
  hideCrisp: bool,
  toolbarHandlers: shape(),
  renderBottomToolbar: func,
  footerBanner: node,
};

Page.defaultProps = {
  hideMobileSubnav: true,
  navbarProps: {},
  navbarBeforeContent: defaultNavbarBeforeContent,
  renderBottomToolbar: defaultRenderBottomToolbar,
  renderFooter: defaultRenderFooter,
};

export default memo(Page);
