import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { stylesProxy } from 'helpers/css';
import { shortenLargeNumber, isFunction, renderNode } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import Loader from 'oc-core-components/src/Loader';
import PointsBadge from 'hw_components/src/_HWv2/Badge/components/PointsBadge';

import React from 'react';
import LivestreamDot from 'oc-core-components/src/components/Navbar/LivestreamDot';
import stylesObj from 'oc-core-components/src/components/Navbar/Navbar.module.scss';
import ampStylesObj from 'oc-core-components/src/components/Navbar/Navbar.module.scss?amp&type=resolve';
import NavbarMenuIcon from 'oc-core-components/src/components/Navbar/NavbarMenuIcon';

const s = stylesProxy(stylesObj, 'Navbar');

const NavbarMenu = ({
  className,
  count,
  items,
  label,
  loaded,
  renderFooter,
  renderItem,
  streamActive,
  styles,
  onItemClick,
  renderInner,
  renderEmpty,
}) => {
  const { t } = useTranslation('navbar');

  return (
    <div className={cn(styles.navbar__menu, className)}>
      {label && <p className={styles.navbar__menu__label}>{label}</p>}

      {
        isFunction(renderInner) ? renderNode(renderInner) : (
          <nav>

            <ul className={styles['navbar__menu__item-container']}>

              <Loader loaded={loaded} svg>
                {
                  (items?.length > 0)

                    ? items?.map((item, indx) => (
                      <li key={item?.key || item?.id || indx}>
                        {renderItem ? (
                          renderItem(item)
                        ) : (
                          <Button
                            color="none"
                            className={styles.navbar__menu__item}
                            href={item.url}
                            onClick={
                              onItemClick ? e => onItemClick(e, item?.data) : undefined
                            }
                          >
                            {item.icon && (
                              <NavbarMenuIcon
                                fill={item.iconFill}
                                icon={item.icon}
                                stroke={item.iconStroke}
                              />
                            )}

                            <div className={styles.navbar__menu__item__text}>
                              <div
                                className={cn(
                                  styles.navbar__menu__item__text__title,
                                  item.description
                                  && styles[
                                  'navbar__menu__item__text__title--has-description'
                                  ],
                                )}
                              >
                                {t(item.title)}

                                {item.livestream && (
                                  <LivestreamDot streamActive={streamActive} />
                                )}

                                {
                                  item.points && (
                                    <PointsBadge
                                      pointsCount={item.points}
                                      color="orange"
                                      size="xs"
                                      className="u-ml-3xs"
                                      addPlusSign
                                    />
                                  )
                                }
                              </div>

                              {item.description && (
                                <p
                                  className={
                                    styles.navbar__menu__item__text__description
                                  }
                                >
                                  {t(item.description, {
                                    count: shortenLargeNumber(count),
                                  })}
                                </p>
                              )}
                            </div>
                            {item.rightIcon && (
                              <Icon
                                className="u-ml-xs"
                                icon={item.rightIcon}
                                size={22}
                                svgClassName={styles['navbar__menu__item__icon-right']}
                              />
                            )}
                          </Button>
                        )}
                      </li>
                    )) : renderNode(renderEmpty)
                }
              </Loader>
            </ul>

            {renderFooter && renderFooter()}
          </nav>
        )}

    </div>
  );
};

export default withAmpStyles(NavbarMenu, s, stylesObj, ampStylesObj);
