import React from 'react';
import { string } from 'prop-types';
import { numberString } from 'types';
import cn from 'classnames';
import Tooltip from 'oc-core-components/src/TooltipPopper';

import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import useFollowUser from 'hw_components/src/hooks/useFollowUser';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './FollowUserButton.module.scss';

const styles = stylesProxy(stylesObj, 'FollowUserButton');

export const SIZES = {
  small: 'small',
  default: 'default',
};

const FollowUserButton = ({
  className,
  modelId,
  size,
  modelType,
  iconSize,
  customCheckIcon,
  customFollowIcon,
  ...restProps
}) => {
  const {
    follow,
    isFollowing,
  } = useFollowUser({
    modelId,
    modelType,
  });

  const isSmall = size === SIZES.small;
  const tooltipText = isFollowing ? 'Following' : 'Follow';

  const {
    icon, label, color, iconStroke,
  } = isFollowing ? {
    // icon: isSmall ? 'oc:check' : 'feather:check',
    icon: isSmall ? (customCheckIcon || 'oc:check') : 'feather:check',
    iconStroke: isSmall ? 'none' : undefined,
    // TODO: i18n
    label: 'Following',
    color: 'primary-light-outline',
  } : {
    icon: isSmall ? (customFollowIcon || 'oc:follow') : null,
    iconStroke: isSmall ? 'none' : undefined,
    label: 'Follow',
    color: 'primary',
  };

  if (!modelId) {
    return null;
  }

  if (size === SIZES.small) {
    return (
      <Tooltip
        dark
        placement="top"
        widthAuto
        // hideArrow
        small
        paddingSmall
        className={styles.tooltip}
        targetClassName="u-d-flex u-align-items-center"
        arrowClassName={styles.tooltip__arrow}
        target={(
          <Button
            onClick={follow}
            className={cn(className, styles.toggler, {
              [styles['toggler--active']]: isFollowing,
            })}
            color={color}
            {...restProps}
          >
            <Icon
              icon={icon}
              stroke={iconStroke}
              svgClassName={cn({
                [styles.toggler_active_icon]: isFollowing,
                [styles.toggler_icon]: !isFollowing,
              })}
              size={iconSize || 16}
            />
          </Button>
        )}
      >
        {tooltipText}
      </Tooltip>
    );
  }


  return (
    <Button size="md" onClick={follow} color={color} className={cn(styles.btn, className)} {...restProps}>
      {icon && <Icon icon={icon} size={14} className="u-mr-3xs" />}
      {label}
    </Button>
  );
};

FollowUserButton.propTypes = {
  modelType: string,
  size: string,
  modelId: numberString,
};

export default FollowUserButton;
