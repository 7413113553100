import { NavbarSearchTypeIndexPrefixes, NavbarSearchTypes, NavbarTypes } from 'shared/components/Navbar';

export const getCategoryIcon = (category) => {
  switch (category) {
    case NavbarSearchTypes.blog:
      return 'feather:feather';
    case NavbarSearchTypes.boosterClass:
      return 'sf:course';
    case NavbarSearchTypes.classNote:
      return 'feather:book';
    case NavbarSearchTypes.course:
      return 'feather:book';
    case NavbarSearchTypes.history:
      return 'feather:clock';
    case NavbarSearchTypes.livestream:
      return 'feather:monitor';
    case NavbarSearchTypes.professor:
      return 'feather:user';
    case NavbarSearchTypes.question:
      return 'feather:message-square';
    case NavbarSearchTypes.school:
      return 'feather:book';
    case NavbarSearchTypes.skill:
      return 'feather:box';
    case NavbarSearchTypes.studyGuide:
      return 'feather:book';
    case NavbarSearchTypes.textbookNote:
      return 'feather:book';
    default:
      return 'feather:search';
  }
};

export const getCategoryLabels = (category) => {
  switch (category) {
    case NavbarSearchTypes.blog:
      return 'navbar:navbar.searchLabels.blog';
    case NavbarSearchTypes.boosterClass:
      return 'navbar:navbar.searchLabels.boosterClass';
    case NavbarSearchTypes.classNote:
      return 'navbar:navbar.searchLabels.classNote';
    case NavbarSearchTypes.course:
      return 'navbar:navbar.searchLabels.course';
    case NavbarSearchTypes.livestream:
      return 'navbar:navbar.searchLabels.livestream';
    case NavbarSearchTypes.professor:
      return 'navbar:navbar.searchLabels.professor';
    case NavbarSearchTypes.question:
      return 'navbar:navbar.searchLabels.question';
    case NavbarSearchTypes.school:
      return 'navbar:navbar.searchLabels.school';
    case NavbarSearchTypes.skill:
      return 'navbar:navbar.searchLabels.skill';
    case NavbarSearchTypes.studyGuide:
      return 'navbar:navbar.searchLabels.studyGuide';
    case NavbarSearchTypes.textbookNote:
      return 'navbar:navbar.searchLabels.textbookNote';
    default:
      return null;
  }
};

export const getSearchTitle = (type, source) => {
  switch (type) {
    case NavbarSearchTypes.blog:
      return source.title;
    case NavbarSearchTypes.boosterClass:
      return source.name;
    case NavbarSearchTypes.classNote:
      return source.name;
    case NavbarSearchTypes.course:
      return source.code;
    case NavbarSearchTypes.livestream:
      return source.title;
    case NavbarSearchTypes.professor:
      return source.name;
    case NavbarSearchTypes.question:
      return source.body;
    case NavbarSearchTypes.school:
      return source.name;
    case NavbarSearchTypes.skill:
      return source.name;
    case NavbarSearchTypes.studyGuide:
      return source.name;
    case NavbarSearchTypes.textbookNote:
      return source.name;
    default:
      return null;
  }
};

export const getSearchType = (index, kind = 0, currentType) => {
  let type = null;
  Object.keys(NavbarSearchTypeIndexPrefixes).forEach((navbarSearchType) => {
    const prefix = NavbarSearchTypeIndexPrefixes[navbarSearchType];


    if (type) return;
    if (index.startsWith(prefix)) {
      if (
        [
          NavbarSearchTypes.classNote,
          NavbarSearchTypes.studyGuide,
          NavbarSearchTypes.textbookNote,
        ].includes(navbarSearchType)
      ) {
        if ([1, 5].includes(kind)) {
          type = NavbarSearchTypes.classNote;
        } else if ([2, 8].includes(kind)) {
          type = NavbarSearchTypes.textbookNote;
        } else if ([3, 6, 7].includes(kind)) {
          type = NavbarSearchTypes.studyGuide;
        } else {
          type = NavbarSearchTypes.unsupported;
        }

        return;
      }

      type = navbarSearchType;
    }
  });

  // TODO: Remove this once the API implements filtering of autocomplete suggestions.
  // TODO: Improve implementation by syncing the keys of NavbarSearchTypes and NavbarTypes.
  // if (
  //   (currentType === NavbarTypes.blogs && type !== NavbarSearchTypes.blog) ||
  //   (currentType === NavbarTypes.boosterClasses &&
  //     type !== NavbarSearchTypes.boosterClass) ||
  //   (currentType === NavbarTypes.classNotes &&
  //     type !== NavbarSearchTypes.classNote) ||
  //   (currentType === NavbarTypes.homeworkHelp &&
  //     type !== NavbarSearchTypes.question) ||
  //   (currentType === NavbarTypes.livestreams &&
  //     type !== NavbarSearchTypes.livestream) ||
  //   (currentType === NavbarTypes.studyGuides &&
  //     type !== NavbarSearchTypes.studyGuide) ||
  //   (currentType === NavbarTypes.textbookNotes &&
  //     type !== NavbarSearchTypes.textbookNote)
  // ) {
  //   type = NavbarSearchTypes.unsupported;
  // }

  return type;
};

export const processElasticSearch = (
  query,
  {
    data,
  } = {},
  currentType,
) => {
  const hits = data?.hits?.hits;
  let processedHits = [];

  hits?.forEach(({ _id, _index, _source }, index) => {
    // disable frontend filtering

    const searchType = getSearchType(_index, _source.kind, currentType);
    // console.log(searchType)
    if (searchType === NavbarSearchTypes.unsupported) return;
    const searchTitle = getSearchTitle(searchType, _source);


    if (searchType && searchTitle) {
      processedHits.push({
        value: searchTitle,
        data: {
          category: searchType,
          obj_id: _id,
          obj_index: index,
          u_q: query,
        },
      });
    }


    // processedHits.push({
    //   value: searchTitle,
    //   data: {
    //     category: searchType,
    //     obj_id: _id,
    //     obj_index: index,
    //     u_q: query,
    //   },
    // });
  });

  return [
    ...processedHits,
    {
      value: query,
      data: {
        category: NavbarSearchTypes.default,
        obj_id: null,
        obj_index: 0,
        u_q: query,
      },
    },
  ];
};
