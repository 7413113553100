import produce from 'immer';
import { handleActions as raHandleActions } from 'redux-actions';
import commonTypes from 'v2/redux/types';
import combineMerge from 'shared/helpers/utils/combineMerge';

const handleActions = (actions, state) => raHandleActions(
  Object.keys(actions).reduce((acc, key) => {
    acc[key] = produce(actions[key]);

    return acc;
  }, {}),
  state,
);

export const handleModelActions = (modelName, actions, initialState) => handleActions(
  {
    [commonTypes.STORE_MODELS]: (state, { payload, meta: { mergingStrategy } = {} }) => {
      const models = payload?.models;

      if (models && models[modelName]) {
        state.data = combineMerge(state.data, models[modelName].data, { strategy: mergingStrategy });
      }
    },
    // @example
    // storeRelationships({
    //   blogArticle: {
    //     id: articleId,
    //     relationKey: 'comments',
    //     data: {
    //       id,
    //       type: 'comment',
    //     },
    //   },
    // })
    [commonTypes.STORE_RELATIONSHIPS]: (state, { payload }) => {
      if (payload && payload[modelName]) {
        const {
          [modelName]: {
            id,
            relationKey,
          },
        } = payload;

        state.data[id].relationships[relationKey].data.push(payload[modelName].data);
      } else {
        return state;
      }
    },
    [commonTypes.STORE_MODEL_ATTRIBUTES]: (state, {
      payload: {
        id, modelType, updates,
      },
      meta: { mergingStrategy } = {},
    }) => {
      if (modelName === modelType) {
        if (state?.data[id]) {
          const attrs = state?.data[id]?.attributes;
          state.data[id].attributes = combineMerge(attrs, updates, { strategy: mergingStrategy });
        } else {
          state.data[id] = {
            attributes: combineMerge({}, updates, { strategy: mergingStrategy }),
          };
        }
      }
    },
    ...actions,
  },
  initialState,
);

export default handleActions;
