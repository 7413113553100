import { pagePropsSelectorFactory } from 'v2/redux/page/selectors';
import { createSelector } from 'reselect';
import { convertLinksToPwa } from 'helpers/pwa';

import { selectV2ModelsDataByType, createEntityByIdSelector } from 'v2/redux/selectors';
// import { createSelector } from 'reselect';


export const getV2PageBoosterClassSkillId = pagePropsSelectorFactory('boosterClassSkillId', true);
export const selectV2SubjectName = pagePropsSelectorFactory('subjectName', true);
export const selectV2Subjects = pagePropsSelectorFactory('subjects', true);
export const selectV2SubjectsPwa = createSelector(
  [selectV2Subjects],
  subjects => subjects.map(({ skills, ...rest }) => {
    return ({
      skills: convertLinksToPwa(skills, 'url'),
      ...rest,
    });
  }),
);

export const selectV2TutorInfo = pagePropsSelectorFactory('tutorInfo', true);
export const selectV2SkillTimeSlots = pagePropsSelectorFactory('skillTimeSlots', true);
export const selectV2UpcomingGroupSessions = pagePropsSelectorFactory('upcomingGroupSessions', true);
export const selectV2GroupSessions = pagePropsSelectorFactory('myUpcomingSessions', true);
export const selectV2PageBoosterClassSkill = createEntityByIdSelector(getV2PageBoosterClassSkillId, selectV2ModelsDataByType.boosterClassSkill);
