// See https://reactstrap.github.io/components/tooltips/
// TODO: The reactstrap tooltip component usess an outdate version of the react-popper
// Replace it with a custom tooltip implementation
import React, { PureComponent, Fragment } from 'react';
import { string, node, bool } from 'prop-types';
import cn from 'classnames';

import { getDynamicImportProps } from 'helpers/utils';
import { withMobileMQ } from 'oc-core-components/src/MediaQuery';


import dynamic from 'next/dynamic';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './TooltipPopper.module.scss';

const styles = stylesProxy(stylesObj, 'TooltipPopper');

const dynamicProps = getDynamicImportProps();
const ReactTooltip = dynamic(() => import(/* webpackChunkName: "ReactTooltip" */ 'reactstrap/lib/Tooltip'), dynamicProps);


class Tooltip extends PureComponent {
  static propTypes = {
    target: node.isRequired,
    className: string,
    targetClassName: string,
    arrowClassName: string,
    placement: string,
    children: node,
    small: bool,
    dark: bool,
    paddingSmall: bool,
    disabled: bool,
    widthAuto: bool,
    mobilePlacement: string,
  }

  static defaultProps = {
    placement: 'auto',
    mobilePlacement: 'auto',
  }

  constructor(props) {
    super(props);
    this.target = React.createRef();

    this.state = {
      tooltipOpen: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  }

  render() {
    const {
      target,
      targetClassName,
      arrowClassName,
      children,
      small,
      dark,
      mobilePlacement,
      mobileBreakpoint,
      placement,
      paddingSmall,
      className,
      disabled,
      widthAuto,
      matches,
      disableOnMobile,
      isOpen: isCustomOpen,
      withOverlay,
      ...restProps
    } = this.props;

    const {
      tooltipOpen,
    } = this.state;

    const popperClassNames = cn(
      className,
      styles.popper,
      {
        [styles.dark]: dark,
        [styles.small]: small,
        [styles.auto]: widthAuto,
        [styles['padding-small']]: paddingSmall,
      },
    );

    const arrowClassNames = cn(
      arrowClassName,
      styles.arrow,
    );

    return (
      <Fragment>
        {
          // NOTE: at the moment overlay is working only with isCustomOpen
          withOverlay && isCustomOpen && (
            <div className={styles.tooltip_overlay} />
          )
        }

        <div
          className={cn(styles.target, targetClassName)}
          ref={this.target}
        >
          {target}
        </div>

        {
          ((!disabled && !(matches && disableOnMobile)) && (React.Children.count(children) > 0)) && (
            <ReactTooltip
              className={popperClassNames}
              placement={matches ? mobilePlacement : placement}
              isOpen={isCustomOpen || tooltipOpen}
              target={this.target}
              toggle={this.toggle}
              arrowClassName={arrowClassNames}
              {...restProps}
            >
              {children}
            </ReactTooltip>
          )
        }

      </Fragment>
    );
  }
}

export default withMobileMQ(Tooltip);
