import { ROUTES } from 'helpers/constants';

export const ExperienceToggleRoutes = Object.freeze({
  boosterClasses: ['/courses'],
  home: [],
  homeworkHelp: [
    '/homework-help',
    '/homework-help-parents',
    '/homework-help-get-started',
    '/app/homework-help',
    '/app/homework-help-parents',
    '/app/homework-help-get-started',
  ],
  studyGuides: ['/my-courses', '/study-guides'],
  classNotes: ['/class-notes'],
  textbookNotes: ['/textbook-notes'],
  livestreams: ['/livestream'],
  blogs: ['/blog'],
});

export const ExperienceToggleTypes = Object.freeze({
  blog: 'blogs',
  boosterClasses: 'boosterClasses',
  classNotes: 'classNotes',
  home: 'home',
  homeworkHelp: 'homeworkHelp',
  livestreams: 'livestreams',
  studyGuides: 'studyGuides',
  textbookNotes: 'textbookNotes',
});

export const EXPERIENCE_TOGGLE_ITEMS = [
  {
    key: 'home',
    title: 'navbar.menuExperienceChanger.home.title',
    url: ROUTES.root,
  },
  {
    key: 'homeworkHelp',
    title: 'navbar.menuExperienceChanger.homeworkHelp.title',
    url: ROUTES.homeworkHelp,
  },
  {
    key: 'studyGuides',
    title: 'navbar.menuExperienceChanger.studyGuides.title',
    url: ROUTES.studyGuides,
  },
  {
    key: 'classNotes',
    title: 'navbar.menuExperienceChanger.classNotes.title',
    url: ROUTES.classNotes,
  },
  {
    key: 'textbookNotes',
    title: 'navbar.menuExperienceChanger.textbookNotes.title',
    url: ROUTES.textbookNotes,
  },
  {
    key: 'textbook_solutions',
    title: 'navbar.menuExperienceChanger.textbookSolutions.title',
    url: ROUTES.textbooks,
  },
  {
    key: 'boosterClasses',
    title: 'navbar.menuExperienceChanger.boosterClasses.title',
    url: ROUTES.boosterClass,
  },
  // {
  //   key: 'livestreams',
  //   title: 'navbar.menuExperienceChanger.livestreams.title',
  //   livestream: true,
  //   url: ROUTES.livestream,
  // },
  {
    key: 'blog',
    title: 'navbar.menuExperienceChanger.blogs.title',
    url: ROUTES.blog,
  },
];
