import React from 'react';
import { func } from 'prop-types';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Container from 'oc-core-components/src/Container';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import { withTranslation } from 'config/i18n';
import { ROUTES } from 'helpers/constants';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './AcceptCookiesBanner.module.scss';

const styles = stylesProxy(stylesObj, 'AcceptCookiesBanner');

const AcceptCookiesBanner = ({ t, onClose }) => (
  <Container fluid className={styles['c-overlay-banner']}>
    <Container className={styles['c-overlay-banner-inner']}>
      <Row className="u-justify-content-center">
        <Col md="10" xs="11">
          <p className="u-mb-0">
            {t('banners.accept_cookies.title')}
            <strong>
              <Link
                title={t('banners.accept_cookies.button')}
                href={ROUTES.websitePrivacy}
              >
                {t('banners.accept_cookies.button')}
              </Link>
            </strong>
            {t('banners.accept_cookies.subtitle')}
          </p>
        </Col>
        <Icon
          className={styles['c-overlay-banner-close']}
          tag="button"
          icon="feather:x"
          color="dark"
          size="xxs"
          stroke="none"
          aria-label="close"
          onClick={onClose}
        />
      </Row>
    </Container>
  </Container>
);

AcceptCookiesBanner.propTypes = {
  onClose: func.isRequired,
};

export default withTranslation('common')(AcceptCookiesBanner);
