import { handleActions } from 'redux-actions';
import mergeable from 'redux-merge-reducers';

import merge from 'lodash/merge';
import {
  RECEIVE_COURSE_DATA,
  POST_RATE_COURSE,
  POST_RATE_COURSE_SUCCESS,
  POST_RATE_COURSE_ERROR,
  RESET_POST_RATE_COURSE_ERROR,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_ERROR,
  GET_COURSE_SUGGESTIONS,
  GET_COURSE_SUGGESTIONS_SUCCESS,
  GET_COURSE_SUGGESTIONS_ERROR,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_ERROR,
  REMOVE_COURSE,
  REMOVE_COURSE_SUCCESS,
  REMOVE_COURSE_ERROR,
  RESET_REMOVE_COURSE_ERROR,
  RESET_REMOVE_COURSE_SUCCESS,
} from './constants';

const initialState = {
  data: {},
  ratingCourse: false,
  rateCourseSuccess: false,
  rateCourseError: false,
  loadingCourses: false,
  loadingCourse: false,
  retrievingCourseSuggestions: false,
  retrieveCourseSuggestionSuccess: false,
  retrieveCourseSuggestionError: false,
  removingCourse: false,
  removeCourseSuccess: false,
  removeCourseError: false,
};

const reducer = handleActions(
  {
    [RECEIVE_COURSE_DATA]: (state, { payload }) => ({
      ...state,
      data: merge({}, state.data, { ...payload }),
    }),
    [POST_RATE_COURSE]: state => ({
      ...state,
      ratingCourse: true,
      rateCourseSuccess: false,
    }),
    [POST_RATE_COURSE_SUCCESS]: (state, { payload }) => ({
      ...state,
      ratingCourse: false,
      rateCourseSuccess: payload?.params?.id ? { [payload?.params?.id]: true } : false,
    }),
    [POST_RATE_COURSE_ERROR]: (state, { payload }) => ({
      ...state,
      ratingCourse: false,
      rateCourseError: payload,
    }),
    [RESET_POST_RATE_COURSE_ERROR]: state => ({
      ...state,
      rateCourseError: false,
    }),
    [GET_COURSES]: state => ({
      ...state,
      loadingCourses: true,
    }),
    [GET_COURSES_SUCCESS]: state => ({
      ...state,
      loadingCourses: false,
    }),
    [GET_COURSES_ERROR]: state => ({
      ...state,
      loadingCourses: false,
    }),
    [GET_COURSE]: state => ({
      ...state,
      loadingCourse: true,
    }),
    [GET_COURSE_SUCCESS]: state => ({
      ...state,
      loadingCourse: false,
    }),
    [GET_COURSE_ERROR]: state => ({
      ...state,
      loadingCourse: false,
    }),
    [REMOVE_COURSE]: (state, { payload }) => ({
      ...state,
      removingCourse: payload?.courseId,
      removeCourseSuccess: false,
    }),
    [REMOVE_COURSE_SUCCESS]: (state, { payload }) => ({
      ...state,
      removingCourse: false,
      removeCourseSuccess: payload?.courseId,
    }),
    [REMOVE_COURSE_ERROR]: (state, { payload }) => ({
      ...state,
      removingCourse: false,
      removeCourseError: payload?.courseId,
    }),
    [RESET_REMOVE_COURSE_ERROR]: state => ({
      ...state,
      removeCourseError: false,
    }),
    [RESET_REMOVE_COURSE_SUCCESS]: state => ({
      ...state,
      removeCourseSuccess: false,
    }),
    [GET_COURSE_SUGGESTIONS]: state => ({
      ...state,
      retrievingCourseSuggestions: true,
    }),
    [GET_COURSE_SUGGESTIONS_SUCCESS]: (state, { payload: { res, params } }) => {
      const fieldNameSuggestions = [];
      res.forEach(({ _source: { id, code, url } }) => {
        fieldNameSuggestions.push({
          type: 'course',
          value: id.toString(),
          label: code,
          url,
        });
      });
      return {
        ...state,
        retrievingCourseSuggestions: false,
        retrieveCourseSuggestionSuccess: {
          [params.inputName]: fieldNameSuggestions,
        },
      };
    },
    [GET_COURSE_SUGGESTIONS_ERROR]: state => ({
      ...state,
      retrievingCourseSuggestions: false,
      retrieveCourseSuggestionError: 'An error occured in GET_COURSE_SUGGESTIONS',
    }),
  },
  { ...initialState },
);

export default mergeable(reducer);
