import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { useRouter } from 'oc-core-components/src/RouterProvider';
import Link from 'oc-core-components/src/Link';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getV2IsLoggedIn } from 'v2/redux/meta/selectors';
import { logErrorDev } from 'helpers/loggers';

import withAmpStyles from 'helpers/withAmpStyles';
import { stylesProxy } from 'helpers/css';
import { getV2ActiveProductCategory } from 'shared/v2/redux/meta/selectors';
import {
  EXPERIENCE_TOGGLE_ITEMS,
  ExperienceToggleRoutes,
  ExperienceToggleTypes,
} from './constants';
import ampStylesObj from './ExperienceToggle.module.scss?amp&type=resolve';
import stylesObj from './ExperienceToggle.module.scss';

const s = stylesProxy(stylesObj, 'ExperienceToggle');


const ExperienceToggle = ({
  styles,
  bannerToggle,
  className,
  bordered,
}) => {
  const isLoggedIn = useSelector(getV2IsLoggedIn);
  const activeProductCategory = useSelector(getV2ActiveProductCategory);

  const { router } = useRouter();
  const { t } = useTranslation('navbar');
  const experienceToggleRef = useRef();
  const activeItemRef = useRef();

  useEffect(() => {
    try {
      if (!activeItemRef?.current || !experienceToggleRef?.current) {
        logErrorDev('Incorrect refs in Experience Toggle');

        return;
      }

      const { offsetLeft } = activeItemRef.current;
      const { clientWidth } = experienceToggleRef.current;
      const { offsetWidth } = activeItemRef.current;

      const scrollOffset = offsetLeft - ((clientWidth - offsetWidth) / 2);
      experienceToggleRef.current.scrollLeft = scrollOffset;
    } catch (e) {
      console.error(e);
    }
  }, []);

  const experienceType = useMemo(() => {
    try {
      let nextExperienceType = ExperienceToggleTypes.home;
      Object.keys(ExperienceToggleRoutes).forEach((type) => {
        const routes = ExperienceToggleRoutes[type];
        if (routes.some(path => router.asPath.startsWith(path))) {
          nextExperienceType = type;
        }
      });

      const isActiveProductCategoryKeyValid = EXPERIENCE_TOGGLE_ITEMS.find(({ key }) => key === activeProductCategory);

      return isActiveProductCategoryKeyValid ? (activeProductCategory || nextExperienceType) : nextExperienceType;
    } catch (e) {
      console.error(e);
    }
  }, [router, activeProductCategory]);

  return (
    <nav
      className={cn(styles['toggle-container'], {
        [styles['toggle-container--bordered']]: bordered,
      }, className)}
      ref={experienceToggleRef}
    >
      <ul className={styles.toggle}>
        {EXPERIENCE_TOGGLE_ITEMS.map(({ key, title, url }) => {
          const isActive = experienceType === key;

          return (
            <li
              className={cn(
                styles.item,
                isActive && styles['item--active'],
              )}
              key={key}
              ref={isActive ? activeItemRef : null}
            >
              <Link className={cn(styles.item__link, isLoggedIn && styles['logged-in'])} href={url}>{t(title)}</Link>
              {isActive && (
                <div className={styles.item__underline} />
              )}

              {
                isActive && bannerToggle
              }
            </li>
          );
        })}
      </ul>

    </nav>
  );
};

export default withAmpStyles(
  ExperienceToggle,
  s,
  stylesObj,
  ampStylesObj,
);
