import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2PromoPopup } from 'v2/redux/meta/selectors';
import usePartnershipPopup from 'shared/helpers/hooks/usePartnershipPopup';

// we have to show notification after signup process for logged out users (see logout flow and hook usePreloadNotificationMsg)
//
// login -> ajax -> success: 1) show notif 2) if redirect -> navigate else reload the page
// logout -> ajax -> success -> redirect_url (signup) -> notification after registration
const usePreloadPartnershipPopup = ({ enabled, className }) => {
  const displayBanner = useSelector(getV2PromoPopup);
  // const displayBanner = true;
  const showPopup = usePartnershipPopup({ event: 'not_used', className });

  useEffect(() => {
    if (!enabled) return;
    if (!displayBanner) return;

    showPopup();
  }, [enabled, displayBanner, showPopup]);
};

export default usePreloadPartnershipPopup;
