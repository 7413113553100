
import React from 'react';
import { Context as ResponsiveContext } from 'react-responsive';
import { connect } from 'react-redux';
import { isBrowser } from 'helpers/utils';

import {
  oneOfType, node, func, number,
} from 'prop-types';

export const MediaQueryProvider = (props = {}) => {
  const {
    dispatch, fakeWidth, children, ...restProps
  } = props;

  const value = isBrowser ? {} : { width: fakeWidth };

  return (
    <ResponsiveContext.Provider value={value} {...restProps}>
      {children}
    </ResponsiveContext.Provider>
  );
};

MediaQueryProvider.propTypes = {
  children: oneOfType([node, func]),
  dispatch: func.isRequired,
  fakeWidth: number.isRequired,
};

MediaQueryProvider.defaultProps = {
  children: null,
};

const mapStateToProps = state => ({
  fakeWidth: state?.responsive?.fakeWidth,
});

export default connect(mapStateToProps, null)(MediaQueryProvider);
