import {
  isFunction,
  isBrowser,
  getGASubcribedId,
  getGoogleAnalyticsId,
  warnOnce,
} from 'helpers/utils';
import {
  postCourseClick,
  postDocumentReferralClick,
  getMobileNavbarClick,
  getPrevNextDocumentTracking,
  postAdblockDetect,
  postAhoyTrackCustom,
  getSaveGaClientId,
} from 'shared/common/api';
import {
  NOTES_URL_PREFIXES,
  HEAP_ID,
  documentReferralReasons,
} from 'helpers/constants';
import fbPixel from 'helpers/fbPixel';
import adblockDetect from 'adblock-detect';

const notesUrls = Object.values(NOTES_URL_PREFIXES).map(obj => obj.url);

export const generateHotjarTrack = (hj, prefix, delimiter = '_') => (...event) => {
  const eventStr = [prefix, ...event.map(ev => ev.toString().toLowerCase())].join(delimiter);

  hj('trigger', eventStr);
};


export const buttonTypes = {
  RIGHT_SIDEBAR_UNLOCK: 'right_sidebar_unlock',
  UPGRADE_BUTTON_UNLOCK: 'upgrade_button_unlock',
  TOP_UNLOCK: 'top_unlock',
  POPUP_BANNER_UNLOCK: 'popup_banner_unlock',
  BETWEEN_PAGES_UNLOCK: 'between_pages_unlock',
  BOTTOM_CTA: 'bottom_cta',
};

const getTrackingExpireDate = () => {
  const date = new Date();
  date.setTime(date.getTime() + 60000000);

  return date;
};


// TODO: Replace with npm cookies package
export const setDocumentCookie = (id) => {
  if (isBrowser && id) {
    document.cookie = `from_unique_doc_id=${id}; expires=${getTrackingExpireDate()}; path=/`;
  }
};

export const setButtonCookie = (type) => {
  if (isBrowser) {
    if (Object.values(buttonTypes).includes(type)) {
      document.cookie = `from_btn_doc_show_page=${type}; expires=${getTrackingExpireDate()}; path=/`;
    } else {
      throw new Error(`Incorrect Tracking Type: ${type}`);
    }
  }
};

export const trackSearchComplete = (value) => {
  if (isBrowser) {
    const data = {
      user_id: document.querySelector('body').dataset.user_id || null,
      search_value: value,
      label: 'Search Intent (search box)',
    };

    if (isFunction(window.trackGAEvent)) {
      window.trackGAEvent({ event_category: 'Search', action: 'complete_search', data });
    }
  }
};

export const trackPrevNextDocumentClick = ({
  isNext = false,
  currentDocumentId,
  destinationDocumentId,
}) => getPrevNextDocumentTracking({
  isNext,
  currentDocumentId,
  destinationDocumentId,
});

export const trackCourseLinkClick = id => postCourseClick({
  from_dropdown: false,
  notification_id: id,
});

export const determineAvailableReferralReasons = ({
  document = {},
  moreDocumentsFromUser = [],
  documentsClassmatesUnlocked = [],
}) => {
  const availableReasons = [];

  if (document.playlist24Hr) availableReasons.push(documentReferralReasons.PLAYLIST_24HR);
  if (moreDocumentsFromUser.length > 0) availableReasons.push(documentReferralReasons.MORE_FROM_USER);
  if (documentsClassmatesUnlocked.length > 0) availableReasons.push(documentReferralReasons.CLASSMATES_ALSO_UNLOCKED);

  return availableReasons.join(', ');
};

export const trackDocumentReferralClick = ({
  id,
  type,
  documentId,
  similarDocsParticipant,
  reason,
  availableReferralReasons,
}) => postDocumentReferralClick({
  target_id: id,
  target_type: type,
  source_document_id: documentId,
  similar_docs_ab: similarDocsParticipant,
  referral_reason: reason,
  available_referral_reasons: availableReferralReasons,
});

// TODO: Refactor
export const getNotesParams = (res = {}) => {
  try {
    const urlArr = window.location.pathname.split('/');
    const paramsArr = urlArr.slice(2);
    const params = {
      notes_browse: true,
      notes_kind: urlArr[1].replace('.en.html', ''),
      ...res,
    };

    // TODO: add a helper function that converts a url to params json
    const paramNames = ['country_n', 'school_n', 'dep_n', 'course_prof_n', 'doc_id_n'];

    paramsArr.forEach((param, indx) => {
      const paramName = paramNames[indx];

      params[paramName] = param.replace('.en.html', '');
    });

    return params;
  } catch (err) {
    console.error(err);
  }

  return res;
};

export const trackMobileNavbarClick = id => getMobileNavbarClick({ i_name: id });

export const addAuthTrackingParams = (data = {}) => {
  const {
    location: {
      pathname,
      search,
    },
  } = window;

  const notesPage = notesUrls.some(url => pathname.startsWith(url));

  let res = {
    current_url: `${pathname}${search}`,
  };

  if (notesPage) {
    res = getNotesParams(res);
  }

  return { ...data, ...res };
};

// TODO: refactor and merge gtag and dataLayerPush;
function gtag() { window.dataLayer.push(arguments); }

export function dataLayerPush(...args) {
  if (isBrowser && args && window.dataLayer) {
    args.forEach(arg => window.dataLayer.push(arg));
  }
}

export const sendGaClientId = (counter = 1) => {
  if (isBrowser && counter <= 10) {
    if (window.ga && window.ga.getAll) {
      const clientId = window.ga.getAll()[0].get('clientId');
      getSaveGaClientId(clientId);
    } else {
      setTimeout(() => sendGaClientId(counter + 1), 500);
    }
  }
};

export const initGATracking = (config) => {
  const gaID = getGoogleAnalyticsId();
  const gaSubscribedID = getGASubcribedId();

  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];

    window.gtag = gtag;
    window.gtag('js', new Date());
    if (config) {
      window.gtag('config', gaID, config);
    } else {
      window.gtag('config', gaID);
    }
    // window.gtag('config', gaSubscribedID);

    sendGaClientId();
  }
};

export const trackPageView = () => {
  if (isBrowser) {
    // if (window.gtag) {
    //   window.gtag('event', 'page_view', { send_to: getGoogleAnalyticsId() });
    // } else {
    //   setTimeout(trackPageView, 500);
    // }

    fbPixel.pageView();
  }
};

// obj example: { category: 'track/trackCustom', action: 'ViewContent/...', data: {} }
export const trackFbEvent = ({ category, action, data = {} }) => {
  if (isBrowser) {
    if (!isFunction(fbPixel.fbq)) {
      warnOnce('fbq is not defined');
      return;
    }
    fbPixel.fbq(category, action, data);
  }
};

// obj example: { action: 'event', name: 'downloaded_document', data: {} }
export const trackGAEvent = ({
  category = 'general',
  action,
  label,
  data = {},
}) => {
  if (isBrowser) {
    if (!isFunction(window.gtag)) {
      warnOnce('gtag is not defined');
      return;
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      send_to: getGoogleAnalyticsId(),
      ...data,
    });
    adblockDetect((adblockDetected) => {
      if (adblockDetected) {
        postAdblockDetect(action);
      }
    });
  }
};

export const trackHighTraficQuestion = () => {
  if (isFunction(window.gtag)) {
    // eslint-disable-next-line quote-props
    window.gtag('config', getGoogleAnalyticsId(), { 'content_group4': 'HighTrafficQuestions' });
  } else {
    warnOnce('gtag is not defined');
  }
};

export const trackUnlockFormTabClick = async ({
  document_id,
  payment_method,
  url,
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'change_payment_method',
    ahoy_params: {
      document_id: parseInt(document_id, 10),
      payment_method,
      url,
    },
  });
};
export const trackUnlockFormPlanSelect = async ({
  document_id,
  initial_plan,
  selected_plan,
  url,
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'change_plan_selection',
    ahoy_params: {
      document_id: parseInt(document_id, 10),
      initial_plan,
      selected_plan,
      url,
    },
  });
};
export const trackUnlockFormCardInfoChange = async ({
  document_id,
  card_state,
  url,
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'change_card_info',
    ahoy_params: {
      document_id: parseInt(document_id, 10),
      card_state,
      url,
    },
  });
};
export const trackUnlockFormPaymentMethodsAvailable = async ({
  document_id,
  url,
  payment_methods_available,
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'payment_methods_available',
    ahoy_params: {
      document_id: parseInt(document_id, 10),
      payment_methods_available,
      url,
    },
  });
};
export const trackUnlockButtonClicked = async ({
  document_id,
  url,
  buttonType,
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'unlock_intent',
    ahoy_params: {
      document_id: parseInt(document_id, 10),
      url,
      buttonType,
    },
  });
};

export const trackPaymentButtonClick = async ({
  from,
  document_id,
  url,
  ...oneClickPaymentProps
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'payment_button_click',
    ahoy_params: {
      document_id,
      url,
      from,
      ...oneClickPaymentProps,
    },
  });
};
export const trackAskQuestionButtonClick = async ({
  trackId,
  url,
  ...rest
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'hw_ask_question_button_click',
    ahoy_params: {
      url,
      trackId,
      ...rest,
    },
  });
};


/* eslint-disable func-names, no-unused-expressions, no-param-reassign, no-multi-assign, no-sequences, no-shadow , prefer-rest-params */
export const heapInit = function () {
  window.heap = window.heap || [], heap.load = function (e, t) {
    window.heap.appid = e, window.heap.config = t = t || {};
    const r = t.forceSSL || document.location.protocol === 'https:';
    const a = document.createElement('script');
    a.type = 'text/javascript', a.async = !0, a.src = `${r ? 'https:' : 'http:'}//cdn.heapanalytics.com/js/heap-${e}.js`;
    const n = document.getElementsByTagName('script')[0];
    n.parentNode.insertBefore(a, n);
    for (let o = function (e) {
        return function () {
          heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }, p = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty'], c = 0; c < p.length; c++) heap[p[c]] = o(p[c]);
  };
};
/* eslint-enable func-names, no-unused-expressions, no-param-reassign, no-multi-assign, no-sequences, no-shadow , prefer-rest-params */


export const heapLoad = (id = HEAP_ID) => {
  try {
    heapInit();
    window.heap.load(id);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err, 'When initializing heap');
  }
};
