const RECEIVE_PROFESSOR_DATA = 'common/professor/RECEIVE_PROFESSOR_DATA';
const GET_PROFESSOR_SUGGESTIONS = 'common/professor/GET_PROFESSOR_SUGGESTIONS';
const GET_PROFESSOR_SUGGESTIONS_SUCCESS = 'common/professor/GET_PROFESSOR_SUGGESTIONS_SUCCESS';
const GET_PROFESSOR_SUGGESTIONS_ERROR = 'common/professor/GET_PROFESSOR_SUGGESTIONS_ERROR';
const RESET_GET_PROFESSOR_SUGGESTIONS_ERROR = 'common/professor/RESET_GET_PROFESSOR_SUGGESTIONS_ERROR';
const GET_PROFESSOR = 'common/professor/GET_PROFESSOR';
const GET_PROFESSOR_SUCCESS = 'common/professor/GET_PROFESSOR_SUCCESS';
const GET_PROFESSOR_ERROR = 'common/professor/GET_PROFESSOR_ERROR';
const GET_PROFESSORS_BY_COURSE = 'common/professor/GET_PROFESSORS_BY_COURSE';
const GET_PROFESSORS_BY_COURSE_SUCCESS = 'common/professor/GET_PROFESSORS_BY_COURSE_SUCCESS';
const GET_PROFESSORS_BY_COURSE_ERROR = 'common/professor/GET_PROFESSORS_BY_COURSE_ERROR';

export {
  RECEIVE_PROFESSOR_DATA,
  GET_PROFESSOR_SUGGESTIONS,
  GET_PROFESSOR_SUGGESTIONS_SUCCESS,
  GET_PROFESSOR_SUGGESTIONS_ERROR,
  RESET_GET_PROFESSOR_SUGGESTIONS_ERROR,
  GET_PROFESSOR,
  GET_PROFESSOR_SUCCESS,
  GET_PROFESSOR_ERROR,
  GET_PROFESSORS_BY_COURSE,
  GET_PROFESSORS_BY_COURSE_SUCCESS,
  GET_PROFESSORS_BY_COURSE_ERROR,
};
