import { MODEL_TYPES } from '../constants';

import {
  normalizeData, isEmpty, isFunction, deepGenerateIDs, generateIDsByPaths,
} from 'shared/helpers/utils';
import combineMerge from 'shared/helpers/utils/combineMerge';

const generateUniqueIds = (modelData, opts = {}) => (
  Object.keys(modelData).reduce((acc, key) => {
    const obj = modelData[key];
    let { attributes } = obj;
    if (isFunction(opts.preprocess)) {
      attributes = opts.preprocess(attributes);
    }

    const newObj = {
      ...obj,
      attributes: opts.paths
        ? generateIDsByPaths(attributes, opts.paths)
        : deepGenerateIDs(attributes),
    };

    return { ...acc, [key]: newObj };
  }, {})
);

const getData = (res, opts = {}) => {
  const result = {};

  if (res) {
    res.forEach((item) => {
      const d = normalizeData(item);

      Object.entries(d).forEach(([modelName, models]) => {
        if (modelName && MODEL_TYPES.includes(modelName)) {
          if (!result[modelName]) result[modelName] = {};

          const modelOpts = opts.withUniqueIds ? opts.withUniqueIds[modelName] : {};
          // Add unique ids to arrays inside the attributes object
          const newModels = modelOpts ? generateUniqueIds(models, modelOpts) : models;

          result[modelName] = combineMerge(result[modelName], newModels, { strategy: opts.mergingStrategy });
        } else {
          console.error('Incorrect model type:', modelName);
        }
      });
    });
  }

  // Sort attributes alphabetically on dev for readability in redux tools
  // TODO: Remove after keys filtering will be implemented in redux devtools extension
  // https://github.com/reduxjs/redux-devtools/issues/361#issuecomment-301063788
  // if (IS_DEV) {
  //   result = Object.keys(result).reduce((acc, key) => {
  //     const obj = result[key];

  //     const newObj = {
  //       ...obj,
  //       attributes: sortObjectKeys(obj.attributes),
  //     };

  //     return { ...acc, [key]: newObj };
  //   }, {});
  // }
  return !isEmpty(result) ? result : null;
};

export default getData;
