import React from 'react';
import cn from 'classnames';
import { node, bool } from 'prop-types';
import Container from 'oc-core-components/src/Container';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SearchBanner.module.scss';

const styles = stylesProxy(stylesObj, 'SearchBanner');

const SearchBanner = ({
  children,
  compact,
  title,
  subtitle,
  className,
  ...restProps
}) => (
  <div className={cn(styles.container, { [styles.compact]: compact }, className)} {...restProps}>
    <Container className="u-px-m u-px-xs-sm">
      {
        !compact && (title || subtitle) && (
          <div className={styles.header}>
            {
              title && (
                <h1 className={styles.title}>{title}</h1>
              )
            }

            {
              subtitle && (
                <div className={styles.subtitle}>{subtitle}</div>
              )
            }
          </div>
        )
      }

      {children}
    </Container>

  </div>
);

SearchBanner.propTypes = {
  compact: bool,
  dark: bool,
  title: node,
  subtitle: node,
};

export default SearchBanner;
