import { takeEvery, put } from 'redux-saga/effects';
import { receiveDepartmentData } from 'common/department/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { getDataByType } from 'common/utils';
import { warnOnce } from 'helpers/utils';
import { GET_DEPARTMENT_SUCCESS, GET_DEPARTMENTS_SUCCESS } from './constants';

// TODO: remove res when all actions are updated with redux-actions
function* storeDepartmentData({ res, payload }) {
  if (res) warnOnce('DEPRECATED: "storeDepartmentData" replace res with payload when using redux-actions', true);
  const departments = getDataByType('department', payload || res);
  if (departments) yield put(receiveDepartmentData(departments));
}

function* departmentSaga() {
  yield takeEvery([RECEIVE_PAGE_DATA, GET_DEPARTMENT_SUCCESS, GET_DEPARTMENTS_SUCCESS], storeDepartmentData);
}

export default departmentSaga;
