import { takeEvery, put } from 'redux-saga/effects';
import { receiveCountryData } from 'common/country/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { getDataByType } from 'common/utils';
import { warnOnce } from 'helpers/utils';
import { GET_COUNTRIES_SUCCESS } from './constants';

// TODO: remove res when all actions are updated with redux-actions
function* storeCountryData({ res, payload }) {
  if (res) warnOnce('DEPRECATED: "storeCountryData" replace res with payload when using redux-actions', true);
  const countries = getDataByType('country', payload || res);
  if (countries) yield put(receiveCountryData(countries));
}

function* countrySaga() {
  yield takeEvery([RECEIVE_PAGE_DATA, GET_COUNTRIES_SUCCESS], storeCountryData);
}

export default countrySaga;
