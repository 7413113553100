import React, { Fragment } from 'react';
import {
  string, number, func, node, bool, oneOfType, shape,
} from 'prop-types';
import { Provider } from './RadioGroupContext';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './RadioGroup.module.scss';

const styles = stylesProxy(stylesObj, 'RadioGroup');

Provider.displayName = 'RadioGroupProvider';

// move to hw_components
const RadioGroup = ({
  selected,
  onChange,
  name,
  disabled,
  children,
  error,
  errorMessage,
}) => (
  <Fragment>
    <Provider
      value={{
        selected,
        onChange,
        name,
        disabledGroup: disabled,
      }}
    >
      {children}
    </Provider>
    {error && <p className={styles.errorMessage}>{errorMessage}</p>}
  </Fragment>

);

RadioGroup.propTypes = {
  children: node.isRequired,
  name: string.isRequired,
  selected: oneOfType([string, number, shape()]),
  onChange: func,
  disabled: bool,
  error: bool,
  errorMessage: string,
};

RadioGroup.defaultProps = {
  disabled: false,
};

export default RadioGroup;
