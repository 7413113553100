import React from 'react';
import Script from 'next/script';
import { GOOGLE_CLIENT_ID, cookiesKeys } from 'helpers/constants';
import { useRouter } from 'oc-core-components/src/RouterProvider';
// import Head from 'next/head';
import buildUrl from 'helpers/utils/buildUrl';
import getEnv from 'helpers/utils/getEnv';
import { cookies } from 'helpers/utils';
import { withHost } from 'common/api';

const env = getEnv();


const GoogleAutoSignIn = () => {
  const { router: { asPath } = {} } = useRouter();
  const url = withHost(buildUrl('/auth/google_oauth2_one_tap', { state: asPath }));

  // if (env.IS_CYPRESS_TESTING_ENV) return null;
  if (cookies.get(cookiesKeys.IS_CYPRESS_TESTING_ENV) === 'true') return null;

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://accounts.google.com/gsi/client"
        async
      />
      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-login_uri={url}
      />
    </>
  );
};

export default GoogleAutoSignIn;
