import React from 'react';
import { Tab as ReactTab } from 'react-tabs';
import { bool, string } from 'prop-types';

import SimpleTab from './SimpleTab';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Tabs.module.scss';

const styles = stylesProxy(stylesObj, 'Tabs');

const propTypes = {
  disabled: bool,
  isLabel: bool,
  disabledClassName: string,
  selectedClassName: string,
};

const defaultProps = {
  disabledClassName: styles['is-disabled'],
  selectedClassName: styles['is-active'],
};

const Tab = (props) => {
  const {
    disabled,
    isLabel,
    ...restProps
  } = props;

  return (
    <SimpleTab
      component={ReactTab}
      isLabel={isLabel}
      disabled={isLabel || disabled}
      {...restProps}
    />
  );
};

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;
Tab.tabsRole = 'Tab';

export default Tab;
