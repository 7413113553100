import React from 'react';
import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './MobileFullScreenModalWrapper.module.scss';

const styles = stylesProxy(stylesObj, 'MobileFullScreenModalWrapper');

export const ModalClose = ({ className, ...restProps }) => (
  <Icon
    icon="feather:x"
    tag="button"
    role="button"
    aria-label="close popup"
    className={className}
    // className={cn(styles.close_btn, className)}
    size={22}
    {...restProps}
  />
);

const DefaultHeader = ({ headerText }) => {
  const { close } = useModal();

  return (
    <div className="u-d-flex">
      <ModalClose onClick={close} />

      <div className={styles.header_text}>{headerText}</div>
    </div>
  );
};

// consider merge with desktop responsive
const MobileFullScreenModalWrapper = ({
  className,
  header: customHeader,
  headerText,
  headerClassName,

  contentClassName,
  contentInnerClassName,
  BeforeComponent,
  // centerContent,
  children,
  ...restProps
}) => {
  const header = customHeader || <DefaultHeader headerText={headerText} />;

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      {
        // for elements with absolute positioning
        BeforeComponent && <BeforeComponent />
      }
      {
        header && (
          <div className={cn(styles.header, headerClassName)}>
            {header}
          </div>
        )
      }

      <div className={cn(styles.content, contentClassName)}>
        <div className={cn(styles.content_inner, contentInnerClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};


export default MobileFullScreenModalWrapper;
