import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { stylesProxy } from 'helpers/css';
import withAmpStyles from 'helpers/withAmpStyles';
import Icon from 'oc-core-components/src/Icon';
import { bool, func, string } from 'prop-types';
import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import ClickOutside from 'react-click-outside-component';
import { MENU_ITEMS_EXPERIENCE_CHANGER, MENU_ITEMS_PRICING } from './constants';
import LivestreamDot from './LivestreamDot';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';
import NavbarMenuIcon from './NavbarMenuIcon';

const s = stylesProxy(stylesObj, 'Navbar');

const menuMainItems = MENU_ITEMS_EXPERIENCE_CHANGER(false);

const SideNavigation = ({
  active,
  loginUrl,
  onClose,
  signupUrl,
  streamActive,
  styles,
}) => {
  const containerRef = useRef();
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (containerRef.current) {
      if (active) {
        disableBodyScroll(containerRef.current);
      } else {
        console.log(containerRef);
        enableBodyScroll(containerRef.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    <>
      <div className={styles['side-navigation-overlay']} />
      <ClickOutside
        className={cn(
          styles['side-navigation'],
          active && styles['side-navigation--active'],
        )}
        onClickOutside={onClose}
      >
        <button
          className={styles['side-navigation__button-close']}
          onClick={onClose}
        >
          <Icon icon="feather:x" size={24} />
        </button>

        <nav
          className={styles['side-navigation__container']}
          ref={containerRef}
        >
          <ul className={styles['side-navigation__menu']}>
            {menuMainItems.map(
              ({
                key, icon, iconFill, iconStroke, livestream, title, url,
              }) => (
                <li key={key}>
                  <a
                    className={styles['side-navigation__menu__item']}
                    href={url}
                  >
                    <NavbarMenuIcon
                      fill={iconFill}
                      icon={icon}
                      stroke={iconStroke}
                    />

                    <div
                      className={styles['side-navigation__menu__item__text']}
                    >
                      {t(title)}
                      {livestream && (
                      <LivestreamDot streamActive={streamActive} />
                      )}
                    </div>
                  </a>
                </li>
              ),
            )}

            <li className={styles['side-navigation__menu__item-separator']} />

            <li>
              <button
                type="button"
                className={styles['side-navigation__menu__item-group']}
                onClick={() => setIsPricingOpen(prevState => !prevState)}
              >
                <p className={styles['side-navigation__menu__group__text']}>
                  {t('navbar:navbar.buttonPricing')}
                </p>

                <Icon
                  icon={
                    isPricingOpen
                      ? 'feather:chevron-up'
                      : 'feather:chevron-down'
                  }
                  size={18}
                />
              </button>

              <ul
                className={cn(
                  styles['side-navigation__menu__item-group__children'],
                  isPricingOpen
                    && styles[
                      'side-navigation__menu__item-group__children--active'
                    ],
                )}
              >
                {MENU_ITEMS_PRICING.map(({
                  key, title, url, icon, iconStroke,
                }) => (
                  <li key={key}>
                    <a
                      className={styles['side-navigation__menu__item']}
                      href={url}
                    >
                      <NavbarMenuIcon
                        icon={icon}
                        stroke={iconStroke}
                      />

                      <div
                        className={styles['side-navigation__menu__item__text']}
                      >
                        {t(title)}
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <a
                className={styles['side-navigation__menu__item']}
                href={loginUrl}
              >
                <p className={styles['side-navigation__menu__item__text']}>
                  {t('navbar:navbar.buttonLogIn')}
                </p>
              </a>
            </li>

            <li>
              <a
                className={styles['side-navigation__menu__item']}
                href={signupUrl}
              >
                <p className={styles['side-navigation__menu__item__text']}>
                  {t('navbar:navbar.buttonSignUp')}
                </p>
              </a>
            </li>
          </ul>
        </nav>
      </ClickOutside>
    </>
  );
};

SideNavigation.propTypes = {
  active: bool,
  loginUrl: string,
  onClose: func,
  signupUrl: string,
  streamActive: bool,
};

export default withAmpStyles(SideNavigation, s, stylesObj, ampStylesObj);
