import React, { useState } from 'react';
import { bool } from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import useScrollPosition from 'helpers/hooks/useScrollPosition';
import { isBrowser } from 'helpers/utils';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './HowItWorksToggle.module.scss';

const styles = stylesProxy(stylesObj, 'HowItWorksToggle');

const breakpoint = 40;

// const isOnTop = pos => pos <=

const HowItWorksToggle = ({
  className,
  expanded,
  ...restProps
}) => {
  const { t } = useTranslation('common');
  const [isHidden, setHidden] = useState(!isBrowser || (window.pageYOffset > breakpoint));

  useScrollPosition(({ currPos }) => {
    const scrolledDown = currPos.y <= -breakpoint;

    if(scrolledDown !== isHidden) {
      setHidden(scrolledDown);
    }
  }, [isHidden]);

  if (isHidden) {
    return null;
  }

  return (
    <Link className={cn(styles.container, className, { [styles.expanded]: expanded })} {...restProps}>
      <span className={styles.inner}>
        {t('how_it_works')}
      </span>
      <Icon icon="oc:chevron-down-fill" size={12} stroke="none" className={styles.icon} />
    </Link>
  );
};

HowItWorksToggle.propTypes = {
  expanded: bool,
};

export default HowItWorksToggle;
