
import { ROUTES } from 'helpers/constants';
import { logError } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';

export const getCourseUploadLink = ({
  courseId,
  schoolId,
  kind,
} = {}) => {
  try {
    return buildUrl(ROUTES.docUpload, { course_id: courseId, kind, school_id: schoolId });
  } catch (e) {
    logError(e);
    return undefined;
  }
};

export default {};
