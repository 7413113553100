import { handleModelActions } from 'v2/redux/utils/handleActions';
import { TOPIC_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const topicReducer = handleModelActions(TOPIC_MODEL_TYPE, {}, initialState);

export { initialState };

export default topicReducer;
