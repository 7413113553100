import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import Button from 'oc-core-components/src/Button';
import LoginSection from 'components/Home/LoginSection';
import { isBrowser } from 'helpers/utils';
import scrollToBlock from 'helpers/utils/scrollToBlock';
import { useAuth } from 'oc-core-components/src/AuthProvider';
import { HEADER_ID } from 'helpers/constants';
import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './LoginAndBackToTopSection.module.scss';
import ampStylesObj from './LoginAndBackToTopSection.module.scss?amp&type=resolve';

const useUniversalEffect = isBrowser ? useLayoutEffect : useEffect;

const s = stylesProxy(stylesObj, 'LoginAndBackToTopSection');

const LoginAndBackToTopSection = ({
  styles,
  className,
}) => {
  const { t } = useTranslation('common');
  const { isLoggedIn } = useAuth();
  const [showGoToTop, setGoToTop] = useState();
  const goToTopRef = useRef(null);

  useUniversalEffect(() => {
    const goToTop = goToTopRef.current;

    if(goToTop) {
      const topOffset = goToTop.getBoundingClientRect().top + window.scrollY;

      if(topOffset > window.innerHeight) {
        setGoToTop(true);
      }
    }
  }, [])


  return (
    <div className={cn(styles.container, className)}>
      {!isLoggedIn && (
        <LoginSection className="u-mb-l u-mb-xl-md u-px-xs u-px-0-md"/>
      )}


      <div ref={goToTopRef} className={cn('u-mb-l u-mb-xl-md u-px-xs u-px-0-md', { [styles.hidden] : !showGoToTop })}>
        <Button
          onClick={() => scrollToBlock('body')}
          color="none"
          className={styles.top}
          size="md"
          fullWidth
          on={`tap:${HEADER_ID}.scrollTo(duration=400)`}
        >
          {t('back_to_top')}
        </Button>
      </div>
    </div>
  );
};

export default withAmpStyles(LoginAndBackToTopSection, s, stylesObj, ampStylesObj);
