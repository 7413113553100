import React, { useMemo } from 'react';
import {
  bool, node, number, shape, string,
} from 'prop-types';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';

import cn from 'classnames';
import { useTranslation } from 'config/i18n';

import Link from 'oc-core-components/src/Link';

import { stylesProxy } from 'helpers/css';
import { getImage, isNil, isFunction } from 'helpers/utils';

import stylesObj from './BoosterClassCard.module.scss';

const styles = stylesProxy(stylesObj, 'BoosterClassCard');

const bgColorsMap = {
  mathematics: '#eff8f6',
  chemistry: '#faf2ea',
  economics: '#e2effc',
  psychology: '#f7ecf8',
  physics: '#EDF1FC',
  statistics: '#edf1fc',
};

const imagesList = ['mathematics', 'chemistry', 'accounting', 'economics', 'physics', 'psychology', 'statistics'];

const getImageHandle = (topicHandle) => {
  if (['microeconomics', 'macroeconomics'].includes(topicHandle)) {
    return 'economics';
  }

  return topicHandle;
};

const BoosterClassCard = ({
  className,
  name,
  description,
  topic,
  skillsCount,
  isCredited,
  url,
  tutorAvatarUrl,
  topicHandle,
  style,
  isPurchased,
  percentComplete,
  renderDescription,
  renderName,
}) => {
  const { t } = useTranslation('boosterClass');
  const imageHandle = getImageHandle(topicHandle);

  const imgPath = useMemo(() => (
    imagesList.includes(imageHandle)
      ? getImage(`pages/booster_class/card_images/${imageHandle}.svg`)
      : undefined
  ), [imageHandle]);

  const cssStyles = useMemo(() => ({
    backgroundColor: bgColorsMap[imageHandle],
    ...style,
  }), [style, imageHandle]);

  const nameFormatted = isFunction(renderName) ? renderName(name) : name;
  const descriptionFormatted = isFunction(renderDescription) ? renderName(description) : description;

  return (
    <Link
      className={cn(styles.container, className)}
      style={cssStyles}
      href={url}
      defaultTag="div"
    >

      <Row noGutters className="u-position-relative u-h-100">
        <Col className={styles.content}>
          <div className={styles.inner}>
            <div>
              {
                topic && (
                  <div className={styles.topic}>{topic}</div>
                )
              }

              {
                nameFormatted && (
                  <div className={styles.name}>{nameFormatted}</div>
                )
              }

              {
                descriptionFormatted && (
                  <div className={styles.description}>{descriptionFormatted}</div>
                )
              }
            </div>


            <div className={styles.footer}>
              { isCredited
                ? (
                  <div className={styles.credits}>
                    {
                      tutorAvatarUrl && (
                        <img src={tutorAvatarUrl} className={styles.tutor} alt="Oneclass Tutor" />
                      )
                    }

                    {/* TODO: 3 college credits or auditing */}
                    {t('college_credit', { count: 3 })}
                  </div>
                )
                : (
                  <>
                    <div className={styles.skills}>{t('skills_num', { count: skillsCount })}</div>
                    {!isNil(percentComplete) && (
                    <div className={styles['progress-container']}>
                      <div className={cn(styles.progress, { [styles.progress_visible]: isPurchased })}>
                        <div
                          className={styles.progress__bar}
                          style={{ width: `${percentComplete}%` }}
                        />
                      </div>
                    </div>
                    )}
                  </>
                )
              }
            </div>
          </div>
        </Col>

        {
          imgPath && (
            <img className={styles.img} src={imgPath} alt={`Topic ${topicHandle} thumbnail`} />
          )
        }
      </Row>
      {
        // TODO: add backend value
        isPurchased && (
          <div className={styles.badge}>
            <Icon
              size={16}
              className={styles.badge__icon}
              stroke="none"
              fill="light-lt"
              icon="oc:booster-purchase"
            />
          </div>
        )
      }
    </Link>
  );
};

BoosterClassCard.propTypes = {
  name: node,
  description: node,
  topic: string,
  skillsCount: number,
  isCredited: bool,
  url: string,
  tutorAvatarUrl: string,
  topicHandle: string,
  style: shape(),
  creditedUnits: number,
  isPurchased: bool,
  percentComplete: number,
};

BoosterClassCard.defaultProps = {

};

export default BoosterClassCard;
