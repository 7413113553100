import React from 'react';
import { useAmp } from 'next/amp';
import LazyImage, { PureLazyImage } from './LazyImage';
import AmpLazyImage from './amp/LazyImage';


const LazyImageWrapper = (props) => {
  const isAmp = useAmp();

  return isAmp ? (
    <AmpLazyImage {...props} />
  ) : (
    <LazyImage {...props} />
  );
};

export default LazyImageWrapper;
export {PureLazyImage};
