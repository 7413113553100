import React, { useMemo } from 'react';
import cn from 'classnames';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';
import Link from 'oc-core-components/src/Link';
import UploadLinkButton from 'components/pages/ProductBrowse/UploadLinkButton';
import { shape, string } from 'prop-types';
import { kindsByMaterialType } from 'oc_components/src/pages/Search/utils';
import { useSelector } from 'react-redux';
import {
  selectV2PageFilters,
} from 'v2/redux/page/selectors';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './UploadDocsBanner.module.scss';

const styles = stylesProxy(stylesObj, 'UploadDocsBanner');

const MORE_LINK = 'https://oneclass.com/blog/featured/189193-how-does-the-oneclass-revenue-sharing-program-work3F.en.html';

const UploadDocsBanner = ({
  className,
  params: customParams,
  moreLink,
  ...restProps
}) => {
  const pageFilters = useSelector(selectV2PageFilters);

  const {
    materialType,
    schoolId,
    courseId,
  } = pageFilters || {};

  const params = useMemo(() => ({
    school_id: schoolId,
    course_id: courseId,
    kind: kindsByMaterialType[materialType],
    ...customParams,
  }), [materialType, schoolId, courseId, customParams]);

  return (
    <div className={cn(styles.container, className)} {...restProps}>
      <Row className="u-justify-content-center u-align-items-center">
        <Col xs="12" lg="auto">
          <Icon icon="oc:revshare" stroke="none" size={40} />
        </Col>
        <Col xs="12" lg="auto" className="u-my-xs u-my-0-lg">
          Upload your study documents today and earn recurring revenue or sitewide access! <Link href={moreLink}>Learn more</Link>
        </Col>
        <Col xs="12" lg="auto">
          <UploadLinkButton
            className="u-px-s u-py-xxs"
            params={params}
          />
        </Col>

      </Row>
    </div>
  );
};

UploadDocsBanner.propTypes = {
  params: shape(),
  moreLink: string,
};

UploadDocsBanner.defaultProps = {
  moreLink: MORE_LINK,
};

export default UploadDocsBanner;
