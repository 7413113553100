import React from 'react';
import {
  string, node, oneOf, bool, oneOfType,
} from 'prop-types';

import { warnOnce } from 'helpers/utils';
import cn from 'classnames';

import Label from 'oc-core-components/src/Label';
import InputFeedback from 'oc-core-components/src/InputFeedback';

import config from 'config';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './FormField.module.scss';
import ampStylesObj from './FormField.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'FormField');

const sizes = config.inputSizes;

// const STATUS = {
//   SUCCESS: 'success',
//   ERROR: 'error',
// };


const FormFieldWrapper = () => (WrappedComponent) => {
  const FormField = (props) => {
    /* eslint-disable prefer-const */
    let {
      id,
      label,
      labelSize,
      errorMessage,
      wrapperClassName,
      labelClassName,
      disabled,
      readonly,
      cssSize,
      error,
      success,
      hideErrorMessage,
      errorClassName,
      wrapperStyles: styles,
      // status,
      // className,
      ...restProps
    } = props;
    /* eslint-enable prefer-const */
    if (!labelSize) {
      labelSize = cssSize;
    }
    // Validation status

    if (error && success) {
      warnOnce('Incorrect validation status (SUCCESS and ERROR at the same time)');
      success = false;
    }

    if (!errorMessage && typeof error === 'string') {
      errorMessage = error;
      error = !!error;
    }

    return (
      <div className={cn(styles.container, wrapperClassName)}>
        {
          label && (
            <Label size={labelSize} className={labelClassName} htmlFor={id}>
              {label}
            </Label>
          )
        }

        <WrappedComponent
          id={id}
          disabled={disabled}
          readOnly={readonly}
          error={error}
          success={success}
          cssSize={cssSize}
          {...restProps}
        />

        {
          (error && !hideErrorMessage) && (
            <InputFeedback error className={errorClassName}>
              {errorMessage}
            </InputFeedback>
          )
        }

      </div>
    );
  };

  FormField.propTypes = {
    id: string,
    label: node,
    errorMessage: string, // Error message
    // status: oneOf(Object.values(STATUS)), // Validation Status
    error: oneOfType([bool, string]), // Validation error status
    success: bool, // Validation success status
    wrapperClassName: string, // Container classes
    labelClassName: string, // Label classes
    cssSize: oneOf(sizes), // Input size
    labelSize: oneOf(sizes), // Label size
    disabled: bool, // Input disabled attribute
    readonly: bool, // Input disabled attribute
    hideErrorMessage: bool,
  };

  FormField.defaultProps = {
    cssSize: sizes[0],
  };


  return withAmpStyles(FormField, s, stylesObj, ampStylesObj, { stylesProp: 'wrapperStyles' });
};


export default FormFieldWrapper;
