import cn from 'classnames';
import { stylesProxy } from 'helpers/css';
import withAmpStyles from 'helpers/withAmpStyles';
import { bool } from 'prop-types';
import React from 'react';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Navbar');

const LivestreamDot = ({ streamActive, styles }) => (
  <div
    className={cn(
      styles['dot-wrapper'],
      streamActive && styles['dot-wrapper--active']
    )}
  >
    <div className={styles.dot} />
  </div>
);

LivestreamDot.propTypes = {
  streamActive: bool,
};

export default withAmpStyles(LivestreamDot, s, stylesObj, ampStylesObj);
