import React, { createContext, useMemo } from 'react';
import { shape } from 'prop-types';

const defaultContextToProps = (context, props) => ({ ...context, ...props });

const getProps = (context, key, props, fallbackContextToProps) => {
  if (!context || !context[key]) {
    return props;
  }

  const getPropsFunc = context[key].contextToProps || fallbackContextToProps;

  return getPropsFunc(context[key].props, props);
};


export const GlobalPropsContext = createContext({});

export const withGlobalProps = (Component, key, contextToProps = defaultContextToProps) => props => (
  <GlobalPropsContext.Consumer>
    {context => <Component {...getProps(context, key, props, contextToProps)} /> }
  </GlobalPropsContext.Consumer>
);


const GlobalPropsProvider = ({
  initialPropsMap,

  children,
}) => {
  const propsMap = useMemo(() => ({
    ...initialPropsMap,
  }), [initialPropsMap]);


  return (
    <GlobalPropsContext.Provider
      value={propsMap}
    >
      {children}
    </GlobalPropsContext.Provider>
  );
};

GlobalPropsProvider.propTypes = {
  initialPropsMap: shape(),
};

export default GlobalPropsProvider;
