import React from 'react';
import { shape } from 'prop-types';
import { setMobileDetect } from 'oc-core-components/src/MediaQuery/actions';
import { mobileParser } from 'oc-core-components/src/MediaQuery/utils';
import merge from 'lodash/merge';

import { getPageIdFromContext, getReduxStateFromInitProps } from 'shared/helpers/preloadedStateUtils';
import initializeStore from '../store';

const isServer = typeof window === 'undefined';

// eslint-disable-next-line no-underscore-dangle
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState);
  }
  // Create store if unavailable on the client and set it on the window object
  // eslint-disable-next-line no-underscore-dangle

  if (!window[__NEXT_REDUX_STORE__]) {
    // eslint-disable-next-line no-underscore-dangle
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }
  // eslint-disable-next-line no-underscore-dangle
  return window[__NEXT_REDUX_STORE__];
}

// eslint-disable-next-line arrow-body-style
const withReduxStore = (App) => {
  return class AppWithRedux extends React.Component {
    static propTypes = {
      initialReduxState: shape(),
    }

    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore();
      // Provide the store to getInitialProps of pages
      // eslint-disable-next-line no-param-reassign
      appContext.ctx.reduxStore = reduxStore;

      const { dispatch } = reduxStore;
      const mobileDetect = mobileParser(appContext.ctx.req);


      dispatch(setMobileDetect(mobileDetect));

      let appProps = {};
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps.call(App, appContext);
      }

      const currentPage = getPageIdFromContext(appContext);
      const currentPath = appContext.router.asPath;
      // set global variables to get access in utils/constants
      // TODO: discuss
      if (typeof window === 'undefined') {
        global.INITIAL_PAGE_PATH = currentPath;
      }

      const preloadedState = getReduxStateFromInitProps(appProps, currentPage, currentPath);

      const preloadedInitialState = merge({}, reduxStore.getState(), preloadedState);

      return {
        ...appProps,
        initialReduxState: preloadedInitialState,
      };
    }

    constructor(props) {
      super(props);

      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  };
};


export default withReduxStore;
