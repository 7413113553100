import React from 'react';
import {
  string, node, bool, func,
} from 'prop-types';

import dynamic from 'next/dynamic';
import { getDynamicImportProps } from 'helpers/utils';
import Link from 'oc-core-components/src/Link';
import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Toolbar.module.scss';
import ampStylesObj from './Toolbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Toolbar');


const dynamicProps = getDynamicImportProps();

const MessageCounter = dynamic(
  () => import(/* webpackChunkName: "chunk_MessageCounter" */ 'oc-core-components/src/Notifications/NotificationsCounterContainer'), dynamicProps,
);


const ToolbarMenuItem = ({
  href,
  children,
  onClick,
  showNotifications,
  nofollow,
  styles,
}) => (
  <li className={styles.dd__item}>
    <Link nofollow={nofollow} className={styles.dd__link} href={href} onClick={onClick}>
      {children}

      {
        showNotifications
        && <MessageCounter className={styles.dd__count} empty />
      }
    </Link>
  </li>
);


ToolbarMenuItem.propTypes = {
  href: string,
  children: node,
  showNotifications: bool,
  onClick: func,
  nofollow: bool,
};


export default withAmpStyles(ToolbarMenuItem, s, stylesObj, ampStylesObj);
