import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Button from 'oc-core-components/src/Button';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Logo from 'oc-core-components/src/Logo';
import LazyImage from 'oc-core-components/src/LazyImage';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import {
  useRouter,
} from 'oc-core-components/src/RouterProvider';
import { postTrackBranchIo } from 'shared/common/api';
import {
  getV2CurrentUserId,
} from 'v2/redux/meta/selectors';

import { getImage } from 'shared/helpers/utils';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './BranchIOPopup.module.scss';

const styles = stylesProxy(stylesObj, 'BranchIOPopup');

// NOTE: Maggie - ah actually i think just the safari one should be fine since most iphone users use safari
const BROWSER_IMG_SRC = getImage('/pages/dashboard/safari.svg');

const BranchIOPopup = ({ link, productId }) => {
  const { close } = useModal();
  const currentUserId = useSelector(getV2CurrentUserId);
  const currentPage = useSelector(getV2CurrentPage);

  const {
    router: {
      query: {
        utm_campaign: utmCampaign,
        utm_source: utmSource,
        utm_medium: utmMedium,
      },
    },
  } = useRouter();

  const onOpenClick = useCallback(async () => {
    const { data } = await postTrackBranchIo({
      kind: 'click_branchio',
      user_id: currentUserId,
      product_id: productId,
      product_name: currentPage === 'documentShow' ? 'document' : 'question',
      utm_campaign: utmCampaign,
      utm_source: utmSource,
      utm_medium: utmMedium,
    });

    if (link) {
      window.location.assign(link);
    } else {
      window.location.reload();
    }
  }, [currentUserId, productId, currentPage, utmMedium, utmSource, utmCampaign, link]);

  return link ? (
    <div>
      <Row className={styles.header}>
        <Col>
          <h1 className={styles.title}>Continue in...</h1>
        </Col>
      </Row>

      <Row className={styles.body_row} noGutters>
        <Col className={styles.label}>
          <div className={styles.app_frame}>
            <Logo small />
          </div>

          OneClass App
        </Col>
        <Col className={styles.cta_row} xs="auto">
          <Button className={styles.btn} size="lg" color="dark" onClick={onOpenClick}>Open</Button>
        </Col>
      </Row>

      <Row className={styles.body_row} noGutters>
        <Col className={styles.label}>
          <div className={styles.app_frame}>
            <LazyImage
              src={BROWSER_IMG_SRC}
              alt="Web browser thumbnail"
              imgClassName={styles.browser_img}
              disableLazy
            />
          </div>

          Browser
        </Col>
        <Col className={styles.cta_row} xs="auto">
          <Button className={styles.btn} size="lg" outline color="light-lt" onClick={close}>Continue</Button>
        </Col>
      </Row>
    </div>
  ) : null;
};

BranchIOPopup.propTypes = {};

export default BranchIOPopup;
