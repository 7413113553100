import React, { memo, useMemo } from 'react';
import Link from 'oc-core-components/src/Link';
import Button from 'oc-core-components/src/Button';
import Container from 'oc-core-components/src/Container';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import Icon from 'oc-core-components/src/Icon';
import { createMarkup } from 'helpers/utils';
import removeEmptyFields from 'helpers/utils/removeEmptyFields';
import cn from 'classnames';
import { HEADER_BANNER_ID } from 'helpers/constants';
import useSummerChallengePopup from 'shared/helpers/hooks/useSummerChallengePopup';
// eslint-disable-next-line import/order
import { string } from 'prop-types';

import { stylesProxy } from 'helpers/css';
import stylesObj from './DefaultNavbarBanner.module.scss';

const styles = stylesProxy(stylesObj, 'DefaultNavbarBanner');


const DefaultNavbarBanner = ({
  className,
  buttonClassName,
  buttonColor,

  url,
  bannerText,
  mobileBannerText,

  buttonText,
  mobileButtonText,

  bannerBgColor,
  bannerTextColor,

  buttonBgColor,
  buttonTextColor,

  buttonBorderColor,

  featherIcon,

  showPopup,
}) => {
  const isMobile = useMobile();
  const callSummerPopup = useSummerChallengePopup({ event: 'banner_click' });

  let onClick;
  if (showPopup && showPopup === 'summerChallenge') {
    onClick = callSummerPopup;
  }

  const WrapperComponent = url ? Link : 'div';

  const bannerContent = useMemo(() => {
    const content = (isMobile && mobileBannerText) ? mobileBannerText : bannerText;
    return content ? createMarkup(content) : null;
  },
  [mobileBannerText, bannerText, isMobile]);

  const buttonContent = useMemo(() => {
    const content = (isMobile && mobileBannerText) ? mobileButtonText : buttonText;
    return content ? createMarkup(content) : null;
  },
  [isMobile, mobileBannerText, mobileButtonText, buttonText]);

  const containerStyle = useMemo(() => removeEmptyFields({
    backgroundColor: bannerBgColor,
    color: bannerTextColor,
  }), [bannerBgColor, bannerTextColor]);

  // unused so far
  const buttonStyle = useMemo(() => removeEmptyFields({
    // backgroundColor: buttonBgColor,
    // borderColor: buttonBorderColor,
    // color: buttonTextColor,
  }), [buttonBgColor, buttonTextColor]);

  return (
    <WrapperComponent
      className={cn(styles.container, className)}
      href={onClick ? undefined : url}
      onClick={onClick}
      onClickTag={onClick ? 'a' : undefined}
      id={HEADER_BANNER_ID}
      style={containerStyle}
    >
      <Container>
        <Row noGutters className="u-justify-content-center u-align-items-center">

          <Col md="auto" className={styles.col}>
            {
              featherIcon && (
                <Icon size="3xs" className={styles['text-icon']} icon={`feather:${featherIcon}`} />
              )
            }
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={bannerContent}
            />
          </Col>

          <Col xs="auto">
            {
              buttonContent && (
                <Button
                  className={cn(styles.btn, buttonClassName)}
                  color={buttonColor}
                  style={buttonStyle}
                >
                  <span
                    // eslint-disable-next-line react/no-danger
                    className={styles.btn_content}
                    dangerouslySetInnerHTML={buttonContent}
                  />
                  {/* <Icon size="3xs" className={styles.icon} icon="feather:chevron-right" /> */}
                </Button>

              )
            }
          </Col>
        </Row>

      </Container>
    </WrapperComponent>
  );
};

DefaultNavbarBanner.propTypes = {
  buttonClassName: string,
  buttonColor: string,

  url: string,
  bannerText: string,
  mobileBannerText: string,

  buttonText: string,
  mobileButtonText: string,

  bannerBgColor: string,
  bannerTextColor: string,

  buttonBgColor: string,
  buttonTextColor: string,

  buttonBorderColor: string,

  featherIcon: string,
  showPopup: string,
};

// DefaultNavbarBanner.defaultProps = {
//   buttonColor: 'none',

//   url: '#',
//   bannerText: 'Test Banner Text',
//   mobileBannerText: 'Mobile Test Banner Text',

//   buttonText: 'Button Text',
//   mobileButtonText: 'Mobile Banner Text',

//   bannerBgColor: 'red',
//   bannerTextColor: 'black',

//   buttonBgColor: 'green',
//   buttonTextColor: 'blue',

//   featherIcon: 'flag',
// };

export default memo(DefaultNavbarBanner);
