// TODO: Modify oc-core-components/src/Page directly to change the navbar

import OneclassPage from 'oc-core-components/src/Page';
import {
  func, node, shape, oneOfType,
} from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { BottomNavbarLogin } from 'shared/components/BottomNavbar';
import { NavbarContainer } from 'shared/components/Navbar';
import { useAuth } from 'oc-core-components/src/AuthProvider';
import { useSelector } from 'react-redux';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import AppBanner from 'shared/components/AppBanner';
import isIos from 'helpers/utils/isIos';
import GoogleAutoSignIn from 'shared/components/GoogleAutoSignIn/GoogleAutoSignIn';
import Container from 'oc-core-components/src/Container';
import CollectPointsModalContainer from 'hw_components/src/_HWv2/CollectPointsModal/CollectPointsModalContainer';
import useShopItemsModal from 'components/modals/ShopItemsModalContent/useShopItemsModal';

import LoginAndBackToTopSection from 'shared/components/LoginAndBackToTopSection';


const defaultRenderNavbar = props => <NavbarContainer {...props} />;
const isHwPage = currentPage => !['boosterClass', 'homeworkHelpShow', 'homeworkHelp', 'boosterClassLanding'].includes(currentPage);

const renderBottomBanner = () => (
  <Container>
    <LoginAndBackToTopSection />
  </Container>
);

const Page = ({
  bottomToolbarBeforeContent,
  children,
  navbarAfterContent,
  navbarBeforeContent,
  renderBottomToolbar,
  bottomToolbarProps,
  renderHero,
  showBottomBanner,
  hideCollectPointsModal,
  ...otherProps
}) => {
  const { isLoggedIn } = useAuth();
  const isMobile = useMobile();
  const currentPage = useSelector(getV2CurrentPage);

  // const isFooterBanner = !isLoggedIn && (!isMobile || !isHwPage(currentPage));

  const [isFooterBanner, setIsFooterBanner] = useState(!isLoggedIn && (!isMobile || !isHwPage(currentPage)));

  useEffect(() => {
    setIsFooterBanner(!isLoggedIn && (isIos() || !isMobile || !isHwPage(currentPage)));
  }, []);

  const defaultBottomToolbar = useCallback(
    ({ toolbarHandlers }) => (
      <BottomNavbarLogin
        bottomToolbarBeforeContent={bottomToolbarBeforeContent}
        toolbarHandlers={toolbarHandlers}
        {...bottomToolbarProps}
      />
    ),
    [bottomToolbarBeforeContent, bottomToolbarProps],
  );

  useShopItemsModal();

  return (
    <OneclassPage
      isLoggedIn={isLoggedIn}
      footerBanner={isFooterBanner ? <AppBanner /> : null}
      renderBottomToolbar={renderBottomToolbar || defaultBottomToolbar}
      renderBeforeFooter={showBottomBanner ? renderBottomBanner : undefined}
      {...otherProps}
    >
      {renderHero ? renderHero() : null}
      {
        !hideCollectPointsModal && (
          <CollectPointsModalContainer />
        )
      }
      {children}
      {!isLoggedIn && <GoogleAutoSignIn />}
    </OneclassPage>
  );
};

Page.defaultProps = {
  authParams: {},
  renderNavbar: defaultRenderNavbar,
};

Page.propTypes = {
  ...OneclassPage.propTypes,
  bottomToolbarBeforeContent: node,
  authParams: shape(),
  renderHero: func,
  renderNavbar: oneOfType([func, node]),
};


export default Page;
