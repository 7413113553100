export const variants = [
  'primary',
  'primary-light-outline',
  'primary-outline',
  'primary-alt',
  'alt',
  'alt-outline',
  'fb',
  'google',
  'none',
  'apple-dark',
  'google-dark',
  'alert',
  'dark',
  'blue',
  'purple',
  'light-lt',
];

export const sizes = ['xs', 'sm', 'md', 'lg'];
