import React, { PureComponent } from 'react';
import {
  string, node, func, bool,
} from 'prop-types';

import cn from 'classnames';
// import { NotificationsCounterContainer as MessageCounter } from 'oc-core-components/src/Notifications';
import Link from 'oc-core-components/src/Link';
import Col from 'oc-core-components/src/Col';
import Icon from 'oc-core-components/src/Icon';
import dynamic from 'next/dynamic';
import { getDynamicImportProps } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Toolbar.module.scss';
import ampStylesObj from './Toolbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Toolbar');

const dynamicProps = getDynamicImportProps();

const MessageCounter = dynamic(
  () => import(/* webpackChunkName: "chunk_MessageCounter" */ 'oc-core-components/src/Notifications/NotificationsCounterContainer'), dynamicProps,
);


class ToolbarItem extends PureComponent {
  render() {
    const {
      icon,
      label,
      href,
      isOpen,
      onClick,
      children,
      linkClassName,
      labelClassName,
      withNotifications,
      round,
      nofollow,
      styles,
      ...restProps
    } = this.props;

    return (
      <Col className={styles.item}>
        <Link
          nofollow={nofollow}
          href={href}
          className={cn(styles.link, linkClassName)}
          onClick={onClick}
          {...restProps}
        >
          <Icon className={cn({ [styles.round]: round })} icon={icon} size="sm">
            {
              withNotifications
              && <MessageCounter sticked className={styles.count} />
            }
          </Icon>
          <div className={cn(styles.label, labelClassName)}>{label}</div>
        </Link>
        {
          (isOpen && React.Children.count(children) > 0)
          && (
          <div className={styles.dd}>
            <ul className={styles.dd__section}>
              {children}
            </ul>
          </div>
          )
        }
      </Col>
    );
  }
}

ToolbarItem.propTypes = {
  icon: string,
  label: string,
  children: node,
  isOpen: bool,
  onClick: func,
  href: string,
  withNotifications: bool,
  linkClassName: string,
  labelClassName: string,
  round: bool,
};

export default withAmpStyles(ToolbarItem, s, stylesObj, ampStylesObj);
