import { createAction } from 'redux-actions';
import { getCountriesByType } from 'common/api';
import {
  RECEIVE_COUNTRY_DATA,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
} from './constants';

const receiveCountryData = createAction(RECEIVE_COUNTRY_DATA, res => res);
const getCountries = createAction(GET_COUNTRIES);
const getCountriesSuccess = createAction(GET_COUNTRIES_SUCCESS, res => res);
const getCountriesError = createAction(GET_COUNTRIES_ERROR, res => res);

function retrieveCountries({ materialType, authToken } = {}) {
  return function action(dispatch) {
    dispatch(getCountries());

    // IF authToken PROCEED
    // ELSE retrieveNewAuthToken THEN PROCEED

    const request = getCountriesByType({ materialType, authToken });
    return request.then(
      ({ data }) => dispatch(getCountriesSuccess([data])),
      () => dispatch(getCountriesError({ error: 'retrieve countries error' })),
    );
  };
}

export {
  receiveCountryData,
  getCountries,
  getCountriesSuccess,
  getCountriesError,
  retrieveCountries,
};
