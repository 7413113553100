import { takeEvery, put } from 'redux-saga/effects';
import {
  receiveNotification,
  clearNotification,
} from 'common/notification/actions';
import { resetOneTimePurchaseError, resetCreateDownloadError } from './actions';
import { POST_ONE_TIME_PURCHASE_ERROR, POST_CREATE_DOWNLOAD_ERROR } from './constants';

function* dispatchErrorNotification({ payload: { error } }) {
  if (error) {
    yield put(receiveNotification({
      type: 'error',
      text: error?.error || error,
    }));
    yield put(clearNotification());
  }
  yield put(resetOneTimePurchaseError());
  yield put(resetCreateDownloadError());
}

function* downloadSaga() {
  yield takeEvery(
    [POST_ONE_TIME_PURCHASE_ERROR, POST_CREATE_DOWNLOAD_ERROR],
    dispatchErrorNotification,
  );
}

export default downloadSaga;
