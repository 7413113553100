import { handleModelActions } from 'v2/redux/utils/handleActions';
import { ANSWERER_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const answererReducer = handleModelActions(ANSWERER_MODEL_TYPE, {}, initialState);

export { initialState };

export default answererReducer;
