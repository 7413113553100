
import React from 'react';
import { tagType } from 'types';

import { toClass } from 'recompose';
import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Dropdown.module.scss';

const styles = stylesProxy(stylesObj, 'Dropdown');

// Transform functional component to class in order to prevent warning:
// https://github.com/facebook/react/issues/10831

const DropdownList = toClass(({ tag: Tag, className, ...props }) => (
  <Tag className={cn(styles.list, className)} {...props} />
));

DropdownList.propTypes = {
  tag: tagType,
};

DropdownList.defaultProps = {
  tag: 'div',
};

export default DropdownList;
