import React, { useCallback } from 'react';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
// import EmptyModalWrapper from 'components/pages/HomeworkHelp/_HWv2/EmptyModalWrapper';
// import SummerChallengeModal from 'oc_components/src/pages/HomeFeed/SummerChallengeModal';
// import { postTrackSCEvents } from 'shared/common/api';
import ScholarshipOwlContent from 'components/pages/HomeworkHelp/ScholarshipOwlContent';

const usePartnershipPopup = ({
  className,
}) => {
  const { inline } = useModal();

  const onClick = useCallback(async () => {
    inline({
      content: <ScholarshipOwlContent />,
      wrapperProps: {
        className,
      },
    });
  }, [inline, className]);

  return onClick;
};


export default usePartnershipPopup;
