import React, { useMemo } from 'react';
import { string, bool } from 'prop-types';
import cn from 'classnames';
import ReduxLoadingBar from 'react-redux-loading-bar';
import { isNil } from 'helpers/utils';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './LoadingBar.module.scss';

const styles = stylesProxy(stylesObj, 'LoadingBar');

const LoadingBar = ({
  scope,
  bottom,
  className,
  disablePosition,
  posOutside,
  size,
  style,
}) =>  {
  const styleObj = useMemo(() => ({
    ...style,
    ...(!Number.isNaN(parseInt(size, 10)) && {
      height: `${size}px`,
    }),
  }), [style, size])

  return (
    <ReduxLoadingBar
      scope={scope}
      style={styleObj}
      className={cn(
        styles['loading-bar'],
        {
          [styles.bottom]: bottom,
          [styles.outside]: posOutside,
          [styles.positioned]: !disablePosition
        }, className)
      } />
  );
};


LoadingBar.propTypes = {
  scope: string.isRequired,
  bottom: bool,
};

export default LoadingBar;
