import { createSelector } from 'reselect';
import { pagePropsSelectorFactory } from 'v2/redux/page/selectors';
import { isNil } from 'helpers/utils';
import createCachedSelector from 're-reselect';

export const getV2AnnotationIdProp = (state, props) => props?.annotationId;

export const selectV2BcAnnotations = pagePropsSelectorFactory('annotations', true);

export const selectV2BcAnnWithNotes = createSelector(
  selectV2BcAnnotations,
  (annotations) => {
    if(!annotations) return undefined;

    const entries = Object.entries(annotations).filter(([uid, ann]) => (
      !isNil(ann.note?.text) || ann.questionId
    ))

    return entries.reduce((acc, [uid, ann]) => ({
      ...acc,
      [uid]: ann
    }), {});
  }
);



export const selectV2BcAnnotationNotes = createSelector(
  selectV2BcAnnotations,
  (annotations) => {
    if(!annotations) return undefined;

    return Object.entries(annotations).reduce((acc, [uid, ann]) => {
      if(!isNil(ann.note?.text)) {
        return {
          ...acc,
          [uid]: {
            draft: ann.draft,
            id: ann.id,
            ...ann.note
          },
        }
      }

      return acc;
    }, {});
  }
);


export const selectV2BcAnnotationByIdProp = createCachedSelector(
  [getV2AnnotationIdProp, selectV2BcAnnotationNotes],
  (annotationId, annotations) => (
    annotationId ? annotations[annotationId] : undefined
  )
)(
  (state, props) => getV2AnnotationIdProp(state, props) || '_default'
);


