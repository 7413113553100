import { createAction } from 'redux-actions';
import {
  RECEIVE_DEPARTMENT_DATA,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_DEPARTMENT,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
} from './constants';
import { getDepartmentById } from '../api';

const receiveDepartmentData = createAction(RECEIVE_DEPARTMENT_DATA, payload => payload);
const getDepartments = createAction(GET_DEPARTMENTS);
const getDepartmentsSuccess = createAction(GET_DEPARTMENTS_SUCCESS, payload => payload);
const getDepartmentsError = createAction(GET_DEPARTMENTS_ERROR, payload => payload);
const getDepartment = createAction(GET_DEPARTMENT, payload => payload);
const getDepartmentSuccess = createAction(GET_DEPARTMENT_SUCCESS, payload => payload);
const getDepartmentError = createAction(GET_DEPARTMENT_ERROR, payload => payload);

function retrieveDepartmentById(departmentId, authToken) {
  return function action(dispatch) {
    dispatch(getDepartment(departmentId));

    // IF authToken PROCEED
    // ELSE retrieveNewAuthToken THEN PROCEED

    return getDepartmentById({ departmentId, authToken }).then(({ data }) => {
      const department = data?.data && data.data.find(dataItem => (dataItem?.id?.toString() === departmentId));
      if (department) {
        dispatch(getDepartmentSuccess([data]));
      } else {
        dispatch(getDepartmentError({ error: 'retrieve department by id error' }));
      }
    }, () => {
      dispatch(getDepartmentError({ error: 'retrieve department by id error' }));
    });
  };
}

export {
  receiveDepartmentData,
  getDepartments,
  getDepartmentsSuccess,
  getDepartmentsError,
  retrieveDepartmentById,
};
