import createAction from 'v2/redux/utils/createAction';
import types from '../types';

export const storeV2BoosterClassComplete = createAction(types.STORE_BC_COMPLETE);

const payloadMap = (payload) => payload;
const metaMap = (payload, meta) => meta;

export const storeV2BcTheoryAnnotation = createAction(types.STORE_BC_ANNOTATION, payloadMap, metaMap);
export const updateV2BcTheoryAnnotation = createAction(types.UPDATE_BC_ANNOTATION, payloadMap, metaMap);
export const removeV2BcTheoryAnnotation = createAction(types.REMOVE_BC_ANNOTATION);

export default {};
