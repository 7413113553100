import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import LazyImage from 'oc-core-components/src/LazyImage';
import BCEmoji from 'shared/components/BCEmoji';
import Icon from 'oc-core-components/src/Icon';
import Button from 'oc-core-components/src/Button';
import getAuthLink from 'helpers/utils/getAuthLink';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import { getV2PromoPopup } from 'v2/redux/meta/selectors';
import { useNotifications } from 'oc-core-components/src/NotificationProvider';
import { useAuth } from 'oc-core-components/src/AuthProvider';
import { get } from 'shared/common/api';

import {
  getImage,
} from 'helpers/utils';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ScholarshipOwlContent.module.scss';

const styles = stylesProxy(stylesObj, 'ScholarshipOwlContent');

const list = [
  'Access to 3.8 million+ questions and answers',
  'Ability to earn through contributing answers and notes to help others',
  // (
  //   <>
  //     {'Ask up to 4 verified questions '}
  //     <Icon
  //       icon="oc:check-seal"
  //       size={18}
  //       stroke="none"
  //       fill="secondary-dk"
  //       className="u-mr-3xs u-ml-3xs"
  //     />
  //     {' answered by our top subject matter experts'}
  //   </>
  // ),
];

const redirectOrReload = (redirectUrl) => {
  if (redirectUrl) {
    window.location.assign(redirectUrl);
  } else {
    window.location.reload();
  }
};

const ScholarshipOwlContent = () => {
  const promoData = useSelector(getV2PromoPopup) || {};
  const { isLoggedIn } = useAuth();
  const {
    addSuccessNotification,
    addErrorNotification,
  } = useNotifications();

  // Meta: promoPopup: {imgUrl, monthsCount, content(Array), btnUrl }
  const {
    imgUrl = getImage('/pages/homework_help/scholarship_logo.png'),
    monthsCount = 1,
    content: backendList,
    btnUrl = getAuthLink({ source: 'hw', intent: false }),
    btnTextLogin = 'Signup',
    btnTextLogout = 'Claim now',
    success_msg = 'Congrats! You just claimed your 12 month of Homework+!',
  } = promoData;

  const onClick = useCallback(async () => {
    const { data } = await get(btnUrl)();
    // const data = { success: true, redirect_url: undefined };

    if (isLoggedIn && data?.success) {
      addSuccessNotification(success_msg);
      redirectOrReload(data?.redirect_url);
    } else if (!isLoggedIn && data?.success) {
      redirectOrReload(data?.redirect_url);
    } else {
      addErrorNotification();
    }
  }, [addErrorNotification, addSuccessNotification, btnUrl, isLoggedIn, success_msg]);

  return (
    <div>
      <div className="u-text-center u-mb-s">
        <LazyImage
          disableLazy
          alt="Clutch Prep logo"
          className={styles.img}
          src={imgUrl}
        />
      </div>

      <h2 className={styles.title}>
        <Row noGutters>
          <Col xs="auto">
            <BCEmoji name="party" size={19} className="u-mr-xxs" />
          </Col>
          <Col className={styles.congrats}>
            Congrats! You got {monthsCount}-month <span className="u-mx-xxs">FREE</span> Homework Help+
          </Col>
        </Row>
      </h2>

      <hr className="u-my-m" />

      <div className={styles.subtitle}>
        Includes:
      </div>

      <ul className={styles.list}>
        {
            (backendList || list).map((content, indx) => (
              <li className={styles.list__item} key={indx}>
                <Row noGutters>
                  <Col xs="auto">
                    <Icon icon="oc:check-product" stroke="none" className="u-mr-xxs" />
                  </Col>
                  <Col>
                    {content}
                  </Col>
                </Row>
              </li>
            ))
          }
      </ul>

      <div className="u-text-center u-mt-l">
        <Button color="dark" onClick={onClick}>
          { isLoggedIn ? btnTextLogout : btnTextLogin }
        </Button>
      </div>

    </div>
  );
};

export default ScholarshipOwlContent;
