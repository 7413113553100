import { ROUTES, cookiesKeys, authUrlsData } from 'helpers/constants';
import { cookies } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';
import { toPwaUrl } from 'helpers/pwa';
import getAuthLink from 'helpers/utils/getAuthLink';

export const goToPageWithCookie = ({
  setCookie,
  cookieName,
  cookieValue,
  url,
  query = {},
}) => {
  if (setCookie) {
    cookies.set(cookieName, cookieValue, { path: '/' });
  }

  const newUrl = buildUrl(toPwaUrl({ url }), query);
  // console.log(newUrl, query);

  setTimeout(() => null, 5000);

  // TODO: refactor
  (typeof window !== 'undefined') && window.location.assign(newUrl);
};

// TODO: Remove this after oc-core-components/src/ToolbarContainer is updated
export const goToHomeworkHelpWithCookie = () => {
  const url = ROUTES.homeworkHelp;

  return goToPageWithCookie({
    setCookie: true,
    cookieName: cookiesKeys.HW_ASK_QUESTION_POPUP,
    cookieValue: true,
    url,
  });
};

export const getChoosePlanPageUrl = ({ purchaseForm, defaultLogin, query } = {}) => toPwaUrl({
  // eslint-disable-next-line no-nested-ternary
  url: buildUrl(
    getAuthLink({
      url: purchaseForm ? authUrlsData.hw?.payment : undefined,
      login: defaultLogin,
      source: 'hw',
    }),
    query,
  ),
});

export const goToChoosePlanPage = ({
  defaultLogin = false,
  purchaseForm = false,
  redirectUrl,
  query,
  ...rest
} = {}) => {
  const url = getChoosePlanPageUrl({ purchaseForm, query });
  // console.log(query, url);
  if (redirectUrl) {
    cookies.set(cookiesKeys.ASK_QUESTION_REDIRECT_URL, redirectUrl, { path: '/' });
  }

  return goToPageWithCookie({
    setCookie: defaultLogin,
    cookieName: cookiesKeys.DEFAULT_LOGIN,
    cookieValue: true,
    url,
    ...rest,
  });
};

export const getAskQuestionPageUrl = ({ query } = {}) => buildUrl(ROUTES.askQuestion, query);

// TODO: Remove this after oc-core-components/src/ToolbarContainer is updated
export const goToAskQuestionPage = ({ query } = {}) => {
  const newUrl = getAskQuestionPageUrl({ query });

  if (typeof window !== 'undefined') {
    window.location.assign(newUrl);
  }
};


export default {};
