import React from 'react';
import cn from 'classnames';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import { useTranslation, Trans } from 'config/i18n';
import BCEmoji from 'shared/components/BCEmoji';
import PointsBadge from 'hw_components/src/_HWv2/Badge/components/PointsBadge';
import { useMobile } from 'oc-core-components/src/MediaQuery';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './CollectPointsModal.module.scss';

const styles = stylesProxy(stylesObj, 'CollectPointsModal');

const Badge = props => (
  <PointsBadge
    color="orange"
    className="u-mx-3xs"
    {...props}
  />
);

const CollectPointsModal = ({
  pointsCount,
  onClick,
  withBannerOffset,
}) => {
  // since this modal could be used across the whole site (HH pages, Homefeed potentially - we're using common locale here)
  const { t } = useTranslation();
  const btnText = t('collect');
  const isMobile = useMobile();

  const iconSize = isMobile ? 16 : 20;
  const badgeSize = isMobile ? 'xs' : 'sm';
  const textI18nKey = isMobile ? 'common:collect_all_points_mobile' : 'common:collect_all_points';

  return (
    <Row noGutters className={cn(styles.container, { [styles['container--banner-offset']]: withBannerOffset })}>
      <Col>
        <span className={styles.text}>
          <BCEmoji className="u-mr-3xs" name="party" size={iconSize} />
          <Trans i18nKey={textI18nKey} count={pointsCount}>
            Congrats! You just earned <Badge
              pointsCount={pointsCount}
              size={badgeSize}
            /> bonus points
          </Trans>
        </span>
      </Col>
      <Col xs="auto">
        <Button
          className={styles.btn}
          onClick={onClick}
        >
          {btnText}
        </Button>
      </Col>
    </Row>
  );
};

CollectPointsModal.propTypes = {};

export default CollectPointsModal;
