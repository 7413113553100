// TODO: Consider merging SimpleDropdown and TooltipPopper
import React, { PureComponent } from 'react';
import cn from 'classnames';
import { func, arrayOf, string } from 'prop-types';

import { dropdownItemType } from 'types';

import { isFunction } from 'helpers/utils';


import Dropdown from './Dropdown';
import List from './List';
import Item from './Item';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Dropdown.module.scss';

const styles = stylesProxy(stylesObj, 'Dropdown');

class SimpleDropdown extends PureComponent {
  itemClickHandler = (item, { close }, e) => {
    const { onItemClick } = this.props;

    close();

    if (isFunction(item.onClick)) {
      item.onClick(e);
    }

    if (isFunction(onItemClick)) {
      onItemClick(item, e);
    }
  }

  render() {
    const {
      items,
      selectedItem,
      onItemClick,
      itemClassName,
      selectedClassName,
      listClassName,
      getListProps,
      ...restProps
    } = this.props;

    return (
      <Dropdown {...restProps}>
        {
          dropdownProps => (
            <List className={listClassName} {...(getListProps ? getListProps(dropdownProps) : null)}>
              {
                items?.map((item) => {
                  const isSelected = selectedItem && (selectedItem.uid === item.uid);
                  const props = {
                    key: item.uid,
                    selected: isSelected,
                    link: item.url,
                    onClick: e => this.itemClickHandler(item, dropdownProps, e),
                    className: cn(itemClassName, isSelected && selectedClassName),
                  };

                  return (
                    item.renderComponent ? (
                      item.renderComponent({ children: item.label, ...props })
                    ) : (
                      <Item {...props}>{item.label}</Item>
                    )
                  );
                })
              }
            </List>
          )
        }

      </Dropdown>
    );
  }
}

SimpleDropdown.propTypes = {
  items: arrayOf(dropdownItemType),
  onItemClick: func,
  selectedItem: dropdownItemType,
  listClassName: string,
  itemClassName: string,
};

SimpleDropdown.defaultProps = {
  items: [],
};

export default SimpleDropdown;
