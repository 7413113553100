export const smallPasswordMessage = (count = 6) => `At least ${count} characters`;
export const bigPasswordMessage = (count = 60) => `At most ${count} characters`;


export const INCORRECT_EMAIL = 'Incorrect email';
export const INCORRECT_PHONE = 'Phone number is not valid';
export const INCORRECT_USERNAME = 'Username can contain only lower case letters, numbers and hyphens.';
export const INCORRECT_REFERRAL_ID = 'Referral ID can contain only lower case letters, numbers and hyphens.';
export const REQUIRED_FIELD = 'This field is required';
export const SMALL_PASSWORD = smallPasswordMessage();
export const PASSWORD_NOT_MATCHES = 'Password Confirmation should match the Password';
export const EMAIL_NOT_MATCHES = 'Email Confirmation should match the Email';
export const BELOW_AGE = 'You need to be over 13 years of age to continue.';
export const TEXTBOOK = 'Please specify textbook use.';
export const PROFESSOR = 'Please select a professor.';
