import React, { useMemo } from 'react';
import Link from 'oc-core-components/src/Link';
import Avatar from 'oc-core-components/src/Avatar';
import cn from 'classnames';
import RawContent from 'oc-core-components/src/RawContent';
import formatDate from 'helpers/utils/formatDate';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './NavbarMenuNotificationsItemV2.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarMenuNotificationsItemV2');


const NavbarMenuNotificationsItemV2 = ({
  notification,
}) => {
  const {
    createdAt,
    read,
    content,
    imgUrl,
    url,
  } = notification || {};

  const formattedDate = useMemo(() => formatDate(createdAt)?.short, [createdAt]);


  if (!content) {
    return null;
  }

  const htmlContent = formattedDate ? `${content}<span class="${styles.date}">${formattedDate}</span>` : content;


  return (

    <Link
      className={cn(
        styles.container,
        !read && styles.unread,
      )}
      href={url}
    >

      {
        imgUrl && (
          <Avatar
            alt=""
            className={styles.avatar}
            src={imgUrl}
          />
        )
      }

      <RawContent className={styles.content}>
        {htmlContent}
      </RawContent>

      {!read && <div className={styles.bullet} />}
    </Link>
  );
};


export default NavbarMenuNotificationsItemV2;
