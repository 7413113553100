import React, {
  useMemo, createContext, useContext,
} from 'react';

import {
  shape, string,
} from 'prop-types';

import { withContext } from 'helpers/hoc';

import { cookies, isBrowser } from 'helpers/utils';
import Cookies from 'universal-cookie';

// Create context with default fields
export const CookiesContext = createContext({
  cookies: '',
});

export const authContextPropTypes = shape({
  cookies: string,
});

export const withCookies = withContext(CookiesContext, 'cookies');

export const useCookies = () => useContext(CookiesContext);

const CookiesProvider = ({
  children,
  serverCookies,
}) => {
  const universalCookies = useMemo(() => (
    (isBrowser && !serverCookies) ? cookies : new Cookies(serverCookies)), [serverCookies]);

  return (
    <CookiesContext.Provider
      value={universalCookies}
    >
      {children}
    </CookiesContext.Provider>
  );
};

CookiesProvider.propTypes = {
  serverCookies: string,
};

export default CookiesProvider;
