import React, { memo } from 'react';
import { shape } from 'prop-types';
import { numberString } from 'types';

import { connect } from 'react-redux';

import { selectV2ModelByIdProp } from 'v2/redux/selectors';
import BoosterClassCard from './BoosterClassCard';

const BoosterClassCardContainer = ({
  boosterClass,
  boosterClassId,
  dispatch,
  ...restProps
}) => {
  if (!boosterClass) return null;

  const {
    name,
    creditCourseStatus,
    description,
    topicName,
    topicHandle,
    skillsCount,
    url,
    acName,
    creditUrl,
    tutorAvatarUrl,
    isPurchased,
    percentComplete,
    auditOnly,
  } = boosterClass || {};

  const isCredited = creditCourseStatus === 'active';

  const title = isCredited ? acName : name;
  const link = isCredited ? creditUrl : url;

  return (
    <BoosterClassCard
      name={title}
      description={description}
      topic={topicName}
      skillsCount={skillsCount}
      isCredited={isCredited}
      url={link}
      tutorAvatarUrl={tutorAvatarUrl}
      topicHandle={topicHandle}
      percentComplete={percentComplete}
      isPurchased={isPurchased}
      // creditedType={"credit" || "audit"}
      {...restProps}
    />
  );
};


const mapStateToProps = (state, props) => ({
  boosterClass: selectV2ModelByIdProp.boosterClass(state, props),
});

BoosterClassCardContainer.propTypes = {
  boosterClass: shape(),
  boosterClassId: numberString,
};

export default connect(mapStateToProps, null)(memo(BoosterClassCardContainer));
