import React, { PureComponent } from 'react';
import { bool, string, oneOfType, number } from 'prop-types';
import Link from 'oc-core-components/src/Link';

import {
  ROUTES,
} from 'helpers/constants';

import Icon from 'oc-core-components/src/Icon';

class Logo extends PureComponent {
  static propTypes = {
    iconClassName: string,
    small: bool,
    href: string,
    iconSize: oneOfType([string, number]),
    iconWidth: oneOfType([string, number]),
    iconHeight: oneOfType([string, number]),
    light: bool,
  };

  static defaultProps = {
    href: ROUTES.root,
  }

  render() {
    /* eslint-disable prefer-const */
    let {
      small,
      light,
      iconClassName,
      iconSize,
      iconWidth,
      iconHeight,
      ...attributes
    } = this.props;
    /* eslint-enable prefer-const */

    const logoType = small ? 'small' : `big${light ? '-light': ''}`;

    const icon = `logo:${logoType}`;

    return (
      <Link
        {...attributes}
      >
        <Icon
          className={iconClassName}
          icon={icon}
          size={iconSize}
          width={iconWidth}
          height={iconHeight}
          stroke="none"
        />
      </Link>
    );
  }
}

export default Logo;
