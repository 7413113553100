import React from 'react';
import { bool, node, string } from 'prop-types';
import Button from 'oc-core-components/src/Button';

const Submit = ({
  loading,
  disabled,
  children,
  loader,
  ...restProps
}) => (
  <Button
    disabled={disabled || loading}
    {...restProps}
  >
    {
      loading ?
        loader :
        children
    }
  </Button>
);

Submit.propTypes = {
  disabled: bool,
  children: node,
  loader: node,
  loading: bool,
  type: string,
};

Submit.defaultProps = {
  loader: 'Please wait...',
  type: 'submit',
};

export default Submit;
