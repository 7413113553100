import { takeEvery, put } from 'redux-saga/effects';
import { receiveUserData, receiveCurrentUserId } from 'common/user/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { getDataByType } from 'common/utils';

function* storeUserData({ res }) {
  let currentUserId;
  res.forEach((o) => {
    if (o.data && Array.isArray(o.data)) {
      o.data.forEach((d) => {
        if (d.type === 'user') currentUserId = d.id;
      });
    }
  });
  if (currentUserId) yield put(receiveCurrentUserId(currentUserId));

  const users = getDataByType('user', res);
  if (users) yield put(receiveUserData(users));
}

function* userSaga() {
  yield takeEvery(RECEIVE_PAGE_DATA, storeUserData);
}

export default userSaga;
