import React, { useMemo } from 'react';
import {
  string, bool, shape, oneOfType, array,
} from 'prop-types';
import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';
import Link from 'oc-core-components/src/Link';
import { PureLazyImage as LazyImage } from 'oc-core-components/src/LazyImage';
import { getImage, renderNode } from 'helpers/utils';
import { useAmp } from 'next/amp';
import AmbassadorBadge from 'oc_components/src/pages/ProfileV2/AmbassadorBadge';


import withAmpStyles from 'helpers/withAmpStyles';
import useNormalizedAvatarSrc from 'helpers/hooks/useNormalizedAvatarSrc';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Avatar.module.scss';
import ampStylesObj from './Avatar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Avatar');


// At the moment there're possible several props combinations:
// 1. src: 'https:...' (just an <img> tag)
// 2. src: [{ srcSet, type }, { srcSet, type }, ...] - consider 'src' as 'sources' prop (temp fallback solution - use (1) or (3))
// 3. src: 'https:...', sources - setup for picture tag, sources and src as fallback
const Avatar = ({
  src: customSrc,
  sources: customSources,
  alt,
  className,
  imgClassName,
  containerClassName,
  isNoteTaker,
  icon,
  iconProps,
  styles,
  href,
  htmlWidth,
  htmlHeight,
  imgContainerClassName,
  // initials,
  renderAfter,
  ambassador,
  ambassadorProps,
  ...restProps
}) => {
  const isAmp = useAmp();

  const { src, sources } = useNormalizedAvatarSrc({ customSrc, customSources });

  return (
    <Link
      defaultTag="div"
      className={cn(className, styles.container, containerClassName)}
      href={href}
      // data-initials={initials}
    >
      <div className={cn(styles['img-container'], imgContainerClassName, { [styles.default]: !src })}>

        {
          // eslint-disable-next-line no-nested-ternary
          src ? (isAmp ? (
            <amp-img
              src={src}
              layout="responsive"
              width={1}
              height={1}
              alt={alt}
              className={cn(styles.img, imgClassName)}
              {...restProps}
            />
          ) : (
            // <img
            //   src={src}
            //   alt={alt}
            //   className={cn(styles.img, imgClassName)} {...restProps}
            // />
            <LazyImage
              disableLazy
              src={src}
              alt={alt}
              imgClassName={cn(styles.img, imgClassName)}
              sources={sources}
              htmlWidth={htmlWidth}
              htmlHeight={htmlHeight}
              {...restProps}
            />
          )


          ) : (
            <Icon
              icon="logo:small"
              stroke="none"
              className={styles['default-img']}
              svgClassName={styles['default-img__icon']}
            />
          )
        }
      </div>

      { isNoteTaker && (
        <Icon
          icon="oc:premium"
          size="xxs"
          stroke="none"
          fill="primary-alt"
          // svgClassName={styles.avatar__svg}
          className={styles.avatar__icon}
        />
      )}

      { icon && (
        <Icon
          // svgClassName={styles.avatar__svg}
          className={styles.avatar__icon}
          icon={icon}
          {...iconProps}
        />
      )}

      {
        ambassador && (
          <AmbassadorBadge className={styles.avatar__icon} {...ambassadorProps} />
        )
      }

      {renderNode(renderAfter)}
    </Link>
  );
};

Avatar.propTypes = {
  // initials: string,
  containerClassName: string,
  imgClassName: string,
  src: oneOfType([array, string]),
  alt: string,
  isNoteTaker: bool,
  icon: string,
  iconProps: shape(),
};

export default withAmpStyles(Avatar, s, stylesObj, ampStylesObj);
