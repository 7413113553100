import { addDebugData } from 'helpers/utils';
import Col from 'oc-core-components/src/Col';
import Container from 'oc-core-components/src/Container';
import Row from 'oc-core-components/src/Row';
import { bool, node, shape } from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import ExperienceToggle from 'shared/components/ExperienceToggle';
import Page from 'shared/components/Page';
import { selectV2Breadcrumbs, getV2IsLoggedIn } from 'v2/redux/meta/selectors';
import HowItWorksToggle from 'shared/components/HowItWorksToggle';
import useToggle from 'helpers/hooks/useToggle';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import DefaultNavbarBannerContainer from 'oc-core-components/src/DefaultNavbarBannerContainer';
import ProductHomeHero, { ProductHomeHeroPropType } from './ProductHomeHero';

const emptyRenderNavbar = () => null;
const defaultNavbarBeforeContent = () => <DefaultNavbarBannerContainer />;

const ProductHomePage = ({
  bannerProps,
  children,
  heroImage,
  pageProps,
  showBreadcrumbs,
  showExperienceToggle,
  showBannerToggle,
  showBottomBanner,
  hideDefaultNavbarBannerInBody,
  colored,
  hideCollectPointsModal,
}) => {
  const breadcrumbs = useSelector(selectV2Breadcrumbs);
  const isLoggedIn = useSelector(getV2IsLoggedIn);

  const isMobile = useMobile();
  const [showBanner, toggleShowBanner] = useToggle();

  const {
    navbarProps: customNavbarProps,
    renderNavbar,
    ...restPageProps
  } = pageProps || {};

  const renderToggle = useCallback(
    () => (
      <ExperienceToggle
        bordered={isMobile}
        bannerToggle={
          (showBannerToggle && !isMobile)
            ? (
              <HowItWorksToggle
                onClick={toggleShowBanner}
                expanded={showBanner}
              />
            )
            : null}
      />
    ),
    [showBannerToggle, isMobile, toggleShowBanner, showBanner],
  );

  const navbarProps = useMemo(() => ({
    renderBottom: (showExperienceToggle ? renderToggle : null),
    navbarBeforeContent: defaultNavbarBeforeContent,
    ...customNavbarProps,
  }), [showExperienceToggle, renderToggle, customNavbarProps]);

  // hide default loggedin navbar on mobile
  // const renderNavbarProp = renderNavbar || ((isMobile && isLoggedIn) ? emptyRenderNavbar : undefined);


  return (
    <Page
      hideCollectPointsModal={hideCollectPointsModal}
      colored={colored}
      showBottomBanner={showBottomBanner}
      // renderNavbar={renderNavbarProp}
      {...restPageProps}
      navbarProps={navbarProps}
    >
      {
        !hideDefaultNavbarBannerInBody && isMobile && isLoggedIn && (<DefaultNavbarBannerContainer />)
      }
      {/* {
        showExperienceToggle && isMobile && renderToggle()
      } */}

      {(bannerProps && (!showBannerToggle || showBanner)) ? <ProductHomeHero {...bannerProps} /> : heroImage}
      {children}
      {showBreadcrumbs && breadcrumbs?.length > 0 && (
        <>
          <Container>
            <Row className="u-my-l u-py-xxs u-justify-content-center">
              <Col lg="auto">
                <Breadcrumbs
                  items={breadcrumbs}
                  className="u-font-size-s u-text-center"
                />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Page>
  );
};

ProductHomePage.propTypes = {
  bannerProps: shape(ProductHomeHeroPropType),
  children: node,
  heroImage: node,
  pageProps: shape(),
  showBreadcrumbs: bool,
  showExperienceToggle: bool,
  showBannerToggle: bool,
};

ProductHomePage.defaultProps = {
  pageProps: {},
  showBottomBanner: true,
  hideDefaultNavbarBannerInBody: true,
};

addDebugData(ProductHomePage);

export default ProductHomePage;
