import React, { useEffect, useCallback } from 'react';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import EmptyModalWrapper from 'components/pages/HomeworkHelp/_HWv2/EmptyModalWrapper';
import SummerChallengeModal from 'oc_components/src/pages/HomeFeed/SummerChallengeModal';
import { postTrackSCEvents } from 'shared/common/api';

const useSummerChallengePopup = ({ event } = {}) => {
  const { inline } = useModal();

  const onClick = useCallback(async () => {
    postTrackSCEvents({ event });

    inline({
      // content: (
      //   <div>123123</div>
      // ),
      content: () => (
        <SummerChallengeModal />
      ),
      CustomModalPortal: EmptyModalWrapper,
    });
  }, [inline, event]);

  return onClick;
};



export default useSummerChallengePopup;
