import React from 'react';
import cn from 'classnames';
import {
  oneOfType, string, number, bool, node, shape,
} from 'prop-types';
import { itemToValue } from 'oc-core-components/src/Autocomplete/utils';

import { Consumer } from './RadioGroupContext';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './RadioGroup.module.scss';

const styles = stylesProxy(stylesObj, 'RadioGroup');

const RadioButton = ({
  id,
  disabled,
  children,
  item,
  className,
  inputClassName,
  labelClassName,
  disableTheme,

  selectedClassName,
  selectedInputClassName,
  selectedLabelClassName
}) => (
  <Consumer>
    {({
      selected,
      onChange,
      disabledGroup,
      name,
    }) => {
      const isSelected = itemToValue(selected) === itemToValue(item);

      return (
        <div className={cn(styles.radio, className, isSelected && selectedClassName)}>
          <input
            type="radio"
            disabled={disabled || disabledGroup}
            id={id}
            checked={isSelected}
            value={id}
            name={name}
            onChange={e => onChange(item, e)}
            className={cn(styles.input, inputClassName, isSelected && selectedInputClassName)}
          />
          <label
            htmlFor={id}
            className={cn(styles.label, labelClassName, { [styles['theme-label']]: !disableTheme }, isSelected && selectedLabelClassName )}
          >
            {children}
          </label>
        </div>
      );
    }}
  </Consumer>
);

RadioButton.propTypes = {
  id: oneOfType([string, number]).isRequired,
  item: shape(),
  disabled: bool,
  children: node,
  disableTheme: bool,
};

RadioButton.defaultProps = {
  disabled: false,
};

export default RadioButton;
