import React from 'react';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import { ROUTES } from 'helpers/constants';
import { stylesProxy } from 'helpers/css';

import stylesObj from './Navbar.module.scss';

const styles = stylesProxy(stylesObj, 'Navbar');

const NavbarRevenueFooter = ({ t }) => (
  <div className={styles.menu_new__footer}>
    <Icon icon="oc:revshare" stroke="none" />
    <div className={styles.menu_new__footer__text}>
      {t('navbar:navbar.menuCreate.footer.title')}
      {' '}
      <Link href={ROUTES.tutors}>
        {t('navbar:navbar.menuCreate.footer.link')}
      </Link>
    </div>
  </div>
);

export default NavbarRevenueFooter;
