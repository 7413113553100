import React from 'react';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import Button from 'oc-core-components/src/Button';
import { ROUTES } from 'shared/helpers/constants';
import { useTranslation } from 'config/i18n';
import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './LoginSection.module.scss';
import ampStylesObj from './LoginSection.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'LoginSection');

const LoginSection = ({
  styles,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.title}>{t('start_filling_gaps')}</div>
      <Button
        color="dark"
        fullWidth
        size="lg"
        href={ROUTES.login}
        className={styles.button}
      >
        {t('log_in')}
      </Button>
      <div className={styles['sign-up']}>
        {t('new_to_oneclass')}
        <Link
          href={ROUTES.signup}
          className={styles.link}
        >
          {t('sign_up')}
        </Link>
      </div>
    </div>
  );
};

export default withAmpStyles(LoginSection, s, stylesObj, ampStylesObj);
