import { animateScroll, Events } from 'react-scroll';
import { isBrowser, getScrollTop } from 'helpers/utils';
import {
  HEADER_ID,
} from 'shared/helpers/constants';

/**
 * @example
 * scrollToBlock('#about', { offset: -50})}
 * @param {node} selector
 * @param {Object} options
 * @param {number} options.duration - animation duration
 * @param {number} options.offset - scroll offset
 * @returns {Promise}
 */

const scrollToBlock = (selector = `#${HEADER_ID}`, { offset = 0, withHeader, ...opts } = {}) => new Promise((resolve) => {
  const defaultOpts = {
    duration: 1000,
  };

  let currentOffset = offset || 0;

  if (!isBrowser) return;

  const block = selector instanceof Element ? selector : document.querySelector(selector);


  if (!block) return;

  if (withHeader) {
    const header = document.getElementById(HEADER_ID);

    if (header) {
      currentOffset = -header.getBoundingClientRect().height;
    }
  }

  const options = {
    ...defaultOpts,
    ...opts,
  };

  const totalBlockOffsetTop = block.getBoundingClientRect().top + getScrollTop() + currentOffset;

  Events.scrollEvent.register('end', () => {
    resolve();
    Events.scrollEvent.remove('end');
  });

  animateScroll.scrollTo(totalBlockOffsetTop, options);
});

export default scrollToBlock;
