import React from 'react';

import cn from 'classnames';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Placeholder.module.scss';

const styles = stylesProxy(stylesObj, 'Placeholder');


const Placeholder = ({
  className,
  disableAnimation,
  ...restProps
}) => (
  <div className={cn(styles.placeholder, className, { [styles.animated]: !disableAnimation })} {...restProps} />
);


export default Placeholder;
