import { trackGAEvent, dataLayerPush } from 'helpers/tracking';
import { getAdwordsConversionId, isBrowser, isFunction } from 'helpers/utils';
import {
  postAhoyTrackCustom,
} from 'common/api';
import kebabCase from 'lodash/kebabCase';


export const trackSubmitSchool = (data) => {
  trackGAEvent({
    category: 'Signup',
    action: 'submitted_school_info_during_signup_process',
    data,
  });

  // trackFbEvent({
  //   category: 'trackCustom',
  //   action: 'CompleteSignupStep2',
  //   data,
  // });
};

// export const trackAccountDetails = (data) => {
//   trackGAEvent({
//     category: 'Signup',
//     action: 'complete_profile',
//     data,
//   });

//   // trackFbEvent({
//   //   category: 'trackCustom',
//   //   action: 'CompleteRegistration',
//   //   data: {
//   //     content_category: 'Signup',
//   //     contents: data,
//   //   },
//   // });
// };

// export const trackSignup = (data) => {
//   trackGAEvent({
//     category: 'Signup',
//     action: 'complete_signup_step1',
//     data,
//   });

//   // trackFbEvent({
//   //   category: 'trackCustom',
//   //   action: 'CompleteSignupStep1',
//   //   data,
//   // });
// };

// export const trackSuccessfulSignup = (data) => {
//   trackGAEvent({
//     category: 'Signup',
//     action: 'successfull_signup',
//     data,
//   });

//   // trackFbEvent({
//   //   category: 'trackCustom',
//   //   action: 'SuccessfullSignup',
//   //   data,
//   // });
// };

export const trackLogin = (data) => {
  trackGAEvent({
    category: 'Login',
    action: 'successfully_logged_in',
    data,
  });
};


// export const trackAddCourses = (data) => {
//   trackGAEvent({
//     category: 'Signup',
//     action: 'complete_profile',
//     data,
//   });

//   // trackFbEvent({
//   //   category: 'track',
//   //   action: 'CompleteRegistration',
//   //   data: {
//   //     content_category: 'Signup',
//   //     contents: data,
//   //   },
//   // });
// };

// export const trackSkipAddCourses = (userId) => {
//   const data = {
//     skip_courses: true,
//     user_id: userId,
//     step3: true,
//     label: 'Skip Adding Courses',
//   };

//   trackGAEvent({
//     category: 'Signup',
//     action: 'complete_profile',
//     data,
//   });

//   // trackFbEvent({
//   //   category: 'track',
//   //   action: 'CompleteRegistration',
//   //   data: { contents: data },
//   // });
// };

// https://oneclass.atlassian.net/browse/OS-3653
export const trackProductBrowseViewCourseContent = ({ courseCode, courseId }) => {
  // We have to specify some magic number, since there is no value
  const defaultValue = 9.99;
  const defaultCurrency = 'USD';
  const defaultContentType = 'product';

  const data = {
    content_name: courseCode,
    content_ids: [courseId],
    content_type: defaultContentType,
    value: defaultValue,
    currency: defaultCurrency,
  };

  // trackFbEvent({
  //   category: 'track',
  //   action: 'ViewContent',
  //   data,
  // });
};


// export const trackFacebookLogin = (userId) => {
//   const data = { facebook: true, user_id: userId };

//   // trackFbEvent({
//   //   category: 'trackCustom',
//   //   action: 'CompleteSignupStep1',
//   //   data,
//   // });
//   // GA event
//   trackGAEvent({
//     category: 'Signup',
//     action: 'complete_signup_step1',
//     data,
//   });
// };


// track successfull purchase
//
// exported from ga_fb_tracking_lib.js
export const trackSuccessfulPurchase = ({ purchaseConfirmationData, ...data } = {}) => {
  if (isBrowser && data) {
    const conversionId = getAdwordsConversionId();

    const gaData = {
      send_to: conversionId,
      value: parseFloat(data.value) || 1.0,
      currency: data.currency,
      transaction_id: parseInt(data.subscription_id, 10),
    };

    trackGAEvent({
      category: 'Subscription',
      label: data.event_label,
      action: 'successfully_purchase',
      data,
    });

    // Separate event track for oneclick payment
    trackGAEvent({
      category: 'Subscription',
      label: data.event_label,
      action: 'successfully_purchase_oneclick',
      data,
    });

    if (isFunction(window.gtag)) {
      window.gtag('event', 'conversion', gaData);
    }

    const fbData = {
      content_type: 'Purchase subscripiton',
      contents: data,
      value: parseFloat(data.value) || 1.0,
      currency: data.currency,
    };

    // trackFbEvent({
    //   category: 'track',
    //   action: 'Purchase',
    //   data: fbData,
    // });

    // Check
    // https://docs.google.com/document/d/16Q1KUOggJXPq18uj8yPltMC94ir475pZDONWZ1g7ieI/edit
    // for details
    // Structure:

    // {
    //   "event" : "purchase confirmation"
    //   "order" : {
    //     "id":"String",
    //     "affiliation":"String",
    //     "productRevenue":"#.##",
    //     "total":"#.##",
    //     "discount":"#.##",
    //     "shipping":"#.##",
    //     "tax":"#.##"
    //     "currency":"String",
    //     "coupon": "String"
    //     "products": {[
    //        {
    //           "id":"String",
    //           "name":"String",
    //           "price":"#.##",
    //           "quantity":0,
    //           "variant":"String",
    //        },
    //        {
    //           "id":"String",
    //           "name":"String",
    //           "price":"#.##",
    //           "quantity":0,
    //           "variant":"String",
    //        }
    //        ]}
    //     }
    //   }

    // const value = parseFloat(data.value) || 1.0;
    // const orderId = data.subscription_id ? String(data.subscription_id) : String(data.sku_id);

    // const purchaseData = {
    //   event: 'purchase confirmation',
    //   order: {
    //     id: orderId,
    //     productRevenue: value,
    //     total: value,
    //     currency: data.currency,
    //     products: [
    //       {
    //         id: data.purchase_id,
    //         name: data.event_label,
    //         price: value,
    //         variant: data.type,
    //       },
    //     ],
    //   },
    // };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(purchaseConfirmationData);
  }
};

export const trackFullDocumentViewer = ({
  userId,
  documentId,
}) => trackGAEvent({
  category: 'Document',
  action: 'full_doc_version',
  data: {
    user_id: userId,
    doc_id: documentId,
  },
});

export const trackMaxPageViewed = ({
  userId,
  documentId,
  pageNumber,
}) => trackGAEvent({
  category: 'Document',
  action: 'document_scroll',
  label: pageNumber,
  data: {
    user_id: userId,
    doc_id: documentId,
    viewed_count_of_pages: pageNumber,
  },
});

export const trackAdBannerClicked = async ({
  pageId,
  url,
}) => {
  postAhoyTrackCustom({
    ahoy_event: 'ad_banner_click',
    ahoy_params: {
      pageId: kebabCase(pageId),
      url,
    },
  });
};

export const trackUnlockQuestionRequestComplete = ({
  allow,
  hasPaidPlan,
}) => {
  let eventName;

  if (allow) {
    if (hasPaidPlan) {
      eventName = 'unlock - success - plan enabled';
    } else {
      eventName = 'unlock - success - free credit';
    }
  } else if (allow === false) {
    eventName = 'unlock - refused';
  }

  if (eventName) {
    dataLayerPush({ event: eventName });
  }
};

export const trackUnlockDocumentRequestComplete = ({
  allow,
  single,
}) => {
  let eventName;

  if (allow) {
    if (single) {
      eventName = 'unlock - success - single purchase';
    } else {
      eventName = 'unlock - success - plan enabled';
    }
  } else {
    eventName = 'unlock - refused';
  }

  if (eventName) {
    dataLayerPush({ event: eventName });
  }

  console.log(window.dataLayer);
};

export const trackAuthView = ({
  type = 'signup',
} = {}) => {
  let event;

  switch (type) {
    case 'signup':
      event = 'view_signup_form';
      break;
    case 'login':
      event = 'view_login_form';
      break;
    default:
      event = null;
  }

  if (event) {
    dataLayerPush({ event });
  }
};
