import {
  logError, deepGenerateIDs, createListItem,
} from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';
import React from 'react';
import { NOTES_URL_PREFIXES, ROUTES } from 'helpers/constants';
import { getUserProfileLink, isNoteTaker } from 'common/user/utils';
import urljoin from 'url-join';
import { MyCoursesListContainer } from 'oc-core-components/src/MyCoursesList';
// TODO: refactor - extract page specific code from this file
import LivestreamLink from 'components/pages/Livestream/LivestreamLink';
import CustomLink from 'oc-core-components/src/Navbar/NavbarDropdown/components/CustomLink';
import {
  PROMO_CODE_MODAL,
} from 'oc-core-components/src/Modal/constants';

/**
 * Generate Material url
 * based on a prefix and path array that comes from backend
 *
 * @param {string} key - a material prefix (all-materials/class-notes/textbook-notes/study-guides)
 * @param {Array} baseProductUrlArr - the rest of the path split by the / character.
 * This part can be different for logged in/logged out users. (e.g. country / country code + school, etc.)
 *
 * @example
 * // returns "/all-materials/canada.en.html"
 * getMaterialUrl('all-materials', ["canada.en.html"])
 * @returns {String} Returns a relative url to the corresponding material page on product browse
 */
export const getMaterialUrl = (key, baseProductUrlArr = [], query) => {
  const prefix = NOTES_URL_PREFIXES[key]?.prefix;
  if (!prefix) {
    logError('Incorrect material url prefix for: ', key);
    return null;
  }

  let url = urljoin('/', prefix, ...baseProductUrlArr);

  if (query) {
    url = buildUrl(url, query);
  }

  return url;
};

/**
 * Currying for the getMaterialUrl function
 * @param {Array} baseProductUrlArr
 *
 */
const createGetMaterialUrl = baseProductUrlArr => (key, ...restArgs) => getMaterialUrl(key, baseProductUrlArr, ...restArgs);

/**
 * A Link List Item
 * @typedef {{ label: String, url: String, uid: String }} LinkListItem
 */

/**
 * Generate urls for the navbar and mobile toolbar menu items
 * @param {Array} baseProductUrlArr
 * @param {bool} isLoggedIn
 *
 * @returns {Object.<string, LinkListItem>}
 */
const createMenuItemsMap = ({
  baseProductUrlArr = [], isLoggedIn, user, questionCredits, t,
}) => {
  const materialUrl = createGetMaterialUrl(baseProductUrlArr);

  // https://oneclass.atlassian.net/browse/OS-3269
  // https://oneclass.atlassian.net/browse/OS-3267
  // https://oneclass.atlassian.net/browse/OS-2353
  const itemsMap = {
    accountSettings: [t('account_settings'), ROUTES.account],
    addCourse: [t('add_course'), isLoggedIn ? null : ROUTES.login],
    allMaterials: [t('all_materials'), materialUrl('allMaterials')],
    ask: [t('ask'), ROUTES.homeworkHelp],
    askQuestion: [t('navbar:navbar.menuCreate.question.title'), ROUTES.askQuestion, { description: t('navbar:navbar.menuCreate.question.description'), icon: 'oc:ask', stroke: 'none' }],
    answer: [t('navbar:navbar.menuCreate.answer.title'), ROUTES.hwAnswers, { description: t('navbar:navbar.menuCreate.answer.description'), icon: 'oc:conversation', stroke: 'none' }],
    addStudyGuide: [t('navbar:navbar.menuCreate.studyGuide.title'), ROUTES.addStudyGuide, { description: t('navbar:navbar.menuCreate.studyGuide.description'), icon: 'feather:book' }],
    addClassNote: [t('navbar:navbar.menuCreate.classNote.title'), ROUTES.addClassNote, { description: t('navbar:navbar.menuCreate.classNote.description'), icon: 'feather:file' }],
    addTextbookNote: [t('navbar:navbar.menuCreate.textbookNote.title'), ROUTES.addTextbookNote, { description: t('navbar:navbar.menuCreate.textbookNote.description'), icon: 'feather:book-open' }],
    addPastExam: [t('navbar:navbar.menuCreate.pastExam.title'), ROUTES.addPastExam, { description: t('navbar:navbar.menuCreate.pastExam.description'), icon: 'feather_new:check-square' }],
    addSyllabus: [t('navbar:navbar.menuCreate.syllabus.title'), ROUTES.addSyllabus, { description: t('navbar:navbar.menuCreate.syllabus.description'), icon: 'feather_new:clipboard' }],
    badges: [t('badges'), ROUTES.badges],
    bookmarks: [t('bookmarks'), ROUTES.hwBookmarks],
    browseHomeworkHelp: [t('browse_homework_help'), ROUTES.homeworkHelp],
    classNotes: [t('class_notes'), materialUrl('classNotes')],
    courses: [t('navbar:courses'), ROUTES.boosterClass],
    earn: [t('earn_by_contributing'), ROUTES.officialNoteTaker],
    examSolutions: [t('exam_solutions'), materialUrl('studyGuides', { order: 'trending' })],
    examPrep: [t('exam_prep'), materialUrl('examPrep')],
    getAccess: [t('get_unlimited_access'), ROUTES.subscriptions],
    home: [t('home'), ROUTES.root],
    homeworkHelp: [t('homework_help'), ROUTES.homeworkHelp],
    hwHelp: [t('hw_help'), null],
    invite: [t('invite'), ROUTES.invite],
    livestream: [t('livestream'), ROUTES.livestream],
    logIn: [t('log_in'), ROUTES.login],
    logOut: [t('log_out'), ROUTES.logout],
    me: [t('me'), ROUTES.mobileNav],
    myCourses: [t('my_courses'), buildUrl(ROUTES.dashboard, { courses_modal: true })],
    yourQuestions: [t('your_questions'), ROUTES.hwYourQuestions],
    myUnlocks: [t('my_unlocks'), ROUTES.hwMyUnlocks],
    notes: [t('notes'), null],
    notetakerProgram: [t('notetaker_program'), ROUTES.notetakerProgram],
    notifications: [t('notifications'), ROUTES.notifications],
    payments: [t('payments'), ROUTES.payments],
    paypal: [t('paypal'), ROUTES.paypal],
    practice: [t('practice'), null],
    profile: [t('profile'), getUserProfileLink(user?.id, user?.nickname)],
    promoCode: [t('section.promo_code.title'), null],
    redemption: [t('redemption_history'), ROUTES.redemption],
    rewards: [t('rewards'), ROUTES.perks],
    shop: [t('shop'), ROUTES.shop],
    search: [t('search'), ROUTES.search],
    signUp: [t('sign_up'), ROUTES.signup],
    studyGuides: [t('study_guides'), materialUrl('studyGuides')],
    studyResources: [t('study_resources'), materialUrl('allMaterials')],
    textbookNotes: [t('textbook_notes'), materialUrl('textbookNotes')],
    transactions: [t('transactions'), ROUTES.transactions],
    upgrade: [t('upgrade'), ROUTES.subscriptions],
    upload: [t('upload'), ROUTES.upload],
  };


  return Object.entries(itemsMap).reduce((acc, [key, value]) => ({ ...acc, [key]: createListItem(value[0], value[1], { ...value[2], uid: key }) }

  ), {});
};


/**
 * A Dropdown Menu Item
 * @typedef {LinkListItem} DDMenuItem
 * @property {Array.<LinkListItem>} items - submenu items
 */

/**
 * Generate items for the navbar menu
 * @param {Array} baseProductUrlArr
 * @param {bool} isLoggedIn
 *
 * @returns {Array.<DDMenuItem>}
 */
export const getMenuItems = ({
  baseProductUrlArr = [], isLoggedIn, allowUploadButtons, user, t,
}) => {
  const items = createMenuItemsMap({
    baseProductUrlArr, isLoggedIn, user, t,
  });
  let menu = [
    {
      ...isLoggedIn ? items.notes : items.studyResources,
      items: [
        items.allMaterials,
        items.classNotes,
        items.textbookNotes,
        items.studyGuides,
        items.examSolutions,
        {
          ...items.earn,
          renderComponent: props => <CustomLink {...props} />,
        },
      ],
    },
    items.homeworkHelp,
    isLoggedIn ? {
      ...items.myCourses,
      // items: [
      //   items.allMaterials,
      //   items.classNotes,
      //   items.textbookNotes,
      // ],
      displayDefaultToggle: true,
      // TODO: probably should not be here
      children: () => <MyCoursesListContainer />,
    } : undefined,
    {
      ...items.livestream,
      renderComponent: props => <LivestreamLink {...props} />,
    },
  ];

  menu = menu.filter(r => r).map((el) => {
    if (el?.items) {
      return {
        ...el,
        items: el.items.filter(item => item),
      };
    }

    return el;
  });


  return deepGenerateIDs(menu.filter(r => r));
};


/**
 * A Dropdown Menu Item
 * @typedef {DDMenuItem} ToolbarDDMenuItem
 * @property {boolean} withNotifications - show a red dot near the item
 * @property {String} icon - icon name
 */

/**
 * Generate items for the mobile toolbar
 * @param {Array} baseProductUrlArr
 * @param {bool} isLoggedIn
 *
 * @returns {Array.<ToolbarDDMenuItem>}
 */
export const getToolbarMenuItems = ({
  baseProductUrlArr, isLoggedIn, activePro, allowUploadButtons, user, questionCredits, t,
} = {}) => {
  const items = createMenuItemsMap({
    baseProductUrlArr, isLoggedIn, user, questionCredits, t,
  });

  let menu = [
    {
      ...items.home,
      icon: 'logo:small',
      uid: 'home',
    },
    {
      ...items.search,
      icon: 'feather:search',
      uid: 'search',
    },
    // {
    //   ...items.courses,
    //   icon: 'sf:course',
    //   iconStroke: 'none',
    //   uid: 'courses',
    // },
    {
      icon: 'feather:plus',
      uid: 'add',
      round: true,
      items: [
        items.askQuestion,
        items.answer,
        // items.addStudyGuide,
        // items.addClassNote,
        // items.addTextbookNote,
        // items.addPastExam,
        // items.addSyllabus,
      ],
      title: t('navbar:navbar.labelAdd'),
    },
    {
      ...items.me,
      icon: 'sf:me',
      iconStroke: 'none',
      uid: 'me',
    },

  ];

  // remove undefiend submenu items
  menu = menu.map((el) => {
    if (el?.items) {
      return {
        ...el,
        items: el.items.filter(item => item),
      };
    }

    return el;
  });


  return deepGenerateIDs(menu);
};

export const getUserDropdown = ({
  user,
  baseProductUrlArr,
  isLoggedIn,
  t,
}) => {
  const items = createMenuItemsMap({
    baseProductUrlArr, isLoggedIn, user, t,
  });

  let userDropdown = [];

  if (!user.activePro) {
    userDropdown.push(items.getAccess);
  } else if (user.activePaypalSubscription) {
    userDropdown.push(items.paypal);
  }

  userDropdown = [
    ...userDropdown,
    (user.activePro || user.activeProTrial) && items.payments,
    items.yourQuestions,
    items.profile,
    items.accountSettings,
    items.rewards,
    items.invite,
    items.badges,
    items.transactions,
    { ...items.promoCode, modalId: PROMO_CODE_MODAL },
    isNoteTaker(user) && items.notetakerProgram,
    items.logOut,
  ];

  return userDropdown.filter(el => el);
};


export default {};
