import querystring from 'qs';

// TODO: check do we really need this function? query from useRouter should be used instead
const getQueryFromUrl = (url) => {
  const q = url && url.split(/[?#]/)[1];
  const query = (q ? querystring.parse(q) : {});

  return query;
};

export default getQueryFromUrl;
