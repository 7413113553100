const RECEIVE_NOTIFICATION = 'common/notification/RECEIVE_NOTIFICATION';
const CLEAR_NOTIFICATION = 'common/notification/CLEAR_NOTIFICATION';
const GET_NOTIFICATIONS_COUNT = 'common/notification/GET_NOTIFICATIONS_COUNT';
const GET_NOTIFICATIONS_COUNT_SUCCESS = 'common/notification/GET_NOTIFICATIONS_COUNT_SUCCESS';
const GET_NOTIFICATIONS_COUNT_ERROR = 'common/notification/GET_NOTIFICATIONS_COUNT_ERROR';
const CLEAR_NOTIFICATIONS_COUNT = 'common/notification/CLEAR_NOTIFICATIONS_COUNT';

export {
  RECEIVE_NOTIFICATION,
  GET_NOTIFICATIONS_COUNT,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_COUNT_ERROR,
  CLEAR_NOTIFICATION,
  CLEAR_NOTIFICATIONS_COUNT,
};
