import { logErrorDev, isFunction } from 'shared/helpers/utils';
import deepmerge from 'deepmerge';
import merge from 'lodash/merge';

export const combineMerge = (originalState, newState, { strategy } = {}) => {
  const strategyArr = Array.isArray(strategy) ? strategy : [strategy];

  return strategyArr.reduce((currentState, str) => {
    if (!str) {
      logErrorDev('merging strategy is not defined');
      return currentState;
    }

    if (isFunction(str)) {
      return str(currentState, originalState, newState);
    }

    if (!['assign', 'replace', 'merge', 'deepMerge'].includes(str)) {
      console.error('Incorrect merging strategy:', str);
      return currentState;
    }

    switch (str) {
      case 'assign':
        return Object.assign({}, currentState, newState);
      case 'merge':
        return merge({}, currentState, newState);
      case 'deepMerge':
        return deepmerge(currentState, newState);
      case 'replace':
        return newState;
      default: return currentState;
    }
  }, originalState);
};

export default combineMerge;
