import React from 'react';
import { node, string } from 'prop-types';
import { tagType } from 'types';
import Link from 'oc-core-components/src/Link';

import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './PageTitle.module.scss';

const styles = stylesProxy(stylesObj, 'PageTitle');

const PageTitle = ({
  subtitle,
  title,
  tag: Tag,
  subtitleTag: SubtitleTag,
  className,
  subtitleClassName,
  titleClassName,
  children,
  afterContent,
  aboutLink,
  bordered,
}) => (
  <div className={cn(styles.container, className, { [styles.bordered]: bordered })}>
    <div>
      {
        (title && Tag) && (
          <Tag className={cn(styles.title, titleClassName)}>{title}</Tag>
        )
      }

      {
        (subtitle && SubtitleTag) && (
          <SubtitleTag className={cn(styles.subtitle, subtitleClassName)}>
            <span className={styles.subtitle__inner}>
              {subtitle}
            </span>
            {
              aboutLink && (
                <span className={styles.subtitle__inner}>
                  <Link className="u-font-light-semibold" href={aboutLink?.href}>
                    {aboutLink?.label}
                  </Link>
                </span>
              )
            }
          </SubtitleTag>
        )
      }

      {children}
    </div>
    {afterContent}
  </div>
);

PageTitle.propTypes = {
  title: node,
  subtitle: node,
  tag: tagType,
  titleClassName: string,
  subtitleTag: tagType,
  subtitleClassName: string,
  afterContent: node,
};

PageTitle.defaultProps = {
  tag: 'h1',
  subtitleTag: 'div',
};

export default PageTitle;
