import React from 'react';
import { ROUTES } from 'helpers/constants';
import buildUrl from 'helpers/utils/buildUrl';
import { Trans } from 'config/i18n';
import { postClickTracking } from 'common/api';

export const NavbarLinks = Object.freeze({
  boosterClasses: ROUTES.boosterClass,
  default: ROUTES.root,
  homeworkHelp: ROUTES.homeworkHelp,
  studyGuides: ROUTES.studyGuides,
  classNotes: ROUTES.classNotes,
  textbookNotes: ROUTES.textbookNotes,
  livestreams: ROUTES.livestream,
  blogs: ROUTES.blog,
  search: ROUTES.root,
});

export const NavbarTypes = Object.freeze({
  boosterClasses: 'boosterClasses',
  default: 'default',
  homeworkHelp: 'homeworkHelp',
  studyGuides: 'studyGuides',
  classNotes: 'classNotes',
  textbookNotes: 'textbookNotes',
  livestreams: 'livestreams',
  blogs: 'blogs',
  search: 'search',
});

export const NavbarMenuIds = Object.freeze({
  create: 'navbarMenuCreate',
  experienceChanger: 'navbarMenuExperienceChanger',
  experienceNotifications: 'navbarMenuExperienceNotifications',
  experiencePricing: 'navbarMenuExperiencePricing',
  search: 'navbarMenuSearch',
  settings: 'navbarMenuSettings',
});

export const NavbarSearchApiTypes = Object.freeze({
  [NavbarTypes.blogs]: 'blog',
  [NavbarTypes.boosterClasses]: 'bc',
  [NavbarTypes.classNotes]: 'cn',
  [NavbarTypes.studyGuides]: 'sg',
  [NavbarTypes.textbookNotes]: 'tn',
  [NavbarTypes.livestreams]: 'livestream',
  [NavbarTypes.homeworkHelp]: 'hw',
});

export const NavbarRoutes = Object.freeze([
  {
    routes: ['/courses', '/booster-classes'],
    type: NavbarTypes.boosterClasses,
  },
  {
    routes: [
      '/homework-help',
      '/homework-help-parents',
      '/homework-help-get-started',
      '/app/homework-help',
      '/app/homework-help-parents',
      '/app/homework-help-get-started',
    ],
    type: NavbarTypes.homeworkHelp,
  },
  {
    routes: ['/study-guides'],
    type: NavbarTypes.studyGuides,
  },
  {
    routes: ['/class-notes'],
    type: NavbarTypes.classNotes,
  },
  {
    routes: ['/class-notes'],
    type: NavbarTypes.classNotes,
  },
  {
    routes: ['/textbook-notes'],
    type: NavbarTypes.textbookNotes,
  },
  {
    routes: ['/livestream'],
    type: NavbarTypes.livestreams,
  },
  {
    routes: ['/blog'],
    type: NavbarTypes.blogs,
  },
  {
    routes: ['/search'],
    type: NavbarTypes.search,
  },
  {
    routes: ['/my-courses'],
    type: ({ query } = {}) => {
      const exp = query?.exp;
      if ([NavbarTypes.textbookNotes, NavbarTypes.studyGuides].includes(exp)) {
        return exp;
      }

      return NavbarTypes.classNotes;
    },
  },
  {
    routes: ['/homework-help/your-questions'],
    type: ({ query } = {}) => {
      const exp = query?.exp;
      if (exp === NavbarTypes.boosterClasses) {
        return exp;
      }

      return NavbarTypes.homeworkHelp;
    },
  },
]);

export const NavbarSearchTypes = Object.freeze({
  default: 'Search for',
  blog: 'blog',
  boosterClass: 'boosterClass',
  classNote: 'classNote',
  course: 'course',
  history: 'history',
  livestream: 'livestream',
  professor: 'professor',
  question: 'question',
  school: 'school',
  skill: 'skill',
  studyGuide: 'studyGuide',
  textbookNote: 'textbookNote',
  unsupported: '',
});

export const NavbarSearchTypeIndexPrefixes = Object.freeze({
  blog: 'wp_post',
  boosterClass: 'booster_classes',
  classNote: 'documents_new',
  course: 'autocomplete_course_search',
  livestream: 'stream_videos',
  professor: 'autocomplete_professors_new',
  question: 'homework_help_question_searches',
  school: 'autocomplete_schools_new',
  skill: 'booster_class_skills',
  studyGuide: 'documents_new',
  textbookNote: 'documents_new',
});

export const MENU_ITEMS_CREATE = ({ askQuestionUrl, isLoggedIn, willEarnPoints }) => {
  const answerPoints = willEarnPoints?.answer?.totalPoints;
  const uploadPoints = willEarnPoints?.upload?.totalPoints;

  return [
    {
      key: 'question',
      icon: 'oc:ask',
      iconStroke: 'none',
      title: 'navbar:navbar.menuCreate.question.title',
      data: askQuestionUrl ? undefined : { addQuestion: true },
      description: 'navbar:navbar.menuCreate.question.description',
      url: askQuestionUrl,
    },
    {
      key: 'answer',
      icon: 'oc:conversation',
      iconStroke: 'none',
      title: 'navbar:navbar.menuCreate.answer.title',
      description: 'navbar:navbar.menuCreate.answer.description',
      url: ROUTES.hwAnswers,
      points: answerPoints,
    },
    {
      key: 'studyGuide',
      icon: 'feather:book',
      title: 'navbar:navbar.menuCreate.studyGuide.title',
      description: 'navbar:navbar.menuCreate.studyGuide.description',
      data: { addCourse: true, kind: 'study-guide' },
      url: buildUrl(ROUTES.docUpload, { kind: 'exam' }),
      points: uploadPoints,
    },
    {
      key: 'classNote',
      icon: 'feather:file',
      title: 'navbar:navbar.menuCreate.classNote.title',
      description: 'navbar:navbar.menuCreate.classNote.description',
      data: { addCourse: true, kind: 'class-note' },
      url: buildUrl(ROUTES.docUpload, { kind: 'lecture' }),
      points: uploadPoints,
    },
    {
      key: 'textbookNote',
      icon: 'feather:book-open',
      title: 'navbar:navbar.menuCreate.textbookNote.title',
      description: 'navbar:navbar.menuCreate.textbookNote.description',
      data: { addCourse: true, kind: 'textbook-note' },
      url: buildUrl(ROUTES.docUpload, { kind: 'textbook' }),
      points: uploadPoints,
    },
    {
      key: 'pastExam',
      icon: 'feather_new:check-square',
      title: 'navbar:navbar.menuCreate.pastExam.title',
      description: 'navbar:navbar.menuCreate.pastExam.description',
      data: { addCourse: true, kind: 'past-exam' },
      url: buildUrl(ROUTES.docUpload, { kind: 'past_exam' }),
      points: uploadPoints,
    },
    {
      key: 'syllabus',
      icon: 'feather_new:clipboard',
      title: 'navbar:navbar.menuCreate.syllabus.title',
      description: 'navbar:navbar.menuCreate.syllabus.description',
      data: { addCourse: true, kind: 'syllabus' },
      url: buildUrl(ROUTES.docUpload, { kind: 'syllabus' }),
      points: uploadPoints,
    },
  ];
};

export const MENU_ITEMS_EXPERIENCE_CHANGER = isLoggedIn => [
  // {
  //   key: 'home',
  //   description: 'navbar:navbar.menuExperienceChanger.home.description',
  //   icon: 'feather:home',
  //   title: 'navbar:navbar.menuExperienceChanger.home.title',
  //   url: isLoggedIn ? ROUTES.dashboard : ROUTES.root,
  // },
  {
    key: 'homeworkHelp',
    description: 'navbar:navbar.menuExperienceChanger.homeworkHelp.description',
    icon: 'oc:conversation',
    iconStroke: 'none',
    title: 'navbar:navbar.menuExperienceChanger.homeworkHelp.title',
    url: ROUTES.homeworkHelp,
  },
  {
    key: 'studyGuides',
    description: 'navbar:navbar.menuExperienceChanger.studyGuides.description',
    icon: 'feather:book',
    title: 'navbar:navbar.menuExperienceChanger.studyGuides.title',
    url: ROUTES.studyGuides,
  },
  {
    key: 'classNotes',
    description: 'navbar:navbar.menuExperienceChanger.classNotes.description',
    icon: 'feather:file',
    title: 'navbar:navbar.menuExperienceChanger.classNotes.title',
    url: ROUTES.classNotes,
  },
  {
    key: 'textbookNotes',
    description: 'navbar:navbar.menuExperienceChanger.textbookNotes.description',
    icon: 'feather:book-open',
    title: 'navbar:navbar.menuExperienceChanger.textbookNotes.title',
    url: ROUTES.textbookNotes,
  },
  {
    key: 'textbookSolutions',
    description: 'navbar:navbar.menuExperienceChanger.textbookSolutions.description',
    icon: 'oc:document-folder',
    iconStroke: 'none',
    title: 'navbar:navbar.menuExperienceChanger.textbookSolutions.title',
    url: ROUTES.textbooks,
  },
  {
    key: 'boosterClasses',
    description: 'navbar:navbar.menuExperienceChanger.boosterClasses.description',
    icon: 'sf:course',
    iconStroke: 'none',
    title: 'navbar:navbar.menuExperienceChanger.boosterClasses.title',
    url: ROUTES.boosterClass,
  },
  {
    key: 'blogs',
    description: 'navbar:navbar.menuExperienceChanger.blogs.description',
    icon: 'feather:feather',
    title: 'navbar:navbar.menuExperienceChanger.blogs.title',
    url: ROUTES.blog,
  },
];

export const MENU_ITEMS_PRICING = [
  {
    key: 'compare',
    title: 'navbar:navbar.menuPricing.compare',
    icon: 'oc:compare-plans',
    iconStroke: 'none',
    url: ROUTES.subscriptions,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'gradePlus',
    title: 'navbar:navbar.menuPricing.gradePlus',
    icon: 'oc:grade-plus',
    iconStroke: 'none',
    url: ROUTES.gradePlusPricing,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'notes',
    title: 'navbar:navbar.menuPricing.notes',
    icon: 'oc:file-solid',
    iconStroke: 'none',
    url: ROUTES.notesPricing,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'homeworkHelp',
    title: 'navbar:navbar.menuPricing.homeworkHelp',
    icon: 'oc:conversation-solid',
    iconStroke: 'none',
    url: ROUTES.homeworkHelpPricing,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'boosterClass',
    title: 'navbar:navbar.menuPricing.boosterClass',
    icon: 'sf:course-solid',
    iconStroke: 'none',
    url: ROUTES.boosterClassPricing,
    rightIcon: 'feather:chevron-right',
  },
  // {
  //   key: 'studyGuides',
  //   title: 'navbar:navbar.menuPricing.studyGuides',
  //   icon: 'oc:book-solid',
  //   iconStroke: 'none',
  //   url: ROUTES.studyGuidesPricing,
  //   rightIcon: 'feather:chevron-right',
  // },

  {
    key: 'vouchers',
    title: 'navbar:navbar.menuPricing.vouchers',
    icon: 'oc:vouchers-solid',
    iconStroke: 'none',
    url: ROUTES.vouchers,
    rightIcon: 'feather:chevron-right',
  },
];

export const MENU_ITEMS_SETTINGS = [
  {
    key: 'account',
    icon: 'feather:user',
    title: 'navbar:navbar.menuSettings.account',
    url: ROUTES.account,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'contributions',
    icon: 'oc:contributions',
    iconStroke: 'none',
    title: 'navbar:navbar.menuSettings.contributions',
    url: ROUTES.insights,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'referrals',
    icon: 'feather:user-plus',
    title: () => (
      <Trans i18nKey="navbar:navbar.menuSettings.referrals.title">
        Refer and earn <span className="u-color-primary">+$5</span>
      </Trans>
    ),
    description: 'navbar:navbar.menuSettings.referrals.description',
    titleI18nProps: { escapeValue: false },
    url: buildUrl(ROUTES.referrals, { source: 'menu' }),
    onClick: () => postClickTracking(window.location.pathname, 'menuRef'),
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'pricing',
    icon: 'oc:compare-plans-outline',
    iconStroke: 'none',
    title: 'navbar:navbar.menuSettings.pricing',
    url: ROUTES.subscriptions,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'voucher',
    icon: 'oc:voucher',
    iconStroke: 'none',
    title: 'navbar:navbar.menuSettings.voucher',
    url: ROUTES.vouchers,
    rightIcon: 'feather:chevron-right',
  },
  {
    key: 'logOut',
    icon: 'feather_new:log-out',
    title: 'navbar:navbar.menuSettings.logOut',
    url: ROUTES.logout,
    rightIcon: 'feather:chevron-right',
  },
];
