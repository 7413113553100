import getConfig from 'next/config';

const env = {
  'process.env.ASSETS_PREFIX': process.env.ASSETS_PREFIX,
  'process.env.ASSETS_URL': process.env.ASSETS_URL,
  'process.env.ELASTIC_SEARCH_URL': process.env.ELASTIC_SEARCH_URL,
  'process.env.RAILS_PORT': process.env.RAILS_PORT,
  'process.env.NODE_PORT': process.env.NODE_PORT,
  'process.env.NODE_ENV': process.env.NODE_ENV,
  'process.env.PROD_URL': process.env.PROD_URL,
  'process.env.IS_DEV': process.env.IS_DEV,
  'process.env.IS_PROD': process.env.IS_PROD,
  'process.env.IS_FEATURE': process.env.IS_FEATURE,
  'process.env.IS_TEST': process.env.IS_TEST,
  'process.env.IS_LOCAL': process.env.IS_LOCAL,
  'process.env.IS_ONECLASS_PROD': process.env.IS_ONECLASS_PROD,
  'process.env.HH_SHOW_REPRESENTATIVE_PATH': process.env.HH_SHOW_REPRESENTATIVE_PATH,
  'process.env.DEBUG_RENDER': process.env.DEBUG_RENDER,
  'process.env.SENTRY_RELEASE': process.env.SENTRY_RELEASE,
  'process.env.DEBUG_SERVER_JS': process.env.DEBUG_SERVER_JS,
  'process.env.IS_CYPRESS_TESTING_ENV': process.env.IS_FEATURE || process.env.IC_LOCAL || process.env.IS_DEV,
};

const envTransformedByBabel = Object.entries(env).reduce((acc, [k, v]) => {
  acc[k.replace('process.env.', '')] = v;
  return acc;
}, {});

const getEnv = () => {
  const config = getConfig();

  const configEnv = (config && config.publicRuntimeConfig && config.publicRuntimeConfig.env);
  const calculatedEnv = envTransformedByBabel || configEnv;

  return calculatedEnv;
};

export default getEnv;
