import React, { Fragment } from 'react';
import { bool, node, shape } from 'prop-types';
import dynamic from 'next/dynamic';
import { IS_DEV, getDynamicImportProps } from 'helpers/utils';

const theme = {
  scheme: 'bright',
  author: 'chris kempson (http://chriskempson.com)',
  base00: '#000000',
  base01: '#303030',
  base02: '#505050',
  base03: '#b0b0b0',
  base04: '#d0d0d0',
  base05: '#e0e0e0',
  base06: '#f5f5f5',
  base07: '#ffffff',
  base08: '#fb0120',
  base09: '#fc6d24',
  base0A: '#fda331',
  base0B: '#a1c659',
  base0C: '#76c7b7',
  base0D: '#6fb3d2',
  base0E: '#d381c3',
  base0F: '#be643c',
};

const defautlMapPropsToData = props => props;


const dynamicProps = getDynamicImportProps();

const JSONTree = dynamic(() => import(/* webpackChunkName: "JSONTree" */ 'react-json-tree'), dynamicProps);

export const Debug = ({
  debug, children, data, viewerProps,
}) => ((debug && IS_DEV) ? (
  <Fragment>
    {children}
    <JSONTree
      data={data}
      theme={theme}
      hideRoot
      shouldExpandNode={(keyName, nodeData, level) => (level < 2)}
      {...viewerProps}
    />
  </Fragment>
) : children);


Debug.propTypes = {
  debug: bool,
  children: node,
  data: shape(),
  viewerProps: shape(),
};


const WithDebug = (Component, { mapPropsToData = defautlMapPropsToData, ...restProps }) => (IS_DEV ? ({ debug, ...props }) => (
  <Debug debug={debug} data={mapPropsToData(props)} viewerProps={restProps}>
    <Component {...props} />
  </Debug>
) : Component);

export default WithDebug;
