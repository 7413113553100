import React from 'react';
import { TabPanel as ReactTabPanel } from 'react-tabs';
import { string } from 'prop-types';

const propTypes = {
  className: string,
};

const TabPanel = (props) => {
  const {
    className,
    ...restProps
  } = props;

  return (
    <ReactTabPanel className={className} {...restProps} />
  );
};

TabPanel.propTypes = propTypes;
TabPanel.tabsRole = 'TabPanel';

export default TabPanel;
