import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2CurrentUser } from 'v2/redux/meta/selectors';
import useSignupDiscountPopup from './useSignupDiscountPopup';

const usePreloadSignupDiscountPopup = ({ enabled }) => {
  const currentUser = useSelector(getV2CurrentUser) || {};
  const isLoggedIn = !!currentUser;
  const displayPopup = currentUser?.show10OffPopup === true;
  // const displayPopup = true;
  const showPopup = useSignupDiscountPopup({ event: 'not_used' });

  useEffect(() => {
    if (!enabled) return;
    if (!isLoggedIn) return;
    if (!displayPopup) return;

    showPopup();
  }, [enabled, displayPopup, showPopup, isLoggedIn]);
};

export default usePreloadSignupDiscountPopup;
