import React from 'react';
import cn from 'classnames';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Button from 'oc-core-components/src/Button';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import ShopItem from './components/ShopItem';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ShopItemsModalContent.module.scss';

const styles = stylesProxy(stylesObj, 'ShopItemsModalContent');


const ShopItemsModalContent = ({
  items = [],
}) => {
  const modal = useModal();

  return (
    <div>
      <h2 className={styles.title}>
        New items available - OneClass Shop
      </h2>
      <p className={styles.subtitle}>
        Free shipping on all orders
      </p>

      <Row xsGap="xs" mdGap="m">
        {
          items.map(({
            id, img, mobile_img: mobileImg, price, name, url,
          }) => (
            <Col xs="4" key={id} className="u-d-flex u-d-block-lg">
              <ShopItem
                className="u-w-100"
                name={name}
                price={price}
                img={img}
                mobileImg={mobileImg}
                id={id}
                href={url}
              />
            </Col>
          ))
        }
      </Row>

      <div className="u-text-center u-mt-s u-mt-l-lg">
        <Button color="dark" className={styles.button} onClick={modal.close}>Not interested</Button>
      </div>

    </div>
  );
};


export default ShopItemsModalContent;
