import zipObject from 'lodash/zipObject';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
// https://github.com/tbranyen/redux-create-action-types
// https://github.com/shadowwzw/redux-types-creator

const checkArguments = (type, collection) => {
  if (collection) {
    if (!Array.isArray(collection)) {
      throw new Error(`${type} collection must be array`);
    }

    const notValidArguments = collection.filter(item => typeof item !== 'string');

    if (notValidArguments.length) {
      throw new Error(`${type} must be string`);
    }
  }
};

export const getStartWithPostfixes = (type, postfixMap = {}) => Object.entries(postfixMap).reduce((acc, item) => {
  const prefix = item[0];
  const postfixArr = item[1];

  checkArguments('startWith postfix', postfixArr);


  if (type.indexOf(prefix) === 0) {
    return [...acc, ...postfixArr];
  }

  return acc;
}, []);

export const typesCreatorFactory = (frozen, {
  prefix: globalPrefix = '',
  postfix: globalPostfix = {},
}) => {
  checkArguments('global default postfix', globalPostfix.default);

  return (prefix = '', { postfix: customPostfix } = {}) => (...types) => {
    checkArguments('types', types);
    const postfix = mergeWith({}, globalPostfix, customPostfix, (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return uniq(objValue.concat(srcValue));
      }
    });

    const defaultPostfix = postfix.default || [];

    const fullPrefix = [globalPrefix, prefix].filter(p => p).join('/');

    const prefixStr = fullPrefix ? `${fullPrefix}/` : '';

    const result = {};

    types.forEach((type) => {
      const postfixArr = defaultPostfix.concat(getStartWithPostfixes(type, postfix.startWith));
      const typesWithPostfixes = postfixArr.map(itemPostfix => `${prefixStr}${type}_${itemPostfix}`);
      const self = `${prefixStr}${type}`;

      if (typesWithPostfixes.length) {
        result[type] = zipObject(postfixArr, typesWithPostfixes);
        result[type].SELF = self;
      } else {
        result[type] = self;
      }
    });


    return frozen ? Object.freeze(result) : result;
  };
};

export default typesCreatorFactory(true, {
  prefix: 'common/v2',
  postfix: {
    startWith: {
      FETCH: ['SUCCESS', 'ERROR'],
      RETRIEVE: ['SUCCESS', 'ERROR'],
      LOAD: ['START', 'STOP'],
    },
  },
});
