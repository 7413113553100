import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';


import { modalContextPropTypes } from 'oc-core-components/src/Modal/ModalProvider';
// import { formSignupSteps } from 'oc-core-components/src/Authentication/constants';
// import { getSignupCookie, removeSignupCookie } from 'oc-core-components/src/Authentication/utils';
// import { trackPageView } from 'helpers/tracking';
import useTrackGaEventsAfterSignup from 'helpers/hooks/useTrackGaEventsAfterSignup';
// import { useLoadNewProfileAnnouncementPopup } from 'shared/helpers/hooks/useNewProfileAnnouncement';
import { useLoadTutorWelcomePopup } from 'shared/helpers/hooks/useTutorWelcomePopup';
// import {
//   getWechatSourceIdCookie,
//   removeWechatSourceIdCookie,
// } from 'oc-react-stripe-utils';
// import { cookiesKeys } from 'helpers/constants';
// import { cookies } from 'helpers/utils';

import { getV2CurrentUserStatus, getV2IsLoggedIn } from 'v2/redux/meta/selectors';
import { selectV2DisplayCrisp } from 'v2/redux/page/selectors';

import Page from './Page';

const PageContainer = props => {
  // useEffect(() => {
  //   // const wechatSourceId = getWechatSourceIdCookie(cookies, cookiesKeys);

  //   // if (userStatus === 'active') {
  //   //   removeSignupCookie();

  //   //   removeWechatSourceIdCookie(cookies, cookiesKeys);
  //   // } else if (isLoggedIn) {
  //   //   const currentSignupStep = getSignupCookie();
  //   //   if (currentSignupStep && formSignupSteps[currentSignupStep]) {
  //   //     modal.openLogin({ activeForm: formSignupSteps[currentSignupStep] });
  //   //   }
  //   // } else if (wechatSourceId) {
  //   //   modal.openSignup({ hideClose: true });
  //   // }

  //   // TODO: move to PageData HOC as soon as it is ready
  //   // trackPageView();
  // }, []);

  useTrackGaEventsAfterSignup();
  // useLoadNewProfileAnnouncementPopup();
  useLoadTutorWelcomePopup();

  return (
    <Page
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  isLoggedIn: getV2IsLoggedIn(state),
  // userStatus: getV2CurrentUserStatus(state),
  displayCrisp: selectV2DisplayCrisp(state),
});

PageContainer.propTypes = {
  modal: modalContextPropTypes,
  userStatus: string,
  isLoggedIn: bool,
  displayCrisp: bool,
};

export { PageContainer };
export default connect(mapStateToProps, null)(PageContainer);
