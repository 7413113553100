import { useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getV2CurrentPage } from 'shared/v2/redux/page/selectors';
import { fetchV2RouteDataLatest } from 'shared/v2/redux/actions';
import { isFunction } from 'shared/helpers/utils';
import scrollToBlock from 'helpers/utils/scrollToBlock';
import { HEADER_ID } from 'shared/helpers/constants';
import { useRouter } from 'oc-core-components/src/RouterProvider';


const defaultMergingStrategy = {
  pageProps: 'assign',
};

const defaultScrollToParams = {
  withHeader: true,
};

const useRouteManager = ({
  mergingStrategy: customMergingStrategy,
  loadingId,
  onRouteChange,
  scrollTop,
  scrollToSelector = `#${HEADER_ID}`,
  scrollToParams,
  progressBar,
} = {}) => {
  const { router } = useRouter();
  const currentPage = useSelector(getV2CurrentPage);
  const dispatch = useDispatch();
  const mergingStrategy = useMemo(() => Object.assign({}, defaultMergingStrategy, customMergingStrategy), [customMergingStrategy]);
  const previousUrlRef = useRef();

  useEffect(() => {
    const handleRouteChange = (url) => {
      dispatch(fetchV2RouteDataLatest({
        page: currentPage,
        url,
        loadingId,
        mergingStrategy,
        progressBar,
      }));

      if (isFunction(onRouteChange)) {
        onRouteChange(url, previousUrlRef.current);
      }

      previousUrlRef.current = url;

      if (scrollTop && scrollToSelector) {
        scrollToBlock(scrollToSelector, { ...defaultScrollToParams, ...scrollToParams });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [currentPage, progressBar, mergingStrategy, loadingId, onRouteChange, scrollToSelector, scrollTop, scrollToParams, dispatch]);
};

export default useRouteManager;
