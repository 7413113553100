import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getV2PointsToCollect, getV2TopBanner } from 'shared/v2/redux/meta/selectors';
import { postCollectPoints } from 'shared/common/api';
import { useNotifications } from 'oc-core-components/src/NotificationProvider';
// import { useAsync } from 'react-async';

import CollectPointsModal from './CollectPointsModal';


const CollectPointsModalContainer = () => {
  const pointsCount = useSelector(getV2PointsToCollect);
  const withBannerOffset = useSelector(getV2TopBanner);
  const {
    addErrorNotification,
  } = useNotifications();


  const [displayModal, setModalVisibility] = useState(!!pointsCount);

  // TODO: consider using react-async
  const handleClick = useCallback(async () => {
    setModalVisibility(false);

    const { data } = await postCollectPoints('all');
    if (data?.error) {
      // at the moment there's no error handling on backend side
      console.log("Something went wrong")
      addErrorNotification(data?.error);
    }
  }, [addErrorNotification]);

  return displayModal ? (
    <CollectPointsModal
      pointsCount={pointsCount}
      onClick={handleClick}
      withBannerOffset={!!withBannerOffset}
    />
  ) : null;
};

CollectPointsModalContainer.propTypes = {};

export default CollectPointsModalContainer;
