import React from 'react'
import {
  THANK_YOU_MODAL,
} from 'oc-core-components/src/Modal/constants';
import DynamicModal from 'oc-core-components/src/DynamicModal';
import { withModal } from 'oc-core-components/src/Modal/ModalProvider';

const ThankYouModalInner = () => import(/* webpackChunkName: "ThankYouModalInner" */
  'components/DocumentShow/ThankYouModal/ThankYouModalInner'
);


const ThankYouModal = ({ modal }) => {
  const {
    modalProps: {
      hideClose,
      disableClose,
      disableOverlayClose,
      centerOnMobile,
      onCloseClick,
    },
  } = modal;

  const dynamicModalProps = {
    hideClose,
    disableClose,
    centerOnMobile,
    disableOverlayClose,
    onCloseClick,
  };

  return (
    <DynamicModal
      disableClose
      hideClose
      centerOnMobile
      component={ThankYouModalInner}
      name={THANK_YOU_MODAL}
      size="lg"
      render={(Inner, { modalProps }) => (
        <Inner
          closeModal={modal.close}
          openModal={modal.open}
          {...modalProps}
        />
      )}
      {...dynamicModalProps}
    />
  );
};

export default withModal(ThankYouModal);
