import React, { Component } from 'react';
import { List, Item, Group } from 'oc-core-components/src/Dropdown';
import TextInput from 'oc-core-components/src/TextInput';
import isEqual from 'react-fast-compare';
import cn from 'classnames';
import {
  bool, string, shape, func, node, oneOfType,
} from 'prop-types';

import { isFunction, isObject } from 'helpers/utils';
import removeEmptyFields from 'helpers/utils/removeEmptyFields';
import { itemsPropTypes } from './utils';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Autocomplete.module.scss';

const styles = stylesProxy(stylesObj, 'Autocomplete');


class Menu extends Component {
  static propTypes = {
    withSearch: bool,
    items: itemsPropTypes,
    notFoundText: string,
    autocompleteProps: shape(),
    onSearchChange: func,
    renderItem: func,
    renderFooter: func,
    hideNotFound: bool,
    optionsClassName: string,
    showResetOption: bool,
    resetOptionText: node,
    optionProps: oneOfType([func, shape()]),
  };

  componentDidMount = () => {
    if (this.searchInput) {
      this.searchInput.focus();
    }

    if (this.props.withSearch) {
      this.props.autocompleteProps.setState({ inputValue: '' });
    }
  }

  reset = () => this.props.autocompleteProps.reset({ selectedItem: null })

  renderItem = (item, index) => {
    const {
      getItemProps,
      highlightedIndex,
      selectedItem,
      itemToString,
    } = this.props.autocompleteProps;

    const {
      itemClassName,
      optionProps,
      selectedClassName,
      highlightedClassName,
      selectedItems,
    } = this.props;

    const selectionProps = {
      highlighted: highlightedIndex === index,
      selected: (selectedItem !== null) && isEqual(removeEmptyFields(selectedItem), removeEmptyFields(item)),
    };

    const itemProps = isFunction(optionProps) ? optionProps(item) : (isObject(optionProps) ? optionProps : null);
    const isBoldItem = selectedItems?.length > 0 ? !!selectedItems.find(si => si.value === item.value) : false;

    return (
      <Item
        {...itemProps}
        {...getItemProps({
          key: item.uid || `${itemToString(item)}-${index}`,
          index,
          item,
          ...selectionProps,
        })}
        className={itemClassName}
        selectedClassName={selectedClassName}
        highlightedClassName={highlightedClassName}
        bold={isBoldItem}
      >
        {this.props.renderItem(item, this.props.autocompleteProps, selectionProps)}

      </Item>
    );
  }

  renderItemList = (items, groupIndex = '0') => items.map((item, index) => {
    if (item.options) {
      return item.options.length > 0 && (
        <Group key={item.uid || `${item.label}-${index}`} label={item.label}>
          {this.renderItemList(item.options, `${groupIndex}-${index}`)}
        </Group>
      );
    }

    // return this.renderItem(item, `${groupIndex}-${index}`);
    return this.renderItem(item, index);
  })

  render() {
    const {
      items,
      notFoundText,
      withSearch,
      hideNotFound,
      optionsClassName,
      showResetOption,
      resetOptionText,
      className,
      itemClassName,
      renderFooter,
      renderAfter,
      inputValue,
      autocompleteProps,
      noDefaultStyles,
      wrapper: Wrapper,
      menuAbove,
      autocompleteProps: {
        getMenuProps,
        getInputProps,
        itemToString,
        reset,
        highlightedIndex,
      },
    } = this.props;

    let { renderItem } = this.props;

    if (!renderItem) renderItem = itemToString;

    return (
      <Wrapper className={cn({ [styles.list]: !noDefaultStyles, [styles.above]: menuAbove }, className)} {...getMenuProps()}>
        {
          withSearch && (
            <div className={cn({ [styles.search]: !noDefaultStyles })}>
              <TextInput
                {...getInputProps({
                  refCallback: (input) => { this.searchInput = input; },
                  className: styles.search__field,
                  placeholder: 'Search',
                  'icon-area-label': 'Search Icon',
                })}
                autoComplete="no"
                data-test-id="js-test_search_input"
              />
            </div>
          )
        }
        <div className={cn({ [styles.options]: !noDefaultStyles }, optionsClassName)}>
          {
            (showResetOption && (items.length > 0)) && (
              <Item onClick={this.reset} className={itemClassName}>
                {resetOptionText}
              </Item>
            )
          }

          {
            (!hideNotFound && (items.length === 0) && inputValue)
            && (
            <Item disabled className={itemClassName}>
              {notFoundText}
            </Item>
            )
          }
          {
            (items.length > 0) && this.renderItemList(items)
          }
          {
            isFunction(renderAfter) && (
              renderAfter({
                inputValue, items, autocompleteProps,
              })
            )
          }
        </div>
        {
          isFunction(renderFooter) && (
            <Item onClick={this.reset} className={itemClassName} tag="span">
              { renderFooter({ items, autocompleteProps }) }
            </Item>
          )
        }
      </Wrapper>
    );
  }
}

Menu.defaultProps = {
  wrapper: List,
};

export default Menu;
