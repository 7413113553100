import React, { useCallback } from 'react';
import { withTranslation } from 'config/i18n';
import { withModal } from 'oc-core-components/src/Modal/ModalProvider';
import Button from 'oc-core-components/src/Button';

import DynamicModal from 'oc-core-components/src/DynamicModal';
import {
  ADD_COURSES_MODAL,
  UPLOAD_COURSE_MODAL,
} from 'oc-core-components/src/Modal/constants';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './UploadCourseModal.module.scss';

const styles = stylesProxy(stylesObj, 'UploadCourseModal');

const UploadCourseFormContainerLoader = () => import(/* webpackChunkName: "UploadCourseFormContainer" */ 'oc-core-components/src/UploadCourseForm');

const UploadCourseModal = ({
  t,
  modal: {
    open: openModal,
  } = {},
  ...restProps
}) => {
  const openAddCoursesModal = useCallback(() => openModal(ADD_COURSES_MODAL), [openModal]);

  return (
    <DynamicModal
      component={UploadCourseFormContainerLoader}
      title={t('section.upload_course.title')}
      name={UPLOAD_COURSE_MODAL}
      size="sm"
      render={UploadCourseFormContainer => (
        <div className={styles.wrapper}>
          <UploadCourseFormContainer
            {...restProps}
          />
          <Button
            type="button"
            className={styles['add-course-btn']}
            color="none"
            size="sm"
            onClick={openAddCoursesModal}
            data-test-id="js-test_tmp_pwd"
          >
            {t('section.upload_course.add_course')}
          </Button>
        </div>
      )}
    />
  );
};

export default withTranslation(['common'])(withModal(UploadCourseModal));
