import React, { createContext, Component, useContext } from 'react';
import { node, func, shape } from 'prop-types';
import {
  isBrowser, dummyFunc, getFacebookApiId, getFacebookPixelId,
} from 'helpers/utils';
import { BASE_URL } from 'common/api';
import { withContext } from 'helpers/hoc';
import fbPixel from 'helpers/fbPixel';

export const FacebookContext = createContext({
  initialize: dummyFunc,
  openShareDialog: dummyFunc,
});

export const FacebookContextPropTypes = shape({
  initialize: func,
  openShareDialog: func,
});

export const withFacebook = withContext(FacebookContext, 'facebookContext');

export const useFacebook = () => useContext(FacebookContext);

export default class FacebookProvider extends Component {
  static propTypes = {
    children: node,
  }

  constructor(props) {
    super(props);
    fbPixel.init(getFacebookPixelId());
  }


  componentDidMount() {
    if (isBrowser) {
      this.initialize();
    }
  }

  /* eslint-disable react/sort-comp */
  initialize = () => {
    if (!this.state.initialized && isBrowser) {
      const appId = getFacebookApiId();

      window.fbAsyncInit = () => {
        window.FB.init({
          appId,
          frictionlessRequests: true,
          status: true,
          cookie: true,
          oauth: true,
          xfbml: true,
          version: 'v3.2',
        });
        window.fbApiInit = true;
        window.FB.getLoginStatus(() => {
          this.setState({ initialized: true });
        });
      };
      // loadFbSDK moved to PageData
      //
      // (function loadFbSDK(d, s, id) {
      //   const fjs = d.getElementsByTagName(s)[0];
      //   if (d.getElementById(id)) { return; }
      //   const js = d.createElement(s); js.id = id;
      //   js.src = 'https://connect.facebook.net/en_US/sdk.js';
      //   fjs.parentNode.insertBefore(js, fjs);
      // }(document, 'script', 'facebook-jssdk'));
    }
  }

  openShareDialog(e, {
    type,
    documentId,
    userId,
  }) {
    e.preventDefault();
    const { initialized } = this.state;
    let url = null;

    if (type === 'fbsharesdocs' && documentId) {
      const timestamp = new Date().getTime();
      url = `${BASE_URL}/fb_objects?object_type=${type}&timestamp=${timestamp}&doc_id=${documentId}`;
      if (userId) url += `&user_id=${userId}`;
    }

    if (initialized && url && isBrowser) {
      window.FB.ui({
        method: 'stream.share',
        u: url,
      });
    }
  }

  state = {
    initialized: false,
    value: {
      initialize: this.initialize,
      openShareDialog: (event, details) => this.openShareDialog(event, details),
    },
  }
  /* eslint-enable react/sort-comp */

  render() {
    const { children } = this.props;
    return (
      <FacebookContext.Provider
        value={this.state.value}
      >
        {children}
      </FacebookContext.Provider>
    );
  }
}
