import {
  getPathFromUrl, isFunction, isNil,
} from 'helpers/utils';
import buildQueryString from 'helpers/utils/buildQueryString';
import getQueryFromUrl from 'helpers/utils/getQueryFromUrl';
import pick from 'lodash/pick';
import deepmerge from 'deepmerge';
import { getSortingOptions } from 'components/forms/FiltersForm/constants';

const reject = (obj, keys) => {
  if (!keys) return obj;

  const keysArr = Array.isArray(keys) ? keys : [keys];

  const vkeys = Object.keys(obj).filter(k => !keysArr.includes(k));

  return pick(obj, vkeys);
};

const filterDefault = (obj, defaultValues) => {
  if (!defaultValues) return obj;

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const defaultValue = defaultValues[key];

    if (!(key in defaultValues) || value !== defaultValue) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});
};

const DEFAULT_PARAMS = {
  page: 1,
  order: 'most-recent',
  upa: 'activity',
  course_id: 'all',
  semester: 'all-semesters',
};

const clearDefaultValues = (q, defaultParams) => filterDefault(q, { ...DEFAULT_PARAMS, ...defaultParams });

// RULES

// reset other query parameters if the current one is changed
const remove = affectedParams => q => reject(q, affectedParams);

// reset other query parameters if the current one is changed and customizer return true
const removeIf = (affectedParams, testFunc) => {
  if (!isFunction(testFunc)) {
    return remove(affectedParams);
  }
  return (q, queryProps, ...rest) => (testFunc(q, queryProps, ...rest) ? reject(q, affectedParams) : q);
};

// add params if the current one is changed
const add = affectedParams => q => ({ ...q, ...affectedParams });

// add params if the current one is changed and if customizer return true
const addIf = (affectedParams, testFunc) => {
  if (!isFunction(testFunc)) {
    return add(affectedParams);
  }
  return (q, queryProps, prevQ) => (testFunc(q, queryProps, prevQ) ? add(affectedParams)(q) : q);
};

// object contains:
// - params whitelist for each page
// - rules how to update query params
const routeParamsMap = {
  directory: {
    params: ['country', 'school', 'start-with', 'page'],
    rules: {
      // Rules also supports array notation
      // E.g.
      // country: [rule1, rule2]
      country: remove(['school', 'page']),
      school: remove('page'),
      // general: [],
    },
  },
  blogDirectory: {
    params: ['country', 'start-with', 'page'],
    rules: {
      country: remove(['school', 'page']),
    },
  },
  blogIndex: {
    params: ['page', 'order'],
  },
  skillsShow: {
    params: [],
  },
  dashboard: {
    params: [
      'page', 'upa', 'course_id', 'kind', 'subject_id',
      'subject', 'status', 'unlocked', 'verified', 'watched', 'course_id',
    ],
    rules: {
      upa: removeIf('upa', (q, props) => (props?.activitiesCount === 0 && q?.upa === 'answer')),
      // remove course_id if upa is not homework-help or if there are no activities and the active tab is uploads
      kind: removeIf('course_id', (q, props, prevQuery) => (q?.kind !== prevQuery?.kind)),
    },
  },
  profile: {
    params: [
      'page', 'upa', 'course_id', 'kind', 'subject_id',
      'subject', 'status', 'unlocked', 'verified', 'watched', 'course_id',
    ],
    rules: {
      upa: removeIf('upa', (q, props) => (props?.activitiesCount === 0 && q?.upa === 'answers')),
      // remove course_id if upa is not homework-help or if there are no activities and the active tab is uploads
      kind: removeIf('subject_id', (q, props, prevQuery) => (q?.kind !== prevQuery?.kind) && q?.upa === 'answers'),
    },
  },
  // TODO: revisit params list (this key is used for HH show page)
  homeworkHelp: {
    params: [
      'page',
      'order',
      'step',
      'status',
      'subject',
      'unlocked',
      'verified',
      'watched',
      'kind',
      'subject_id',
      'answer-form',
    ],
  },
  // TODO: revisit params list
  homeworkHelpIndex: {
    params: [
      'page',
      'order',
      'step',
      'status',
      'subject',
      'unlocked',
      'verified',
      'watched',
      'kind',
      'subject_id',
    ],
  },
  // TODO: revisit params list
  homeworkHelpAnswers: {
    params: [
      'page',
      'order',
      'step',
      'status',
      'subject',
      'unlocked',
      'verified',
      'watched',
      'kind',
      'subject_id',
      'type',
    ],
  },
  // TODO: revisit params list
  homeworkHelpYourQuestions: {
    params: [
      'page',
      'order',
      'step',
      'status',
      'subject',
      'unlocked',
      'verified',
      'watched',
      'kind',
      'subject_id',
    ],
  },
  examPrep: {
    params: ['term', 'year', 'type', 'number'],
  },
  ntdocs: {
    params: ['term'],
  },
  productBrowse: {
    params: ['page', 'order', 'semester', 'search', 'exam', 'lecture', 'chapter', 'professor_id'],
    rules: {
      general: [
        removeIf('order', (q, props) => props?.hasChanged),
      ],
    },
    defaultParams: {
      order: 'most-recent',
    },
  },
  account: {
    params: ['page', 'kind'],
    defaultParams: {
      kind: 'all',
    },
  },
  insights: {
    params: ['page', 'order', 'order_direction', 'kind'],
    defaultParams: {
      kind: 'all',
    },
  },
  textbooks: {
    params: ['page'],
  },
  search: {
    params: [
      'tb_query',
      'query',
      'kind',
      'page',
      'order',
      'country_id',
      'course_id',
      'school_id',
      'semester',
      'lecture_no',
      'exam_type',
      'nt_courses',
      'type',
      'subject',
      'status',
      'userq',
    ],
    rules: {
      kind: [
        removeIf(['course_id', 'school_id', 'tb_query', 'nt_courses', 'userq', 'subject'], (q, _, prevQuery) => {
          const kind = q?.kind;
          // const prevKind = prevQuery?.kind;


          // as we discussed with Max we don't delete these params (so that preserve previous user state for docs tab - no need to filter again)
          // return ['hw', 'blog'].includes(kind);
          return [].includes(kind);
        }),
        // remove('tb_query'),
        removeIf('kind', q => !q?.kind),
        remove('order'),
        removeIf('userq', (q, _, prevQuery) => {
          const kind = q?.kind;
          const prevKind = prevQuery?.kind;

          if (prevKind === 'user' && kind !== prevKind) {
            return true;
          }

          // to avoid /search.en.html?userq=
          return !q.userq;
        }),
        addIf({ type: 'documents' }, q => q?.course_id && q.kind === 'user'),
      ],
      userq: remove('type'),
      type: [
        removeIf('course_id', (q, _, prevQ) => (q.type === 'answers' && prevQ.type === 'documents')),
        removeIf('school_id', (q, _, prevQ) => (q.type === 'answers' && prevQ.type === 'documents')),
        removeIf('subject', (q, _, prevQ) => (q.type === 'documents' && prevQ.type === 'answers')),
      ],
      subject: [
        addIf({ type: 'answers' }, q => !!q?.subject),
        remove('userq'),
      ],

      query: removeIf('query', q => !q?.query),

      lecture_no: removeIf('exam_type', (q, _, prevQuery) => !!prevQuery.exam_type),
      exam_type: removeIf('lecture_no', (q, _, prevQuery) => !!prevQuery.lecture_no),
      // we have to reset nt_courses because there're not much courses with NT docs
      school_id: [
        remove(['query', 'nt_courses', 'userq']),
        addIf({ type: 'documents' }, q => q?.school_id && q.kind === 'user'),
      ],
      course_id: [
        remove(['query', 'nt_courses', 'userq']),
        addIf({ type: 'documents' }, q => q?.course_id && q.kind === 'user'),
      ],
      tb_query: removeIf('tb_query', q => !q?.tb_query),
    },
    defaultParams: {
      order: 'most-recent',
      kind: 'all',
    },
  },
};

// Delete some params from query by some rules
// i.e. if country filter was changed then remove school key from query
// or if selected first page of any collection - delete page key from query (etc.)
const applyQueryRules = ({
  query, queryUpdates, pageId, queryProps, prevQuery,
}) => {
  const routeParams = routeParamsMap[pageId];

  if (routeParams) {
    const { rules } = routeParams;

    let newQuery = query;

    if (rules) {
      newQuery = Object.entries(rules).reduce((acc, [key, keyRules]) => {
        const keyRulesArr = Array.isArray(keyRules) ? keyRules : [keyRules];

        if ((key in queryUpdates) || key === 'general') {
          return (keyRulesArr.reduce((q, rule) => rule(q, queryProps, prevQuery), acc));
        }

        return acc;
      },
      query);
    }

    const defaultParams = isFunction(routeParams.defaultParams) ? routeParams.defaultParams(newQuery, queryProps) : routeParams.defaultParams;


    newQuery = clearDefaultValues(newQuery, defaultParams);

    return newQuery;
  }

  return query;
};

const processOptions = ({
  pageId,
  query,
  options: {
    reset,
  } = {},
}) => {
  let newQuery = {};

  if (reset) {
    newQuery = reject(query, routeParamsMap[pageId]?.params);
  }

  return newQuery;
};

const pushRoute = ({
  pageId,
  url: routeUrl,
  // TODO: remove FallbackRouter
  router: fallbackRouter,
  queryUpdates = {},
  cb,
  options,
  nextOptions,
  beforePushRoute,
  isHumanUser,
  queryProps,
  replace,
  scrollTop,
  routerInstance: RouterInstance,
}) => {
  try {
    if (!RouterInstance) {
      console.error('Router Instance is not defined');

      return;
    }
    const {
      router,
    } = RouterInstance || {};

    const {
      asPath,
      query: routerQuery,
    } = router || fallbackRouter || {};

    // const query = routerQuery;
    const query = getQueryFromUrl(asPath);

    const allowedParams = routeParamsMap[pageId]?.params;


    const currentUrl = routeUrl || asPath;
    const pathWithoutQuery = getPathFromUrl(currentUrl);
    // const urlParams = querystring.parse(currentUrl.split('?')[1]);

    const sanitazedQuery = pick(query, allowedParams);
    const sanitazedQueryUpdates = pick(queryUpdates, allowedParams);

    let newQuery = applyQueryRules({
      query: {
        // ...pick(urlParams, allowedParams),
        ...sanitazedQuery,
        ...sanitazedQueryUpdates,
      },
      prevQuery: sanitazedQuery,
      queryUpdates: sanitazedQueryUpdates,
      pageId,
      queryProps,
    });

    if (options) {
      newQuery = processOptions({ query: newQuery, pageId, options });
    }

    const queryString = buildQueryString(newQuery);
    const url = `${pathWithoutQuery}${queryString}`;

    if (isFunction(beforePushRoute)) {
      beforePushRoute({ pageId, url, newQuery });
    }

    const shallow = options?.shallow;

    // check isHumanUser explicitly

    if (isHumanUser === false) {
      window.location = url;
    } else {
      const handler = replace ? RouterInstance.replaceRoute : RouterInstance.pushRoute;

      if (handler) {
        handler(url, { shallow: isNil(shallow) || shallow }, nextOptions);
      }
    }

    if (scrollTop) {
      window?.scroll(0, 0);
    }

    if (isFunction(cb)) {
      cb({ pageId, url, newQuery });
    }
  } catch (e) {
    console.error(e);
  }
};


export {
  pushRoute,
};
