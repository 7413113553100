import React from 'react';
import Button from 'oc-core-components/src/Button';
import TextInput from 'oc-core-components/src/TextInput';
import cn from 'classnames';
import { func } from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './NavbarSearchButton.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarSearchButton');

const NavbarSearchButton = ({
  onClick,
  className,
}) => {

  return (
    <Button
      onClick={onClick}
      className={cn(styles.container, className)}
    >
      <TextInput
        icon="feather:search"
        iconPosition="first"
        iconClassName="u-color-dark"
        placeholder="Search notes or questions"
        className={styles.btn}
        inputClassName={styles.input}
      />
    </Button>
  );
};

NavbarSearchButton.propTypes = {
  onClick: func,
};

export default NavbarSearchButton;
