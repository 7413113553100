import { handleActions } from 'redux-actions';
import mergeable from 'redux-merge-reducers';

import merge from 'lodash/merge';
import {
  RECEIVE_DOWNLOAD_TOKEN_DATA,
  POST_PURCHASE_DOWNLOAD_TOKEN,
  POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS,
  POST_PURCHASE_DOWNLOAD_TOKEN_ERROR,
  RESET_PURCHASE_ERROR,
} from './constants';

const initialState = {
  data: {},
  purchasing: false,
  purchaseSuccess: false,
  purchaseError: false,
};

const reducer = handleActions(
  {
    [RECEIVE_DOWNLOAD_TOKEN_DATA]: (state, { payload }) => ({
      ...state,
      data: merge({}, state.data, { ...payload }),
    }),
    [POST_PURCHASE_DOWNLOAD_TOKEN]: state => ({
      ...state,
      purchasing: true,
      purchaseSuccess: false,
    }),
    [POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS]: (state, { payload }) => ({
      ...state,
      purchasing: false,
      purchaseSuccess: payload.res,
    }),
    [POST_PURCHASE_DOWNLOAD_TOKEN_ERROR]: (state, { payload }) => ({
      ...state,
      purchasing: false,
      purchaseError: payload.error,
    }),
    [RESET_PURCHASE_ERROR]: state => ({
      ...state,
      purchaseError: false,
    }),
  },
  { ...initialState },
);

export default mergeable(reducer);
