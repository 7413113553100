// Paths
const ALL_MATERIALS = 'all-materials';
const BIOLOGY = 'biology';
const BLOG = 'blog';
const CHEMISTRY = 'chemistry';
const CLASS_NOTES = 'class-notes';
const EXAM_PREP = 'exam-prep';
const HOMEWORK_HELP = 'homework-help';
const HOMEWORK_HELP_PARENTS = 'homework-help-parents';
const HW_PAYMENT_LANDING = 'homework-help-get-started';
const LIVESTREAM = 'livestream';
const MATHEMATICS = 'mathematics';
const MY_COURSES = 'my-courses';
const PEOPLE = 'people';
const PHYSICS = 'physics';
const PROFESSOR_HANDLE = 'professorHandle';
const SCHOOL = 'schools';
const SEARCH = 'search';
const STATISTICS = 'statistics';
const STUDY_GUIDES = 'study-guides';
const TEXTBOOK_NOTES = 'textbook-notes';
const COURSES = 'courses';

// Url Params
const MATERIAL_TYPE = 'materialType';
const DOCUMENT_TYPE = 'documentType';
const DOC_HANDLE = 'docHandle';
const COUNTRY_NAME = 'countryName';
const COUNTRY_CODE = 'countryCode';
const STATE_CODE = 'stateCode';
const SCHOOL_NAME = 'schoolName';
const SCHOOL_CODE = 'schoolCode';
const SCHOOL_HANDLE = 'schoolHandle';
const SCHOOL_TOPIC = 'schoolTopic';
const DEPARTMENT_NAME = 'departmentName';
const DEPARTMENT_CODE = 'departmentCode';
const COURSE_NAME = 'courseName';
const COURSE_CODE = 'courseCode';
const DIRECTORY_FILTER_TYPE = 'directoryFilterType';
const BLOG_DIRECTORY_FILTER_TYPE = 'blogDirectoryFilterType';
const PROFESSOR_NAME = 'professorName';
const DOCUMENT_NAME = 'documentName';
const USER_ID = 'userId';
const HOMEWORK_HELP_SUBJECT = 'topic';
const QUESTION_HANDLE = 'questionHandle';
const LIVESTREAM_HANDLE = 'livestreamHandle';
const SUBJECT = 'subject';
const SKILL = 'skill';

const BLOG_ZONE = 'blogZone';
const BLOG_ARTICLE_ID = 'blogArticleId';
const CATEGORY_HANDLE = 'categoryArticle';

// Query Params
const ORDER = 'order';
const SEMESTER = 'semester';

module.exports = {
  ALL_MATERIALS,
  BIOLOGY,
  BLOG_ARTICLE_ID,
  BLOG_DIRECTORY_FILTER_TYPE,
  BLOG_ZONE,
  BLOG,
  CATEGORY_HANDLE,
  CHEMISTRY,
  CLASS_NOTES,
  COUNTRY_CODE,
  COUNTRY_NAME,
  COURSE_CODE,
  COURSE_NAME,
  DEPARTMENT_CODE,
  DEPARTMENT_NAME,
  DIRECTORY_FILTER_TYPE,
  DOCUMENT_NAME,
  DOCUMENT_TYPE,
  EXAM_PREP,
  HOMEWORK_HELP_PARENTS,
  HOMEWORK_HELP_SUBJECT,
  HW_PAYMENT_LANDING,
  HOMEWORK_HELP,
  LIVESTREAM,
  LIVESTREAM_HANDLE,
  MATERIAL_TYPE,
  MATHEMATICS,
  MY_COURSES,
  ORDER,
  PEOPLE,
  PHYSICS,
  PROFESSOR_NAME,
  PROFESSOR_HANDLE,
  QUESTION_HANDLE,
  SCHOOL_CODE,
  SCHOOL_HANDLE,
  SCHOOL_NAME,
  SCHOOL_TOPIC,
  SCHOOL,
  SEMESTER,
  STATE_CODE,
  STATISTICS,
  STUDY_GUIDES,
  TEXTBOOK_NOTES,
  USER_ID,
  COURSES,
  SUBJECT,
  SKILL,
  SEARCH,
  DOC_HANDLE,
};
