import { createSelector } from 'reselect';
import { startWithValues } from 'components/Directory/StartWithFilter/StartWithFilterContainer/constants';
import { getPathFromUrl, logError } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';
import { createItem, itemToValue } from 'oc-core-components/src/Autocomplete/utils';
import { selectListKeyProp, selectListIdProp } from 'common/selectors';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import { selectV2AvailableQuestionCreditCount } from 'v2/redux/page/homeworkHelp/selectors';

import { MATERIAL_TYPE_COUNT_DEFAULT_TYPES } from './constants';
import { getMenuItems, getToolbarMenuItems, getUserDropdown } from './utils';

import {
  selectCourseIdProp,
} from '../selectors';


const getSub = state => state.common.page;
const selectMeta = state => getSub(state).meta;
const selectIsLoggedIn = state => selectMeta(state).isLoggedIn;
const selectBaseProductUrlArr = state => selectMeta(state).baseProductUrlArr;
const selectSearchQuery = state => selectMeta(state)?.query;
const selectLoadingDataBySection = state => getSub(state).loadingDataBySection;
const selectIsGoogleBot = state => selectMeta(state).googleBotView || selectMeta(state).itIsFp;


// TODO: merge selectUserAttrs and selectUserAttrs

const selectTopLists = state => selectMeta(state).topLists;
const selectLoadingPageData = state => getSub(state).loadingPageData || false;
const selectLoadingPageDataError = state => getSub(state).loadingPageDataError;

// CURRENT USER SELECTORS
// TODO: move to the common/user/selectors ???

const selectUserAttrs = state => selectMeta(state).currentUser;
const selectCurrentUserSchoolId = state => selectUserAttrs(state).schoolId;


// TODO: Deprecated ???
const selectPageProps = state => getSub(state).pageProps.props;


const selectTranslationProp = (_state, { t }) => t;

const selectNavbarMenuItems = createSelector(
  [selectBaseProductUrlArr, selectIsLoggedIn, selectUserAttrs, selectTranslationProp],
  (baseProductUrlArr, isLoggedIn, currentUser, t) => getMenuItems({
    baseProductUrlArr, isLoggedIn, ...currentUser, user: currentUser, t,
  }),
);

const selectToolbarMenuItems = createSelector(
  [
    selectBaseProductUrlArr,
    selectIsLoggedIn,
    selectUserAttrs,
    getV2CurrentPage,
    selectV2AvailableQuestionCreditCount,
    selectTranslationProp,
  ],
  (baseProductUrlArr, isLoggedIn, currentUser, currentPage, questionCredits, t) => getToolbarMenuItems({
    baseProductUrlArr, isLoggedIn, ...currentUser, user: currentUser, currentPage, questionCredits, t,
  }),
);

const selectUserDropdown = createSelector(
  [selectBaseProductUrlArr, selectIsLoggedIn, selectUserAttrs, selectTranslationProp],
  (baseProductUrlArr, isLoggedIn, currentUser, t) => ((isLoggedIn && currentUser) ? getUserDropdown({
    baseProductUrlArr, isLoggedIn, user: currentUser, t,
  }) : null),
);


const selectV2AccessToken = state => getSub(state)?.meta?.V2AccessToken;

const selectHomePageProps = (state) => {
  const homePage = getSub(state).home;
  return homePage ? homePage.props : null;
};

const selectProductBrowsePageProps = (state) => {
  const productBrowsePage = getSub(state).productBrowse;
  return productBrowsePage ? productBrowsePage.props : null;
};

const selectMyCoursesPageProps = (state) => {
  const { myCourses } = getSub(state);
  return myCourses ? myCourses.props : null;
};

const selectLoadingDataBySectionKey = createSelector(
  [selectLoadingDataBySection, selectListKeyProp],
  (loadingDataBySection, listKey) => listKey && loadingDataBySection.includes(listKey),
);

const selectProductBrowsePageFilters = createSelector(selectProductBrowsePageProps, pageProps => pageProps?.pageFilters);
const selectProductBrowsePageLists = createSelector(selectProductBrowsePageProps, pageProps => pageProps?.pageLists);
const selectProductBrowseMaterials = createSelector(selectProductBrowsePageProps, pageProps => pageProps?.materials);
const selectProductBrowseNoResults = createSelector(selectProductBrowsePageProps, pageProps => pageProps?.noResults);

const selectMaterialTypeCounts = createSelector(selectProductBrowsePageFilters, pageFilters => pageFilters?.materialTypeCounts?.options);
const selectMaterialTypeCountsType = createSelector(selectProductBrowsePageFilters, pageFilters => pageFilters?.materialTypeCounts?.type);

const selectHomePageLists = createSelector(selectHomePageProps, pageProps => pageProps?.pageLists);
const selectHomePageMaterials = createSelector(selectHomePageProps, pageProps => pageProps?.materials);

const selectMyCoursesPageEnrollments = createSelector(selectMyCoursesPageProps, pageProps => pageProps?.enrollments);

const selectTopSchoolsListIds = createSelector(
  selectHomePageLists,
  (pageLists) => {
    const topSchools = pageLists && pageLists.find(list => list?.uid && list.uid === 'topSchools');
    return topSchools?.data?.ids || [];
  },
);

const selectMaterialTypeCountDefault = createSelector(
  [selectMaterialTypeCountsType],
  (type) => {
    const labelsByKey = {
      lecture: MATERIAL_TYPE_COUNT_DEFAULT_TYPES.LECTURES,
      chapter: MATERIAL_TYPE_COUNT_DEFAULT_TYPES.CHAPTERS,
      exam: MATERIAL_TYPE_COUNT_DEFAULT_TYPES.EXAMS,
    };

    const label = labelsByKey[type];

    return label ? createItem(label, null, { type }) : null;
  },
);


/*
  Check if we have a default value with the correct name,
  then make sure we have at least one other non-default option
  otherwise return an empty array
  There will only ever be one type of material type count populated at a time
*/
const selectMaterialTypeCountOptions = createSelector(
  [selectMaterialTypeCountDefault, selectMaterialTypeCountsType, selectMaterialTypeCounts],
  (materialTypeCountDefault, type, materialTypeCounts) => {
    let results = materialTypeCountDefault ? [materialTypeCountDefault] : [];
    if (materialTypeCountDefault) {
      // Filter material type counts array to non-default values
      results = results.concat(materialTypeCounts.map(({ label, value }) => createItem(label, value, { type })));
    }

    return results;
  },
);

const selectMaterialTypeCountSelection = createSelector(
  [selectProductBrowsePageFilters, selectMaterialTypeCountOptions, selectMaterialTypeCountsType],
  (pageFilters, materialTypeCounts, materialTypeCountsType) => {
    let typeCountSelection;
    if (pageFilters) {
      const { lecture, chapter, exam } = pageFilters;
      typeCountSelection = lecture || chapter || exam;
    }

    // These could be random values in the url,
    // So make sure it matches a valid option before its selected
    const selectedOption = materialTypeCounts.find(item => itemToValue(item)?.toString() === typeCountSelection?.toString());

    return (typeCountSelection && selectedOption && selectedOption?.type === materialTypeCountsType) ? selectedOption : null;
  },
);

const selectDocumentShowRelationships = (state) => {
  const docShowPage = getSub(state).documentShow;
  return docShowPage ? docShowPage.relationships : null;
};

const selectLoadingQueryResultsPageList = state => getSub(state).loadingPageList?.queryResults || false;

// directory selectors
const selectDirectoryPageProps = (page, state) => {
  const directoryPage = getSub(state)[page];
  return directoryPage ? directoryPage.props : null;
};

const selectDirectoryAvailableChars = (ignored, { availableChars }) => availableChars;
const selectRouter = (ignored, { router: { query, asPath } }) => ({ query, asPath });

const selectDirectoryStartWithFilterItems = createSelector(
  selectDirectoryAvailableChars,
  selectRouter,
  (availableChars, { asPath, query }) => {
    const listItems = [];

    const path = getPathFromUrl(asPath);
    const { country, school, page } = { ...query };
    const queryObject = {};
    if (country) queryObject.country = country;
    if (school) queryObject.school = school;
    if (page && page !== 1) queryObject.page = page;

    startWithValues.forEach((value) => {
      let isDisabled = true;
      const upperCaseValue = value.toUpperCase();
      // determine if this value is disabled
      if (availableChars.map(c => c.toUpperCase()).includes(upperCaseValue)) {
        isDisabled = false;
      }

      const tempQuery = { 'start-with': value, ...queryObject };

      listItems.push({
        value,
        isDisabled,
        title: upperCaseValue,
        url: buildUrl(path, tempQuery),
      });
    });

    return listItems;
  },
);

// /////////////////
export const selectCurrentPage = state => getSub(state).currentPage;

export const selectPagePropsV2 = (state, props) => {
  const pageId = selectCurrentPage(state, props);

  if (!pageId) {
    logError('pageId is not defined');
    return undefined;
  }
  const pageData = getSub(state)[pageId];

  return pageData?.props;
};


// TODO: remove createSelectors
export const selectPageLists = createSelector(selectPagePropsV2, pageProps => pageProps?.pageLists);
export const selectProductSilos = createSelector(selectPagePropsV2, pageProps => pageProps?.materials);
export const selectEnrollments = createSelector(selectPagePropsV2, pageProps => pageProps?.enrollments);
export const selectEnrollmentsMap = createSelector(selectPagePropsV2, (pageProps) => {
  const enrollments = pageProps?.enrollments;

  if (!enrollments) return undefined;

  return enrollments.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
});
export const selectEnrollmentsCount = createSelector(selectPagePropsV2, pageProps => pageProps?.enrollmentsCount);
export const selectSubscriptionExtension = createSelector(selectPagePropsV2, pageProps => pageProps?.subscriptionExtension);
export const selectSubscriptionUpgrade = createSelector(selectPagePropsV2, pageProps => pageProps?.subscriptionUpgrade);
export const selectClassmatesCourses = createSelector(selectPagePropsV2, pageProps => pageProps?.classmatesCourses);
export const selectPageFilters = createSelector(selectPagePropsV2, pageProps => pageProps?.pageFilters);
export const selectExamTabs = createSelector(selectPagePropsV2, pageProps => pageProps?.examTabs);
export const selectCurrentExamTab = createSelector(selectPagePropsV2, pageProps => pageProps?.currentTab);
export const selectPaymentPlans = createSelector(selectPagePropsV2, pageProps => pageProps?.paymentPlans);


// DEPRECATED
export const selectListById = createSelector(
  [selectPageLists, selectListIdProp],
  (pageLists, listId) => pageLists?.find(list => list?.uid === listId),
);

export const selectEnrollmentById = createSelector(
  [selectEnrollments, selectCourseIdProp],
  (enrollments, id) => {
    // TODO: move parseInt to coursePropId
    const numericCourseId = parseInt(id, 10);
    if (enrollments && !Number.isNaN(numericCourseId)) {
      return enrollments.find(({ courseId }) => parseInt(courseId, 10) === numericCourseId);
    }
  },
);

export const selectClassmatesCourseById = createSelector(
  [selectClassmatesCourses, selectCourseIdProp],
  (classmatesCourses, id) => {
    // TODO: move parseInt to coursePropId
    const numericCourseId = parseInt(id, 10);
    if (classmatesCourses && !Number.isNaN(numericCourseId)) {
      return classmatesCourses.find(({ courseId }) => parseInt(courseId, 10) === numericCourseId);
    }
  },
);

export const selectProgramOptions = createSelector(
  selectPagePropsV2,
  (pageProps) => {
    const programs = pageProps?.programs;

    if (programs) {
      return programs.map(({ id, name, ...rest }) => createItem(name, id, rest));
    }

    return null;
  },
);

export const selectSectionsToLoad = state => getSub(state).pageSectionsToLoad;
export const selectSectionsAreLoading = state => getSub(state).loadingPageSections;

export {
  selectMeta,
  selectPageProps,
  selectV2AccessToken,
  selectNavbarMenuItems,
  selectUserAttrs,
  selectTopLists,
  selectLoadingPageData,
  selectLoadingPageDataError,
  selectHomePageLists,
  selectHomePageMaterials,
  selectProductBrowsePageProps,
  selectProductBrowsePageFilters,
  selectProductBrowsePageLists,
  selectProductBrowseMaterials,
  selectProductBrowseNoResults,
  selectMyCoursesPageEnrollments,
  selectDocumentShowRelationships,
  selectDirectoryPageProps,
  selectDirectoryStartWithFilterItems,
  selectLoadingQueryResultsPageList,
  selectMaterialTypeCountSelection,
  selectMaterialTypeCountOptions,
  selectMaterialTypeCountsType,
  selectLoadingDataBySectionKey,
  selectTopSchoolsListIds,
  selectToolbarMenuItems,
  selectSearchQuery,
  selectBaseProductUrlArr,
  selectUserDropdown,
  selectIsLoggedIn,
  selectCurrentUserSchoolId,
  selectIsGoogleBot,
};
