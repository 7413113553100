import handleActions from 'v2/redux/utils/handleActions';
import { combineActions } from 'redux-actions';
import { logErrorDev } from 'helpers/utils';

import types from './types';

const initialState = {};

const loadingReducer = handleActions(
  {
    [combineActions(
      types.LOADING.START,
      types.LOADING.STOP,
      types.LOADING.RESET,
    )]: (state, action) => {
      const { type, payload, meta } = action;
      const id = meta?.id;

      if (id && typeof id === 'string') {
        if (type === types.LOADING.RESET) {
          delete state[id];
        } else {
          state[id] = payload;
        }
      } else {
        logErrorDev('Incorrect loading id for action', action);
      }
    },
  },
  initialState,
);

export { initialState };

export default loadingReducer;
