import { handleActions } from 'redux-actions';
import mergeable from 'redux-merge-reducers';

import merge from 'lodash/merge';
import {
  RECEIVE_DEPARTMENT_DATA,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_ERROR,
  GET_DEPARTMENT,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,
} from './constants';

const initialState = {
  data: {},
  loadingDepartments: false,
  loadingDepartment: false,
};

const reducer = handleActions(
  {
    [RECEIVE_DEPARTMENT_DATA]: (state, { payload }) => ({
      ...state,
      data: merge({}, state.data, { ...payload }),
    }),
    [GET_DEPARTMENTS]: state => ({
      ...state,
      loadingDepartments: true,
    }),
    [GET_DEPARTMENTS_SUCCESS]: state => ({
      ...state,
      loadingDepartments: false,
    }),
    [GET_DEPARTMENTS_ERROR]: state => ({
      ...state,
      loadingDepartments: false,
    }),
    [GET_DEPARTMENT]: state => ({
      ...state,
      loadingDepartment: true,
    }),
    [GET_DEPARTMENT_SUCCESS]: state => ({
      ...state,
      loadingDepartment: false,
    }),
    [GET_DEPARTMENT_ERROR]: state => ({
      ...state,
      loadingDepartment: false,
    }),
  },
  { ...initialState },
);

export default mergeable(reducer);
