import React, { Fragment } from 'react';
import { oneOfType, number, string } from 'prop-types';
import { IMAGES_PATH } from 'helpers/utils';
import {
  title,
  notFoundTitle,
  description,
  notFoundDescription,
} from 'components/Error/constants';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Error.module.scss';

const styles = stylesProxy(stylesObj, 'Error');

const getLayoutByStatusCode = (statusCode) => {
  if (statusCode === 404) {
    return (
      <Fragment>
        <img src={`${IMAGES_PATH}/404_error.svg`} alt={notFoundTitle} />
        <p>{notFoundDescription}</p>
      </Fragment>
    );
  }

  if (statusCode === 410) {
    return (
      <Fragment>
        <img src={`${IMAGES_PATH}/generic_error.svg`} alt="Generic error" />
        <p>The page you are looking for doesn't exist.</p>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <img src={`${IMAGES_PATH}/generic_error.svg`} alt={title} className="u-mr-xl" />
      <p>{description}</p>
    </Fragment>
  );
};

const Error = ({ statusCode, url }) => (
  <Fragment>
    <div className={styles.container}>
      {getLayoutByStatusCode(statusCode)}
      <p>
        {'Back to '}
        <a href={`${url}/`} title="Home">home</a>
        {' or '}
        <a href={`${url}/about.en.html#contact`} title="tell us about it">tell us</a>
        {' about it!'}
      </p>
    </div>
    <div className={styles.push} />
  </Fragment>
);

Error.propTypes = {
  statusCode: oneOfType([string, number]),
  url: string,
};

Error.defaultProps = {
  url: '',
};

export default Error;
