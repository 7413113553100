import Downshift from 'downshift';
import { stylesProxy } from 'helpers/css';
import Icon from 'oc-core-components/src/Icon';
import React, { useEffect, useState } from 'react';
import stylesObj from './NavbarSearchOverlay.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarSearchOverlay');

const NavbarSearchOverlayInput = ({
  initialValue,
  onKeyDown,
  onStateChange,
  placeholder,
  value,
}) => {
  const [internalValue, setInternalValue] = useState(initialValue || '');

  useEffect(() => {
    setInternalValue(initialValue || '');
  }, [initialValue]);

  const handleCancel = (autocompleteProps) => {
    autocompleteProps.clearSelection();
  };

  const handleChange = (e) => {
    setInternalValue(value || e.target.value);
  };

  const handleStateChange = (changes, downshiftState) => {
    if (
      changes.isOpen === false ||
      changes.type === '__autocomplete_blur_input__'
    ) {
      return;
    }

    if (changes.hasOwnProperty('inputValue')) {
      setInternalValue(changes.inputValue);
    }

    onStateChange(changes, downshiftState);
  };

  return (
    <Downshift inputValue={value} onStateChange={handleStateChange}>
      {(autocompleteProps) => {
        const inputProps = {
          autoFocus: true,
          className: styles.input,
          onChange: handleChange,
          onKeyDown,
          placeholder,
          value: internalValue,
        };

        return (
          <div className={styles['input-container']}>
            <input {...autocompleteProps.getInputProps(inputProps)} />

            <Icon
              className={styles['icon-search']}
              icon="feather:search"
              onClick={() => {}}
              size={18}
              tag="button"
            />

            {internalValue && (
              <Icon
                className={styles['icon-cancel']}
                icon="feather:x-circle"
                onClick={() => handleCancel(autocompleteProps)}
                size={15}
                tag="button"
              />
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

export default NavbarSearchOverlayInput;
