import React from 'react';
import DefaultNavbarBanner from 'oc-core-components/src/DefaultNavbarBanner';
import { connect } from 'react-redux';
import { getV2TopBanner } from 'v2/redux/meta/selectors';
import { shape } from 'prop-types';

const DefaultNavbarBannerContainer = ({
  className,
  dispatch,
  banner,
  ...restProps
}) => (banner ? (
  <DefaultNavbarBanner
    {...banner}
    {...restProps}
  />
) : null);


const mapStateToProps = state => ({
  banner: getV2TopBanner(state),
});

DefaultNavbarBannerContainer.propTypes = {
  banner: shape(),
};

export default connect(
  mapStateToProps,
  null,
)(DefaultNavbarBannerContainer);
