import {
  takeEvery, put, fork, call, takeLatest, select,
} from 'redux-saga/effects';
import { keysToSnakeCase } from 'helpers/utils';
import { updateV2BcTheoryAnnotation } from 'v2/redux/page/boosterClassTheory/actions'
import types from 'v2/redux/page/types';
import { selectV2BcAnnotations } from 'v2/redux/boosterClassTheory/selectors'
import { goToAskQuestionByAnnId } from 'components/pages/HomeworkHelp/BcTheory/TextHighlighter/utils';

import {
  postUpdateBcAnnotaion,
  postDeleteBcAnnotaion,
} from 'common/api';

const getAnnotationType = (ann) => {
  if(!ann) return;

  if(ann.askQuestion) {
    return 2;
  }

  if(ann.note) {
    return 0;
  }

  return 1;
}

function* postNewAnnotationData({ payload, meta }) {
  const {
    id: fakeId,
    draft,
    askQuestion,
    ...restPayload
  } = payload;

  if(draft) return;

  const props = keysToSnakeCase({
    annotation_type: getAnnotationType(payload),
    ...restPayload,
    ...meta
  });


  const { data } = yield call(postUpdateBcAnnotaion, props);

  if(data.success) {
    // update annotation id in store without sending new data to server
    yield put(updateV2BcTheoryAnnotation({ uid: fakeId, id: data.id }, { fetch: false }))

    if(askQuestion) {

      goToAskQuestionByAnnId(data.id);
    }
  } else {
    console.error('failed uploading annotation')
    // TODO: handling error
  }
}

function* postUpdateAnnotationData({ payload, meta }) {
  const {
    fetch,
    ...restMeta
  } = meta;

  const {
    draft,
    id,
    uid,
    highlight,
    ...restPayload
  } = payload;


  if(fetch !== false) {
    let props = {
      annotation_type: getAnnotationType(payload),
      ...restPayload,
      ...restMeta
    };

    // Do not send fake ids to server
    if(!draft) {
      props.id = id;
    } else {
      props.highlight = highlight;
    }

    props = keysToSnakeCase(props);

    const { data } = yield call(postUpdateBcAnnotaion, props);

    if(data.success) {
      if(draft) {
        yield put(updateV2BcTheoryAnnotation({ uid: payload.id, id: data.id }, { fetch: false }))
      }
    } else {
      console.error('failed updating annotation')
      // TODO: handling error
    }
  }

}

function* removeAnnotationData({ payload } = {}) {
  const {
    id,
    draft,
    content_only,
  } = payload;

  if(draft) return;

  if(id) {
    const { data } = yield call(postDeleteBcAnnotaion, {
      id,
      content_only,
    });

    if(!data.success) {
      console.log('failed removing annotation');
    }
  } else {
    console.log('failed removing annotation; id is not defined');
  }
}


function* boosterClassSaga() {
  yield takeEvery(types.STORE_BC_ANNOTATION, postNewAnnotationData);
  yield takeEvery(types.UPDATE_BC_ANNOTATION, postUpdateAnnotationData);
  yield takeEvery(types.REMOVE_BC_ANNOTATION, removeAnnotationData);
}

export default boosterClassSaga;
