import { takeEvery, put } from 'redux-saga/effects';
import { receiveSchoolData } from 'common/school/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { getDataByType } from 'common/utils';
import { preprocess } from './utils';

import { GET_SCHOOL_SUCCESS, GET_SCHOOLS_SUCCESS } from './constants';

function* storeSchoolData({ res }) {
  const paths = [
    'statsCounters',
    'academics',
    'blogArticles',
    'campus',
    'overview',
    'studentServices',
    'athletics',
    'studyResources',
    'reviews',
    'studentServices',

    // COLLEGE DATA paths
    'freshmanAdmissionRequirements',
    'applyingForAdmission',
    'selectionOfStudents',
    'profileOfFallAdmission',
    'tuitionAndExpenses',
    'applyingForFinancialAid',
    'profileOfFinancialAid',
    'financialAidPrograms',
    'academicsOverview',
    'undergraduateEducation',
    'cirriculumAndGraduationRequirements',
    'facultyAndInstruction',
    'advancedPlacement',
    'academicResources',
    'academicSupportServices',
    'graduateProfessionalSchoolEducation',
    'locationAndSetting',
    'housing',
    'security',
    'personalSupportServices',
    'sportsAndRecreation',
    'studentActivities',
    'studentBody',
    'undergraduateRetentionAndGraduation',
  ];

  const schools = getDataByType('school', res, {
    withUniqueIds: true,
    paths,
    preprocess,
  });

  if (schools) { yield put(receiveSchoolData(schools)); }
}

function* schoolSaga() {
  yield takeEvery([RECEIVE_PAGE_DATA, GET_SCHOOL_SUCCESS, GET_SCHOOLS_SUCCESS], storeSchoolData);
}

export default schoolSaga;
