import React, { useState, useEffect } from 'react';
import { stylesProxy } from 'helpers/css';
import { getImage } from 'helpers/utils';
import { useTranslation } from 'config/i18n';
import { getStudentsOnline } from 'common/api';
import stylesObj from './StudentsOnlineContainer.module.scss';

const styles = stylesProxy(stylesObj, 'StudentsOnlineContainer');
const StudentsOnlineContainer = ({

}) => {
  const [users, setUsers] = useState([]);
  const [studentsOnline, setStudentsOnline] = useState(null);

  useEffect(() => {
    getStudentsOnline().then((response) => {
      if (response.resp.ok) {
        setUsers(response.data.users);
        setStudentsOnline(response.data.totalOnline);
      }
    });
  }, []);
  
  const { t } = useTranslation('home');

  return (
    studentsOnline && (users?.length > 0)
      ? (
        <div className={styles.wrapper}>
          <div className={styles.photos}>
            {users.map((item, i) => (
              <div key={i} className={styles.frame}>
                <img className={styles.photo} src={item.avatar} alt="student" />
              </div>
            ))}
          </div>
          <div className={styles.indicator} />
          <div className={styles.text}><b>{studentsOnline}</b> {t('students_online')}</div>
        </div>
      )
      : null
  );
};

export default StudentsOnlineContainer;
