import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2NotificationMsg } from 'v2/redux/meta/selectors';
import { useNotifications } from 'oc-core-components/src/NotificationProvider';

// used to show some flash notifications from backend on page load
const usePreloadNotificationMsg = ({ enabled }) => {
  const notificationMsg = useSelector(getV2NotificationMsg);

  const {
    addSuccessNotification,
    addErrorNotification,
  } = useNotifications();

  useEffect(() => {
    if (!enabled) return;
    if (!notificationMsg) return;

    const {
      success,
      msg,
    } = notificationMsg;

    if (success) {
      addSuccessNotification(msg);
    } else {
      addErrorNotification(msg);
    }
  }, [enabled, notificationMsg, addSuccessNotification, addErrorNotification]);
};

export default usePreloadNotificationMsg;
