import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { ROUTES, COUNTRY_SUFFIXES } from 'shared/helpers/constants';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import { useSelector } from 'react-redux';
import { getV2ProdBrowseCountryCode } from 'v2/redux/meta/selectors';

import urljoin from 'url-join';


import HomeMaterialCard from './HomeMaterialCard';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './HomeMaterialsSections.module.scss';

const styles = stylesProxy(stylesObj, 'HomeMaterialsSections');

const getUrl = (url, handle) => {
  const suffix = COUNTRY_SUFFIXES[handle];

  if (!suffix) {
    return url;
  }

  return urljoin(url, suffix);
};

const HomeMaterialsSections = ({
  className,
}) => {
  const { t } = useTranslation('home');
  const i18nData = t('material_cards', { returnObjects: true });
  const countryHandle = useSelector(getV2ProdBrowseCountryCode);

  const items = {
    sg: {
      viewMoreLink: ROUTES.studyGuides,
      kind: 'study-guides',
      title: i18nData.sg.title,
      description: i18nData.sg.description,
      color: 'green',
    },
    tn: {
      viewMoreLink: ROUTES.textbookNotes,
      kind: 'textbook-notes',
      title: i18nData.tn.title,
      description: i18nData.tn.description,
      color: 'yellow',
    },
    cn: {
      viewMoreLink: ROUTES.classNotes,
      kind: 'class-notes',
      title: i18nData.cn.title,
      description: i18nData.cn.description,
      color: 'blue',
    },
  };


  return (
    <div className={cn(styles.container, className)}>
      <Row className={styles.row}>
        <Col xs="12" lg="5" className={styles.col}>
          <HomeMaterialCard
            vertical
            {...items.sg}
          />
        </Col>

        <Col xs="12" lg="7" className={styles.col}>
          <HomeMaterialCard
            {...items.cn}
          />

          <HomeMaterialCard
            {...items.tn}
          />
        </Col>
      </Row>
    </div>
  );
};

HomeMaterialsSections.propTypes = {

};

export default HomeMaterialsSections;
