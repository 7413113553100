import { getStudyModePageAttrs } from 'shared/helpers/study_mode';
import { camelizeKeys } from 'shared/helpers/utils';
import getData from 'shared/v2/redux/utils/getData';
import mapValues from 'lodash/mapValues';
import camelCase from 'lodash/camelCase';

import { uniqueIdsPaths as documentUniqueIdsPaths } from 'shared/common/document/utils';
import { uniqueIdsPaths as schoolUniqueIdsPaths, preprocess as schoolPreprocess } from 'shared/common/school/utils';
import { uniqueIdsPaths as liveTutorialSessionUniqueIdsPaths } from 'shared/v2/redux/liveTutorialSession/utils';
import { uniqueIdsPaths as boosterClassSkillUniqueIdsPaths } from 'shared/v2/redux/boosterClassSkill/utils';


export const getPageIdFromContext = (ctx) => {
  let path = ctx.router.route;

  if (path === '/') {
    path = 'home';
  }

  return camelCase(path.replace('/', ''));
};

export const getDataOpts = {
  mergingStrategy: 'deepMerge',
  withUniqueIds: {
    school: {
      paths: schoolUniqueIdsPaths,
      preprocess: schoolPreprocess,
    },
    document: {
      paths: documentUniqueIdsPaths,
    },
    liveTutorialSession: {
      paths: liveTutorialSessionUniqueIdsPaths,
    },
    boosterClassSkill: {
      paths: boosterClassSkillUniqueIdsPaths,
    },
  },
};

export const getAdditionalPageAttributes = ({ serverCookies }) => {
  const studyModePageAttrs = getStudyModePageAttrs(serverCookies);

  return ({
    ...studyModePageAttrs,
  });
};

export const getReduxStateFromInitProps = (initProps, pageId, pagePath) => {
  const {
    pageProps: {
      data,
      meta,
      pageProps,
    },
  } = initProps;

  const { serverCookies } = initProps;
  const additionalPageAttributes = getAdditionalPageAttributes({ serverCookies });

  return {
    common: {
      ...mapValues(getData(data, getDataOpts), v => ({ data: v })),
      page: {
        ...additionalPageAttributes,
        meta,
        currentPage: pageId,
        initialPagePath: pagePath,
        [pageId]: {
          props: camelizeKeys(pageProps),
        },
      },
    },
  };
};

export default {};
