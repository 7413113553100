
import createAction from 'v2/redux/utils/createAction';
import { createLoadingActions } from 'v2/redux/loading/actions';

import types from './types';


export const storeV2Notifications = createAction(types.STORE_NOTIFICATIONS);
export const setV2NotificationsLoaded = createAction(types.UPDATE_ALL_LOADED);
export const fetchV2Notifications = createAction(types.FETCH_NOTIFICATIONS.SELF);

export const loadingNotificationsRequest = createLoadingActions('notificationRequest');
