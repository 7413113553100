import React from 'react';
import { connect } from 'react-redux';
import Link from 'oc-core-components/src/Link';
import { string, bool } from 'prop-types';
import cn from 'classnames';
import { selectV2StreamActive } from 'v2/redux/meta/selectors';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './LivestreamLink.module.scss';

const styles = stylesProxy(stylesObj, 'LivestreamLink');

const LivestreamLink = ({
  url,
  label,
  streamActive,
  className,
}) => {
  return (
    <>
      <Link href={url} className={cn(styles.link, className)}>
        <div
          className={
            cn(styles['dot-wrapper'], {
              [styles['dot-wrapper--active']]: streamActive,
            })
          }
        >
          <div className={styles.dot} />
        </div>
        {label}
      </Link>
    </>
  );
};

const mapStateToProps = state => ({
  streamActive: selectV2StreamActive(state),
});

LivestreamLink.propTypes = {
  url: string,
  label: string,
  streamActive: bool,
};

export default connect(mapStateToProps)(LivestreamLink);
