// https://marmelab.com/blog/2016/10/18/using-redux-saga-to-deduplicate-and-group-actions.html
import {
  takeLatest, put, call, takeEvery, select,
} from 'redux-saga/effects';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import { getAPIData } from 'common/api';
import { catchError } from 'v2/redux/actions';
import { getApiPathFromUrl, isApiUrl } from 'helpers/utils';
import types from 'v2/redux/types';
import { showLoading, hideLoading } from 'react-redux-loading-bar';


function* fetchAPI({ payload = {}, meta = {} }) {
  const {
    params,
  } = payload;

  const url = payload.url || (window.location.pathname + window.location.search);

  const {
    actions: {
      callback: callbackAction,
      loading: loadingAction,
    } = {},
    progressBar,
    ...restMeta
  } = meta;

  try {
    const currentPage = yield select(getV2CurrentPage);

    if (callbackAction) {
      if (loadingAction?.start) {
        yield put(loadingAction.start());
      }

      if (progressBar && currentPage) {
        yield put(hideLoading(currentPage));
        yield put(showLoading(currentPage));
      }

      const { data } = yield call(getAPIData, {
        url: isApiUrl(url) ? url : getApiPathFromUrl(url),
        params,
      });


      yield put(callbackAction({
        ...restMeta,
        params: payload,
        data,
        stopLoading: loadingAction?.stop,
        ...(
          (progressBar && currentPage) ? {
            stopGlobalLoading: () => hideLoading(currentPage),
          } : undefined
        ),
      }));
    }
  } catch (e) {
    const stopLoading = loadingAction?.stop;

    if (stopLoading) {
      yield put(stopLoading(e));
    }

    yield put(catchError(e));
  }
}

function* fetchApiSaga() {
  yield takeEvery(types.FETCH_API_EVERY.SELF, fetchAPI);
  // automatically cancel all previous unfinished requests
  yield takeLatest(types.FETCH_API_LATEST.SELF, fetchAPI);
}

export default fetchApiSaga;
