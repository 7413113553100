// TODO: Modify oc-core-components/src/Navbar/NavbarSearchContainer

import { getSearchSuggestions } from 'common/api';
import { selectSearchQuery } from 'common/page/selectors';
import Downshift from 'downshift';
import { cookiesKeys, ROUTES } from 'helpers/constants';
import { toPwaUrl } from 'helpers/pwa';
import { trackSearchComplete } from 'helpers/tracking';
import { cookies, isFunction, keyCodes } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';
import debounce from 'lodash/debounce';
import { bool, func, string, shape } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavbarSearchTypes, NavbarSearchApiTypes } from '../constants';
import { processElasticSearch } from './utils';

const DEBOUNCE_TIME = 500;

const NavbarSearchContainer = ({
  allowBlur,
  children,
  onChange,
  onSubmit,
  trackId,
  type,
  searchUrlParams,
}) => {
  const query = useSelector(selectSearchQuery);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(query || '');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);

  const suggestionsHistory = useMemo(() => {
    const histories = cookies.get(cookiesKeys.SEARCH_HISTORIES) || [];

    return histories.map((history, index) => ({
      value: history,
      data: {
        category: NavbarSearchTypes.history,
        obj_index: index,
        url: buildUrl(ROUTES.search, { query: history }),
      },
    }));
  }, [cookies]);

  const handleSubmit = () => {
    let newLocation = null;
    const value = selectedItem?.value || inputValue;

    if (isFunction(onSubmit)) {
      newLocation = onSubmit({ selectedItem, value, inputValue });

      // disable default location behaviour
      if (newLocation === false) return;
    } else {
      const lastGroup = suggestions.slice(-1)[0]?.options;
      const lastSuggestion = lastGroup?.length > 0 ? lastGroup[0] : {};
      const data = selectedItem?.data || lastSuggestion?.data;

      newLocation =
        data?.url || buildUrl(ROUTES.search, { ...searchUrlParams, query: value });
    }

    newLocation = toPwaUrl({ url: newLocation });
    if (newLocation) {
      window.location.assign(newLocation);
      trackSearchComplete(value);
    } else {
      console.error('Search error: ', value);
    }
  };

  useEffect(() => {
    setInputValue(query || '');
  }, [query]);

  useEffect(() => {
    if (selectedItem && selectedType === Downshift.stateChangeTypes.clickItem) {
      handleSubmit();
    }
  }, [selectedItem, selectedType]);

  const inputChangeHandler = useCallback(
    debounce((val) => {
      if (!val) return;

      onChange(val, { trackId });
      setLoading(true);

      const apiEntity = NavbarSearchApiTypes[type];

      getSearchSuggestions(val, apiEntity).then((data) => {
        const suggestions = processElasticSearch(val, data, type);

        setLoading(false);
        setSuggestions(suggestions);
      });
    }, DEBOUNCE_TIME),
    [onChange, trackId]
  );

  const selectHandler = (item, type) => {
    setSelectedItem(item);
    setSelectedType(type);
    setSuggestions([]);
  };

  const handleStateChange = (changes) => {
    if (changes.selectedItem) {
      selectHandler(changes.selectedItem, changes.type);
      return;
    }

    if (allowBlur && changes.isOpen === false) {
      return;
    }

    if (changes.inputValue != null) {
      if (!changes.inputValue) {
        setSuggestions([]);
      }

      inputChangeHandler(changes.inputValue);
      setInputValue(changes.inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (inputValue && event?.keyCode === keyCodes.enter) {
      handleSubmit();
    }
  };

  return children({
    hasHistory: suggestionsHistory.length > 0,
    inputValue,
    loading,
    onStateChange: handleStateChange,
    onSubmit: handleSubmit,
    onKeyDown: handleKeyDown,
    query,
    selectedItem,
    suggestions: inputValue ? suggestions : suggestionsHistory,
  });
};

NavbarSearchContainer.propTypes = {
  allowBlur: bool,
  onChange: func,
  onSubmit: func,
  trackId: string,
  type: string,
  searchUrlParams: shape(),
};

NavbarSearchContainer.defaultProps = {
  onChange: () => null,
};

export default NavbarSearchContainer;
