import React, { Fragment } from 'react';
import {
  bool, node, string, shape, oneOfType, number,
} from 'prop-types';
import cn from 'classnames';

import Icon from 'oc-core-components/src/Icon';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Loader.module.scss';

const styles = stylesProxy(stylesObj, 'Loader');

const Loader = ({
  loaded,
  children,
  className,
  iconClassName,
  iconSize,
  hideLoader,
  style,
  svg,
  ...restProps
}) => (loaded
  ? (
    <Fragment>
      {children}
    </Fragment>
  )
  : (
    <div className={cn(styles.container, className)} style={style}>
      {
        !hideLoader && (svg ? (
          <Icon icon="oc:loader" className={iconClassName} fill="dark-dk" stroke="none" size={iconSize} {...restProps} />
        ) : (
          <div className={cn(styles.spinner, iconClassName, { [`u-size-${iconSize}`]: !!iconSize })} {...restProps}>
            <div className={styles.spinner__oh}>
              <div className={cn(styles.spinner__inner, { [`u-size-${iconSize}`]: !!iconSize })} />
            </div>
          </div>
        ))
      }
    </div>
  )
);

Loader.propTypes = {
  loaded: bool,
  children: node,
  className: string,
  iconClassName: string,
  hideLoader: bool,
  svg: bool,
  style: shape(),
  iconSize: oneOfType([string, number]),
};

Loader.defaultProps = {
  iconSize: 'lg',
};

export default Loader;
