import React, { PureComponent } from 'react';
import { shape, arrayOf } from 'prop-types';
import DeferredAutocomplete from 'oc-core-components/src/DeferredAutocomplete';
import { uidType } from 'types';
import { getProfessors } from './utils';


class ProfessorAutocomplete extends PureComponent {
  state = {
    suggestions: [],
  }

  inputChangeHandler = val => getProfessors(val, this.props.courseId)
    .then(({ data }) => {
      const {
        hits: { hits: rawHits } = {},
      } = data || {};

      return rawHits?.map(({ _source: { name, id, all_materials_url: url } }) => ({

        // some course codes are written in the following format:
        // {variation 1},{variation 2}
        // We need to show only the first variation
        label: name,
        value: id.toString(),
        url,
      }));
    });

  render() {
    const {
      suggestions,
    } = this.state;

    const {
      items: customItems,
      countryId,
      ...restProps
    } = this.props;

    return (
      <DeferredAutocomplete
        promise={this.inputChangeHandler}
        items={suggestions}
        disableFiltering
        {...restProps}
      />
    );
  }
}

ProfessorAutocomplete.propTypes = {
  countryId: uidType,
  items: arrayOf(shape()),
};

export default ProfessorAutocomplete;
