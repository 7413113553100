import React from 'react';
import { oneOf } from 'prop-types';
import cn from 'classnames';

import Link from 'oc-core-components/src/Link';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Tag.module.scss';

const styles = stylesProxy(stylesObj, 'Tag');

const themes = ['primary', 'alt', 'accent', 'cold'];

const Tag = ({ color, className, ...props }) => (
  <Link
    className={cn(
      className,
      styles.tag,
      {
        [styles[color]]: !!color,
        [styles.link]: props.href || props.onClick,
      },
    )}
    {...props}
  />
);

Tag.propTypes = {
  color: oneOf(themes),
};

Tag.defaultProps = {
  color: themes[0],
};

export default Tag;
