import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'config/i18n';

import { shortenLargeNumber, isFunction, renderNode } from 'helpers/utils';

import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import Loader from 'oc-core-components/src/Loader';
import PointsBadge from 'hw_components/src/_HWv2/Badge/components/PointsBadge';

import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import NavbarMenuIcon from '../../NavbarMenuIcon';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './NavbarMenuV2.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarMenuV2');

const NavbarMenuV2 = ({
  className,
  count,
  items,
  label,
  loaded,
  renderFooter,
  renderItem,
  onItemClick,
  renderInner,
  renderEmpty,
}) => {
  const { t } = useTranslation('navbar');
  return (
    <div className={cn(styles.container, className)}>
      {label && <p className={styles.label}>{label}</p>}

      {
        isFunction(renderInner) ? renderNode(renderInner) : (


          <nav>

            <ul className={styles.list}>

              <Loader loaded={loaded} svg>
                {
                  (items?.length > 0)

                    ? items?.map((item, indx) => (
                      <li key={item?.key || item?.id || indx}>
                        {renderItem ? (
                          renderItem(item)
                        ) : (
                          <Button
                            color="none"
                            className={styles.item}
                            href={item.url}
                            onClick={(e) => {
                              const cb = item?.onClick;

                              if (onItemClick) {
                                onItemClick(e, item?.data);
                              }

                              if (cb) {
                                cb();
                              }
                            }}
                          >
                            {item.icon && (
                              <NavbarMenuIcon
                                fill={item.iconFill}
                                icon={item.icon}
                                stroke={item.iconStroke}
                              />
                            )}

                            <div className={styles.item__text}>
                              <div
                                className={cn(
                                  styles.item__title,
                                  item.description
                                  && styles[
                                    'item__title--has-description'
                                  ],
                                )}
                              >
                                {isFunction(item.title) ? item.title(t) : t(item.title)}


                                {
                                  item.points && (
                                    <PointsBadge
                                      pointsCount={item.points}
                                      color="orange"
                                      size="xs"
                                      className="u-ml-3xs"
                                      addPlusSign
                                    />
                                  )
                                }
                              </div>

                              {item.description && (
                                <p
                                  className={
                                    styles.item__description
                                  }
                                >
                                  {t(item.description, {
                                    count: shortenLargeNumber(count),
                                  })}
                                </p>
                              )}
                            </div>
                            {item.rightIcon && (
                              <Icon
                                className="u-ml-xs"
                                icon={item.rightIcon}
                                size={22}
                                svgClassName={styles['item__icon-right']}
                              />
                            )}
                          </Button>
                        )}
                      </li>
                    )) : renderNode(renderEmpty)
                }
              </Loader>
            </ul>


            {renderFooter && renderFooter()}
          </nav>
        )}

    </div>
  );
};

export default NavbarMenuV2;
