import typesCreator from 'v2/redux/utils/typesCreator';

export default typesCreator('page')(
  'ACCUMULATE_FETCH',
  'FETCH_PAGE_SECTION',
  'FETCH_PAGE_SECTIONS_LIST',
  'FETCH_SKILL_TIME_SLOTS',
  'REMOVE_IDS_FROM_PAGE_LIST_DATA',
  'REMOVE_LATEST_ENROLLMENT_BY_ID',
  'INSERT_IDS_IN_PAGE_LIST_DATA',
  'RETRIEVE_DATA_BY_SECTIONS',
  'SET_CURRENT_PAGE',
  'SET_SEARCH_OVERLAY',
  'SET_STUDY_MODE',
  'SET_STUDY_MODE_TOOLTIP',
  'SET_WAS_LIKED',
  'STORE_META',
  'STORE_PAGE_PROPS',
  'STORE_SKILL_TIME_SLOTS',
  'STORE_STREAM_ACTIVE',
  'STORE_BC_ANNOTATION',
  'STORE_BC_COMPLETE',
  'STORE_TEMP_ANSWER_DRAFT',
  'STORE_HOT_COURSES',
  'STORE_SHOW_HOT_COURSES_BANNER',
  'UPDATE_BC_ANNOTATION',
  'REMOVE_BC_ANNOTATION',
  'UPDATE_IS_PURCHASED',
  'UPDATE_STREAM_VIDEO_SCHEDULE_ID',
  'UPDATE_TOPIC_FOLLOW',
  'UPDATE_HAS_UNPUBLISHED_DOCS',
  'UPDATE_USER_SETTINGS_NOTIFICATION',
  'UPDATE_WATCH',
  'HIGHLIGHT_UNLOCK_QUESTION_BTN_ON',
  'HIGHLIGHT_UNLOCK_QUESTION_BTN_OFF',
  'UPDATE_CHART_DATA',
  'UPDATE_PAYMENT_FORM',
  'RESET_PAYMENT_FORM',
  'SHOW_ANSWER_FORM',
  'UPDATE_AVATAR',
  'SET_SECTION_CHAPTER',
  'SET_USER_FEEDBACK',
);
