import { takeEvery, put } from 'redux-saga/effects';
import { receiveProfessorData } from 'common/professor/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { getDataByType } from 'common/utils';
import { GET_PROFESSOR_SUCCESS } from './constants';

function* storeProfessorData({ res }) {
  const professors = getDataByType('professor', res);
  if (professors) yield put(receiveProfessorData(professors));
}

function* professorSaga() {
  yield takeEvery([RECEIVE_PAGE_DATA, GET_PROFESSOR_SUCCESS], storeProfessorData);
}

export default professorSaga;
