const title = 'Oh no! Seems like something is wrong! - OneClass';
const notFoundTitle = 'The page you are looking for doesn\'t exist (404) - OneClass';

const description = 'Oh no! Seems like something is wrong! Perhaps grab yourself a cup of coffee and check back later?';
const notFoundDescription = 'The page you are looking for doesn\'t exist.';

export {
  title,
  notFoundTitle,
  description,
  notFoundDescription,
};
