import { getUpdateBookmark } from 'common/api';
import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import { selectV2ModelByIdProp } from 'v2/redux/selectors';

import { storeBookmark, updateBookmarkActions } from 'v2/redux/question/actions';
import types from 'v2/redux/question/types';

function* updateBookmark({ payload: { id, value } }) {
  const model = yield select(state => selectV2ModelByIdProp.question(state, { questionId: id }));
  const originalVal = model?.isBookmarked;

  yield put(storeBookmark({
    id,
    value,
  }));

  const res = yield call(getUpdateBookmark, { questionId: id });

  if (res?.data?.success) {
    yield put(updateBookmarkActions.success());
  } else {
    yield put(updateBookmarkActions.error(new Error(`Incorrect Bookmark response: ${id}`)));
    yield put(storeBookmark({
      id,
      value: originalVal,
    }));
  }
}

function* questionSaga() {
  yield takeEvery(types.UPDATE_BOOKMARK.SELF, updateBookmark);
}

export default questionSaga;
