import { takeEvery, put } from 'redux-saga/effects';
import { receiveDocumentData } from 'common/document/actions';
import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS } from 'common/downloadToken/constants';
import { getDataByType } from 'common/utils';

function* storeDocumentData({ res }) {
  const paths = [
    'htmlPagesArr',
    // 'cssLinkUrls',
  ];
  // TODO: getDataByType should accept an array of string types
  const documents = getDataByType('document', res, {
    withUniqueIds: true,
    paths,
  });

  if (documents) yield put(receiveDocumentData(documents));
}

function* updateAvailableDownloadTokensCount({
                                               payload: {
                                                 params: {
                                                   documentId,
                                                 },
                                                 res: {
                                                   available_download_tokens_count,
                                                 },
                                               },
                                             }) {
  if (documentId && (available_download_tokens_count || available_download_tokens_count === 0)) {
    yield put(receiveDocumentData({
      [documentId]: {
        availableDownloadTokensCount: available_download_tokens_count,
      },
    }));
  }
}

function* documentSaga() {
  yield takeEvery(RECEIVE_PAGE_DATA, storeDocumentData);
  yield takeEvery(POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS, updateAvailableDownloadTokensCount);
}

export default documentSaga;
