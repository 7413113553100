import uniqBy from 'lodash/uniqBy';

import { camelizeKeys } from 'helpers/utils';
import { handleModelActions } from 'v2/redux/utils/handleActions';
import { QUESTION_MODEL_TYPE } from 'v2/redux/constants';
import {
  QUESTIONS_LIST_CHANNEL,
  QUESTIONS_SHOW_CHANNEL,
} from 'v2/redux/websocket/constants';
import websocketTypes from 'v2/redux/websocket/types';
import baseTypes from 'v2/redux/types';

const initialState = {
  data: {},
  incoming: 0,
};

const questionReducer = handleModelActions(
  QUESTION_MODEL_TYPE,
  {
    [baseTypes.FETCH_API_LATEST.SELF]: (state, { payload }) => {
      const { page, params = {} } = payload;

      if (['home', 'homeworkHelp'].includes(page) && !params.page) {
        state.incoming = 0;
      }

      return state;
    },

    [websocketTypes.RECEIVED_EVENT]: (state, { payload }) => {
      const {
        channel: { channel },
        data,
      } = payload;

      if (![QUESTIONS_LIST_CHANNEL, QUESTIONS_SHOW_CHANNEL].includes(channel)) return state;

      const { id, questionId, status } = camelizeKeys(data);
      //
      // if (status === 'new') {
      //   state.incoming += 1;
      // }

      const question = state.data[questionId];
      if (!question) return state;

      if (status === 'typing') {
        state.incoming += 1;
        const typersData = [...(question?.relationships?.typers?.data || [])];
        typersData.unshift({
          id: `${id}`,
          type: 'typer',
        });

        state.data[questionId].relationships.typers.data = uniqBy(
          typersData,
          'id',
        );
      } else if (status === 'stop') {
        const typersData = [...(question?.relationships?.typers?.data || [])];

        state.data[questionId].relationships.typers.data = typersData.filter(
          typer => typer.id !== `${id}`,
        );
      }

      return state;
    },
  },
  initialState,
);

export { initialState };

export default questionReducer;
