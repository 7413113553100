import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2GaEvents } from 'v2/redux/meta/selectors';
// import { trackAccountDetails } from 'helpers/tracks';
// import { trackSuccessfulSignup } from 'helpers/tracks';
import { isFunction } from 'helpers/utils';

const eventHandlers = {
  // trackAccountDetails,
  // trackSuccessfulSignup,
};

// meta: {
//   ...
//   gaEvents: [
//     {
//       eventName: 'trackAccountDetails',
//       data: { ...payload }
//     }
//   ]
// }
const useTrackGaEventsAfterSignup = () => {
  const events = useSelector(getV2GaEvents);

  useEffect(() => {
    // skip this effect if meta.gaEvents is undefined
    if (!events) return;

    // TODO: investigate why window.gtag is undefined when this hook is called
    setTimeout(() => {
      events.map((e) => {
        const f = eventHandlers[e.eventName];

        if (isFunction(f)) {
          console.log({ data: e.data, gtag: window.gtag });
          f(e.data);
        }
      });
    }, 2000);
  }, []);
};

export default useTrackGaEventsAfterSignup;
