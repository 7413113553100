const selectRelationshipsProp = (state, { relationships }) => relationships;
const selectCourseIdProp = (state, { courseId }) => courseId;
const selectCoursePropId = (state, { course }) => course?.id;
const selectAutocompleteNameProp = (state, { autocompleteName }) => autocompleteName;
const selectDepartmentIdProp = (state, { departmentId }) => departmentId;
const selectSchoolIdProp = (state, { schoolId }) => schoolId;
const selectDocumentIdProp = (state, { documentId }) => documentId;
const selectDocumentIdsProp = (state, { documentIds }) => documentIds;
const selectDownloadTokenIdsProp = (state, { downloadTokenIds }) => downloadTokenIds;
const selectProfessorIdProp = (state, { professorId }) => professorId;
const selectCountryIdProp = (state, { countryId }) => countryId;
const selectUserIdProp = (state, { userId }) => userId;

const selectListIdProp = (state, { listId }) => listId;
const selectListProp = (state, { list }) => list;
const selectListKeyProp = (state, { listKey }) => listKey;
const selectItemIdsProp = (state, { itemIds }) => itemIds;

export {
  selectRelationshipsProp,
  selectCourseIdProp,
  selectCoursePropId,
  selectAutocompleteNameProp,
  selectDepartmentIdProp,
  selectSchoolIdProp,
  selectDocumentIdProp,
  selectDocumentIdsProp,
  selectDownloadTokenIdsProp,
  selectProfessorIdProp,
  selectCountryIdProp,
  selectUserIdProp,
  selectListIdProp,
  selectListProp,
  selectListKeyProp,
  selectItemIdsProp,
};
