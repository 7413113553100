import React from 'react';
import { withTranslation } from 'config/i18n';

import DynamicModal from 'oc-core-components/src/DynamicModal';
import {
  PROMO_CODE_MODAL,
} from 'oc-core-components/src/Modal/constants';

const PromoCodeFormLoader = () => import(/* webpackChunkName: "PromoCodeForm" */ 'oc-core-components/src/PromoCodeForm');

const initialData = {
  code: '',
};

const PromoCodeModal = ({
  t,
}) => (
  <DynamicModal
    component={PromoCodeFormLoader}
    name={PROMO_CODE_MODAL}
    size="sm"
    title={t('section.promo_code.title')}
    render={(PromoCodeForm, modal) => (
      <PromoCodeForm
        initialData={initialData}
        onSubmit={modal?.close}
        ariaLabel={t('section.promo_code.aria_label')}
        placeholder={t('section.promo_code.placeholder')}
        submitAriaLabel={t('section.promo_code.submit_aria_label')}
        submitText={t('section.promo_code.submit_text')}
        onSuccessText={t('section.promo_code.on_success_text')}
      />
    )}
  />
);


export default withTranslation('common')(PromoCodeModal);
