import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { stylesProxy } from 'helpers/css';
import { shortenLargeNumber, isFunction, renderNode } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
import { useAmp } from 'next/amp';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import Loader from 'oc-core-components/src/Loader';
import PointsBadge from 'hw_components/src/_HWv2/Badge/components/PointsBadge';

import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useEffect } from 'react';
import ClickOutside from 'react-click-outside-component';
import { getMenuButtonOn } from './utils';
import LivestreamDot from './LivestreamDot';
import MenuButton from './MenuButton';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';
import NavbarMenuIcon from './NavbarMenuIcon';

const s = stylesProxy(stylesObj, 'Navbar');

const NavbarMenu = ({
  active,
  className,
  count,
  id,
  items,
  label,
  loaded,
  onActive,
  onClickOutside,
  onToggle,
  renderFooter,
  renderItem,
  streamActive,
  styles,
  toggleCount,
  toggleIcon,
  toggleIconStroke,
  toggleLabel,
  iconClassName,
  title,
  onItemClick,
  toggleAriaLabel,
  renderInner,
  renderEmpty,
  menuButtonLabel,
  isActiveLabel,
}) => {
  const isAmp = useAmp();
  const { t } = useTranslation('navbar');

  useEffect(() => {
    if (active) onActive();
  }, [active, onActive]);

  const button = toggleIcon ? (
    <MenuButton
      active={active}
      count={toggleCount}
      icon={toggleIcon}
      iconStroke={toggleIconStroke}
      on={isAmp ? getMenuButtonOn(id) : undefined}
      onClick={onToggle}
      title={title}
      aria-label={toggleAriaLabel}
      iconClassName={iconClassName}
      label={menuButtonLabel}
      isActiveLabel={isActiveLabel}
    />
  ) : (
    <button
      className={cn(
        styles.navbar__button,
        active && styles['navbar__button--active'],
      )}
      onClick={onToggle}
      on={isAmp ? getMenuButtonOn(id) : undefined}
      type="button"
      aria-label={toggleAriaLabel}
    >
      {toggleLabel}
    </button>
  );

  if (!isAmp && !active) {
    return button;
  }


  const content = (
    <>
      {label && <p className={styles.navbar__menu__label}>{label}</p>}

      {
        isFunction(renderInner) ? renderNode(renderInner) : (


          <nav>

            <ul className={styles['navbar__menu__item-container']}>

              <Loader loaded={loaded} svg>
                {
                      (items?.length > 0)

                        ? items?.map((item, indx) => (
                          <li key={item?.key || item?.id || indx}>
                            {renderItem ? (
                              renderItem(item)
                            ) : (
                              <Button
                                color="none"
                                className={styles.navbar__menu__item}
                                href={item.url}
                                onClick={
                                  onItemClick ? e => onItemClick(e, item?.data) : undefined
                                }
                              >
                                {item.icon && (
                                  <NavbarMenuIcon
                                    fill={item.iconFill}
                                    icon={item.icon}
                                    stroke={item.iconStroke}
                                  />
                                )}

                                <div className={styles.navbar__menu__item__text}>
                                  <div
                                    className={cn(
                                      styles.navbar__menu__item__text__title,
                                      item.description
                                        && styles[
                                          'navbar__menu__item__text__title--has-description'
                                        ],
                                    )}
                                  >
                                    {isFunction(item.title) ? item.title(t) : t(item.title)}

                                    {item.livestream && (
                                      <LivestreamDot streamActive={streamActive} />
                                    )}

                                    {
                                      item.points && (
                                        <PointsBadge
                                          pointsCount={item.points}
                                          color="orange"
                                          size="xs"
                                          className="u-ml-3xs"
                                          addPlusSign
                                        />
                                      )
                                    }
                                  </div>

                                  {item.description && (
                                    <p
                                      className={
                                        styles.navbar__menu__item__text__description
                                      }
                                    >
                                      {t(item.description, {
                                        count: shortenLargeNumber(count),
                                      })}
                                    </p>
                                  )}
                                </div>
                                {item.rightIcon && (
                                  <Icon
                                    className="u-ml-xs"
                                    icon={item.rightIcon}
                                    size={22}
                                    svgClassName={styles['navbar__menu__item__icon-right']}
                                  />
                                )}
                              </Button>
                            )}
                          </li>
                        )) : renderNode(renderEmpty)
                    }
              </Loader>
            </ul>

            {renderFooter()}
          </nav>
        )}

    </>
  );

  if (isAmp) {
    return (
      <>
        {button}

        <div
          className={cn(
            styles.navbar__menu,
            isAmp && styles['navbar__menu--amp'],
            className,
          )}
          hidden
          data-amp-bind-hidden={`!${id}Visible`}
        >
          {content}
        </div>
        <div
          className={styles['amp-overlay']}
          role="button"
          tabIndex={-1}
          hidden
          data-amp-bind-hidden={`!${id}Visible`}
          on={`tap:AMP.setState({${id}Visible: false})`}
        />
      </>
    );
  }

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      {button}

      <div className={cn(styles.navbar__menu, className)}>{content}</div>
    </ClickOutside>
  );
};
NavbarMenu.propTypes = {
  active: bool,
  className: string,
  count: number,
  id: string,
  items: oneOfType([
    arrayOf(
      shape({
        key: string.isRequired,
        description: string,
        icon: string,
        livestream: bool,
        rightIcon: string,
        title: string.isRequired,
        url: string.isRequired,
      }),
    ),
    arrayOf(shape()), // requires renderItem
  ]),
  label: string,
  loaded: bool,
  onActive: func,
  onClickOutside: func,
  onToggle: func,
  renderFooter: func,
  renderItem: func,
  streamActive: bool,
  toggleCount: number,
  toggleIcon: string,
  toggleIconStroke: string,
  toggleLabel: string,
};
NavbarMenu.defaultProps = {
  count: 0,
  loaded: true,
  onActive: () => {},
  renderFooter: () => {},
};
export default withAmpStyles(NavbarMenu, s, stylesObj, ampStylesObj);
