import React, { useState, useCallback } from 'react';
import Link from 'oc-core-components/src/Link';
import Button from 'oc-core-components/src/Button';
import BCEmoji from 'shared/components/BCEmoji';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { useNotifications } from 'oc-core-components/src/NotificationProvider';
import { postResendCouponDiscount } from 'shared/common/api';
import ChangeEmailForm from './components/ChangeEmailForm';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SignupDiscountModal.module.scss';

const styles = stylesProxy(stylesObj, 'SignupDiscountModal');

const WELCOME_STEP = 1;
const CHANGE_EMAIL_STEP = 2;

const SignupDiscountModal = () => {
  const isMobile = useMobile();
  const [step, setStep] = useState(WELCOME_STEP);
  const modal = useModal();
  const onCloseClick = modal.close;
  const onChangeEmailClick = useCallback(() => {
    setStep(CHANGE_EMAIL_STEP);
  }, []);
  const { addErrorNotification, addSuccessNotification } = useNotifications();
  const onResendClick = useCallback(async () => {
    try {
      const successText = 'Coupon code has been successfully sent to your e-mail';

      const {
        data: {
          success,
          error,
        },
      } = await postResendCouponDiscount();

      if (success) {
        onCloseClick();
        addSuccessNotification(successText);
      } else {
        addErrorNotification(error);
      }
    } catch (e) {
      addErrorNotification();
      console.error(e);
    }
  }, [onCloseClick, addErrorNotification, addSuccessNotification]);

  const onBackBtnClick = useCallback(() => setStep(WELCOME_STEP), []);

  const emoji = step === WELCOME_STEP ? 'party-face' : 'mail';
  const title = step === WELCOME_STEP ? 'Welcome to OneClass!' : 'Update email';
  const subtitle = step === WELCOME_STEP ?
    'Your 10% OFF coupon has been sent to your email!'
    : 'Your 10% OFF coupon and future promotions will be sent to this new email.';

  return (
    <div className={styles.container}>
      <div className={styles.emoji_container}>
        <BCEmoji name={emoji} size={24} />
      </div>

      {
        (isMobile || step === WELCOME_STEP) ? null : (
          <Button icon="feather:arrow-left" className={styles.back_btn} onClick={onBackBtnClick} />
        )
      }

      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      {
        step === WELCOME_STEP ? (
          <>
            <div className={styles.description}>
              Didn’t receive it?
              {' '}
              <Link onClick={onResendClick} className={styles.link}>Resend</Link>
              {' '}
              or
              {' '}
              <Link onClick={onChangeEmailClick} className={styles.link}>change email</Link>
            </div>

            <Button onClick={onCloseClick} className={styles.continue_btn}>
              Continue to home
            </Button>
          </>
        ) : (
          <ChangeEmailForm onSubmit={onCloseClick} />
        )
      }

    </div>

  );
};

SignupDiscountModal.propTypes = {};

export default SignupDiscountModal;
