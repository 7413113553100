import createAction from './createAction';

// TODO: Add error handling
const createActions = (actionsObj, actionsPayloadMap = {}) => Object.keys(actionsObj).reduce((acc, type) => {
  const payloadProps = actionsPayloadMap[type] ? actionsPayloadMap[type] : [];
  const args = [
    actionsObj[type],
    ...payloadProps,
  ];

  acc[type.toLowerCase()] = createAction(...args);
  return acc;
}, {});

export default createActions;
