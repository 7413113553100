
import { useMemo } from 'react';
// import { useRouter } from 'oc-core-components/src/RouterProvider';
// import getAuthLink from 'helpers/utils/getAuthLink';
// import { useAuth } from 'oc-core-components/src/AuthProvider';
// import AddDocumentModalContent from 'components/MyCourses/AddDocumentModalContent';
// import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { getCourseUploadLink } from 'common/course/utils';

// const DEFAULT_SOURCE = 'navbar-add-course';


export default function useDocumentUploadHandler({
  kind,
  schoolId,
  courseId,
  // modalWrapperProps,
  // addCourseOnly,
  // source = DEFAULT_SOURCE,
  // checkSourceParam,
} = {}) {
  // const { isLoggedIn } = useAuth();
  // const modal = useModal();
  // const { router: { query } = {} } = useRouter();
  // const querySource = query?.source;
  // const queryKind = query?.kind;

  // const openPopup = useCallback(props => modal.inline({
  //   content: () => <AddDocumentModalContent {...props} />,
  //   wrapperProps: modalWrapperProps,
  // }), [modalWrapperProps]);

  const btnProps = useMemo(() => ({
    href: getCourseUploadLink({ courseId, kind, schoolId }),
  }), [courseId, kind, schoolId]);

  // useEffect(() => {
  //   if (checkSourceParam && querySource === source) {
  //     openPopup({ documentKind: queryKind });
  //   }
  // }, []);

  return btnProps;
}
