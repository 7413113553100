import React, { memo } from 'react';
import {
  string, node, func, oneOfType, bool, shape, oneOf,
} from 'prop-types';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import Container from 'oc-core-components/src/Container';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import { isFunction, createMarkup } from 'helpers/utils';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './NavbarBanner.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarBanner');

const renderDescription = (description) => {
  if (isFunction(description)) {
    return description();
  }

  if (typeof description === 'string') {
    // eslint-disable-next-line
    return <span dangerouslySetInnerHTML={createMarkup(description)} />;
  }

  if (React.isValidElement(description)) {
    return description;
  }

  return null;
};

const NavbarBanner = ({
  title,
  description,
  url,
  buttonLink,
  buttonText,
  mobileButtonText,
  onButtonClick,
  hideDescriprionOnMobile,
  className,
  descriptionColProps,
  theme,
  buttonClassName,
  buttonColor,
  ...restProps
}) => (
  <Link className={cn(styles.container, className, { [styles[theme]]: !!theme })} href={url} {...restProps}>
    <Container fluid>
      <Row className="u-justify-content-center u-align-items-center">
        <Col xs="auto" className="u-text-right" {...descriptionColProps}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={cn('u-align-middle', hideDescriprionOnMobile ? 'u-d-none u-d-inline-md' : 'u-d-inline')}>
            {renderDescription(description)}
          </div>
        </Col>

        {
          buttonText && (
            <Col xs="auto">
              <Button
                aria-label={buttonText}
                className={cn(styles.btn, buttonClassName)}
                color={buttonColor}
                href={!url ? buttonLink : null}
                onClick={onButtonClick}
              >
                <span className="u-d-none u-d-inline-sm">
                  {buttonText}
                </span>

                <span className="u-d-none-sm">
                  {mobileButtonText || buttonText}
                </span>
                <Icon size="3xs" className={styles.icon} icon="feather:chevron-right" />
              </Button>
            </Col>
          )
        }
      </Row>
    </Container>
  </Link>
);


NavbarBanner.propTypes = {
  title: node,
  description: oneOfType([func, node]),
  url: string,
  buttonLink: string,
  buttonText: node,
  mobileButtonText: node,
  onButtonClick: func,
  hideDescriprionOnMobile: bool,
  descriptionColProps: shape(),
  theme: oneOf(['alert']),
  buttonClassName: string,
  buttonColor: string,
};

NavbarBanner.defaultProps = {
  buttonColor: 'primary-alt',
};


export default memo(NavbarBanner);
