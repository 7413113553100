import React, { useCallback } from 'react';
import TextInput from 'oc-core-components/src/TextInput';
import { withGlobalProps } from 'components/pages/HomeworkHelp/GlobalPropsProvider';
import { Field, getIn } from 'formik';
import InputMask from 'react-input-mask';
import { string, shape, func } from 'prop-types';
import { tagType } from 'types';

import { isFunction } from 'helpers/utils';

const EnhancedTextInput = ({
  field,
  form: {
    touched: touchedArr,
    errors,
  },
  form,
  meta,
  onChange,
  onBlur,
  ...restProps
}) => {
  const touched = getIn(touchedArr, field.name);
  const error = getIn(errors, field.name);

  const handleChange = useCallback((...args) => {
    field.onChange(...args);

    if (isFunction(onChange)) {
      onChange(...args);
    }
  }, [onChange, field]);

  const handleBlur = useCallback((...args) => {
    field.onBlur(...args);

    if (isFunction(onBlur)) {
      onBlur(...args);
    }
  }, [onBlur, field]);

  return (
    <TextInput
      {...field}
      {...restProps}
      error={touched && !!error}
      errorMessage={error}
      tag={InputMask}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

EnhancedTextInput.defaultProps = {
  mask: '+1 (999) 999 9999',
  tag: InputMask,
};

EnhancedTextInput.propTypes = {
  mask: string,
  maskChar: string,
  tag: tagType,
  field: shape(),
  form: shape(),
  meta: shape(),
  onChange: func,
  onBlur: func,
};


const PhoneField = ({ ...props }) => (
  <Field
    component={EnhancedTextInput}
    {...props}
  />
);


export {
  PhoneField as RawPhoneField,
};

export default withGlobalProps(PhoneField, 'Field');
