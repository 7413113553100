import { handleModelActions } from 'v2/redux/utils/handleActions';
import { EARNING_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const earningReducer = handleModelActions(EARNING_MODEL_TYPE, {}, initialState);

export { initialState };

export default earningReducer;
