import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import { selectV2TopSchools } from 'shared/v2/redux/page/selectors';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './HomeMaterialCard.module.scss';

const styles = stylesProxy(stylesObj, 'HomeMaterialCard');

const getSchools = ({ kind, topSchools }) => {
  if (kind === 'class-notes') {
    return topSchools.classNotes;
  } else if (kind === 'textbook-notes') {
    return topSchools.textbookNotes;
  } else if (kind === 'study-guides') {
    return topSchools.studyGuides;
  }

  // fallback
  return topSchools.studyGuides;
};

const getViewMoreLabel = ({ kind }) => {
  if (kind === 'class-notes') {
    return "View more class notes"
  } else if (kind === 'textbook-notes') {
    return "View more textbook notes"
  } else if (kind === 'study-guides') {
    return "View more study guides"
  }
};


const HomeMaterialCard = ({
  color,
  title,
  description,
  kind,
  viewMoreLink,
  vertical,
}) => {
  const { t } = useTranslation('home');

  const topSchools = useSelector(selectV2TopSchools);
  const schools = getSchools({ topSchools, kind });
  const viewMoreLabel = getViewMoreLabel({ kind });


  return (

    <div className={cn(styles.container, color && styles[color], vertical && styles.vertical)}>
      <div className={styles.info}>
        <h5 className={styles.title}>
          {title}
        </h5>
        <div className={styles.description}>
          {description}
        </div>

        <Link href={viewMoreLink} className={styles.more}>
          {/* {t('view_details')} */}
          {viewMoreLabel}
        </Link>
      </div>

      <div className={styles.buttons}>
        {
          schools.map(({
            label,
            value,
          }) => (
            <Link
              key={label}
              href={value}
              className={styles.button}
            >
              <Icon icon="oc:school" size={18} stroke="none" />
              <span className={styles.button__label}>
                {label}
              </span>

              <Icon className={styles.button__arrow} icon="feather:arrow-right" size={14} />
            </Link>
          ))
        }
      </div>
    </div>
  );
};

export default HomeMaterialCard;
