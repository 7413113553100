import React from 'react';
import {
  string, node, bool, func,
} from 'prop-types';

import cn from 'classnames';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './InputFeedback.module.scss';

const styles = stylesProxy(stylesObj, 'InputFeedback');

const InputFeedback = ({
  children, onClick, error, ...restProps
}) => {
  const Tag = onClick ? 'button' : 'span';

  return (React.Children.count(children) > 0) ? (
    <div {...restProps}>
      <Tag
        onClick={onClick}
        onKeyPress={onClick}
        className={cn(styles.message, { [styles.error]: error, [styles.button]: onClick })}
        type={onClick && 'button'}
      >
        {children}
      </Tag>
    </div>
  ) : null;
};

InputFeedback.propTypes = {
  href: string,
  children: node,
  error: bool,
  onClick: func,
};

export default InputFeedback;
