import { defaultSizes, SET_MOBILE_DETECT } from './constants';

export const defaultSize = defaultSizes.desktop;

export const initialState = {
  phone: false,
  tablet: false,
  mobile: false,
  desktop: false,
  fakeWidth: defaultSize,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_DETECT: {
      const {
        mobile, tablet, phone, desktop,
      } = { ...initialState, ...action };

      let fakeWidth;

      if (mobile) {
        if (phone) {
          fakeWidth = defaultSizes.phone;
        } else if (tablet) {
          fakeWidth = defaultSizes.tablet;
        } else {
          fakeWidth = defaultSizes.phone;
        }
      } else if (desktop) {
        fakeWidth = defaultSizes.desktop;
      } else {
      // nothing set, default to our defaultSize
        fakeWidth = defaultSize;
      }

      return {
        ...state, mobile, tablet, phone, desktop, fakeWidth,
      };
    }
    default:
      return state;
  }
};
