
import { MAIL_TO_REGEXP } from './pwa';

const AMP_EXT_REGEXP = new RegExp(/amp$/);

export const AMP_ROUTES_KEYS = [
  'homeworkHelp',
];

const AMP_URLS_REGEXPS = [
  /homework-help/,
];

export const urlWithAmpSupport = url => !!(AMP_URLS_REGEXPS.find(regexp => regexp.test(url)));

export const isAmp = (currentPath) => {
  const path = currentPath || (typeof window === 'undefined' ? global.INITIAL_PAGE_PATH : window.location.pathname);

  return AMP_EXT_REGEXP.test(path);
};

export const toAmpUrl = ({
  url,
  prefix,
  lng = 'en',
  format = 'html',
  currentPath,
  force,
}) => {
  if (!url) return url;
  // don't convert mailto links
  if (MAIL_TO_REGEXP.test(url)) return url;

  // don't convert url if there's no AMP version for this url
  if (!urlWithAmpSupport(url)) return url;

  // if current page isn't AMP page return initial url (we convert links only for AMP pages)
  if (!isAmp(currentPath) && !force) return url;


  let protocolPlusHost = '';
  let newPath = url.slice();
  const match = url.match(/(^https?:\/\/.+?)\//);
  if (match) {
    protocolPlusHost = match[1];
    newPath = newPath.replace(protocolPlusHost, '');
  }

  return `${protocolPlusHost}${prefix}${newPath}`.replace(`${lng}.${format}`, `${lng}.amp`);
};


export default {};
