import {
  getItemByValue,
  createItem,
  translateItemsList,
  translateItem,
  itemToValue,
} from 'oc-core-components/src/Autocomplete/utils';

export const SEMESTERS = [
  ['all_semesters', 'all-semesters'],
  ['winter', 'winter'],
  ['spring', 'spring'],
  ['summer', 'summer'],
  ['fall', 'fall'],
].map(el => createItem(...el));

export const getTranslSemesters = (t, ns = 'common') => translateItemsList(SEMESTERS, ns, t);

export const DOCUMENT_TYPES = [
  ['all_materials', 'all-materials'],
  ['class_notes', 'class-notes'],
  ['textbook_notes', 'textbook-notes'],
  ['study_guides', 'study-guides'],
].map(el => createItem(...el));

export const getTranslDocumentTypes = (t, ns = 'common') => translateItemsList(DOCUMENT_TYPES, ns, t);


const sortOptions = {
  blank: createItem('sort', ''),
  suggested: createItem('suggested', 'suggested'),
  mostPopular: createItem('most_popular', 'popular-alltime'),
  mostRecent: createItem('most_recent', 'most-recent'),
  trending: createItem('trending', 'trending'),
};

export const getSortingOptions = (materialType, opts = sortOptions, sortConfig = {}) => {
  if (sortConfig.allowBlank) {
    return {
      defaultOption: opts.blank,
      defaultValue: itemToValue(opts.blank),
      options: [
        opts.blank,
        opts.suggested,
        opts.mostPopular,
        opts.mostRecent,
      ]
    }
  }

  // Check https://oneclass.atlassian.net/browse/OS-2796
  const baseSortOptions = [
    opts.suggested,
    opts.mostPopular,
    opts.mostRecent,
  ];

  if (['all-materials', 'study-guides'].includes(materialType)) {
    baseSortOptions.push(opts.trending);
  }

  let defaultOption = opts.mostPopular;

  if (materialType === 'study-guides') {
    defaultOption = opts.suggested;
  }

  return {
    defaultOption,
    defaultValue: itemToValue(defaultOption),
    options: baseSortOptions,
  };
};

export const getTranslSortOptions = (materialType, t, sortConfig = {}, ns = 'productBrowse') => {
  const options = getSortingOptions(materialType, sortOptions, sortConfig);

  return {
    default: options.defaultOption ? translateItem(options.defaultOption, ns, t) : null,
    options: translateItemsList(options.options, ns, t),
  };
};


export const getInitialData = t => ({
  materialType: getItemByValue(getTranslDocumentTypes(t), 'all-materials'),
  countryId: null,
  schoolId: null,
  departmentId: null,
  courseId: null,
  professorId: null,
  semester: getItemByValue(getTranslSemesters(t), 'all-semesters'),
  materialTypeCount: null,
});

export default {};
