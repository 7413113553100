import { handleModelActions } from 'v2/redux/utils/handleActions';
import { BOOK_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const bookReducer = handleModelActions(BOOK_MODEL_TYPE, {}, initialState);

export { initialState };

export default bookReducer;
