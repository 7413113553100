import React from 'react';
import { stylesProxy } from 'helpers/css';
import Button from 'oc-core-components/src/Button';
import getImagePropsFactory from 'helpers/getImageProps';
import LazyImage from 'oc-core-components/src/LazyImage';
import cn from 'classnames';
import { ROUTES } from 'shared/helpers/constants';
import { useTranslation } from 'config/i18n';
import stylesObj from './Cards.module.scss';

const styles = stylesProxy(stylesObj, 'Cards');


const Cards = ({

}) => {
  const { t } = useTranslation('home');
  
  const cardsContent = [
    {
      title: t('card-1.title'),
      text: t('card-1.subtitle'),
      imgSrc: 'card-1',
      className: 'connect',
    },
    {
      title: t('card-2.title'),
      text: t('card-2.subtitle'),
      button: t('common:sign_up'),
      href: ROUTES.signup,
      imgSrc: 'card-2',
      className: 'sign-up',
    },
  ];
  return (
    <div className={styles.wrapper}>
      {cardsContent.map((item) => {
        const getImageProps = getImagePropsFactory('/pages/home/');
        const imageProps = getImageProps({
          alt: item.title,
          defaultExt: 'png',
          sourcesExt: 'webp',
          filename: item.imgSrc,
          allowedSizes: ['1x', '2x'],
        });
        return (
          <div key={item.imgSrc} className={cn(styles.card, styles[`card_${item.className}`])}>
            <LazyImage className={styles.pic} {...imageProps} />
            <div className={styles.title}>
              {item.title}
            </div>
            <div className={styles.text}>
              {item.text}
            </div>
            {item.button && (
            <Button
              className={styles.button}
              color="dark"
              href={item.href}
            >
              {item.button}
            </Button>
            )
          }
          </div>
        );
      })}
    </div>
  );
};

export default Cards;
