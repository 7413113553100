import React from 'react';
import cn from 'classnames';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './FormGroup.module.scss';

const styles = stylesProxy(stylesObj, 'FormGroup');

const FormGroup = ({ className, ...props }) => <div className={cn(styles.container, className)} {...props} />;

export default FormGroup;
