import merge from 'lodash/merge';
import mergeable from 'redux-merge-reducers';

import {
  RECEIVE_DOCUMENT_DATA,
  POST_REPORT_DOCUMENT,
  POST_REPORT_DOCUMENT_SUCCESS,
  POST_REPORT_DOCUMENT_ERROR,
  RESET_POST_REPORT_DOCUMENT_ERROR,
  POST_REQUEST_NOTES,
  POST_REQUEST_NOTES_SUCCESS,
  POST_REQUEST_NOTES_ERROR,
  RESET_POST_REQUEST_NOTES_ERROR,
  POST_VOTE,
  POST_VOTE_SUCCESS,
  POST_VOTE_ERROR,
  POST_NOTES_REQUEST,
  POST_NOTES_REQUEST_ERROR,
  POST_NOTES_REQUEST_SUCCESS,
  GET_PREV_NEXT_DATA,
  GET_PREV_NEXT_DATA_SUCCESS,
  GET_PREV_NEXT_DATA_ERROR,
  HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON,
  HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF,
} from './constants';

const initialState = {
  data: {},
  reportingDocument: false,
  reportDocumentSuccess: false,
  reportDocumentError: false,
  requestingNotes: false,
  requestNotesSuccess: false,
  requestNotesError: false,
  notesRequestInProgress: false,
  notesRequestSuccess: false,
  notesRequestError: false,
  voting: false,
  voteSuccess: false,
  voteError: false,
  prevNextDocumentData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_DOCUMENT_DATA: {
      return merge({}, state, {
        data: action.res,
      });
    }
    case POST_REPORT_DOCUMENT:
      return Object.assign({}, state, {
        reportingDocument: true,
        reportDocumentSuccess: false,
      });
    case POST_REPORT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        reportingDocument: false,
        reportDocumentSuccess: {
          [action.params.documentId]: true,
        },
      });
    case POST_REPORT_DOCUMENT_ERROR:
      return Object.assign({}, state, {
        reportingDocument: false,
        reportDocumentError: 'An error occured in POST_REPORT_DOCUMENT',
      });
    case RESET_POST_REPORT_DOCUMENT_ERROR:
      return Object.assign({}, state, {
        reportDocumentError: false,
      });
    case POST_REQUEST_NOTES:
      return Object.assign({}, state, {
        requestingNotes: true,
        requestNotesSuccess: false,
      });
    case POST_REQUEST_NOTES_SUCCESS:
      return Object.assign({}, state, {
        requestingNotes: false,
        requestNotesSuccess: action.res,
      });
    case POST_REQUEST_NOTES_ERROR:
      return Object.assign({}, state, {
        requestingNotes: false,
        requestNotesError: 'An error occured in POST_REQUEST_NOTES',
      });
    case POST_NOTES_REQUEST:
      return Object.assign({}, state, {
        notesRequestInProgress: true,
        notesRequestSuccess: false,
      });
    case POST_NOTES_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        notesRequestInProgress: false,
        notesRequestSuccess: true,
      });
    case POST_NOTES_REQUEST_ERROR:
      return Object.assign({}, state, {
        notesRequestInProgress: false,
        notesRequestError: 'An error occured in POST_NOTES_REQUEST',
      });
    case RESET_POST_REQUEST_NOTES_ERROR:
      return Object.assign({}, state, {
        requestNotesError: false,
      });
    case POST_VOTE:
      return Object.assign({}, state, {
        voting: true,
        voteSuccess: false,
      });
    case POST_VOTE_SUCCESS:
      return merge({}, state, {
        voting: false,
        voteSuccess: {
          [action.params.target_id]: true,
        },
      });
    case POST_VOTE_ERROR:
      return Object.assign({}, state, {
        voting: false,
        voteError: {
          [action.params.target_id]: 'a voting error occured',
        },
      });
    case GET_PREV_NEXT_DATA_SUCCESS:
      return Object.assign({}, state, {
        prevNextDocumentData: {
          [action.params]: action.res,
        },
      });
    case GET_PREV_NEXT_DATA:
    case GET_PREV_NEXT_DATA_ERROR:
      return Object.assign({}, state, {
        prevNextDocumentData: {
          [action.params]: false,
        },
      });
    case HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON:
      return Object.assign({}, state, {
        highlightUnlockDocumentForm: true,
      });
    case HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF:
      return Object.assign({}, state, {
        highlightUnlockDocumentForm: false,
      });
    default:
      return state;
  }
};

export default mergeable(reducer);
