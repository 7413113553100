
export const SESSION_STATUS = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
};

export default {};
