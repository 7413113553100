import React from 'react';
import cn from 'classnames';
import PageTitle from 'components/pages/ProductBrowse/PageTitle';
import { bool, node } from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ProductBrowseSection.module.scss';

const styles = stylesProxy(stylesObj, 'ProductBrowseSection');

const ProductBrowseSection = ({
  title,
  subtitle,
  topContent,
  children,
  className,
  borderedHeader,
  aboutLink,
  pageTitleClassName,
  innerRef,
  ...restProps
}) => (
  <div
    className={cn(styles.container, className)}
    ref={innerRef}
    {...restProps}
  >
    {
      (title || subtitle || topContent) && (
        <>
          <PageTitle
            tag="h2"
            title={title}
            subtitle={subtitle}
            afterContent={topContent}
            bordered={borderedHeader}
            aboutLink={aboutLink}
            className={pageTitleClassName}
          />


        </>
      )
    }

    {children}
  </div>
);

ProductBrowseSection.propTypes = {
  title: node,
  subtitle: node,
  topContent: node,
  borderedHeader: bool,
};

export default ProductBrowseSection;
