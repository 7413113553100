import React from 'react';
import Link from 'oc-core-components/src/Link';
import cn from 'classnames';
import { string, bool, func } from 'prop-types';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './CustomLink.module.scss';
import ampStylesObj from './CustomLink.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'CustomLink');


export const CustomLink = ({
  children,
  selected,
  link,
  onClick,
  styles,
  className,
}) => (
  <Link
    href={link}
    onClick={onClick}
    className={
        cn(styles.link, className, {
          [styles['link--selected']]: selected,
        })
      }
  >
    {children}
  </Link>
);

CustomLink.propTypes = {
  url: string,
  selected: bool,
  link: string,
  onClick: func,
};

export default withAmpStyles(CustomLink, s, stylesObj, ampStylesObj);
