import React from 'react';
import { oneOf, bool } from 'prop-types';
import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Slider.module.scss';

const styles = stylesProxy(stylesObj, 'Slider');


const Button = ({
  dir, disabled, className, ...restProps
}) => (
  <button
    type="button"
    className={cn(
      styles.btn,
      { [styles.disabled]: disabled },
      styles[`btn--${dir === 'next' ? 'next' : 'prev'}`],
      className,
    )}
    {...restProps}
  >
    <Icon
      size="xs"
      icon={`feather:chevron-${dir === 'next' ? 'right' : 'left'}`}
      color="dark"
    />
  </button>
);

Button.propTypes = {
  dir: oneOf(['prev', 'next']),
  disabled: bool,
};

export default Button;
