import mergeable from 'redux-merge-reducers';

import {
  RECEIVE_NOTIFICATION,
  CLEAR_NOTIFICATION,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  CLEAR_NOTIFICATIONS_COUNT,
} from './constants';

const initialNotificationState = {
  notifications: [],
  notificationsCount: 0,
  newNotification: false,
};

const reducer = (state = initialNotificationState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_COUNT_SUCCESS:
      return Object.assign({}, state, {
        notificationsCount: action.res || 0,
      });
    case CLEAR_NOTIFICATIONS_COUNT:
      return Object.assign({}, state, {
        notificationsCount: 0,
      });
    case RECEIVE_NOTIFICATION:
      return Object.assign({}, state, {
        newNotification: action.res,
      });
    case CLEAR_NOTIFICATION:
      return Object.assign({}, state, {
        newNotification: false,
      });
    default:
      return state;
  }
};

export { initialNotificationState };
export default mergeable(reducer);
