import { takeEvery, put } from 'redux-saga/effects';
import {
  REMOVE_COURSE_SUCCESS,
} from 'common/course/constants';
import { receivePageRelationships, removeEnrollment } from './actions';
import { RECEIVE_PAGE_DATA } from './constants';


function* storeRelationshipData({ pageSubState, res }) {
  const relationships = res[0]?.data[0]?.relationships;
  if (relationships) yield put(receivePageRelationships(pageSubState, relationships));
}


function* removeEnrollmentFromStore(...props) {
  yield put(removeEnrollment(...props));
}

function* pageSaga() {
  yield takeEvery(RECEIVE_PAGE_DATA, storeRelationshipData);
  yield takeEvery(REMOVE_COURSE_SUCCESS, removeEnrollmentFromStore);
}

export default pageSaga;
