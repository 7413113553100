import React, { PureComponent, Fragment } from 'react';
import {
  number, string, oneOfType, func, arrayOf, shape, bool,
} from 'prop-types';
import { arrayOfShapes } from 'types';
import cn from 'classnames';

import Swiper from 'react-id-swiper';
import { containerWidths } from 'config';
import { isObject, addDebugData } from 'helpers/utils';

import SliderButton from './SliderButton';

import sliderStyles from './Slider.vendor.module.scss';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Slider.module.scss';

const styles = stylesProxy(stylesObj, 'Slider');

const SLIDERS_PER_VIEW = 4;

const defaultGetItemKey = (item, indx) => (isObject(item) ? item?.uid : item) || indx;

class Slider extends PureComponent {
  state = {
    isBeginning: true,
    isEnd: false,
    loaded: false,
    loadedSlides: 5,
  }

  updateSwiper = (swiper) => { this.swiper = swiper; };

  getSwiper = () => this.swiper;

  goNext = () => {
    this.getSwiper()?.slideNext();
  }

  goPrev = () => {
    this.getSwiper()?.slidePrev();
  }

  loadOffscreenSlides = () => {
    const swiper = this.getSwiper();
    const slidesSize = swiper?.slides?.length;

    if (slidesSize > this.state.loadedSlides) {
      this.setState({
        loadedSlides: slidesSize,
      });
    }
  }

  onSlideChange = () => {
    const swiper = this.getSwiper();

    if (swiper) {
      this.setState({
        isBeginning: swiper?.isBeginning,
        isEnd: swiper?.isEnd,
      });

      this.loadOffscreenSlides();
    }
  }

  onInit = () => {
    this.setState({ loaded: true });
  }

  /* eslint-disable react/sort-comp */
  params = {
    containerClass: cn('swiper-container', styles.slider),
    on: {
      slideChange: this.onSlideChange,
      init: this.onInit,
      sliderMove: this.loadOffscreenSlides,
    },
    slidesPerView: SLIDERS_PER_VIEW,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slideVisibleClass: styles.visible,
    slidePrevClass: styles.prev,
    simulateTouch: false,
    spaceBetween: 24,
    freeMode: true,
    breakpoints: {
      [containerWidths.lg]: {
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 0,
        watchSlidesProgress: false,
        watchSlidesVisibility: false,
      },
    },
  }
  /* eslint-enable react/sort-comp */

  render() {
    const {
      className,
      items,
      itemClassName,
      prevAriaLabel,
      nextAriaLabel,
      renderItem,
      itemProps,
      hidePagination,
      itemAutoWidth,
      arrowClassName,
      containerClass: customContainerClass,
      ...restProps
    } = this.props;

    const {
      isBeginning,
      isEnd,
      loaded,
      loadedSlides,
    } = this.state;

    const {
      containerClass,
      ...restParams
    } = this.params;

    return (
      <div className={cn(styles.container, sliderStyles.container, className, { [styles.loaded]: loaded })}>
        <Swiper
          getSwiper={this.updateSwiper}
          containerClass={cn(containerClass, customContainerClass)}
          {...restParams}
          {...restProps}
        >
          {
            items.map((item, indx) => (
              <div key={item?.uid || item?.id || indx} className={cn(styles.item, { [styles['item--auto-width']]: itemAutoWidth }, itemClassName)}>
                {
                  renderItem({
                    ...itemProps,
                    disableLoading: indx > (loadedSlides - 1),
                    data: item,
                  }, indx)
                }
              </div>
            ))
          }
        </Swiper>

        {
          (items?.length > (this.props.slidesPerView || SLIDERS_PER_VIEW) && !hidePagination) && (
            <Fragment>
              <SliderButton dir="prev" aria-label={prevAriaLabel} disabled={isBeginning} onClick={this.goPrev} className={arrowClassName} />
              <SliderButton dir="next" aria-label={nextAriaLabel} disabled={isEnd} onClick={this.goNext} className={arrowClassName} />
            </Fragment>
          )
        }
      </div>
    );
  }
}

export const itemsPropTypes = oneOfType([arrayOfShapes(), arrayOf(oneOfType([number, string]))]);

Slider.propTypes = {
  items: itemsPropTypes,
  itemClassName: string,
  prevAriaLabel: string,
  nextAriaLabel: string,
  containerClass: string,
  renderItem: func.isRequired,
  getItemKey: func,
  itemProps: shape(),
  hidePagination: bool,
};

Slider.defaultProps = {
  items: [],
  getItemKey: defaultGetItemKey,
  // slidesPerPage,
  // mobileSlidesPerPage,
};

addDebugData(Slider);

export default Slider;
