import React from 'react';
import { oneOf, number } from 'prop-types';
import { getImage } from 'helpers/utils';
import cn from 'classnames';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './BCEmoji.module.scss';

const styles = stylesProxy(stylesObj, 'BCEmoji');

// TODO: sort items in alphabetical order
const emojiNames = [
  'biceps',
  'clock',
  'grimacing',
  'grinning-face-with-smiling-eyes',
  'hand-face',
  'mail',
  'party-face',
  'party',
  'postbox',
  'sunglasses',
  'thinking-face',
  'thumb-up',
  'timer',
  'waving-hand',
  'well-done',
  'writing-hand',
];

const BCEmoji = ({
  name,
  size,
  className,
  ...restProps
}) => (emojiNames.includes(name) ? (
  <img width={size} className={cn(styles.emoji, className)} src={getImage(`/emoji/${name}@2x.png`)} alt={`${name} emoji`} {...restProps} />
) : null);

BCEmoji.propTypes = {
  name: oneOf(emojiNames),
  size: number,
};

export default BCEmoji;
