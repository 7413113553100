import {
  email, required,
} from 'helpers/validationSchema';
import { object } from 'yup';

const schema = object().shape({
  email: required(email),
});

export default schema;
