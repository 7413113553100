import React, { Component, Fragment } from 'react';
import {
  string, oneOf, node, bool,
} from 'prop-types';
import cn from 'classnames';
import FormField from 'oc-core-components/src/FormField';
import config from 'config';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Checkbox.module.scss';

const styles = stylesProxy(stylesObj, 'Checkbox');

const {
  inputSizes: sizes,
} = config;

@FormField()

class Checkbox extends Component {
  render() {
    const {
      id,
      children,
      cssSize,
      error,
      textClassName,
      roundBorders,
      ...restProps
    } = this.props;

    return (
      <Fragment>
        <input
          id={id}
          type="checkbox"
          {...restProps}
        />
        {React.Children.count(children) > 0 && (
          <label className={cn(styles.label, textClassName)} htmlFor={id}>
            {children}
          </label>
        )}
      </Fragment>
    );
  }
}

Checkbox.propTypes = {
  id: string,
  children: node,
  textClassName: string,
  cssSize: oneOf(sizes),
  error: bool,
};

export default Checkbox;
