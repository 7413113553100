import React from 'react';
import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './SubjectModalWrapper.module.scss';

const styles = stylesProxy(stylesObj, 'SubjectModalWrapper');

export const ModalContainer = ({ className, ...restProps }) => <div className={cn(styles.container, className)} {...restProps} />;

export const ModalOverlay = ({ className, ...restProps }) => <div className={cn(styles.overlay, className)} {...restProps} />;

export const ModalClose = ({ className, ...restProps }) => (
  <Icon
    icon="feather:x"
    tag="button"
    role="button"
    aria-label="close popup"
    className={className}
    size={24}
    {...restProps}
  />
);

export const Modal = ({
  className,
  header,
  renderBefore,
  headerClassName,
  contentClassName,
  children,
  ...restProps
}) => (
  <div className={cn(styles.inner, className)} {...restProps}>
    {
      renderBefore && renderBefore()
    }
    {
      header && (
        <div className={cn(styles.header, headerClassName)}>
          {header}
        </div>
      )
    }

    <div className={cn(styles.content, contentClassName)}>
      {children}
    </div>
  </div>
);


export default {};
