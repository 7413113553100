import { createSelector } from 'reselect';
import omitBy from 'lodash/omitBy';
import { isObject } from 'helpers/utils';
import { ROUTES_BASE, ROUTES } from 'helpers/constants';
import urljoin from 'url-join';
import createCachedSelector from 're-reselect';

import { createItem } from 'oc-core-components/src/Autocomplete/utils';
import {
  selectV2ModelByIdProp,
  getV2PageSub,
} from 'v2/redux/selectors';
import { getV2CurrentUserId } from 'v2/redux/meta/selectors';


import { getV2PageProps, pagePropsSelectorFactory } from 'v2/redux/page/selectors';

export const selectV2TopicIdProp = (state, { topicId }) => topicId;

export const selectV2isOwnQuestionByIdProp = createSelector(
  [
    selectV2ModelByIdProp.question,
    getV2CurrentUserId,
  ],
  (question, currentUserId) => {
    const qUserId = question?.userId;

    return (qUserId && currentUserId && (parseInt(currentUserId, 10) === parseInt(qUserId, 10)));
  },
);

export const selectV2isOwnAnswerByIdProp = createSelector(
  [
    selectV2ModelByIdProp.hwAnswer,
    getV2CurrentUserId,
  ],
  (answer, currentUserId) => {
    const aUserId = answer?.answerer?.id;

    return (aUserId && currentUserId && (parseInt(currentUserId, 10) === parseInt(aUserId, 10)));
  },
);

export const selectV2LimitedPreviewData = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.limitedPreviewData,
);


export const selectV2PaywallType = createSelector(
  [selectV2LimitedPreviewData],
  limitedPreviewData => limitedPreviewData?.paywallType,
);

export const getUnlocksRemaining = createSelector(
  [selectV2LimitedPreviewData],
  limitedPreviewData => limitedPreviewData?.unlocksRemaining,
);

// export const selectV2AllTopics = pagePropsSelectorFactory('allTopics', true);
export const selectV2AllTopics = pagePropsSelectorFactory('allTopics', true);

export const sortTopics = (topics) => {
  try {
    return (Array.isArray(topics) ? topics.sort((a, b) => {
      if (a.handle === 'all') return -1;
      if (b.handle === 'all') return 1;
      return a.name.localeCompare(b.name);
    }) : topics);
  } catch (e) {
    console.error(e);
    return topics;
  }
};

export const selectV2UniqueTopics = createCachedSelector(
  [
    selectV2AllTopics,
    (state, config) => config,
  ],
  (allTopics, config) => {
    try {
      if (!allTopics || !isObject(allTopics)) {
        return undefined;
      }

      const res = omitBy(allTopics, topic => topic?.handle === 'all');

      return config?.asArray ? sortTopics(Object.values(res)) : res;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
)((_state, config) => config?.asArray ? 'asArray' : '_default');

export const selectV2FallbackSearchTopics = createSelector(
  [
    state => selectV2UniqueTopics(state, { asArray: true }),
  ],
  topics => topics?.map(t => ({
    label: t?.name,
    value: t?.id,
    count: t?.count,
  })),
);

const topicsConfig = { asArray: true };

export const selectV2FollowedTopicsArray = createSelector(
  [state => selectV2UniqueTopics(state, topicsConfig)],
  topics => topics?.filter(topic => topic.followed),
);

export const selectV2HasEnoughFollowedTopics = createSelector(
  [selectV2FollowedTopicsArray],
  topics => topics?.length >= 3,
);

export const selectV2TopicsArray = createSelector(
  [selectV2AllTopics],
  allTopics => (isObject(allTopics) ? sortTopics(Object.values(allTopics)) : undefined),
);

export const selectV2TextbookTopicsArray = createSelector(
  [selectV2TopicsArray],
  allTopics => allTopics?.filter(({ handle, hasTextbook }) => hasTextbook || handle === 'all')?.map(({ handle, ...rest }) => {
    const newUrl = handle === 'all' ? ROUTES.textbooks : urljoin(ROUTES_BASE.textbooks, `${handle}.en.html`);

    return {
      ...rest,
      handle,
      url: newUrl,
    };
  }),
);

export const selectV2TopicByIdProp = createSelector(
  [selectV2TopicsArray, selectV2TopicIdProp],
  (allTopics, topicId) => {
    return allTopics?.find(t => `${t?.id}` === `${topicId}`);
  },
);

// autocomplete list
export const selectV2TopicsOptionsArray = createSelector(
  [selectV2UniqueTopics],
  topics => (isObject(topics) ? sortTopics(Object.values(topics))?.map(({ id, name }) => createItem(name, id)) : undefined),
);

export const selectV2ParentPaymentRequest = pagePropsSelectorFactory('parentPaymentRequest', true);
export const selectV2ParentVisitUrl = pagePropsSelectorFactory('parentVisitUrl', true);
export const selectV2TopicDescription = pagePropsSelectorFactory('topicDescription', true);
export const selectV2ShowVerificationSection = pagePropsSelectorFactory('showVerificationSection', true);
export const selectV2LastQuestionUrl = pagePropsSelectorFactory('lastQuestionUrl', true);
export const selectV2QuestionAnnotation = pagePropsSelectorFactory('annotation', true);
export const selectV2AnswersCount = pagePropsSelectorFactory('answersCount', true);

export const selectV2QuestionCreditData = pagePropsSelectorFactory('questionCreditData', true);

export const selectV2PurchasedQuestionCreditCount = createSelector(
  selectV2QuestionCreditData,
  data => data?.availableAskQuestionCreditCount,
);

export const selectV2HasFreeQuestionCredit = createSelector(
  selectV2QuestionCreditData,
  data => data?.hasFreeAskQuestionCredit,
);

export const selectV2HasQuestionCredit = createSelector(
  [
    selectV2PurchasedQuestionCreditCount,
    selectV2HasFreeQuestionCredit,
  ],
  (purchasedQuestionCreditCount, hasFreeQuestionCredit) => (purchasedQuestionCreditCount > 0 || hasFreeQuestionCredit),
);
export const selectV2AvailableQuestionCreditCount = createSelector(
  [
    selectV2PurchasedQuestionCreditCount,
    selectV2HasFreeQuestionCredit,
  ],
  (purchasedQuestionCreditCount, hasFreeQuestionCredit) => (purchasedQuestionCreditCount + hasFreeQuestionCredit),
);
export const selectV2DraftQuestionData = pagePropsSelectorFactory('draftQuestionData', true);
export const selectV2RelatedSkill = pagePropsSelectorFactory('relatedSkill', true);
export const selectV2RelatedBoosterClass = pagePropsSelectorFactory('relatedBoosterClass', true);

export const selectV2LivestreamAd = pagePropsSelectorFactory('livestreamAd', true);

export const selectV2DraftQuestionFormData = createSelector(
  [selectV2DraftQuestionData, selectV2TopicsOptionsArray],
  (draftQuestionData, topicsList) => {
    if (!draftQuestionData || !topicsList) return undefined;
    const {
      topicId,
      gradeLevel,
      bodyHtml,
      attachmentsFilenames,
    } = draftQuestionData;

    return {
      topic_id: topicsList.find(({ value }) => value === topicId),
      body_html: bodyHtml,
      filenames: attachmentsFilenames || [],
    };
  },
);

export const selectV2AllAnswersCount = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.allAnswers || {},
);

export const selectV2VerifiedAnswersCount = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.verifiedAnswers || {},
);

export const selectV2UnlockedCount = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.unlocked || 0,
);

export const selectV2WatchedCount = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.watched || 0,
);

export const selectV2YourProgress = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.yourProgress || {},
);

// "Your Question" page topic list
export const selectV2Subjects = createSelector(
  [getV2PageProps],
  pageProps => pageProps?.subjects || [],
);

export const selectV2ProfileLikesTopics = createSelector(
  [
    selectV2Subjects,
    state => selectV2UniqueTopics(state, topicsConfig),
  ],
  (requiredTopics, allTopics) => {
    const normalizedAllTopics = allTopics?.reduce((acc, t) => {
      const obj = ({
        id: t?.id,
        name: t?.name,
        value: t?.id,
        count: t?.count,
      });

      acc[t?.id] = obj;
      return acc;
    }, {});

    return requiredTopics.map(({ id, count }) => ({ ...normalizedAllTopics[`${id}`], count }));
  },
);

export const selectV2ScholarshipOwlPopup = pagePropsSelectorFactory('scholarshipOwlPopup', true);

export const getV2TempAnswerDraft = state => getV2PageSub(state).tempAnswerDraft;

export const selectV2NextPrevProblem = pagePropsSelectorFactory('nextPrevProblem', true);
export const selectV2ChaptersList = pagePropsSelectorFactory('chaptersList', true);
export const selectV2SectionsMap = pagePropsSelectorFactory('sectionsList', true);
export const selectV2ProblemsMap = pagePropsSelectorFactory('problemsList', true);
export const selectV2AllBookEditionIds = pagePropsSelectorFactory('allBookEditionIds', true);
export const selectV2SimilarProblems = pagePropsSelectorFactory('similarProblems', true);
export const selectV2WasLiked = pagePropsSelectorFactory('wasLiked');
export const selectV2AnswerTabsCounters = pagePropsSelectorFactory('tabsCounters');

export default {};
