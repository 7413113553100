import React from 'react';
import { useSelector } from 'react-redux';
import { selectV2BackBtnUrl } from 'shared/v2/redux/page/selectors';
import BackButton from 'oc_components/src/pages/Auth/BackButton';

const BackButtonContainer = (props) => {
  const backButtonLink = useSelector(selectV2BackBtnUrl);

  return <BackButton href={backButtonLink} {...props} />;
};

BackButtonContainer.propTypes = {};

export default BackButtonContainer;
