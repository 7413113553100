import { handleModelActions } from 'v2/redux/utils/handleActions';
// import { logErrorDev } from 'helpers/utils';
// import types from './types';

import { BOOSTER_CLASS_THEORY } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const boosterClassTheoryReducer = handleModelActions(
  BOOSTER_CLASS_THEORY,
  {

  },
  initialState,
);

export { initialState };

export default boosterClassTheoryReducer;
