import merge from 'lodash/merge';
import mergeable from 'redux-merge-reducers';

import { camelizeKeys } from 'helpers/utils';
import {
  LOAD_MORE,
  LOAD_MORE_SUCCESS,
  LOAD_MORE_ERROR,
} from 'common/document/constants';
import {
  RECEIVE_PAGE_META,
  RECEIVE_PAGE_LIST,
  RECEIVE_PAGE_PROPS,
  MERGE_PAGE_LISTS,
  RECEIVE_PAGE_RELATIONSHIPS,
  GET_PAGE_DATA,
  GET_PAGE_DATA_SUCCESS,
  GET_PAGE_DATA_ERROR,
  RESET_LOADING_PAGE_DATA_ERROR,
  RECEIVE_SEARCH_QUERY,
  GET_DATA_BY_SECTION,
  GET_DATA_BY_SECTION_SUCCESS,
  GET_DATA_BY_SECTION_ERROR,
  REMOVE_ENROLLMENT,

  START_PAGE_SECTIONS_LOADING,
  STOP_PAGE_SECTIONS_LOADING,
  CLEAR_PAGE_SECTIONS,
  SET_PAGE_SECTIONS_TO_LOAD,
} from './constants';

const initialPageState = {
  meta: {
    userAttrs: {},
    studyResourcesDropdown: {},
    notesDropdown: {},
  },
  currentPage: '',
  loadingDataBySection: [],
  loadingPageData: false,
  loadingPageDataError: false,
  loadingPageList: false,

  loadingPageSections: false,
  pageSectionsToLoad: [],
};

const pageReducer = (state = initialPageState, action) => {
  switch (action.type) {
    case RECEIVE_PAGE_META:
      return Object.assign({}, state, {
        meta: action.res,
      });
    case RECEIVE_PAGE_LIST: {
      // ENSURE WE HAVE REQUIRED PARAMS & PAYLOAD
      const { params, res } = action;
      if (!params || !res) return state;
      const { listUid, pageSubState, override } = params;
      const {
        uid, viewAll, data, section_type: sectionType,
      } = res;
      if (!listUid || !pageSubState || !uid || !data) return state;
      // RETRIEVE NEW ITEM FROM PAYLOAD
      const actionItem = { uid, data, sectionType };
      if (viewAll) actionItem.viewAll = viewAll;
      const newListIds = data?.ids || [];

      // MERGE LIST ITEM DATA IDS AND REPLACE LIST ITEM IN LISTS ARRAY
      const pageProps = state[pageSubState]?.props;
      const pageLists = pageProps?.pageLists ? [...pageProps?.pageLists] : [];
      const currentItem = pageLists.find(list => list.uid === listUid);
      const currentListIds = currentItem?.data?.ids || [];
      actionItem.data.ids = override ? newListIds : [...currentListIds, ...newListIds];

      if (currentItem) {
        const itemIndex = pageLists.findIndex(list => list.uid === params.listUid);
        pageLists[itemIndex] = actionItem;
      }

      return Object.assign({}, state, {
        [pageSubState]: {
          ...state[pageSubState],
          props: {
            ...state[pageSubState].props,
            pageLists: camelizeKeys(pageLists),
          },
        },
      });
    }
    case RECEIVE_PAGE_PROPS:
      return Object.assign({}, state, {
        [action.pageSubState]: {
          props: camelizeKeys(action.res),
        },
      });
    case MERGE_PAGE_LISTS: {
      // Only replace items with modified ones, dont change list length/order
      let pageLists = state[action.pageSubState]?.props?.pageLists || [];
      pageLists = [...pageLists];
      let listChanges = action?.res?.pageLists || [];
      listChanges = [...camelizeKeys(listChanges)];

      // Go through current page lists and find modified list items
      pageLists.forEach(({ uid }, listKey) => {
        const modifiedItem = listChanges.find(l => l.uid === uid);
        // Replace old item with modified list item
        if (modifiedItem) {
          // Ensure lazyLoad is false for new list items
          pageLists[listKey] = Object.assign({}, { ...modifiedItem, lazyLoad: false });
        }
      });

      return merge({}, state, {
        [action.pageSubState]: {
          props: {
            pageLists,
          },
        },
      });
    }
    case RECEIVE_PAGE_RELATIONSHIPS:
      return merge({}, state, {
        [action.pageSubState]: {
          relationships: action.res,
        },
      });
    case GET_PAGE_DATA:
      return Object.assign({}, state, {
        loadingPageData: true,
      });
    case GET_PAGE_DATA_SUCCESS:
      return Object.assign({}, state, {
        loadingPageData: false,
        // meta: action.res.meta,
      });
    case GET_PAGE_DATA_ERROR:
      return Object.assign({}, state, {
        loadingPageData: false,
        loadingPageDataError: {
          errorMessage: action.res,
          params: action.params,
        },
      });
    case GET_DATA_BY_SECTION: {
      const stateChanges = {};
      const { sections } = action.params;
      if (sections) {
        stateChanges.loadingDataBySection = sections;
      }
      return Object.assign({}, state, stateChanges);
    }
    case GET_DATA_BY_SECTION_SUCCESS: {
      const stateChanges = {};
      const { sections } = action.params;
      if (sections) stateChanges.loadingDataBySection = [];
      return Object.assign({}, state, stateChanges);
    }
    case GET_DATA_BY_SECTION_ERROR: {
      const stateChanges = {};
      const { sections } = action.params;
      if (sections) stateChanges.loadingDataBySection = [];
      return Object.assign({}, state, stateChanges);
    }
    case RESET_LOADING_PAGE_DATA_ERROR:
      return Object.assign({}, state, {
        loadingPageDataError: false,
      });
    case LOAD_MORE:
      return Object.assign({}, state, {
        loadingPageList: {
          [action.params.listUid]: true,
        },
      });
    case LOAD_MORE_SUCCESS:
      return Object.assign({}, state, {
        loadingPageList: false,
      });
    case LOAD_MORE_ERROR:
      return Object.assign({}, state, {
        loadingPageList: false,
      });
    case RECEIVE_SEARCH_QUERY:
      return merge({}, state, {
        meta: {
          query: action.query,
        },
      });
    case REMOVE_ENROLLMENT: {
      const {
        res: {
          payload: {
            pageId,
            courseId,
          } = {},
        } = {},
      } = action;
      let newEnrollments = [...state[pageId]?.props?.enrollments];

      if (newEnrollments) {
        newEnrollments = newEnrollments.filter(({ courseId: id }) => id !== courseId);
      }

      return {
        ...state,
        [pageId]: {
          props: {
            // ...state[pageId]?.props,
            enrollments: newEnrollments,
          },
        },
      };
    }

    case START_PAGE_SECTIONS_LOADING:
      return Object.assign({}, state, {
        loadingPageSections: true,
      });
    case STOP_PAGE_SECTIONS_LOADING:
      return Object.assign({}, state, {
        loadingPageSections: false,
      });
    case CLEAR_PAGE_SECTIONS:
      return Object.assign({}, state, {
        pageSectionsToLoad: [],
      });
    case SET_PAGE_SECTIONS_TO_LOAD:
      return Object.assign({}, state, {
        pageSectionsToLoad: action.payload,
      });
    default:
      return state;
  }
};

export { initialPageState };
export default mergeable(pageReducer);
