import React from 'react';
import { number, bool } from 'prop-types';
import cn from 'classnames';
import LazyImage from 'oc-core-components/src/LazyImage';
import { isNil } from 'helpers/utils';
import getImagePropsFactory from 'helpers/getImageProps';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './AmazonGiftCard.module.scss';

const styles = stylesProxy(stylesObj, 'AmazonGiftCard');

const getImageProps = getImagePropsFactory('/amazon_card/');

const imageProps = getImageProps({
  alt: 'amazon gift card',
  defaultExt: 'png',
  sourcesExt: 'webp',
  filename: 'amazon_card',
  allowedSizes: ['1x', '2x'],
});

const AmazonGiftCard = ({
  sideShadow,
  percentComplete,
  className,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const percents = (percentComplete < 0) ? 0 : (percentComplete > 100 ? 100 : percentComplete);

  return (
    <div className={cn(styles.container, { [styles.side]: sideShadow }, className)}>

      <div className={styles.inner}>
        <LazyImage
          className={styles.img}
          disableLazy
          responsiveWidth
          {...imageProps}
        />

        {
            !isNil(percentComplete) && (
              <div
                className={
                  cn(styles.overlay,
                    {
                      [styles['overlay--full']]: percents < 2,
                    })
                  }
                style={{ width: `${100 - percents}%` }}
              />
            )
          }
      </div>

    </div>
  );
};


AmazonGiftCard.propTypes = {
  sideShadow: bool,
  percentCompleted: number,
};

export default AmazonGiftCard;
