const RECEIVE_DOCUMENT_DATA = 'common/document/RECEIVE_DOCUMENT_DATA';
const POST_REPORT_DOCUMENT = 'common/document/POST_REPORT_DOCUMENT';
const POST_REPORT_DOCUMENT_SUCCESS = 'common/document/POST_REPORT_DOCUMENT_SUCCESS';
const POST_REPORT_DOCUMENT_ERROR = 'common/document/POST_REPORT_DOCUMENT_ERROR';
const RESET_POST_REPORT_DOCUMENT_ERROR = 'common/document/RESET_POST_REPORT_DOCUMENT_ERROR';
const POST_REQUEST_NOTES = 'common/document/POST_REQUEST_NOTES';
const POST_REQUEST_NOTES_SUCCESS = 'common/document/POST_REQUEST_NOTES_SUCCESS';
const POST_REQUEST_NOTES_ERROR = 'common/document/POST_REQUEST_NOTES_ERROR';
const RESET_POST_REQUEST_NOTES_ERROR = 'common/document/RESET_POST_REQUEST_NOTES_ERROR';
const POST_VOTE = 'common/document/POST_VOTE';
const POST_VOTE_SUCCESS = 'common/document/POST_VOTE_SUCCESS';
const POST_VOTE_ERROR = 'common/document/POST_VOTE_ERROR';
const POST_NOTES_REQUEST = 'common/document/POST_NOTES_REQUEST';
const POST_NOTES_REQUEST_ERROR = 'common/document/POST_NOTES_REQUEST_ERROR';
const POST_NOTES_REQUEST_SUCCESS = 'common/document/POST_NOTES_REQUEST_SUCCESS';
const GET_REAL_DOCUMENT_VIEW = 'common/document/GET_REAL_DOCUMENT_VIEW';
const GET_REAL_DOCUMENT_VIEW_SUCCESS = 'common/document/GET_REAL_DOCUMENT_VIEW_SUCCESS';
const GET_REAL_DOCUMENT_VIEW_ERROR = 'common/document/GET_REAL_DOCUMENT_VIEW_ERROR';
const GET_PREV_NEXT_DATA = 'common/document/GET_PREV_NEXT_DATA';
const GET_PREV_NEXT_DATA_SUCCESS = 'common/document/GET_PREV_NEXT_DATA_SUCCESS';
const GET_PREV_NEXT_DATA_ERROR = 'common/document/GET_PREV_NEXT_DATA_ERROR';
const LOAD_MORE = 'common/document/LOAD_MORE';
const LOAD_MORE_SUCCESS = 'common/document/LOAD_MORE_SUCCESS';
const LOAD_MORE_ERROR = 'common/document/LOAD_MORE_ERROR';
const HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON = 'common/document/HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON';
const HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF = 'common/document/HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF';

export {
  RECEIVE_DOCUMENT_DATA,
  POST_REPORT_DOCUMENT,
  POST_REPORT_DOCUMENT_SUCCESS,
  POST_REPORT_DOCUMENT_ERROR,
  RESET_POST_REPORT_DOCUMENT_ERROR,
  POST_REQUEST_NOTES,
  POST_REQUEST_NOTES_SUCCESS,
  POST_REQUEST_NOTES_ERROR,
  RESET_POST_REQUEST_NOTES_ERROR,
  POST_VOTE,
  POST_VOTE_SUCCESS,
  POST_VOTE_ERROR,
  POST_NOTES_REQUEST,
  POST_NOTES_REQUEST_ERROR,
  POST_NOTES_REQUEST_SUCCESS,
  GET_REAL_DOCUMENT_VIEW,
  GET_REAL_DOCUMENT_VIEW_SUCCESS,
  GET_REAL_DOCUMENT_VIEW_ERROR,
  GET_PREV_NEXT_DATA,
  GET_PREV_NEXT_DATA_SUCCESS,
  GET_PREV_NEXT_DATA_ERROR,
  HIGHLIGHT_UNLOCK_DOCUMENT_FORM_ON,
  HIGHLIGHT_UNLOCK_DOCUMENT_FORM_OFF,
  LOAD_MORE,
  LOAD_MORE_SUCCESS,
  LOAD_MORE_ERROR,
};
