import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { getUserProfileLink } from 'common/user/utils';
import { CSSTransition } from 'react-transition-group';
import {
  ASK_QUESTION_PARAM,
  ENFORCE_HTML_PARAM,
  ROUTES,
} from 'helpers/constants';
import { renderNode, isFunction } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';
import { stylesProxy } from 'helpers/css';

import withAmpStyles from 'helpers/withAmpStyles';
import { useAmp } from 'next/amp';
import Col from 'oc-core-components/src/Col';
import Container from 'oc-core-components/src/Container';
import Icon from 'oc-core-components/src/Icon';
import SearchBannerContainer from 'oc_components/src/pages/SearchNew/SearchBannerContainer';
import { useMobile, useTablet } from 'oc-core-components/src/MediaQuery';
import Row from 'oc-core-components/src/Row';
import NavbarSearchButton from 'oc-core-components/src/components/NavbarV2/NavbarSearchButton';
import NavbarMobileSearchButton from 'oc-core-components/src/components/NavbarV2/NavbarMobileSearchButton';
import Dropdown from 'oc-core-components/src/Dropdown';
import { useRouter } from 'oc-core-components/src/RouterProvider';
import {
  getV2CurrentUser,
  getV2TotalQuestionsCount,
  getV2WillEarnPoints,
  getV2RecentSearches,
} from 'v2/redux/meta/selectors';
// import Tooltip from 'oc-core-components/src/TooltipPopper';

import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, {
  Fragment,
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import { useSelector } from 'react-redux';

import StudyModeOverlayContainer from 'hw_components/src/tests/StudyModeOverlay';
import useAskQuestionModal, {
  usePreloadAskQuestionModal,
} from 'helpers/hooks/useAskQuestionModal';
import urljoin from 'url-join';
import Link from 'oc-core-components/src/Link';
import useNavbarSearch from 'shared/helpers/hooks/useNavbarSearch';
import BackButtonContainer from 'oc_components/src/pages/Auth/BackButtonContainer';

import usePreloadSignupDiscountPopup from 'shared/helpers/hooks/usePreloadSignupDiscountPopup';
// import usePreloadSummerChallengePopup from 'shared/helpers/hooks/usePreloadSummerChallengePopup';
import usePreloadPartnershipPopup from 'shared/helpers/hooks/usePreloadPartnershipPopup';
import usePreloadNotificationMsg from 'shared/helpers/hooks/usePreloadNotificationMsg';
import usePreloadInviteSignupPopup from 'shared/helpers/hooks/usePreloadInviteSignupPopup';
import usePreloadBranchIOPopup from 'shared/helpers/hooks/usePreloadBranchIOPopup';
// import usePreloadCourseRequestPopup from 'shared/helpers/hooks/usePreloadCourseRequestPopup';
// import useNewProfileAnnouncement from 'shared/helpers/hooks/useNewProfileAnnouncement';
import {
  MENU_ITEMS_CREATE,
  MENU_ITEMS_EXPERIENCE_CHANGER,
  MENU_ITEMS_PRICING,
  MENU_ITEMS_SETTINGS,
  NavbarMenuIds,
  NavbarTypes,
} from './constants';
import LivestreamDot from './LivestreamDot';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';
import NavbarMenu from './NavbarMenu';
// import NewProfileAnnouncement from './NewProfileAnnouncement';
import NavbarDropdownV2 from './components/NavbarDropdownV2';
import NavbarMenuV2 from './components/NavbarMenuV2';

import NotificationsListContainer from './NotificationsListContainer';

import NavbarSearchOverlay from './NavbarSearch/NavbarSearchOverlay';
import SideNavigation from './SideNavigation';
import UserAvatar from './UserAvatar';
import NavbarRevenueFooter from './NavbarRevenueFooter';
import NavbarSearchTabs from '../NavbarV2/NavbarSearchTabs/NavbarSearchTabs';
import NavbarProductChangeMenu from '../NavbarV2/NavbarProductChangeMenu/NavbarProductChangeMenu';

const s = stylesProxy(stylesObj, 'Navbar');

const renderNotifications = () => <NotificationsListContainer />;

const SMALL_BLOCK_TRANSITION_TIMEOUT = 300;
const BANNER_TRANSITION_TIMEOUT = 400;

const Navbar = ({
  afterContent,
  beforeContent,
  buttonLoginClassName,
  buttonSignupClassName,
  className,
  loading,
  loginUrl,
  renderRightContent,
  renderStudyMode,
  searchId,
  showMobileSearch,
  signupUrl,
  streamActive,
  styles,
  type,
  unreadNotificationsCount,
  userAvatar,
  userDisplayName,
  userId,
  userNickname,
  hideYourQuestions,
  hideMyCourses,
  fluid,
  hideSearch,
  pricingMenuProps,
  searchUrlParams,
  renderBottom,
  containerClassName,
  defaultLinkClick,
  hideFirstRow: customHideFirstRow,
  isMobileSearchOnly = false,
  showBorderIfSearchOnly = false,
  showBackButton = false,
}) => {
  const recentSearches = useSelector(getV2RecentSearches);
  const hasRecentSearches = recentSearches?.length >= 1;
  const { current: tabsTransitionProps } = useRef({
    enter: styles.a__enter,
    enterActive: styles['a__enter-active'],
    exit: styles.a__exit,
  });
  const { current: searchBtnTransitionProps } = useRef({
    enter: styles.a__enter,
    enterActive: styles['a__enter-active'],
    exit: styles.a__exit,
  });
  const { current: bannerTransitionProps } = useRef({
    enter: styles['a__enter-2'],
    enterActive: styles['a__enter-active-2'],
    exit: hasRecentSearches ? styles['a__exit-2'] : styles['a__exit-3'],
    exitActive: hasRecentSearches ? styles['a__exit-active-2'] : styles['a__exit-active-3'],
  });
  const isAmp = useAmp();
  const askQuestionModal = useAskQuestionModal();
  const currentUser = useSelector(getV2CurrentUser) || {};
  const profileLink = getUserProfileLink(currentUser?.id);
  const {
    isSearchButtonHidden,
    isSearchDropdownOpen,
    closeSearchDropdown,
    openSearchDropdown,

    setActiveNavbarSearchTabId,
    activeNavbarSearchTab,
    activeNavbarSearchTabId,
    activeProductCategory,
    navbarSearchTabs,
  } = useNavbarSearch();

  const { router: { asPath } = {} } = useRouter();
  const [isMenuCreateOpen, setIsMenuCreateOpen] = useState(false);
  const [
    isMenuExperienceChangerOpen,
    setIsMenuExperienceChangerOpen,
  ] = useState(false);
  const [isMenuNotificationsOpen, setIsMenuNotificationsOpen] = useState(false);
  const [isMenuPricingOpen, setIsMenuPricingOpen] = useState(false);
  const [isMenuSettingsOpen, setIsMenuSettingsOpen] = useState(false);
  const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false);
  const totalQuestionsCount = useSelector(getV2TotalQuestionsCount);
  const willEarnPoints = useSelector(getV2WillEarnPoints);
  const { t } = useTranslation(['navbar', 'search']);
  const isLoggedIn = !!userId;
  const isMobile = useMobile();
  const isTablet = useTablet();

  const isMobileView = (isLoggedIn && isMobile) || (!isLoggedIn && isTablet);
  const hideFirstRow = typeof customHideFirstRow !== 'undefined' ? customHideFirstRow : (isMobileView && isLoggedIn);
  // const hideFirstRow = false;
  const profileUrl = getUserProfileLink(userId, userNickname);

  const iconMenu = renderStudyMode ? [
    {
      id: 'home',
      url: ROUTES.dashboard,
      iconProps: {
        icon: 'oc:home',
      },
      activeIconProps: {
        icon: 'oc:home-filled',
        stroke: 'none',
      },
      label: 'Home',
      tooltip: 'Home',
    },
    {
      id: 'booster_classes',
      url: ROUTES.boosterClass,
      startsWith: '/booster-classes',
      iconProps: {
        icon: 'oc:bc',
        stroke: 'none',
      },
      activeIconProps: {
        icon: 'oc:bc',
        stroke: 'none',
      },
      label: 'Booster Classes',
      tooltip: 'Booster Classes',
    },
  ] : [
    {
      id: 'home',
      url: ROUTES.dashboard,
      iconProps: {
        icon: 'oc:home',
      },
      activeIconProps: {
        icon: 'oc:home-filled',
        stroke: 'none',
      },
      label: 'Home',
      tooltip: 'Home',
    },
    {
      id: 'answers',
      url: ROUTES.hwAnswers,
      iconProps: {
        icon: 'oc:conversation',
        stroke: 'none',
      },
      activeIconProps: {
        icon: 'oc:conversation-solid',
        stroke: 'none',
      },
      label: 'Answer',
      tooltip: 'Answer',
    },

    {
      id: 'study_resources',
      url: profileUrl ? buildUrl(profileUrl, { upa: 'questions', status: 'all' }) : undefined,
      iconProps: {
        icon: 'oc:tn',
        stroke: 'none',
      },
      activeIconProps: {
        icon: 'oc:tb-solid',
        stroke: 'none',
      },
      label: 'Study resources',
      tooltip: 'Study resources',
      experienceChanger: true,
    },
  ];


  usePreloadAskQuestionModal({
    callback: isAmp ? undefined : askQuestionModal,
  });
  usePreloadSignupDiscountPopup({ enabled: true });
  // usePreloadSummerChallengePopup({ enabled: false });
  usePreloadPartnershipPopup({ enabled: true, className: styles.partnership });
  usePreloadNotificationMsg({ enabled: true });
  usePreloadInviteSignupPopup({ enabled: true });
  usePreloadBranchIOPopup({ enabled: true });
  // usePreloadCourseRequestPopup({ enabled: true });


  const askQuestionUrl = isAmp
    ? urljoin(
      asPath,
      `?${ENFORCE_HTML_PARAM}=true`,
      `&${ASK_QUESTION_PARAM}=true`,
    )
    : undefined;

  const menuItemsExperienceChanger = useMemo(
    () => MENU_ITEMS_EXPERIENCE_CHANGER(isLoggedIn),
    [isLoggedIn],
  );

  const menuItemsCreateChanger = useMemo(
    () => MENU_ITEMS_CREATE({ askQuestionUrl, isLoggedIn, willEarnPoints }),
    [askQuestionUrl, isLoggedIn, willEarnPoints],
  );

  // const { isProfileAnnouncementOpen, onProfileAnnouncementClose } = useNewProfileAnnouncement();

  useEffect(() => {
    if (isMobileView) {
      setIsMenuExperienceChangerOpen(false);
    } else {
      setIsSideNavigationOpen(false);
    }
  }, [isMobileView]);

  const handleExperienceChangerOpen = useCallback(() => {
    if (isMobileView) {
      setIsSideNavigationOpen(true);
    } else {
      setIsMenuExperienceChangerOpen(true);
      // setIsMenuExperienceChangerOpen(prevState => !prevState);
    }
  }, [isMobileView]);

  // const isMobileSearchOnly = isLoggedIn && (showMobileSearch || type === NavbarTypes.search);

  const onMenuCreateItemClick = useCallback(
    (e, {
      addCourse, addQuestion, kind, source,
    } = {}) => {
      setIsMenuCreateOpen(false);

      if (addQuestion && !isAmp) {
        askQuestionModal();
      }

      if (defaultLinkClick) {
        defaultLinkClick(e);
      }
      // if (!isLoggedIn) {
      //   return;
      // }

      // if (addCourse && kind) {
      //   modal.inline({
      //     content: <AddDocumentModalContent documentKind={kind} />,
      //     wrapperProps: addDocumentWrapperProps,
      //   });
      // }
    },
    [askQuestionModal, isAmp, defaultLinkClick],
  );

  const navbarComponent = (
    <header
      className={cn(
        styles['navbar-container'],
        isMobileSearchOnly && !showBorderIfSearchOnly && styles['navbar-container--search'],
        className,
        !isLoggedIn && styles.loggedout,
        {
          [styles['navbar-container--active-desktop-search']]: isSearchDropdownOpen,
        },
      )}
    >
      {
        !hideFirstRow && (
          <Container className={cn(styles.navbar, containerClassName)} fluid={fluid}>
            <Row noGutters className="u-w-100 u-align-items-center">
              {
                isLoggedIn && renderStudyMode && isFunction(renderStudyMode) && (
                  <StudyModeOverlayContainer />
                )
              }
              <Col lg={((!isLoggedIn && !isSearchDropdownOpen)) ? 'auto' : undefined}>
                <Row noGutters className="u-align-items-center u-flex-nowrap">
                  {
                    isMobileView && (
                      <div
                        className={cn(
                          styles['navbar__menu-parent'],
                          styles['navbar__menu-parent--experience-changer'],
                          (isMobileSearchOnly) && styles['desktop-only'],
                        )}
                      >
                        <NavbarMenu
                          active={isMenuExperienceChangerOpen}
                          count={totalQuestionsCount}
                          id={NavbarMenuIds.experienceChanger}
                          items={menuItemsExperienceChanger}
                          label={t('navbar:navbar.labelSwitchTo')}
                          onClickOutside={() => setIsMenuExperienceChangerOpen(false)}
                          onToggle={handleExperienceChangerOpen}
                          streamActive={streamActive}
                          toggleIcon="sf:experience-changer"
                          toggleIconStroke="none"
                          title={t('navbar:experience_toggle')}
                          onItemClick={defaultLinkClick}
                        />
                      </div>
                    )
                  }

                  {
                    isMobileView && isMobileSearchOnly && showBackButton && (
                      <BackButtonContainer />
                    )
                  }

                  <a
                    className={cn(
                      styles.navbar__link,
                      styles['navbar__link--logo'],
                      (isMobileSearchOnly) && styles['desktop-only'],
                    )}
                    onClick={defaultLinkClick}
                    // href={NavbarLinks[type]}
                    href={ROUTES.root}
                  >
                    <Icon
                      svgClassName={styles['navbar__logo-large']}
                      icon="logo:big"
                      size={120}
                      stroke="none"
                    />
                    {/* {[NavbarTypes.default, NavbarTypes.search].includes(type) ? (
                      <Icon
                        svgClassName={styles['navbar__logo-large']}
                        icon="logo:big"
                        size={120}
                        stroke="none"
                      />
                    ) : (
                      <Icon
                        svgClassName={styles['navbar__logo-small']}
                        icon="logo:small"
                        size={30}
                        stroke="none"
                      />
                    )} */}

                    {/* {![NavbarTypes.default, NavbarTypes.search].includes(type)
                      && t(`navbar:navbar.titles.${type}`)} */}
                    {type === NavbarTypes.livestreams && (
                      <LivestreamDot streamActive={streamActive} />
                    )}
                  </a>

                  <CSSTransition
                    in={isLoggedIn && !isSearchDropdownOpen}
                    timeout={SMALL_BLOCK_TRANSITION_TIMEOUT}
                    classNames={tabsTransitionProps}
                    unmountOnExit
                  >
                    <div className={styles.icons}>
                      {
                        iconMenu.map(({
                          id,
                          activeIconProps,
                          iconProps,
                          urlParams,
                          url,
                          tooltip,
                          experienceChanger,
                          label,
                          startsWith,
                          target,
                          rel,
                        }) => {
                          const isActive = startsWith ? asPath.startsWith(startsWith) : asPath.startsWith(url);
                          const isStudyResources = (
                            activeProductCategory === 'studyResources'
                            || activeProductCategory === 'boosterClasses'
                            || activeProductCategory === 'textbook_solutions'
                          );

                          return experienceChanger ? (
                            <Fragment key={id}>
                              {/* <NavbarMenu
                                active={isMenuExperienceChangerOpen}
                                isActiveLabel={isStudyResources}
                                // className={styles['navbar__menu--experience-changer']}
                                count={totalQuestionsCount}
                                id={NavbarMenuIds.experienceChanger}
                                items={menuItemsExperienceChanger}
                                onClickOutside={() => setIsMenuExperienceChangerOpen(false)}
                                onToggle={handleExperienceChangerOpen}
                                streamActive={streamActive}
                                toggleIcon={isStudyResources ? activeIconProps?.icon : iconProps?.icon}
                                toggleIconStroke="none"
                                title={t('navbar:experience_toggle')}
                                onItemClick={defaultLinkClick}
                                menuButtonLabel={label}
                                useHover
                              /> */}
                              <Dropdown
                                trigger="hover"
                                closeDelay={50}
                                menuClassName={styles['navbar__menu--experience-changer-items']}
                                // toggleClassName={styles['navbar__menu--experience-changer-toggle']}
                                toggleClassName={cn(
                                  styles.icons__item,
                                  {
                                    [styles['icons__item--active']]: isStudyResources,
                                  },
                                )}
                                // portal

                                toggle={
                                  (
                                    <div className={styles.icons__item__inner}>
                                      <Icon
                                        className={styles.icons__item__icon}
                                        size={20}
                                        {
                                          ...(
                                            isStudyResources ? activeIconProps : iconProps
                                          )
                                        }
                                      />
                                      <div
                                        className={
                                          cn(styles.navbar__menu__btn_label, {
                                            [styles['navbar__menu__btn_label--active']]: isStudyResources,
                                          })
                                        }
                                      >
                                        {label}
                                      </div>

                                      {
                                        isStudyResources && (<div className={styles.icons__item__underline} />)
                                      }
                                    </div>
                                  )
                                }
                                // {...restProps}
                              >
                                {
                                  ({ close }) => (
                                    <NavbarProductChangeMenu
                                      loaded
                                      active={isMenuExperienceChangerOpen}
                                      isActiveLabel={isStudyResources}
                                      // className={styles['navbar__menu--experience-changer']}
                                      count={totalQuestionsCount}
                                      id={NavbarMenuIds.experienceChanger}
                                      items={menuItemsExperienceChanger}
                                      onClickOutside={() => setIsMenuExperienceChangerOpen(false)}
                                      onToggle={handleExperienceChangerOpen}
                                      streamActive={streamActive}
                                      toggleIcon={isStudyResources ? activeIconProps?.icon : iconProps?.icon}
                                      toggleIconStroke="none"
                                      title={t('navbar:experience_toggle')}
                                      onItemClick={defaultLinkClick}
                                      menuButtonLabel={label}
                                    />
                                  )
                                }
                              </Dropdown>
                            </Fragment>
                          ) : (
                            <Link
                              href={buildUrl(url, urlParams || {})}
                              key={id}
                              onClick={defaultLinkClick}
                              target={target}
                              rel={rel}
                              className={cn(
                                styles.icons__item,
                                {
                                  [styles['icons__item--active']]: isActive,
                                },
                              )}
                            >
                              <div className={styles.icons__item__inner}>
                                <Icon
                                  className={styles.icons__item__icon}
                                  size={20}
                                  {
                                    ...(
                                      isActive ? activeIconProps : iconProps
                                    )
                                  }
                                />

                                {
                                  label ? (
                                    <div
                                      className={
                                        cn(styles.navbar__menu__btn_label, {
                                          [styles['navbar__menu__btn_label--active']]: isActive,
                                        })
                                      }
                                    >
                                      {label}
                                    </div>
                                  ) : null
                                }

                                {/* <div className={styles.icons__tooltip}>
                                  {tooltip}
                                </div> */}
                                {
                                  isActive && (<div className={styles.icons__item__underline} />)
                                }
                              </div>
                            </Link>
                          );
                        })
                      }

                      {isLoggedIn && renderStudyMode && renderStudyMode()}
                    </div>
                  </CSSTransition>
                  {/*
                    {isLoggedIn
                      && [
                        NavbarTypes.classNotes,
                        NavbarTypes.studyGuides,
                        NavbarTypes.textbookNotes,
                      ].includes(type)
                      && !hideMyCourses && (
                        <a
                          className={cn(
                            styles.navbar__link,
                            styles['navbar__link--secondary'],
                            styles['desktop-only'],
                          )}
                          href={buildUrl(ROUTES.myCourses, { exp: type })}
                        >
                          <Icon
                            className="u-mr-3xs"
                            icon="sf:courses"
                            size={16}
                            stroke="none"
                          />
                          {t('navbar:navbar.buttonMyCourses')}
                        </a>
                    )} */}
                </Row>
              </Col>

              {
                !hideSearch && !isSearchButtonHidden && (
                  <Col
                    className={styles['desktop-only']}
                    // TODO: refactor
                    xs={isMobileSearchOnly ? 12 : ((isLoggedIn || isSearchDropdownOpen) ? 'auto' : '7')}
                    // TODO: refactor
                    md={(isLoggedIn || isSearchDropdownOpen) ? 'auto' : '3'}
                    lg={(isLoggedIn || isSearchDropdownOpen) ? 'auto' : '5'}
                    xl={(isLoggedIn || isSearchDropdownOpen) ? 'auto' : '7'}
                  >
                    {/* {isAmp ? (
                      <NavbarSearchAmp
                        actionId="navigateAction"
                        placeholder={t(`navbar.searchPlaceholders.${type}`)}
                        templateId="amp-template-custom"
                        searchUrlParams={searchUrlParams}
                      />
                    ) : (
                      <NavbarSearch
                        id={searchId}
                        isMobileSearchOnly={isMobileSearchOnly}
                        placeholder={t(`navbar.searchPlaceholders.${type}`)}
                        type={type}
                        searchUrlParams={searchUrlParams}
                      />
                    )} */}

                    <>
                      <CSSTransition
                        in={isSearchDropdownOpen}
                        timeout={SMALL_BLOCK_TRANSITION_TIMEOUT}
                        classNames={tabsTransitionProps}
                        unmountOnExit
                      >
                        {/* NOTE: extra div is required to fix CSSTransition animation bug */}
                        <div>
                          <NavbarSearchTabs
                            tabs={navbarSearchTabs}
                            onTabClick={setActiveNavbarSearchTabId}
                            // // className="u-mb-m"
                            defaultActiveTab={activeNavbarSearchTabId}
                          />
                        </div>
                        {/* <div>123</div> */}
                      </CSSTransition>

                      <CSSTransition
                        in={!isSearchDropdownOpen}
                        timeout={SMALL_BLOCK_TRANSITION_TIMEOUT}
                        classNames={searchBtnTransitionProps}
                        unmountOnExit
                      >
                        <NavbarSearchButton
                          onClick={openSearchDropdown}
                          className={cn({
                            'u-pr-l': isLoggedIn,
                            'u-pr-xl': !isLoggedIn,
                            'u-w-100': !isLoggedIn,
                            [styles.nav_search_btn]: true,
                          })}
                        />
                      </CSSTransition>
                    </>

                  </Col>
                )
              }


              <Col xs="auto" lg={isSearchDropdownOpen ? '' : (isLoggedIn ? undefined : '')}>
                <div
                  className={cn(
                    styles.navbar__section,
                    styles['navbar__section--right'],
                    loading && styles['navbar__section--loading'],
                  )}
                >
                  {isLoggedIn && (
                    <UserAvatar
                      className={styles['desktop-only']}
                      userAvatar={userAvatar}
                      userDisplayName={userDisplayName}
                      userNickname={userNickname}
                      userId={userId}
                      onClick={defaultLinkClick}
                    />
                  )}

                  {/* {!isMobileSearchOnly && (
                    <MenuButton
                      className={styles['mobile-only']}
                      icon="feather:search"
                      on={isAmp ? getMenuButtonOn(NavbarMenuIds.search) : undefined}
                      onClick={() => dispatch(toggleSearchOverlay())}
                      aria-label="search"
                    />
                  )} */}
                  {
                    !hideSearch && !isSearchButtonHidden && (
                      <NavbarMobileSearchButton />
                    )
                  }

                  <NavbarDropdownV2
                    icon="sf:create"
                    iconStroke="none"
                    buttonClassName="u-color-primary"
                    className={cn(
                      styles['navbar__menu-parent'],
                      (isMobileSearchOnly) && styles['desktop-only'],
                    )}
                  >
                    <NavbarMenuV2
                      loaded
                      items={menuItemsCreateChanger}
                      label={t('navbar:navbar.labelAdd')}
                      onItemClick={onMenuCreateItemClick}
                    />

                  </NavbarDropdownV2>


                  {!isLoggedIn && (
                    <NavbarDropdownV2
                      toggleLabel={t('navbar:navbar.buttonPricing')}
                      className={cn(
                        styles['navbar__menu-parent'],
                        (isMobileSearchOnly) && styles['desktop-only'],
                      )}
                    >
                      <NavbarMenuV2
                        loaded
                        items={MENU_ITEMS_PRICING}
                        onItemClick={defaultLinkClick}
                      />
                    </NavbarDropdownV2>
                  )}

                  {!isLoggedIn && (
                    <a
                      className={cn(
                        styles.navbar__button,
                        styles['desktop-only'],
                        buttonLoginClassName,
                      )}
                      href={loginUrl}
                    >
                      {t('navbar.buttonLogIn')}
                    </a>
                  )}

                  {!isLoggedIn && (
                    <a
                      className={cn(
                        styles.navbar__button,
                        styles['desktop-only'],
                        styles['navbar__button--signup'],
                        buttonSignupClassName,
                      )}
                      href={signupUrl}
                      data-test-id="Navbar__signup_btn"
                    >
                      {t('navbar.buttonSignUp')}
                    </a>
                  )}

                  {isLoggedIn && (
                    <NavbarDropdownV2
                      icon="feather:bell"
                      toggleCount={unreadNotificationsCount}
                      className={cn(
                        styles['navbar__menu-parent'],
                        (isMobileSearchOnly) && styles['desktop-only'],
                      )}
                    >
                      <NavbarMenuV2
                        className={styles.notifications__menu}
                        renderInner={renderNotifications}
                        label={t('navbar:navbar.labelNotifications')}
                        onItemClick={defaultLinkClick}
                      />
                    </NavbarDropdownV2>
                  )}

                  {isLoggedIn && (
                    <NavbarDropdownV2
                      icon="feather:settings"
                      className={cn(
                        styles['navbar__menu-parent'],
                        (isMobileSearchOnly) && styles['desktop-only'],
                      )}
                    >
                      <NavbarMenuV2
                        loaded
                        items={MENU_ITEMS_SETTINGS}
                        label={t('navbar:navbar.labelAdd')}
                        onItemClick={defaultLinkClick}
                      />

                    </NavbarDropdownV2>
                  )}

                  {isFunction(renderRightContent) && renderRightContent()}
                </div>
              </Col>
            </Row>
          </Container>
        )
      }
      {
        renderBottom && !isSearchDropdownOpen && (
          <div>
            {renderNode(renderBottom)}
          </div>
        )
      }

      {
        isSearchDropdownOpen && (
          <div
            className={cn({
              [styles.banner_back_layer]: isSearchDropdownOpen,
              [styles['banner_back_layer--lg']]: hasRecentSearches,
            })}
          />
        )
      }
      <CSSTransition
        in={isSearchDropdownOpen}
        timeout={isSearchDropdownOpen ? BANNER_TRANSITION_TIMEOUT : 250}
        classNames={bannerTransitionProps}
        unmountOnExit
      >
        <SearchBannerContainer
          standalone
          className="u-d-none u-d-block-lg"
          searchData={activeNavbarSearchTab?.searchData}
          withRedirect
          // redirectParams={searchParams}
        />
      </CSSTransition>
    </header>
  );

  return (
    <>
      <div className={styles.content}>
        {isFunction(beforeContent) ? beforeContent() : beforeContent}

        {navbarComponent}

        <NavbarSearchOverlay
          id={NavbarMenuIds.search}
          placeholder={t(`navbar.searchPlaceholders.${type}`)}
          type={type}
        />

        <SideNavigation
          active={isSideNavigationOpen}
          loginUrl={loginUrl}
          onClose={() => setIsSideNavigationOpen(false)}
          signupUrl={signupUrl}
          streamActive={streamActive}
        />

        {isFunction(afterContent) ? afterContent() : afterContent}
      </div>

      {
        isSearchDropdownOpen && (
          <div className={styles.overlay} onClick={closeSearchDropdown} />
        )
      }
    </>
  );
};

Navbar.defaultProps = {
  loginUrl: ROUTES.login,
  signupUrl: ROUTES.signup,
  type: NavbarTypes.default,
  searchId: 'js-navbar-search',
};

// TODO: Most of these fields can be retrieved directly in the components once the Rails views are obsolete.
Navbar.propTypes = {
  afterContent: oneOfType([func, node]),
  beforeContent: oneOfType([func, node]),
  buttonLoginClassName: string,
  buttonSignupClassName: string,
  className: string,
  loadedNotifications: bool,
  loading: bool,
  loginUrl: string,
  notifications: arrayOf(shape()),
  onNotificationsActive: func,
  renderRightContent: func,
  searchId: string,
  showMobileSearch: bool,
  fluid: bool,
  signupUrl: string,
  streamActive: bool,
  type: oneOf(Object.values(NavbarTypes)),
  unreadNotificationsCount: number,
  userAvatar: oneOfType([string, arrayOf(shape())]),
  userDisplayName: string,
  userId: number,
  userNickname: string,
};

export default withAmpStyles(Navbar, s, stylesObj, ampStylesObj);
