import cn from 'classnames';
import { getUserProfileLink } from 'common/user/utils';
import { stylesProxy } from 'helpers/css';
import withAmpStyles from 'helpers/withAmpStyles';
import Icon from 'oc-core-components/src/Icon';
import LazyImage from 'oc-core-components/src/LazyImage';
import useNormalizedAvatarSrc from 'helpers/hooks/useNormalizedAvatarSrc';
import {
  number, string, oneOfType, shape, arrayOf,
} from 'prop-types';
import React, { useState } from 'react';
import stylesObj from './Navbar.module.scss';
import ampStylesObj from './Navbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Navbar');

// TODO: Can be replaced by oc-core-components/src/Avatar after it's updated.
const UserAvatar = ({
  className,
  styles,
  userAvatar,
  userDisplayName,
  userId,
  userNickname,
  onClick,
}) => {
  const [hasError, setHasError] = useState(false);
  const profileUrl = getUserProfileLink(userId, userNickname);
  const hasAvatar = !hasError && userAvatar;

  const { src, sources } = useNormalizedAvatarSrc({ customSrc: userAvatar });

  return (
    <a
      className={cn(
        styles['user-avatar'],
        !hasAvatar && styles['user-avatar--has-error'],
        className,
      )}
      href={profileUrl}
      title={userDisplayName}
      onClick={onClick}
    >
      <LazyImage
        onError={() => setHasError(true)}
        onLoad={() => setHasError(false)}
        disableLazy
        src={src}
        alt={userDisplayName}
        sources={sources}
      />
      {/* {!hasAvatar && (
        <Icon
          icon="sf:me"
          size={24}
          stroke="none"
          svgClassName={styles['user-avatar__icon']}
        />
      )} */}
    </a>
  );
};

UserAvatar.propTypes = {
  className: string,
  userAvatar: oneOfType([string, arrayOf(shape())]),
  userDisplayName: string,
  userId: number,
  userNickname: string,
};

export default withAmpStyles(UserAvatar, s, stylesObj, ampStylesObj);
