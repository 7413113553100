
import React, { createContext, PureComponent, useContext } from 'react';
import { connect } from 'react-redux';
import { number, node, bool } from 'prop-types';
import { hotjar } from 'react-hotjar';

import { HJID, HJSV } from 'helpers/constants';
import { withContext } from 'helpers/hoc';
import { isBrowser, dummyFunc } from 'helpers/utils';
import { getV2AllowHotjarTracking } from 'v2/redux/meta/selectors';

export const HotjarContext = createContext({
  hj: dummyFunc,
});

export const withHotjar = withContext(HotjarContext, 'hj');

export const useHotjar = () => useContext(HotjarContext);

class HotjarProvider extends PureComponent {
  constructor(props) {
    super(props);

    const {
      hjid,
      hjsv,
      allowHotjarTracking,
    } = props;

    if (isBrowser && allowHotjarTracking && !window.hj) {
      hotjar.initialize(hjid, hjsv);
    }
  }

  hj = (...props) => {
    const {
      allowHotjarTracking,
    } = this.props;

    if (isBrowser && allowHotjarTracking && window.hj) {
      try {
        window.hj(...props);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err, 'When triggering HJ event');
      }
    }
  }

  render() {
    const {
      children,
    } = this.props;

    return (
      <HotjarContext.Provider
        value={this.hj}
      >
        {children}
      </HotjarContext.Provider>
    );
  }
}

const mapStateToProps = (state, props) => ({
  allowHotjarTracking: getV2AllowHotjarTracking(state),
});

HotjarProvider.propTypes = {
  children: node,
  hjid: number,
  hjsv: number,
  allowHotjarTracking: bool,
};

HotjarProvider.defaultProps = {
  hjid: HJID,
  hjsv: HJSV,
};

export default connect(mapStateToProps)(HotjarProvider);
