import { NavbarMenuIds } from './constants';

export const getMenuButtonOn = (id) => {
  const buttonOnState = Object.values(NavbarMenuIds)
    .filter(navbarMenuId => navbarMenuId !== id)
    .map(navbarMenuId => `${navbarMenuId}Visible: false`)
    .join(',');

  return `tap:AMP.setState({${id}Visible: !${id}Visible, ${buttonOnState}})`;
};
