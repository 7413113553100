// Based on https://github.com/reactstrap/reactstrap/blob/master/src/Row.js
import React from 'react';
import {
  string, bool, oneOfType, arrayOf,
} from 'prop-types';
import cn from 'classnames';
import { tagType } from 'types';


const colWidths = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

const gapProps = oneOfType([
  bool,
  string,
]);

const getGapSizeClass = (isXs, gapWidth, gapSize) => (isXs ? `l-row-gap--${gapSize}` : `l-row-gap-${gapWidth}--${gapSize}`);

const Row = (props) => {
  const {
    className,
    noGutters,
    tag: Tag,
    gap,
    widths,
    ...attributes
  } = props;

  const classes = cn(
    className,
    noGutters ? 'no-gutters' : null,
    'l-row',
  );

  const rowClasses = [classes];

  widths.forEach((rowWidth, i) => {
    const rowPropKey = `${rowWidth}Gap`;
    const rowProp = props[rowPropKey];

    delete attributes[rowPropKey];

    if (!rowProp && rowProp !== '') {
      return;
    }

    const isXs = !i;

    rowClasses.push(getGapSizeClass(isXs, rowWidth, rowProp));
  });

  return (
    <Tag {...attributes} className={cn(...rowClasses)} />
  );
};

Row.propTypes = {
  tag: tagType,
  noGutters: bool,
  className: string,
  xsGap: gapProps,
  smGap: gapProps,
  mdGap: gapProps,
  lgGap: gapProps,
  xlGap: gapProps,
  xxlGap: gapProps,
  widths: arrayOf(string),
};

Row.defaultProps = {
  tag: 'div',
  widths: colWidths,
};

export default Row;
