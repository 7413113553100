import { containerWidths } from 'shared/config';


export const defaultSizes = {
  phone: containerWidths.sm - 1,
  tablet: containerWidths.md - 1,
  desktop: containerWidths.lg,
};

export const SET_MOBILE_DETECT = 'oc-core-components/src/MediaQueries/SET_MOBILE_DETECT';
