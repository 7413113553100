import React, { PureComponent } from 'react';
import {
  string, bool, arrayOf, shape, func,
} from 'prop-types';
import { uidType, LinkListType } from 'types';
import cn from 'classnames';
import { isFunction } from 'helpers/utils';

import Row from 'oc-core-components/src/Row';
import withAmpStyles from 'helpers/withAmpStyles';
import Item from './ToolbarItem';
import MenuItem from './ToolbarMenuItem';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Toolbar.module.scss';
import ampStylesObj from './Toolbar.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Toolbar');


class Toolbar extends PureComponent {
  state = {
    showOverlay: false, // show overlay if a dropdown is open
    openMenu: null,
  }


  onItemClick = (openedMenuId, itemUid, callback) => (e) => {
    this.toggleMenu(openedMenuId);
    this.props.onItemClick(itemUid, e);
    if (isFunction(callback)) {
      callback(itemUid);
    }
  }

  toggleMenu = (uid) => {
    const { openMenu } = this.state;

    const currentMenu = (openMenu === uid) ? null : uid;
    this.setState({
      openMenu: currentMenu,
      showOverlay: !!currentMenu,
    });
  }

  overlayClickHandler = (callback) => {
    this.setState({
      openMenu: null,
      showOverlay: false,
    });

    if (isFunction(callback)) {
      callback();
    }
  }

  render() {
    const {
      userMenu,
      items,
      logoUrl,
      isLoggedIn,
      onItemClick,
      className,
      linkClassName,
      labelClassName,
      innerClassName,
      renderBefore,
      styles,
      ...restProps
    } = this.props;

    const {
      showOverlay,
      openMenu,
    } = this.state;

    return (
      <div className={cn(className, styles.container)} {...restProps}>
        {
          showOverlay && (
            // eslint-disable-next-line
            <div className={styles.overlay} onClick={this.overlayClickHandler} />
          )
        }

        <div className={cn(styles.inner, innerClassName)}>
          {
            isFunction(renderBefore) && renderBefore()
          }
          <Row className="u-justify-content-around">

            {/* HOME */}
            {/* <Col className={styles.item}>
              <a className={cn(styles.link, styles.logo)} href={logoUrl}>
                <Icon icon="logo:small" size="sm" stroke="none" />
                <div className={styles.label}>Home</div>
              </a>
            </Col> */}

            {
              items?.map(({
                uid, label, url, items: subMenu, icon, round, onClick, nofollow,
                props,
              }) => (
                <Item
                  icon={icon}
                  label={label}
                  key={uid}
                  href={url}
                  isOpen={openMenu === uid}
                  round={round}
                  withNotifications={isLoggedIn && (uid === 'me')}
                  onClick={this.onItemClick(subMenu ? uid : null, uid, onClick)}
                  nofollow={nofollow}
                  linkClassName={linkClassName}
                  labelClassName={labelClassName}
                  {...props}
                >
                  {
                    subMenu?.map(el => (
                      <MenuItem
                        key={el.uid}
                        href={el.url}
                        onClick={this.onItemClick(null, el.uid, el.onClick)}
                        showNotifications={isLoggedIn && (el.uid === 'notifications')}
                        nofollow={el.nofollow}
                      >
                        {el.label}
                      </MenuItem>
                    ))
                  }
                </Item>
              ))
            }

            { /* TODO: merge userMenu to the menus prop */ }
            {/* <Item
              icon="feather:user"
              label="Me"
              isOpen={openMenu === 'Me'}
              withNotifications={isLoggedIn}
              onClick={this.onItemClick('Me')}
            >
              {
                  userMenu.map(({
                    uid, label, url, withNotifications, onClick,
                  }) => (
                    <MenuItem key={uid} href={url} showNotifications={withNotifications && isLoggedIn} onClick={this.onItemClick(null, onClick)}>
                      {label}
                    </MenuItem>
                  ))
                }
            </Item> */}
          </Row>
        </div>
      </div>
    );
  }
}

Toolbar.propTypes = {
  items: arrayOf(shape({
    uid: uidType,
    label: string,
    url: string,
    items: LinkListType,
  })),
  logoUrl: string,
  isLoggedIn: bool,
  onItemClick: func,
  linkClassName: string,
  labelClassName: string,
  innerClassName: string,
};

Toolbar.defaultProps = {
  items: [],
  onItemClick: () => {},
};


export default withAmpStyles(Toolbar, s, stylesObj, ampStylesObj);
