import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import {
  selectV2DeepLink,
  getV2PageQuestionId,
} from 'v2/redux/page/selectors';
import { selectV2Document } from 'v2/redux/document/selectors';
// import { getV2sCSpotsLeft } from 'v2/redux/meta/selectors';
import BranchIOPopup from 'oc_components/src/Dashboard/BranchIOPopup';

// eslint-disable-next-line import/order
import { stylesProxy } from 'shared/helpers/css';
import stylesObj from 'oc_components/src/Dashboard/BranchIOPopup/BranchIOPopup.module.scss';

const styles = stylesProxy(stylesObj, 'BranchIOPopup');

const usePreloadBranchIOPopup = ({ enabled } = {}) => {
  const deepLink = useSelector(selectV2DeepLink);
  const document = useSelector(selectV2Document);
  const questionId = useSelector(getV2PageQuestionId);
  const isMobile = useMobile();
  const documentId = document?.id;

  // const displayBanner = true
  const displayBanner = !!deepLink;

  const { inline } = useModal();

  useEffect(() => {
    if (!enabled) return;
    if (!isMobile) return;
    if (!displayBanner) return;

    inline({
      content: () => (
        <BranchIOPopup link={deepLink} productId={documentId || questionId} />
      ),
      wrapperProps: {
        // className: styles.modal_wrapper,
        innerClassName: styles.modal_inner,
      },
    });
  }, [displayBanner, inline, enabled, isMobile, deepLink, documentId, questionId]);
};

export default usePreloadBranchIOPopup;
