import React from 'react';
import DynamicModal from 'oc-core-components/src/DynamicModal';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';

import { stylesProxy } from 'helpers/css';
import stylesObj from './UserListPopup.module.scss';

const styles = stylesProxy(stylesObj, 'UserListPopup');

const UserListPopupInner = () => import(
    /* webpackChunkName: "UserListPopupInner" */ 'shared/components/UserListPopup/UserListPopupInner'
  );

export const USER_LIST_POPUP_MODAL = 'userListPopupModal';

const UserListPopup = () => {
  const modal = useModal();

  return (
    <DynamicModal
      centerOnMobile
      hideClose
      component={UserListPopupInner}
      name={USER_LIST_POPUP_MODAL}
      size="sm"
      noPadding
      className={styles.modal}
      innerClassName={styles['modal-inner']}
      render={(Inner, { modalProps }) => (
        <Inner closeModal={modal.close} {...modalProps} />
      )}
    />
  );
};

export default UserListPopup;
