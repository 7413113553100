import React from 'react';
import Script from 'next/script';
import { string } from 'prop-types';
import uniq from 'lodash/uniq';
// import PageHead from 'oc-core-components/src/PageHead';
import {
  getInitialState,
} from 'helpers/utils';
import Head from 'next/head';

import { DEFAULT_I18N } from 'helpers/constants';

const withPageData = ({
  PageContainer,
  pageId,
  i18nNS,
}) => {
  const PageDataComponent = ({
    currentPage,
    meta,
  }) => {
    const title = meta?.title;

    return (
      <>
        {/* <PageHead name={currentPage} /> */}
        {
          title && (
            <Head>
              <title>{title}</title>
            </Head>
          )
        }
        {/* required for Google one tap signup */}
        <Script async defer strategy="afterInteractive" src="https://apis.google.com/js/platform.js" />

        {/* required for FB login & sharing */}
        <Script
          async
          defer
          strategy="afterInteractive"
          src="https://connect.facebook.net/en_US/sdk.js"
          id="facebook-jssdk"
        />
        <PageContainer />
      </>
    );
  };

  PageDataComponent.getInitialProps = ({ query }) => {
    const { pages } = getInitialState(query);

    const meta = pages?.meta;
    const currentPage = pages?.currentPage;
    const pageData = pages[pageId];
    meta.isAmp = query.amp;

    const initialProps = {
      data: pageData?.data,
      meta,
      currentPage,
      pageProps: pageData?.pageProps,
      namespacesRequired: uniq([...DEFAULT_I18N, ...i18nNS]),
    };

    return initialProps;
  };

  PageDataComponent.propTypes = {
    currentPage: string,
  };

  return PageDataComponent;
};

export default withPageData;
