import { camelizeKeys } from 'helpers/utils';
import { handleModelActions } from 'v2/redux/utils/handleActions';
import { TYPER_MODEL_TYPE } from 'v2/redux/constants';
import { QUESTIONS_LIST_CHANNEL, QUESTIONS_SHOW_CHANNEL } from 'v2/redux/websocket/constants';
import websocketTypes from 'v2/redux/websocket/types';

const initialState = {
  data: {},
};

const typerReducer = handleModelActions(
  TYPER_MODEL_TYPE,
  {
    [websocketTypes.RECEIVED_EVENT]: (state, { payload }) => {
      const {
        channel: { channel },
        data,
      } = payload;

      if (![QUESTIONS_LIST_CHANNEL, QUESTIONS_SHOW_CHANNEL].includes(channel)) return state;

      const {
        id, avatar, email, name, status,
      } = camelizeKeys(data);

      if (status === 'typing') {
        state.data[id] = {
          id: `${id}`,
          type: 'typer',
          attributes: {
            name,
            email,
            avatar,
          },
        };
      }

      return state;
    },
  },
  initialState,
);

export { initialState };

export default typerReducer;
