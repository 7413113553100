import { createSelector } from 'reselect';
import { getTypeIdByRelation } from 'common/utils';
import { selectUserAttrs } from 'common/page/selectors';
import { getCourseUploadLink } from 'common/course/utils';
import {
  selectRelationshipsProp,
  selectUserIdProp,
} from '../selectors';

const getSub = state => state.common;
const selectUsers = state => getSub(state).user.data;


// const selectCurrentUser = createSelector(
//   [selectCurrentUserId, selectUsers],
//   (id, users) => (users[id] && users[id].attributes) || null,
// );

// TODO: merge selectCurrentUser and selectUserAttrs
const selectCurrentUser = props => selectUserAttrs(props);

const selectUserIdByRelation = createSelector(
  selectRelationshipsProp, rel => getTypeIdByRelation(rel, 'user'),
);

const selectUserById = createSelector(
  [selectUserIdProp, selectUsers],
  (userId, users) => (users[userId] && users[userId].attributes) || null,
);

const selectRelatedUser = createSelector(
  [selectUserIdByRelation, selectUsers],
  (userId, users) => (users[userId] && users[userId].attributes) || null,
);

const selectUserSchoolCourses = createSelector(
  getSub, common => common?.user?.userSchoolCourses,
);
const selectEnrolledUserCourses = createSelector(
  // TODO: fix selectUserAttrs - returns undefined
  [selectCurrentUser, getSub],
  (user, common) => common?.user?.enrolledUserCourses.map((c) => {
    const withAnchor = user.schoolId === c.school_id;

    return ({ ...c, url: getCourseUploadLink({ courseId: c.value }) });
  }),
);
const selectEnrolledUserCoursesLoaded = createSelector(
  getSub, common => common?.user?.enrolledUserCoursesLoaded,
);

export {
  selectRelatedUser,
  selectUserById,
  selectCurrentUser,
  selectUserSchoolCourses,
  selectEnrolledUserCourses,
  selectEnrolledUserCoursesLoaded,
};
