import React from 'react';

import { List } from 'oc-core-components/src/Dropdown';
import Icon from 'oc-core-components/src/Icon';
import Loader from 'oc-core-components/src/Loader';
import Button from 'oc-core-components/src/Button';

import {
  arrayOf, shape, string, bool, func,
} from 'prop-types';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './MyCoursesList.module.scss';

const styles = stylesProxy(stylesObj, 'MyCoursesList');

const Item = ({
  title, subtitle, url, children, ...restProps
}) => (
  <li className={styles.item} {...restProps}>
    <a href={url}>
      <div className={styles['item-title']}>{title}</div>
      <div className={styles['item-subtitle']}>{subtitle}</div>
    </a>
  </li>
);

const EmptyState = ({
  onClick,
  addCourseText,
  noCoursesText,
}) => (
  <div className={styles['empty-state']}>
    <span>{noCoursesText}</span>
    <Button
      type="button"
      className={styles['add-course']}
      color="none"
      onClick={onClick}
      data-test-id="js-test_add-course-btn"
    >
      {addCourseText}
    </Button>
  </div>
);

const MyCoursesList = ({
  items,
  linkText,
  myCoursesUrl,
  loaded,
  onClick,
  addCourseText,
  noCoursesText,
}) => (
  <List className={styles.container}>
    <List className={styles.content} tag="ul">
      {
        loaded ? (
          (items.length > 0) ? (
            items.map(({ title, subtitle, url }) => (
              <Item
                key={title}
                title={title}
                subtitle={subtitle}
                url={url}
              />
            ))
          ) : (
            <EmptyState
              onClick={onClick}
              addCourseText={addCourseText}
              noCoursesText={noCoursesText}
            />
          )
        ) : (<Loader iconSize="sm" />)
      }
    </List>
    <div className={styles.footer}>
      <a
        href={myCoursesUrl}
        className={styles.footer__btn}
      >
        <div>{linkText}</div>
        <Icon
          icon="feather:chevron-right"
          size="xxs"
          color="dark-lt"
        />
      </a>
    </div>
  </List>
);

MyCoursesList.propTypes = {
  items: arrayOf(shape({
    titile: string,
    subtitile: string,
  })),
  linkText: string,
  myCoursesUrl: string,
  loaded: bool,
  onClick: func,
  addCourseText: string,
  noCoursesText: string,
};

MyCoursesList.defaultProps = {
  items: [],
};

export default MyCoursesList;
