import React, { useMemo } from 'react';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectV2ModelById } from 'v2/redux/selectors';
import { selectV2PageSectionById } from 'v2/redux/page/selectors';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import ContributorCard from 'components/pages/ProductBrowse/ContributorCard';
import ProductBrowseSection from 'components/pages/ProductBrowse/ProductBrowseSection';

import Slider from 'oc-core-components/src/_sections/Slider';

const renderMobileItem = ({
  data: {
    id,
    avatar,
    modelType,
    level,
    name,
    uploadsCount,
  } = {},
}) => (
  <ContributorCard
    src={avatar}
    name={name}
    uploadsCount={uploadsCount}
    // level={level}
    id={id}
    modelType={modelType}
  />
);


const ContributorSection = ({
  sectionId,
  ...restProps
}) => {
  const sectionData = useSelector(state => selectV2PageSectionById(state, sectionId));
  const isMobile = useMobile();

  const {
    itemIds,
    modelType: sectionModelType,
  } = sectionData || {};


  const items = useSelector(state => selectV2ModelById.model(state, itemIds, sectionModelType));

  if (!items?.length) {
    return null;
  }


  return (
    <ProductBrowseSection
      {...restProps}
    >

      <Slider
        renderItem={renderMobileItem}
        items={items}
        hidePagination
        className="u-my-0 u-nmx-m u-d-none-lg"
        itemClassName="u-py-0 u-pl-m u-mr-0"
      />

      <Row className="u-d-none u-d-flex-lg">
        {
          items?.map(({
            id,
            avatar,
            modelType,
            level,
            name,
            uploadsCount,
          }) => (
            <Col
              xs={12}
              md={4}
              lg={2}
              key={id}
            >
              <ContributorCard
                src={avatar}
                name={name}
                uploadsCount={uploadsCount}
                // level={level}
                id={id}
                modelType={modelType}
              />
            </Col>
          ))
        }
      </Row>
    </ProductBrowseSection>
  );
};

ContributorSection.propTypes = {
  sectionId: string,
};

export default ContributorSection;
