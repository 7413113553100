import React, { useEffect, useState } from 'react';
import { selectV2PageSectionById } from 'v2/redux/page/selectors';
import { getV2IsCountryCodeEurope } from 'v2/redux/meta/selectors';
import { ROUTES } from 'shared/helpers/constants';
import SearchBannerContainer from 'oc_components/src/pages/SearchNew/SearchBannerContainer';
import { useMobile } from 'oc-core-components/src/MediaQuery';

import SubscribeBannerContainer from 'components/pages/ProductBrowse/SubscribeBannerContainer';
import { useTranslation } from 'config/i18n';
import { cookiesKeys } from 'helpers/constants';
import { stylesProxy } from 'helpers/css';
import { cookies, scrollToTop } from 'helpers/utils';
import AcceptCookiesBanner from 'oc-core-components/src/AcceptCookiesBanner';
import Container from 'oc-core-components/src/Container';
import { shape, bool } from 'prop-types';
import ContributorSection from 'components/pages/ProductBrowse/sections/ContributorSection';
import UploadDocsBanner from 'components/pages/ProductBrowse/UploadDocsBanner';
// import DashboardBFBanner from 'oc_components/src/Dashboard/DashboardBFBanner';

import Button from 'oc-core-components/src/Button';

import { connect } from 'react-redux';
import ProductHomePage from 'shared/components/ProductHomePage';
import UserListPopup from 'shared/components/UserListPopup';

import PromoLinks from 'components/Home/PromoLinks';
// import InviteBanner from 'components/Home/InviteBanner';
import Cards from 'components/Home/Cards';
import StudentsOnlineContainer from 'components/Home/StudentsOnlineContainer';
// import LoginSection from 'components/Home/LoginSection';
import InviteBanner from 'components/Home/InviteBanner';
import HomeSection from 'components/Home/HomeSection';
import PopularBoosterClasses from 'components/Home/PopularBoosterClasses';
import HomeMaterialsSections from 'components/Home/HomeMaterialsSections';

import stylesObj from './HomeContainer.module.scss';

const styles = stylesProxy(stylesObj, 'HomeContainer');

const pageProps = {
  navbarBeforeContent: <SubscribeBannerContainer />,
  navbarProps: {
    hideSearch: true,
  },
};

const HomeContainer = ({ popularBoosterClasses, isCountryCodeEurope }) => {
  const [userHasAcceptedCookies, setUserHasAcceptedCookies] = useState(true);
  const { t } = useTranslation('home');
  const isMobile = useMobile();

  useEffect(() => {
    const nextUserHasAcceptedCookies = !!cookies.get(
      cookiesKeys.ACCEPTED_COOKIES,
    );

    setUserHasAcceptedCookies(nextUserHasAcceptedCookies);
  }, []);

  const handleAcceptCookies = () => {
    cookies.set(cookiesKeys.ACCEPTED_COOKIES, true);
    setUserHasAcceptedCookies(true);
  };

  const sectionList = [
    {
      component: <Cards />,
      title: t('students_online_section.title'),
      subtitle: t('students_online_section.subtitle'),
      rightElement: <StudentsOnlineContainer />,
    },
    {
      component: (
        <>
          <ContributorSection sectionId="topContributors" />
          <UploadDocsBanner className="u-mt-m u-mt-xl-lg u-nmx-m" />
        </>
      ),
      title: 'Meet Featured Contributors',
      subtitle: "These are just a few of our community's amazing and dedicated contributors.",
    },

    {
      component: <HomeMaterialsSections />,
      title: t('home_materials_section.title'),
      subtitle: t('home_materials_section.subtitle'),
      // linkText: t('home_materials_section.link_text'),
      // href: ROUTES.allMaterials,
    },
    {
      component: (
        <PopularBoosterClasses itemIds={popularBoosterClasses?.itemIds} />
      ),
      title: t('popular_booster_classes_section.title'),
      subtitle: t('popular_booster_classes_section.subtitle'),
      linkText: t('popular_booster_classes_section.link_text'),
      href: ROUTES.boosterClass,
    },
  ];

  return (
    <ProductHomePage pageProps={pageProps} showExperienceToggle>
      <UserListPopup />

      <SearchBannerContainer withTitles withRedirect />

      <Container className="u-px-m u-px-xs-sm u-pb-xl">
        {/* <div className="u-mt-m">
          <DashboardBFBanner source="homepage" bannerType="homePage" />
        </div> */}

        <div className="u-mt-m">
          <InviteBanner />
        </div>

        <div className="u-mt-l u-mt-2xl-xl">
          {/* <PromoLinksV2 /> */}
          <PromoLinks />
        </div>

        {sectionList.map(item => (
          <HomeSection
            key={item.title}
            title={item.title}
            subtitle={item.subtitle}
            linkText={item.linkText}
            href={item.href}
            rightElement={item.rightElement}
          >
            {item.component}
          </HomeSection>
        ))}

      </Container>

      {!userHasAcceptedCookies && isCountryCodeEurope && (
        <AcceptCookiesBanner onClose={handleAcceptCookies} />
      )}
    </ProductHomePage>
  );
};

const mapStateToProps = (state, props) => ({
  popularBoosterClasses: selectV2PageSectionById(
    state,
    'popularBoosterClasses',
  ),
  isCountryCodeEurope: getV2IsCountryCodeEurope(state),
});

HomeContainer.propTypes = {
  popularBoosterClasses: shape(),
  isCountryCodeEurope: bool,
};

export default connect(mapStateToProps)(HomeContainer);
