import 'shared/helpers/polyfills';
import 'shared/helpers/sentryInit';

import 'styles/main.scss';
import 'oc-core-components/src/Container/_layouts.grid.scss';
import 'oc-core-components/src/Button/Button.scss';
import 'hw_components/src/TinyMce/TinyMce.scss';
import 'shared/oc-core-components/src/NotificationProvider/NotificationProvider.scss';
import App from 'next/app';
import React, { Fragment, useMemo } from 'react';
import { Provider } from 'react-redux';
import withReduxStore from 'lib/withReduxStore';
import InlineModal from 'oc-core-components/src/Modal/InlineModal';
import { postCreateUserWithAdblock } from 'common/api';
import FacebookProvider from 'oc-core-components/src/FacebookProvider';
import ModalProvider from 'oc-core-components/src/Modal/ModalProvider';
import { RouterProvider } from 'oc-core-components/src/RouterProvider';
import routesConfig from 'routes';
import patchRouterInstance from 'shared/helpers/utils/patchRouterInstance';

// import GtagContainer from 'oc-core-components/src/GtagContainer';
// import InitPwa from 'oc-core-components/src/InitPwa';
import NotificationProvider from 'oc-core-components/src/NotificationProvider';
import HotjarProvider from 'oc-core-components/src/HotjarProvider';
import AuthProvider from 'oc-core-components/src/AuthProvider';
import CookiesProvider from 'oc-core-components/src/CookiesProvider';
// import { heapLoad } from 'helpers/tracking';
import { appWithTranslation } from 'config/i18n';
import {
  IS_PROD, IS_FEATURE, isBrowser, DEBUG_RENDER,
} from 'helpers/utils';

import ErrorBoundary from 'oc-core-components/src/ErrorBoundary';
// import { withAxe } from 'oc-core-components/src/AxeWrapper';

import MediaQueryProvider from 'oc-core-components/src/MediaQuery/MediaQueryProvider';

import { nest } from 'recompose';
import adblockDetect from 'adblock-detect';
import ImpersonatingUserContainer from 'oc-core-components/src/ImpersonatingUserContainer/ImpersonatingUserContainer';
import { useAmp } from 'next/amp';
import Head from 'next/head';

// import global from 'styles/main.scss.amp?amp&type=global';
import { sendGaClientId } from 'helpers/tracking';

// import NextGoogleFont from 'oc-core-components/src/NextGoogleFonts';
// import { fonts } from 'config';

const WithProviders = nest(NotificationProvider, ModalProvider, AuthProvider, MediaQueryProvider);
const UserProviders = nest(FacebookProvider, HotjarProvider);

// Do not add the following providers for google bots
const getUserProviders = isGoogleBot => (isGoogleBot ? Fragment : UserProviders);
const isGoogleBot = false;

// if (!IS_PROD && isBrowser && DEBUG_RENDER) {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, { exclude: [/^Switch/, /^Router/, /^Route/, /^Connect/] });
//   // whyDidYouRender(React);
// }


const WrappedContainer = (({
  Component,
  pageProps,
  reduxStore,
  serverCookies,
  router,
}) => {
  const nextRoutesRouter = useMemo(() => patchRouterInstance({ router, routesConfig }), []);
  // const WithUserProviders = getUserProviders(pageProps?.meta?.isLightHouse);
  const WithUserProviders = getUserProviders(isGoogleBot);
  // console.log(pageProps?.meta?.isLightHouse);

  // TODO: refactor; use redux selectors
  // Redux meta is not available at this point
  const userId = pageProps?.meta?.currentUser?.id;
  return (

    <Provider store={reduxStore}>
      <ErrorBoundary>
        {
            () => (
              // <GtagContainer
              //   userId={userId}
              // >
              <>
                {
                  // isAmp && (
                  //   <>
                  //     {/* have to add amp gtm scripts immediately after the opening <body> element according to the docs */}
                  //     {/* https://support.google.com/tagmanager/answer/9205783 */}
                  //     <AmpGTMScripts />
                  //     <style jsx global>{global}</style>
                  //   </>
                  // )
                }
                {/* <style jsx global>{global}</style> */}
                {/* <InitPwa /> */}
                {/*
                    https://github.com/vercel/next.js/issues/13230
                    https://github.com/vercel/next.js/issues/6919
                */}
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, minimum-scale=1"
                  />
                </Head>

                <RouterProvider
                  router={nextRoutesRouter?.Router}
                >
                  <WithUserProviders>
                    <WithProviders>

                      <CookiesProvider serverCookies={serverCookies}>
                        <InlineModal />
                        <Component {...pageProps} />
                        <ImpersonatingUserContainer />
                        <div id="modal-root" />
                      </CookiesProvider>

                    </WithProviders>
                  </WithUserProviders>
                </RouterProvider>
              </>
              // </GtagContainer>
            )
          }
      </ErrorBoundary>
    </Provider>

  );
});

class OneClass extends App {
  static async getInitialProps(ctx) {
    const initialProps = await App.getInitialProps(ctx);

    return {
      serverCookies: ctx.ctx.req?.headers?.cookie,
      ...initialProps,
    };
  }

  constructor(props) {
    super(props);

    if (isBrowser) {
      // SET CSRF TOKEN for post requests
      window.OC_CSR_TOKEN = props?.pageProps?.meta?.csrfToken;

      if (IS_PROD && !isGoogleBot) {
        // heapLoad();
        adblockDetect((adblockDetected) => {
          if (adblockDetected) postCreateUserWithAdblock();
        });
      }

      // const ireData = this.props?.pageProps?.meta?.ireData;
      // if ((IS_PROD || IS_FEATURE) && !isGoogleBot && ireData) {
      //   (function (a, b, c, d, e, f, g) { e.ire_o = c; e[c] = e[c] || function () { (e[c].a = e[c].a || []).push(arguments); }; f = d.createElement(b); g = d.getElementsByTagName(b)[0]; f.async = 1; f.src = a; g.parentNode.insertBefore(f, g); }('//d.impactradius-event.com/A1367686-3588-487e-90ba-93c01cb7e1cc1.js', 'script', 'ire', document, window));
      //   window.ire('identify', { customerId: ireData.user_id, customerEmail: ireData.user_email });
      // }
    }

    sendGaClientId();
  }

  render() {
    return <WrappedContainer {...this.props} />;
  }
}

export default appWithTranslation(withReduxStore(OneClass));
