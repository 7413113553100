
// Generate a page subtitle link to School/Course Object page
export const getAboutLink = (pageFilters, school, course) => {
  // When the user is currently on a product browse page at the school level
  if (school) {
    return {
      label: school?.shortenedName,
      url: school?.showUrl,
    };
  }

  // When the user is currently on a product browse page at the course level (not the prof level)
  if (course && course?.objectPageUrl && course?.code && !pageFilters?.professorId) {
    return {
      label: course?.code,
      url: course?.objectPageUrl,
    };
  }

  return {};
};

export const getFilterLevel = ({
  countryId,
  schoolId,
  departmentId,
  courseId,
  professorId,
}) => {
  const levels = [
    ['professor', professorId],
    ['course', courseId],
    ['dept', departmentId],
    ['school', schoolId],
    ['country', countryId],
  ];

  const currentLevel = levels.find(l => l[1]);

  if (currentLevel) {
    return currentLevel[0];
  }

  return 'root';
};

// utm_source=google+ads+search
// utm_source=google+ads+display
// utm_source=facebook&utm_medium=paid+ad
const GOOGLE_UTM_SOURCES = [
  'google ads search',
  'google ads display',
];
const FACEBOOK_UTM_SOURCES = [
  'facebook',
];
const FACEBOOKS_UTM_MEDIUM = [
  'paid ad',
];

export const displaySubscriptionCardsList = ({ utm_source, utm_medium }) => {
  if (utm_source && GOOGLE_UTM_SOURCES.includes(utm_source)) return true;
  if (utm_source && utm_medium
    && FACEBOOK_UTM_SOURCES.includes(utm_source)
    && FACEBOOKS_UTM_MEDIUM.includes(utm_medium)
  ) return true;
};

export default {};
