import React from 'react';
import { getImage } from 'shared/helpers/utils';
import Link from 'oc-core-components/src/Link';
import LazyImage from 'oc-core-components/src/LazyImage';
import Button from 'oc-core-components/src/Button';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { ROUTES } from 'shared/helpers/constants';


// eslint-disable-next-line import/order
import { stylesProxy } from 'shared/helpers/css';
import stylesObj from './InviteSignupPopup.module.scss';

const styles = stylesProxy(stylesObj, 'InviteSignupPopup');

const DISCOUNT_COUPON = getImage('/pages/invite_signup/discount-coupon@2x.png');

const InviteSignupPopup = () => {
  const { close } = useModal();
  const purchaseLink = `${ROUTES.purchasesGradePlus}?source=invite_signup`;
  const isMobile = useMobile();

  return (
    <div className={styles.container}>
      <LazyImage
        src={DISCOUNT_COUPON}
        alt="Coupon with $5 OFF discount"
        disableLazy={isMobile}
        className={styles.coupon}
        width={219}
        height={80}
      />

      <div className={styles.title}>
        Enjoy your $5 OFF!
      </div>

      <div className={styles.description}>
        You may use it on any subscription plans over $10 to access millions of pieces of study content and subject experts!
      </div>

      <Button className={styles.btn} href={purchaseLink}>
        Choose a plan
      </Button>

      <Link onClick={close} className={styles.link}>
        Explore first
      </Link>
    </div>
  );
};

InviteSignupPopup.propTypes = {};

export default InviteSignupPopup;
