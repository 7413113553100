const {
  ALL_MATERIALS,
  CLASS_NOTES,
  STUDY_GUIDES,
  TEXTBOOK_NOTES,
  MATERIAL_TYPE,
  EXAM_PREP,
  SCHOOL,
  MY_COURSES,
  HOMEWORK_HELP,
  HOMEWORK_HELP_SUBJECT,
  HW_PAYMENT_LANDING,
  QUESTION_HANDLE,
  PROFESSOR_HANDLE,
  // MATHEMATICS,
  // CHEMISTRY,
  // PHYSICS,
  // BIOLOGY,
  // STATISTICS,
  // DOCUMENT_TYPE,
  COUNTRY_CODE,
  COUNTRY_NAME,
  STATE_CODE,
  SCHOOL_CODE,
  SCHOOL_NAME,
  // DEPARTMENT_CODE,
  DEPARTMENT_NAME,
  // COURSE_CODE,
  COURSE_NAME,
  BLOG_DIRECTORY_FILTER_TYPE,
  DIRECTORY_FILTER_TYPE,
  DOCUMENT_NAME,
  BLOG,
  SCHOOL_HANDLE,
  BLOG_ZONE,
  BLOG_ARTICLE_ID,
  CATEGORY_HANDLE,
  SCHOOL_TOPIC,
  PEOPLE,
  USER_ID,
  LIVESTREAM,
  LIVESTREAM_HANDLE,
  SKILL,
  COURSES,
  SUBJECT,
  SEARCH,
  DOC_HANDLE,
} = require('./constants');


const LANG = ':lang(.en|)?:html(.html|)?';
const AMP_LANG = ':lang(.en|):amp(.amp)';
const LANG_PWA = ':lang(.en|):pwa(.pwa)';
const LANG_REQUIRED = ':lang(.en):html(.html)';

const materialTypes = [ALL_MATERIALS, CLASS_NOTES, STUDY_GUIDES, TEXTBOOK_NOTES, EXAM_PREP];
const PREFIX = `:${MATERIAL_TYPE}(${materialTypes.join('|')})`;

// Product Browse routes
const PRODUCT_BROWSE_MATERIALS = `/${PREFIX}${LANG}`;

const PRODUCT_BROWSE_COUNTRY = `/${PREFIX}/:${COUNTRY_NAME}${LANG}`;

const PRODUCT_BROWSE_SCHOOL = `/${PREFIX}/:${COUNTRY_NAME}/:${SCHOOL_NAME}${LANG}`;

const PRODUCT_BROWSE_DEPARTMENT = `/${PREFIX}/:${COUNTRY_NAME}/:${SCHOOL_NAME}/:${DEPARTMENT_NAME}${LANG}`;

const PRODUCT_BROWSE_COURSE = `/${PREFIX}/:${COUNTRY_NAME}/:${SCHOOL_NAME}/:${DEPARTMENT_NAME}/:${COURSE_NAME}${LANG}`;

const DOC_SHOW_ROUTES = [
  // it looks like we're not using those params anymore in DocShow container
  `/${PREFIX}/:${COUNTRY_NAME}/:${SCHOOL_NAME}/:${DEPARTMENT_NAME}/:${COURSE_NAME}/:${DOCUMENT_NAME}${LANG}`,
  `/doc/:${DOC_HANDLE}${LANG}`,
];

const SCHOOL_SHOW_ROUTE = `/${SCHOOL}/:${COUNTRY_CODE}/:${STATE_CODE}/:${SCHOOL_CODE}${LANG}`;

const GRADE_PLUS_PAYMENT_ROUTES = [
  `/purchases/:purchaseType(grade-plus|notes-plus|class-plus)${LANG}`,
];

// SchoolIndex
const SCHOOL_INDEX_ROUTE = `/${SCHOOL}${LANG}`;
const SCHOOL_INDEX_STATE_ROUTE = `/${SCHOOL}/:${COUNTRY_CODE}/:${STATE_CODE}${LANG}`;

const DASHBOARD_ROUTE = '/dashboard';
const MY_COURSES_ROUTE = `/${MY_COURSES}${LANG}`;


const PRODUCT_BROWSE_ROUTES = [
  PRODUCT_BROWSE_MATERIALS,
  PRODUCT_BROWSE_COUNTRY,
  PRODUCT_BROWSE_SCHOOL,
  PRODUCT_BROWSE_DEPARTMENT,
  PRODUCT_BROWSE_COURSE,
];

const toPwaRoutes = (routes, prefix = '/app') => routes.map(r => `${prefix}${r}`.replace(LANG, LANG_PWA));
const toAmpRoutes = (routes, prefix = '/app') => routes.map(r => `${r}`.replace(LANG, AMP_LANG));
const generateRoutes = ({ key, pattern, page }) => ({
  html: {
    [key]: {
      pattern,
      page,
    },
  },
  pwa: {
    [`${key}Pwa`]: {
      pattern: toPwaRoutes(pattern),
      page,
    },
  },
  // amp: {
  //   [`${key}Amp`]: {
  //     pattern: toAmpRoutes(pattern),
  //     page: `${page}Amp`,
  //   },
  // },
});

// TODO: check index routes
const HOMEWORK_HELP_INDEX_ROUTES = [
  `/${HOMEWORK_HELP}${LANG}`,
  `/${HOMEWORK_HELP}/:${HOMEWORK_HELP_SUBJECT}${LANG}`,
  `/${HOMEWORK_HELP}/:${HOMEWORK_HELP_SUBJECT}/:${QUESTION_HANDLE}${LANG}`,
];

const HW_PAYMENT_LANDING_PATTERN = [
  `/${HW_PAYMENT_LANDING}${LANG}`,
];

const directoryByType = type => `/${type}/${type}-by-:${DIRECTORY_FILTER_TYPE}${LANG}`;
// Directory routes
const DIRECTORY_STUDY_GUIDES_BY_TYPE = directoryByType(STUDY_GUIDES);
const DIRECTORY_CLASS_NOTES_BY_TYPE = directoryByType(CLASS_NOTES);
const DIRECTORY_TEXTBOOK_NOTES_BY_TYPE = directoryByType(TEXTBOOK_NOTES);

const DIRECTORY_ROUTES = [
  DIRECTORY_STUDY_GUIDES_BY_TYPE,
  DIRECTORY_CLASS_NOTES_BY_TYPE,
  DIRECTORY_TEXTBOOK_NOTES_BY_TYPE,
];

const BLOG_DIRECTORY_ROUTES = [
  `/${BLOG}/blog-articles-by-:${BLOG_DIRECTORY_FILTER_TYPE}${LANG}`,
];

const ALL_MATERIALS_PATTERN = directoryByType(ALL_MATERIALS);

const ALL_MATERIALS_ROUTES = [
  ALL_MATERIALS_PATTERN,
];

const SCHOOL_INDEX_ROUTES = [
  SCHOOL_INDEX_ROUTE,
  SCHOOL_INDEX_STATE_ROUTE,
];

const pricingLandingPageTypes = [
  'notes',
  'study-guides',
  'courses',
  'homework-help',
];

const PRICING_LANDING_ROUTES = [
  `/pricing/:pageType(${pricingLandingPageTypes.join('|')})${LANG}`,
];

const PRICING_GRADE_PLUS_ROUTES = [
  `/pricing/grade-plus${LANG}`,
];

const SUBSCRIPTIONS_ROUTES = [
  `/subscriptions${LANG}`,
];

const TUTOR_LANDING_ROUTES = [
  `/tutors${LANG}`,
];

// BLOG
const blogZones = [
  'easiest-courses',
  'hardest-courses',
  'school-clubs',
  'fun-courses',
  'majors',
  'professors',
  'library',
  'clubs',
  'buildings',
  'dorms',
  'health-wellness',
  'restaurants',
  'jobs',
  'coolest-classes',
];

const blogTopics = ['all', 'news', 'finance', 'sports', 'style', 'wellness', 'opinions'];
const blogCategories = ['featured'];

// BLOG INDEX
const BLOG_INDEX = `/${BLOG}${LANG}`;
const BLOG_CATEGORY = `/${BLOG}/:${CATEGORY_HANDLE}(${blogCategories.join('|')})${LANG}`;

const BLOG_ZONE_CATEGORY = `/${BLOG}/:${BLOG_ZONE}(${blogZones.join('|')})${LANG}`;
const BLOG_SCHOOL = `/${BLOG}/:${SCHOOL_HANDLE}${LANG}`;
const BLOG_SCHOOL_TOPIC = `/${BLOG}/:${SCHOOL_HANDLE}/:${SCHOOL_TOPIC}(${blogTopics.join('|')})${LANG}`;

const BLOG_INDEX_ROUTES = [
  BLOG_INDEX,
  BLOG_CATEGORY,

  BLOG_ZONE_CATEGORY,
  BLOG_SCHOOL,
  BLOG_SCHOOL_TOPIC,
];

// PROFILE


const PROFILE_ROUTES = [
  `/${PEOPLE}/:${USER_ID}${LANG}`,
  `/:${USER_ID}${LANG_REQUIRED}`,
];

// BLOG SHOW
const BLOG_CATEGORY_ARTICLE = `/${BLOG}/:${CATEGORY_HANDLE}(${blogCategories.join('|')})/:${BLOG_ARTICLE_ID}${LANG}`;
const BLOG_SCHOOL_TOPIC_ARTICLE = `/${BLOG}/:${SCHOOL_HANDLE}/:${SCHOOL_TOPIC}(${blogTopics.join('|')})/:${BLOG_ARTICLE_ID}${LANG}`;
const BLOG_SCHOOL_ARTICLE = `/${BLOG}/:${SCHOOL_HANDLE}/:${BLOG_ARTICLE_ID}${LANG}`;

const BLOG_SHOW_ROUTES = [
  BLOG_CATEGORY_ARTICLE,
  BLOG_SCHOOL_TOPIC_ARTICLE,
  BLOG_SCHOOL_ARTICLE,
];

// Live Tutorial
const LIVE_TUTORIAL_SHOW = `/${EXAM_PREP}/:${COUNTRY_NAME}/:${SCHOOL_NAME}/:${DEPARTMENT_NAME}/:${COURSE_NAME}${LANG}`;

const LIVE_TUTORIAL_ROUTES = [
  LIVE_TUTORIAL_SHOW,
];

// Livestream
const LIVESTREAM_INDEX = `/${LIVESTREAM}${LANG}`;
const LIVESTREAM_SHOW = `/${LIVESTREAM}/:${LIVESTREAM_HANDLE}${LANG}`;

const LIVESTREAM_ROUTES = [
  LIVESTREAM_SHOW,
  LIVESTREAM_INDEX,
];

// Booster Class

const SKILLS_SHOW_SHOW = `/${COURSES}/:${SUBJECT}/:${COURSE_NAME}/:${SKILL}${LANG}`;
// const BOOSTER_CLASS_LANDING_PAGE = `/${COURSES}/:${SUBJECT}/:${COURSE_NAME}${LANG}`;

const BOOSTER_CLASS_GROUPS_ROUTES = [`/courses/booster-for-groups${LANG}`];

const BOOSTER_CLASS_LANDING_PAGE = `/courses/:subjectHandle/:boosterClassHanlde${LANG}`;

const TERMS_ROUTES = [`/terms${LANG}`];
const PRIVACY_ROUTES = [`/privacy${LANG}`];

// Settings - PWA ONLY page
const SETTINGS_ROUTES = [`/settings${LANG}`];
// CHANGE PASSWORD - PWA ONLY page
const CHANGE_PASSWORD_ROUTES = [`/change_password${LANG}`];
const MANAGE_SUBSCRIPTIONS_ROUTES = [`/manage_subscriptions${LANG}`];

const SKILLS_SHOW_ROUTES = [
  SKILLS_SHOW_SHOW,
];
const BOOSTER_CLASS_LANDING_PAGE_ROUTES = [
  BOOSTER_CLASS_LANDING_PAGE,
];

// SIGNUP
const AUTH_ROUTES = [
  `/:type(signup|login|school-selection)${LANG}`,
];

const ASK_QUESTION_ROUTES = [
  `/homework-help/ask-question${LANG}`,
];

const SHARE_LINK_ROUTES = [
  `/share_link${LANG}`,
];
const REGISTER_SW_ROUTES = [
  `/register_sw${LANG}`,
];

const MOBILE_NAV_ROUTES = [
  `/settings${LANG}`,
  `/settings/:type(mobile-nav)${LANG}`,
];

// Search
const SEARCH_ROUTE = `/${SEARCH}${LANG}`;
const SEARCH_ROUTE_NEW = `/${SEARCH}-new${LANG}`;

const INVITE_SIGNUP_ROUTES = [
  '/ryerson/code/:code',
  '/r/:nickname',
];

// static pages
const EDU_LANDING = `/edu${LANG}`;
const EDU_EVENTS_LANDING = `/edu/events${LANG}`;
const ACADEMIC_INTEGRITY_ROUTE = `/academic-integrity${LANG}`;
const ABOUT_ROUTE = `/about${LANG}`;
const TEAM_ROUTE = `/team${LANG}`;
const SUPPORT_ROUTE = `/support${LANG}`;
const FAQ_ROUTE = `/faqs${LANG}`;


const accountTabs = [
  'account',
  'password',
  'notifications',
  'billing',
  'manage-subscription',
  'payments',
  'transactions',
  'redemption-history',
  'referrals',
];

const upgradeSubscriptionTabs = [
  'gradeplus-upgrade',
  'hwplus-upgrade',
  'notesplus-upgrade',
];

const ACCOUNT_ROUTES = [
  `/settings/:type(${accountTabs.join('|')})${LANG}`,
  `/settings/:type(manage-subscription)/:subscriptionId${LANG}`,
];

const UPGRADE_SUBSCRIPTIONS_ROUTES = [
  `/settings/manage-subscription/:subscriptionId/:type(${upgradeSubscriptionTabs.join('|')})${LANG}`,
  `/settings/manage-subscription/:type(gradeplus-upgrade)${LANG}`,
];

export const HwPages = [
  'HomeworkHelp',
  'EduEvents',
  'AskQuestionV2',
  'Settings',
  'BoosterClassLanding',
  'BoosterClassGroups',
  'EditProfile',
  'changePassword',
  'DocumentShow',
  'Account',
];

const thankYouPagePurchaseTypes = [
  'booster-class',
  'grade-plus',
  'notes-plus',
  'hw-plus',
  'study-guide',
  'note',
  'voucher',
];

const THANK_YOU_PAGE_ROUTES = [
  `/purchases/:purchaseType(${thankYouPagePurchaseTypes.join('|')})/thank-you${LANG}`,
];

const VOUCHERS_ROOT_ROUTES = [
  `/vouchers${LANG}`,
];

const CONTRIBUTORS_PAGE_TYPES = [
  'insights',
  'answers',
  'notes',
  'earnings',
];
const CONTRIBUTORS_SHOW_PAGE_TYPES = [
  'answers',
  'notes',
];

const CONTRIBUTORS_DASHBOARD_ROUTES = [
  `/insights${LANG}`,
  `/insights/:pageType(${CONTRIBUTORS_PAGE_TYPES.join('|')})${LANG}`,
  `/insights/:pageType(${CONTRIBUTORS_SHOW_PAGE_TYPES.join('|')})/:id${LANG}`,
];

const CONTRIBUTOR_LANDING_ROUTES = [
  `/contributor-championship${LANG}`,
];

const BLACK_FRIDAY_ROUTES = [
  `/black-friday${LANG}`,
];

const DOC_UPLOAD_ROUTE = `/upload${LANG}`;

const REFERRALS_ROUTE = `/referrals${LANG}`;

const VERIFIED_COURSE_ROUTES = [
  `/courses/:${COUNTRY_CODE}/:${SCHOOL_HANDLE}/:${COURSE_NAME}${LANG}`,
  `/courses/:${COUNTRY_CODE}/:${SCHOOL_HANDLE}/:${COURSE_NAME}/:${PROFESSOR_HANDLE}${LANG}`,
];

const pwaRoutes = {
  gradePlus: {
    payment: generateRoutes({
      key: 'GradePlusPayment',
      pattern: GRADE_PLUS_PAYMENT_ROUTES,
      page: 'GradePlusPayment',
    }),
  },
  // TODO: rename page
  thankYou: {
    index: generateRoutes({
      key: 'ThankYou',
      pattern: THANK_YOU_PAGE_ROUTES,
      page: 'ThankYou',
    }),
  },
  hw: {
    index: generateRoutes({
      key: 'HomeworkHelp',
      pattern: HOMEWORK_HELP_INDEX_ROUTES,
      page: 'HomeworkHelp',
    }),
    askQuestion: generateRoutes({
      key: 'AskQuestion',
      pattern: ASK_QUESTION_ROUTES,
      page: 'AskQuestionV2',
    }),
  },
  skills: {
    show: generateRoutes({
      key: 'SkillsShow',
      pattern: SKILLS_SHOW_ROUTES,
      page: 'SkillsShow',
    }),
    groups: generateRoutes({
      key: 'BoosterClassGroups',
      pattern: BOOSTER_CLASS_GROUPS_ROUTES,
      page: 'BoosterClassGroups',
    }),
    landing: generateRoutes({
      key: 'BoosterClassLanding',
      pattern: BOOSTER_CLASS_LANDING_PAGE_ROUTES,
      page: 'BoosterClassLanding',
    }),
  },
  terms: {
    index: generateRoutes({
      key: 'Terms',
      pattern: TERMS_ROUTES,
      page: 'Terms',
    }),
  },
  privacy: {
    index: generateRoutes({
      key: 'Privacy',
      pattern: PRIVACY_ROUTES,
      page: 'Privacy',
    }),
  },
  settings: {
    index: generateRoutes({
      key: 'Settings',
      pattern: SETTINGS_ROUTES,
      page: 'Settings',
    }),
  },
  pricingLanding: {
    index: generateRoutes({
      key: 'pricingLanding',
      pattern: PRICING_LANDING_ROUTES,
      page: 'PricingLanding',
    }),
  },
  gradePlusLanding: {
    index: generateRoutes({
      key: 'gradePlusLanding',
      pattern: PRICING_GRADE_PLUS_ROUTES,
      page: 'GradePlusLanding',
    }),
  },
  subscriptions: {
    index: generateRoutes({
      key: 'subscriptionsLanding',
      pattern: SUBSCRIPTIONS_ROUTES,
      page: 'Subscriptions',
    }),
  },
  changePassword: {
    index: generateRoutes({
      key: 'ChangePassword',
      pattern: CHANGE_PASSWORD_ROUTES,
      page: 'ChangePassword',
    }),
  },
  manageSubscriptions: {
    index: generateRoutes({
      key: 'ManageSubscriptions',
      pattern: MANAGE_SUBSCRIPTIONS_ROUTES,
      page: 'ManageSubscriptions',
    }),
  },
  auth: {
    index: generateRoutes({
      key: 'Auth',
      pattern: AUTH_ROUTES,
      page: 'Auth',
    }),
  },
  account: {
    index: generateRoutes({
      key: 'Account',
      pattern: ACCOUNT_ROUTES,
      page: 'Account',
    }),
  },
  upgradeSubscriptions: {
    index: generateRoutes({
      key: 'UpgradeSubscriptions',
      pattern: UPGRADE_SUBSCRIPTIONS_ROUTES,
      page: 'UpgradeSubscriptions',
    }),
  },
  voucher: {
    // we have to process step=1 and step=2 pages - that's why the same next.js page for account and for purchase pages
    root: generateRoutes({
      key: 'VouchersLanding',
      pattern: VOUCHERS_ROOT_ROUTES,
      page: 'VouchersLanding',
    }),
  },
  contributors: {
    dashboard: generateRoutes({
      key: 'Insights',
      pattern: CONTRIBUTORS_DASHBOARD_ROUTES,
      page: 'Insights',
    }),
    landing: generateRoutes({
      key: 'ContributorsLanding',
      pattern: CONTRIBUTOR_LANDING_ROUTES,
      page: 'ContributorsLanding',
    }),
  },
  blackFriday: {
    landing: generateRoutes({
      key: 'blackFridayLanding',
      pattern: BLACK_FRIDAY_ROUTES,
      page: 'BlackFridayLanding',
    }),
  },
  tutorsLanding: {
    index: generateRoutes({
      key: 'TutorsLanding',
      pattern: TUTOR_LANDING_ROUTES,
      page: 'TutorsLanding',
    }),
  },
  inviteSignup: {
    index: generateRoutes({
      key: 'InviteSignup',
      pattern: INVITE_SIGNUP_ROUTES,
      page: 'InviteSignup',
    }),
  },
  // docUpload: {
  //   index: generateRoutes({
  //     key: 'DocUpload',
  //     pattern: DOC_UPLOAD_ROUTES,
  //     page: 'DocUpload',
  //   }),
  // },
};

// add isStatic: true, if a page doesn't use getInitialProps
const routesConfig = {
  MobileNav: {
    pattern: MOBILE_NAV_ROUTES,
    page: 'MobileNav',
  },
  AllMaterials: {
    pattern: ALL_MATERIALS_ROUTES,
    page: 'AllMaterials',
  },
  BlogDirectory: {
    pattern: BLOG_DIRECTORY_ROUTES,
    page: 'BlogDirectory',
  },
  Directory: {
    pattern: DIRECTORY_ROUTES,
    page: 'Directory',
  },
  SchoolShow: {
    pattern: SCHOOL_SHOW_ROUTE,
    page: 'SchoolShow',
  },
  SchoolIndex: {
    pattern: SCHOOL_INDEX_ROUTES,
    page: 'SchoolIndex',
  },
  // DocumentShow: {
  //   pattern: DOC_SHOW_ROUTE,
  //   page: 'DocumentShow',
  // },
  DocumentShow: {
    pattern: DOC_SHOW_ROUTES,
    page: 'DocumentShow',
  },
  ExamPrep: {
    pattern: LIVE_TUTORIAL_ROUTES,
    page: 'ExamPrep',
  },
  ProductBrowse: {
    pattern: PRODUCT_BROWSE_ROUTES,
    page: 'ProductBrowse',
  },
  Home: {
    pattern: '/',
    page: 'index',
  },
  HomePwa: {
    pattern: '/app/home.en.pwa',
    page: 'index',
  },
  MyCourses: {
    pattern: MY_COURSES_ROUTE,
    page: 'MyCourses',
  },
  Dashboard: {
    pattern: DASHBOARD_ROUTE,
    page: 'Dashboard',
  },
  BlogIndex: {
    pattern: BLOG_INDEX_ROUTES,
    page: 'BlogIndex',
  },
  BlogShow: {
    pattern: BLOG_SHOW_ROUTES,
    page: 'BlogShow',
  },

  DocUpload: {
    pattern: DOC_UPLOAD_ROUTE,
    page: 'DocUpload',
  },
  Referrals: {
    pattern: REFERRALS_ROUTE,
    page: 'Referrals',
  },
  Ntdocs: {
    pattern: VERIFIED_COURSE_ROUTES,
    page: 'Ntdocs',
  },
  // ...pwaRoutes.hw.index.amp,
  // ...pwaRoutes.hw.askQuestion.html,
  // ...pwaRoutes.hw.askQuestion.pwa,
  // ...pwaRoutes.hw.index.pwa,
  // ...pwaRoutes.hw.index.html,

  // ...pwaRoutes.docUpload.index.html,
  ...pwaRoutes.contributors.landing.html,
  ...pwaRoutes.blackFriday.landing.html,

  ...pwaRoutes.pricingLanding.index.html,
  ...pwaRoutes.gradePlusLanding.index.html,
  ...pwaRoutes.subscriptions.index.html,

  ...pwaRoutes.auth.index.html,
  ...pwaRoutes.auth.index.pwa,

  ...pwaRoutes.gradePlus.payment.html,
  ...pwaRoutes.gradePlus.payment.pwa,

  ...pwaRoutes.terms.index.html,
  ...pwaRoutes.terms.index.pwa,

  ...pwaRoutes.privacy.index.html,
  ...pwaRoutes.privacy.index.pwa,

  ...pwaRoutes.upgradeSubscriptions.index.html,
  ...pwaRoutes.upgradeSubscriptions.index.pwa,
  ...pwaRoutes.account.index.html,
  ...pwaRoutes.account.index.pwa,

  ...pwaRoutes.thankYou.index.html,
  ...pwaRoutes.voucher.root.html,

  ...pwaRoutes.contributors.dashboard.html,
  ...pwaRoutes.tutorsLanding.index.html,

  ...pwaRoutes.inviteSignup.index.html,

  // ...pwaRoutes.settings.index.html,
  // ...pwaRoutes.settings.index.pwa,
  // ...pwaRoutes.changePassword.index.html,
  // ...pwaRoutes.changePassword.index.pwa,
  // ...pwaRoutes.manageSubscriptions.index.html,
  // ...pwaRoutes.manageSubscriptions.index.pwa,
  Support: {
    pattern: SUPPORT_ROUTE,
    page: 'Support',
  },
  EduEvents: {
    pattern: EDU_EVENTS_LANDING,
    page: 'EduEvents',
  },
  Edu: {
    pattern: EDU_LANDING,
    page: 'Edu',
  },
  About: {
    pattern: ABOUT_ROUTE,
    page: 'About',
  },
  AcademicIntegrity: {
    pattern: ACADEMIC_INTEGRITY_ROUTE,
    page: 'AcademicIntegrity',
  },
  Team: {
    pattern: TEAM_ROUTE,
    page: 'Team',
  },
  FAQ: {
    pattern: FAQ_ROUTE,
    page: 'FAQ',
  },
  Livestream: {
    pattern: LIVESTREAM_ROUTES,
    page: 'Livestream',
  },
  Search: {
    pattern: SEARCH_ROUTE,
    page: 'Search',
  },
  SearchNew: {
    pattern: SEARCH_ROUTE_NEW,
    page: 'SearchNew',
  },
  // ...pwaRoutes.skills.landing.html,
  // ...pwaRoutes.skills.landing.pwa,
  // ...pwaRoutes.skills.groups.html,
  // ...pwaRoutes.skills.groups.pwa,
  // ...pwaRoutes.skills.show.html,
  // ...pwaRoutes.skills.show.pwa,
  Auth: {
    pattern: AUTH_ROUTES,
    page: 'Auth',
  },

  // PROFILE MUST BE AT THE END
  Profile: {
    pattern: PROFILE_ROUTES,
    page: 'Profile',
  },
};

export default routesConfig;
