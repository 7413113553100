import React from 'react';
import { connect } from 'react-redux';
import NavbarBanner from 'oc-core-components/src/NavbarBanner';
import { bool, shape } from 'prop-types';
import { getV2SubscribeBanner } from 'v2/redux/meta/selectors';


const SubscribeBannerContainer = ({
  subscribeBanner,
  dispatch,
  ...props
}) => (subscribeBanner ? (
  <NavbarBanner
    {...subscribeBanner}
    {...props}
  />
) : null);

SubscribeBannerContainer.propTypes = {
  hideDescriprionOnMobile: bool,
  subscribeBanner: shape(),
};

SubscribeBannerContainer.defaultProps = {
  hideDescriprionOnMobile: true,
};

const mapStateToProps = state => ({
  subscribeBanner: getV2SubscribeBanner(state),
});


export default connect(
  mapStateToProps,
)(SubscribeBannerContainer);
