import {
  selectV2ModelByIdProp, createModelPropByIdSelector, getV2ModelIdProp, getV2Common,
} from 'v2/redux/selectors';

import createCachedSelector from 're-reselect';

export const createSchoolPropByIdSelector = key => createModelPropByIdSelector(selectV2ModelByIdProp.school, 'schoolId', key);

export const selectV2SchoolNameByIdProp = createSchoolPropByIdSelector('name');

export const selectV2SchoolUrlByIdProp = createSchoolPropByIdSelector('url');

export const selectV2SchoolAllMaterialsCountByIdProp = createSchoolPropByIdSelector('allMaterialsCount');

export const getV2DefaultTermMap = state => getV2Common(state).school?.defaultTerm || {};

export const selectV2DefaultTermByIdProp = createCachedSelector(
  [
    getV2ModelIdProp.school,
    getV2DefaultTermMap,
  ],
  (schoolId, termMap) => {
    if (!schoolId) return undefined;

    return termMap[schoolId];
  },
)(
  (state, props) => getV2ModelIdProp.school(state, props) || '_default',
);
