// TODO: update import after redux-saga update
import { delay } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import commonTypes from 'v2/redux/types';
import { postSearchAttempt } from 'common/api';
import removeEmptyFields from 'helpers/utils/removeEmptyFields';
import kebabCase from 'lodash/kebabCase';

const DEBOUNCE_TIME = 1000;

export function* saveSearchAttemptSaga({ payload }) {
  yield delay(DEBOUNCE_TIME);

  const currentPage = yield select(getV2CurrentPage);
  const newPayload = {
    product: kebabCase(currentPage),
    current_url: window.location.href,
    ...removeEmptyFields(payload),
  };

  yield call(postSearchAttempt, newPayload);
}

export default function* saveSearchAttempt() {
  // TODO: rewrite using debounce API after redux-saga upgrade
  yield takeLatest(commonTypes.SAVE_SEARCH_ATTEMPT, saveSearchAttemptSaga);
}
