export const EARNING_MODEL_TYPE = 'earning';
export const HW_ANSWER_MODEL_TYPE = 'hwAnswer';
export const ANSWERER_MODEL_TYPE = 'answerer';
export const AUTHOR_MODEL_TYPE = 'author';
export const BLOG_ARTICLE_MODEL_TYPE = 'blogArticle';
export const BLOG_CATEGORY_MODEL_TYPE = 'blogCategory';
export const BLOG_COMMENT_MODEL_TYPE = 'blogComment';
export const BOOK_MODEL_TYPE = 'book';
export const BOOSTER_CLASS = 'boosterClass';
export const BOOSTER_CLASS_CHAPTER = 'boosterClassChapter';
export const BOOSTER_CLASS_EXERCISE = 'boosterClassExercise';
export const BOOSTER_CLASS_EXERCISE_ANSWER = 'boosterClassExerciseAnswer';
export const BOOSTER_CLASS_LECTURE = 'boosterClassLecture';
export const BOOSTER_CLASS_SKILL = 'boosterClassSkill';
export const BOOSTER_CLASS_TESTIMONIAL = 'boosterClassTestimonial';
export const BOOSTER_CLASS_THEORY = 'boosterClassTheory';
export const BOOSTER_CLASS_USER_SESSION = 'boosterClassUserSession';
export const BOOSTER_CLASS_WHY_LEARN = 'boosterClassWhyLearn';
export const COUNTRY_MODEL_TYPE = 'country';
export const COURSE_MODEL_TYPE = 'course';
export const DEFAULT_MODEL_TYPE = 'model';
export const DEPARTMENT_MODEL_TYPE = 'department';
export const DOCUMENT_MODEL_TYPE = 'document';
export const DOWNLOAD_TOKEN_MODEL_TYPE = 'downloadToken';
export const LIVE_TUTORIAL_MODEL_TYPE = 'liveTutorialSession';
export const LIVE_TUTORIAL_SECTION_MODEL_TYPE = 'liveTutorialSection';
export const NOTIFICATION_MODEL_TYPE = 'notification';
export const POINTS_TASK_MODEL_TYPE = 'pointsTask';
export const PROFESSOR_MODEL_TYPE = 'professor';
export const QUESTION_MODEL_TYPE = 'question';
export const REDEMPTION_MODEL_TYPE = 'redemption';
export const REFERRAL_MODEL_TYPE = 'referral';
export const SCHOOL_MODEL_TYPE = 'school';
export const STREAM_TUTOR_MODEL_TYPE = 'streamTutor';
export const STREAM_VIDEO_MODEL_TYPE = 'streamVideo';
export const STREAM_VIDEO_SCHEDULE_MODEL_TYPE = 'streamVideoSchedule';
export const SUBSCRIPTION_MODEL_TYPE = 'subscription';
export const TESTIMONIAL_MODEL_TYPE = 'testimonial';
export const TOPIC_MODEL_TYPE = 'topic';
export const TRANSACTION_MODEL_TYPE = 'transaction';
export const TUTOR_MODEL_TYPE = 'tutor';
export const TYPER_MODEL_TYPE = 'typer';
export const USER_MODEL_TYPE = 'user';
export const WATCHER_MODEL_TYPE = 'watcher';
export const VIDEO_MODEL_TYPE = 'video';
export const VIDEO_SOLUTION_MODEL_TYPE = 'videoSolution';
export const CHAPTER_MODEL_TYPE = 'chapter';
export const SECTION_MODEL_TYPE = 'section';
export const HOT_COURSE_MODEL_TYPE = 'hotCourse';
export const BLACK_FRIDAY_CAMPAIGN_MODEL_TYPE = 'blackFridayCampaign';

export const MODEL_TYPES = [
  ANSWERER_MODEL_TYPE,
  AUTHOR_MODEL_TYPE,
  BLOG_ARTICLE_MODEL_TYPE,
  BLOG_CATEGORY_MODEL_TYPE,
  BLOG_COMMENT_MODEL_TYPE,
  BOOK_MODEL_TYPE,
  BOOSTER_CLASS_CHAPTER,
  BOOSTER_CLASS_EXERCISE_ANSWER,
  BOOSTER_CLASS_EXERCISE,
  BOOSTER_CLASS_LECTURE,
  BOOSTER_CLASS_SKILL,
  BOOSTER_CLASS_TESTIMONIAL,
  BOOSTER_CLASS_THEORY,
  BOOSTER_CLASS_USER_SESSION,
  BOOSTER_CLASS_WHY_LEARN,
  BOOSTER_CLASS,
  COUNTRY_MODEL_TYPE,
  COURSE_MODEL_TYPE,
  DEFAULT_MODEL_TYPE,
  DEPARTMENT_MODEL_TYPE,
  DOCUMENT_MODEL_TYPE,
  DOWNLOAD_TOKEN_MODEL_TYPE,
  EARNING_MODEL_TYPE,
  HW_ANSWER_MODEL_TYPE,
  LIVE_TUTORIAL_MODEL_TYPE,
  LIVE_TUTORIAL_SECTION_MODEL_TYPE,
  NOTIFICATION_MODEL_TYPE,
  POINTS_TASK_MODEL_TYPE,
  PROFESSOR_MODEL_TYPE,
  QUESTION_MODEL_TYPE,
  REDEMPTION_MODEL_TYPE,
  REFERRAL_MODEL_TYPE,
  SCHOOL_MODEL_TYPE,
  STREAM_TUTOR_MODEL_TYPE,
  STREAM_VIDEO_MODEL_TYPE,
  STREAM_VIDEO_SCHEDULE_MODEL_TYPE,
  SUBSCRIPTION_MODEL_TYPE,
  TESTIMONIAL_MODEL_TYPE,
  TOPIC_MODEL_TYPE,
  TRANSACTION_MODEL_TYPE,
  TUTOR_MODEL_TYPE,
  TYPER_MODEL_TYPE,
  USER_MODEL_TYPE,
  WATCHER_MODEL_TYPE,
  VIDEO_MODEL_TYPE,
  VIDEO_SOLUTION_MODEL_TYPE,
  CHAPTER_MODEL_TYPE,
  SECTION_MODEL_TYPE,
  HOT_COURSE_MODEL_TYPE,
  BLACK_FRIDAY_CAMPAIGN_MODEL_TYPE,
];
