import 'regenerator-runtime/runtime';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import commonSaga from 'shared/common/sagas';

const sagaMiddleware = createSagaMiddleware();

const initialState = {
  loadingBar: {},
};

export default function initializeStore(state = initialState) {
  const store = createStore(
    reducers,
    state,
    composeWithDevTools(applyMiddleware(
      thunkMiddleware,
      sagaMiddleware,
      loadingBarMiddleware(),
    )),
  );
  sagaMiddleware.run(commonSaga);
  return store;
}
