import React, { useCallback } from 'react';
import cn from 'classnames';
import {
  string, shape, bool, arrayOf, func,
} from 'prop-types';
import { isFunction } from 'helpers/utils';
import TextInput from 'oc-core-components/src/TextInput';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Autocomplete.module.scss';

const styles = stylesProxy(stylesObj, 'Autocomplete');


const Input = ({
  select,
  disabled,
  autocompleteProps: {
    getInputProps,
    selectedItem,
    toggleMenu,
    itemToString,
  },
  autocompleteProps,
  inputClassName,
  iconClassName,
  iconSize,
  iconName,
  items,
  value,
  className,
  onIconClick,
  readonly,
  inputFieldClassName,
  onEnter,
  onKeyDown: customOnKeyDown,
  ...restProps
}) => {
  let props;

  const onKeyDown = useCallback((e) => {
    if ((e.key === 'Enter') && onEnter) {
      onEnter(e, { items, autocompleteProps, value });
    }

    if (customOnKeyDown) {
      onKeyDown(e);
    }
  }, [customOnKeyDown, autocompleteProps, items, value, onEnter]);

  const onIconClickHandler = useCallback((...args) => {
    if (!disabled && !readonly) {
      toggleMenu(...args);

      if (isFunction(onIconClick)) {
        onIconClick(...args);
      }
    }
  }, [disabled, readonly, toggleMenu, onIconClick]);

  // Select field
  if (select) {
    props = {
      readonly: true,
      value: itemToString(selectedItem),
      icon: iconName,
      onClick: (!disabled && !readonly) ? toggleMenu : null,
      onIconClick: onIconClickHandler,
      iconClassName: cn(styles.select__icon, iconClassName),
      inputClassName: cn(styles.select__label, inputClassName),
      iconSize,
      className: cn(className),
      disabled,
      onKeyDown,
      ...restProps,
    };
  } else {
    props = {
      disabled,
      readonly,
      value,
      // TODO: inputClassName should not be here
      className: cn(className, inputClassName),
      inputClassName: inputFieldClassName,
      iconClassName,
      onKeyDown,
      ...restProps,
    };
    props = getInputProps(props);

    // if (items.length > 0) {
    //   props = getInputProps(props);
    // }
    // Simple text field
  }
  return <TextInput {...props} />;
};

Input.defaultProps = {
  iconName: 'feather:chevron-down',
};

Input.propTypes = {
  cssSize: string, // TextInput sizes
  placeholder: string,
  select: bool, // true if the component is a 'select'
  autocompleteProps: shape(), // downshift options
  inputClassName: string,
  iconClassName: string,
  disabled: bool,
  readonly: bool,
  value: string,
  iconSize: string,
  items: arrayOf(shape()),
  onIconClick: func,
  iconName: string,
};

export default Input;
