import { useMemo } from 'react';
import { getImage } from 'helpers/utils';

// TODO: check edge cases for customSrc (i.e. customSrc == [ null ])
const useNormalizedAvatarSrc = ({
  customSrc,
  customSources,
}) => {
  const areSourcesInSrc = (Array.isArray(customSrc) && customSrc.length > 0 && !customSources);

  const src = useMemo(() => {
    // if sourcesInSrc - take latest srcSet from sources array as a fallback for browsers that don't support <picture> tags
    const s = areSourcesInSrc ? customSrc[customSrc.length - 1]?.srcSet : customSrc;
    return s || getImage('/avatar/default.svg');
  }, [areSourcesInSrc, customSrc]);

  // [{ srcSet, type }, ... {}]
  const sources = useMemo(
    () => (areSourcesInSrc ? customSrc : customSources),
    [areSourcesInSrc, customSrc, customSources],
  );

  return {
    src,
    sources,
  };
};

export default useNormalizedAvatarSrc;
