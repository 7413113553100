import React from 'react';
import { func, string } from 'prop-types';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './BottomModalWrapper.module.scss';

const styles = stylesProxy(stylesObj, 'BottomModalWrapper');

const BottomModalWrapper = ({
  onOverlayClick,
  children,
  className,
  innerClassName,
  overlayClassName,
  onCloseClick,
  hideClose,
  closeWrapperClassName,
}) => (
  <div className={cn(styles.container, className)}>
    <div className={cn(styles.overlay, overlayClassName)} onClick={onOverlayClick} />

    <div className={cn(styles.inner, innerClassName)}>
      <>
        {children}
      </>

      {
        onCloseClick && !hideClose && (
          <div className={cn('u-pt-xl u-text-center', closeWrapperClassName)}>
            <Link className={styles.close} onClick={onCloseClick}>
              <Icon icon="feather:x" size={16} />
            </Link>
          </div>
        )
      }
    </div>
  </div>
);

BottomModalWrapper.propTypes = {
  onOverlayClick: func,
  innerClassName: string,
  onCloseClick: func,
};

export default BottomModalWrapper;
