import { withHost } from 'common/api';
import { useTranslation } from 'config/i18n';
import { ROUTES } from 'helpers/constants';
import { stylesProxy } from 'helpers/css';
import { getImage } from 'helpers/utils';
import withAmpStyles from 'helpers/withAmpStyles';
import Button from 'oc-core-components/src/Button';
import Icon from 'oc-core-components/src/Icon';
import TextInput from 'oc-core-components/src/TextInput';
import { string } from 'prop-types';
import React from 'react';
import Col from 'oc-core-components/src/Col';
import Row from 'oc-core-components/src/Row';
import stylesObj from './NavbarSearchOverlay.module.scss';
import ampStylesObj from './NavbarSearchOverlay.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'NavbarSearchOverlay');
const actionId = 'navigate-action-overlay';
const templateId = 'amp-template-overlay';

const NavbarSearchOverlayAmp = ({ id, styles }) => {
  const { t } = useTranslation('navbar');

  return (
    <>
      <div
        className={styles['amp-container']}
        hidden
        data-amp-bind-hidden={`!${id}Visible`}
      >
        <amp-action-macro
          id={actionId}
          execute="AMP.navigateTo(url=event.value, target='_top')"
        />

        <amp-autocomplete
          filter="none"
          src={withHost(ROUTES.hwAutocomplete)}
          query="query"
          className={styles.autocomplete}
          on={`select:${actionId}.execute`}
          tabIndex="0"
        >
          <header className={styles.header}>
            <div className={styles.header__autocomplete}>
              <div className={styles['input-container']}>
                <TextInput
                  className={styles.input}
                  on="input-throttled:AMP.setState({ query: event.value })"
                />

                <Icon
                  className={styles['icon-search']}
                  icon="feather:search"
                  size={18}
                />
              </div>
            </div>

            <Button
              className={styles.header__cancel}
              color="none"
              on={`tap:AMP.setState({${id}Visible: false})`}
              size="sm"
              type="button"
            >
              {t('cancel')}
            </Button>

            <template type="amp-mustache" id={templateId}>
              <Row
                data-value={`${ROUTES.search}?query={{name}}`}
                className={styles['autocomplete-item']}
                noGutters
              >
                <Col
                  className="u-d-flex u-align-items-center u-mr-xs"
                  xs="auto"
                >
                  <amp-img
                    className={styles['autocomplete-icon']}
                    src={getImage('autocomplete/{{icon}}')}
                    height={18}
                    width={18}
                  />
                </Col>
                <Col className={styles['autocomplete-name']}>
                  {'{{name}}'}

                  {'{{#label}}'}
                  <span className={styles['autocomplete-label']}>
                    {'{{label}}'}
                  </span>
                  {'{{/label}}'}
                </Col>
              </Row>
            </template>
          </header>
        </amp-autocomplete>
      </div>

      <div
        className={styles['amp-overlay']}
        role="button"
        tabIndex={-1}
        hidden
        data-amp-bind-hidden={`!${id}Visible`}
        on={`tap:AMP.setState({${id}Visible: false})`}
      />
    </>
  );
};

NavbarSearchOverlayAmp.propTypes = {
  id: string,
};

export default withAmpStyles(
  NavbarSearchOverlayAmp,
  s,
  stylesObj,
  ampStylesObj,
);
