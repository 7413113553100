import React, { useEffect, useCallback } from 'react';
import { useCookies } from 'oc-core-components/src/CookiesProvider';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import ShopItemsModalContent from 'components/modals/ShopItemsModalContent';
import { cookiesKeys } from 'helpers/constants';
import useTimeout from 'helpers/hooks/useTimeout';
import { useSelector } from 'react-redux';
import { getV2ShopCampaignItems } from 'v2/redux/meta/selectors';
import { postTrackShopPopup } from 'common/api';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ShopItemsModalContent.module.scss';

const styles = stylesProxy(stylesObj, 'ShopItemsModalContent');


export default function useShopItemsModal() {
  const modal = useModal();
  const cookies = useCookies();
  const items = useSelector(getV2ShopCampaignItems);
  const isDisabled = !!cookies.get(cookiesKeys.SHOWED_PRODUCT_POPUP) || !items?.length;

  // console.log(isDisabled, cookies.get(cookiesKeys.SHOWED_PRODUCT_POPUP), items);

  const openModal = useCallback(
    () => {
      if (!isDisabled) {
      // if (true) {
        cookies.set(cookiesKeys.SHOWED_PRODUCT_POPUP, true, { path: '/', SameSite: true });
        postTrackShopPopup();

        modal.inline({
          content: <ShopItemsModalContent items={items} />,
          wrapperProps: {
            className: styles.popup,
            innerClassName: styles.popup__inner,
            overlayClassName: styles.popup__overlay,
            closeWrapperClassName: 'u-d-none',
            disableOverlayClose: true,
          },
        });
      }
    },
    [isDisabled],
  );

  // useEffect(() => openModal(), []);

  useTimeout(() => {
    openModal();
  }, (!isDisabled ? 5000 : null));
}
