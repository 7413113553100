import types from 'v2/redux/loading/types';
import commonTypes from 'v2/redux/types';

import { takeEvery, select, put } from 'redux-saga/effects';
import { getV2AllowHotjarTracking } from 'v2/redux/meta/selectors';
import { getV2CurrentPage, selectV2PageFilters } from 'v2/redux/page/selectors';
// import { getFilterLevel } from 'components/pages/ProductBrowse/utils';
import { getFilterLevel } from 'components/ProductBrowse/ProductBrowseContainer/utils';
import { logWarning } from 'helpers/utils';
import { catchError } from 'v2/redux/actions';

function* getHJ() {
  const allowHotjarTracking = yield select(getV2AllowHotjarTracking);
  return allowHotjarTracking && window.hj;
  // TODO: add ENV variable to allow local testing
  // return window.hj;
}

function* trackStopLoading(payload) {
  try {
    const hj = yield getHJ();
    const loadingType = payload?.meta?.id;

    if (loadingType === 'sections' && hj) {
      const currentPage = yield select(getV2CurrentPage);
      let lazyLoadeCompleteMsg;
      switch (currentPage) {
        case 'productBrowse': {
          const pageFilters = yield select(selectV2PageFilters);
          if (pageFilters) {
            lazyLoadeCompleteMsg = `${pageFilters.materialType}_lazyloading_finished_${getFilterLevel(pageFilters)}`;
          }

          break;
        }
        case 'home': {
          lazyLoadeCompleteMsg = 'homepage_is_fully_loaded';
          break;
        }
        default: {
          logWarning('incorrect hotjar lazy loading arguments for', loadingType);
        }
      }

      if (lazyLoadeCompleteMsg) {
        // setTimeout is required because of how react committing component updates after state change
        // I've used this option because neither ComponentDidUpdate nor setState/2 are working as expected
        setTimeout(() => hj('trigger', lazyLoadeCompleteMsg), 100);
      }
    }
  } catch (e) {
    yield put(catchError(e));
  }
}

const clickEventsArray = [
  'hwHelpParentsFormLink',
];

function* trackClickEvents({ payload: { eventName } }) {
  try {
    const hj = yield getHJ();
    if (!hj) return;

    if (clickEventsArray.includes(eventName)) {
      hj('trigger', `${eventName}Click`);
    } else {
      logWarning('Unkown event received', eventName);
    }
  } catch (e) {
    yield put(catchError(e));
  }
}

function* trackingSaga() {
  yield takeEvery(types.LOADING.STOP, trackStopLoading);
  yield takeEvery(commonTypes.TRACK_CLICK, trackClickEvents);
}

export default trackingSaga;
