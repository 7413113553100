import { Enhancer } from 'oc-core-components/src/Formik';
import {
  postChangeUserEmail,
} from 'common/api';
import { isFunction } from 'helpers/utils';

import Form from './ChangeEmailForm';

import schema from './schema';

const defaultInitialData = {
  email: '',
};

const formikEnhancer = Enhancer('ChangeEmailForm', schema, {
  defaultInitialData,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const {
      notificationContext: {
        addErrorNotification,
        addSuccessNotification,
      } = {},
      onSubmit,
    } = props;

    try {
      const {
        email,
      } = values;

      const {
        data: {
          success,
          error,
        },
      } = await postChangeUserEmail({
        email,
      });

      if (!success) {
        // Error handling
        addErrorNotification(error);
        setSubmitting(false);
        return;
      }

      const successText = 'Coupon code has been successfully sent to your e-mail';
      setSubmitting(false);
      addSuccessNotification(successText);

      if (isFunction(onSubmit)) {
        // Go to account details
        onSubmit(values);
      }
    } catch (e) {
      addErrorNotification();
      setSubmitting(false);
      console.error(e);
    }
  },
});

const EnhancedForm = formikEnhancer(Form);

export {
  formikEnhancer as Enhancer,
};

export default EnhancedForm;
