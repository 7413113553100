import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import commonReducer from 'common/reducer';
import loadingReducerV2 from 'v2/redux/loading/reducer';
import { reducer as responsiveReducer } from 'oc-core-components/src/MediaQuery/reducer';

const combinedReducers = combineReducers({
  common: commonReducer,
  // TODO: DEPRECATED; move to loading
  loadingBar: loadingBarReducer,
  responsive: responsiveReducer,
  loading: loadingReducerV2,
});

export default combinedReducers;
