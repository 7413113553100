import React, { memo } from 'react';
import { string, shape } from 'prop-types';
import { useSelector } from 'react-redux';
import buildUrl from 'helpers/utils/buildUrl';
import Button from 'oc-core-components/src/Button';
import { ROUTES } from 'helpers/constants';
import removeEmptyFields from 'shared/helpers/utils/removeEmptyFields';

import PointsBadge from 'hw_components/src/_HWv2/Badge/components/PointsBadge/PointsBadge';
import {
  getV2WillEarnPoints,
} from 'v2/redux/meta/selectors';

const UploadLinkButton = ({
  href,
  params,
  ...restProps
}) => {
  const willEarnPoints = useSelector(getV2WillEarnPoints);
  const uploadPoints = willEarnPoints?.upload?.regular;
  const queryParams = params ? removeEmptyFields(params, { removeFalsy: true }) : undefined;
  const link = href ? buildUrl(href, queryParams) : undefined;

  return (
    <Button
      icon="oc:upload-2"
      iconStroke="none"
      iconSize={14}
      href={link}
      {...restProps}
    >
      Upload
      {
        (uploadPoints > 0) && (
          <PointsBadge
            pointsCount={uploadPoints}
            color="grey"
            size="xs"
            addPlusSign
            className="u-ml-xxs"
          />
        )
      }
    </Button>
  );
};

UploadLinkButton.propTypes = {
  href: string,
  params: shape(),
};

UploadLinkButton.defaultProps = {
  href: ROUTES.docUpload,
  params: {},
};

export default memo(UploadLinkButton);
