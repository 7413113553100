import {
  takeEvery, put, fork, call, takeLatest, select,
} from 'redux-saga/effects';

import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';

import accumulateFetch from 'v2/redux/utils/sagas/accumulateFetch';

import { setV2CurrentPage, storeV2Meta, storeV2PageProps } from 'v2/redux/page/actions';
import { storeV2SkillTimeSlots } from 'v2/redux/page/skillsShow/actions';

import { getSkillSchedule, postUserNotificationSettings } from 'common/api';

import { RECEIVE_PAGE_DATA } from 'common/page/constants';
import { mergePageLists } from 'common/page/actions';

import types from 'v2/redux/page/types';
import commonTypes from 'v2/redux/types';

import { catchError, storeModels } from 'v2/redux/actions';
import { selectV2UpcomingGroupSessions } from 'v2/redux/page/skillsShow/selectors';

import {
  modelsDataToObject, isEmpty, isObject, getDataFromResponse, IS_ONECLASS_PROD,
} from 'helpers/utils';

const getMergingByType = (mergingStrategy, type) => {
  if (!isObject(mergingStrategy)) return mergingStrategy;
  return mergingStrategy[type];
};

// TODO: replace width payload
function* storeCurrentPage({ pageSubState }) {
  yield put(setV2CurrentPage(pageSubState));
}

function* retrieveDataBySections({ payload }) {
  try {
    const {
      page,
      data,
      stopLoading,
      stopGlobalLoading,
      mergingStrategy,
    } = payload;

    let newData;
    let newPageProps;

    if (data?.pages[page]) {
      const pageData = data?.pages[page];
      if (pageData?.data && pageData?.pageProps) {
        newPageProps = pageData.pageProps;
        newData = pageData.data;
      }
    }

    const meta = data?.pages?.meta;
    if (isObject(meta) && !isEmpty(meta)) {
      yield put(storeV2Meta(meta, 'merge'));
    }

    if (newData && newPageProps) {
      yield put(storeModels(modelsDataToObject(newData)));

      // yield put(receivePageData(page, newData));
      yield put(mergePageLists(page, newPageProps));
      if (stopLoading) {
        yield put(stopLoading());
      }
      if (stopGlobalLoading) {
        yield put(stopGlobalLoading());
      }
    }
  } catch (e) {
    if (payload?.stopLoading) {
      yield put(payload.stopLoading({ error: e }));
    }
    if (payload?.stopGlobalLoading) {
      yield put(payload.stopGlobalLoading());
    }
    yield put(catchError(e));
  }
}

function* retrieveRouteData({ payload }) {
  try {
    const {
      params: {
        page,
      } = {},
      data,
      stopLoading,
      stopGlobalLoading,
      mergingStrategy,
    } = payload;

    const {
      meta,
      pages = {},
    } = getDataFromResponse(data);

    if (meta) {
      const redirectToLink = meta?.redirectTo;

      if (redirectToLink) {
        // TODO: QUICK FIX; implement redirect without page reloading
        if (!IS_ONECLASS_PROD) {
          console.warn('REDIRECT TO', redirectToLink);
        }
        window.location.assign(redirectToLink);

        return;
      }
      yield put(storeV2Meta(meta, getMergingByType(mergingStrategy, 'meta')));
    }

    // store data for a specific page, if the page id is given; otherwise, store all pages
    const pageEntries = page ? [[page, pages[page]]] : Object.entries(pages);

    for (let i = 0; i < pageEntries.length; i++) {
      const [pageId, pageData] = pageEntries[i];
      if (pageData?.models) {
        yield put(storeModels(pageData.models, getMergingByType(mergingStrategy, 'models')));
      }

      if (pageData?.pageProps) {
        yield put(storeV2PageProps(pageId, pageData.pageProps, getMergingByType(mergingStrategy, 'pageProps')));
      }
    }

    if (stopLoading) {
      yield put(stopLoading());
    }

    if (stopGlobalLoading) {
      yield put(stopGlobalLoading());
    }
  } catch (e) {
    if (payload?.stopLoading) {
      yield put(payload.stopLoading({ error: e }));
    }
    if (payload?.stopGlobalLoading) {
      yield put(payload.stopGlobalLoading());
    }
    yield put(catchError(e));
  }
}

function* retrieveSkillTimeSlots({ payload }) {
  const res = yield call(getSkillSchedule, payload);
  const upcomingSessions = yield select(selectV2UpcomingGroupSessions);

  const upcomingSessionsData = upcomingSessions.map(({ startTimeTimestamp }) => ({
    time: startTimeTimestamp,
    booked: true,
  }));

  if (res?.data) {
    const { data } = res;
    const result = {};

    const combinedData = [...data, ...upcomingSessionsData];
    combinedData.forEach(({ time, ...rest }) => {
      const date = new Date(time);

      const dateStr = startOfDay(date).getTime();
      const timeStr = format(date, 'h:mm aa');

      if (!result[dateStr]) {
        result[dateStr] = [];
      }

      result[dateStr].push({ time: timeStr, timestamp: time, ...rest });
    });

    const newResult = Object.entries(result)
      .reduce((acc, [key, array]) => {
        acc[key] = array.sort((obj1, obj2) => new Date(obj1.timestamp) - new Date(obj2.timestamp));

        return acc;
      }, {});

    yield put(storeV2SkillTimeSlots(newResult));
  }
}

function* updateUserSettingsNotification({ payload }) {
  // const {
  //   group,
  //   values,
  // } = payload || {};


  // if (group && isObject(values) && !isEmpty(values)) {
  //   const valuePair = Object.entries(values)[0];

  //   if (valuePair) {
  //     const data = {
  //       product_name: snakeCase(group),
  //       key: valuePair[0],
  //       value: valuePair[1],
  //     };

  //     const res = yield call(postUserNotificationSettings, data);

  //     // TODO: Add error notification

  //     // if(!res?.data?.success) {

  //     // }
  //   }
  // }


  const res = yield call(postUserNotificationSettings, payload);
}


function* pageSaga() {
  yield takeEvery(RECEIVE_PAGE_DATA, storeCurrentPage);
  yield takeEvery(types.FETCH_PAGE_SECTIONS_LIST.SELF, retrieveDataBySections);
  yield takeEvery(commonTypes.STORE_ROUTE_DATA, retrieveRouteData);
  yield takeLatest(types.FETCH_SKILL_TIME_SLOTS.SELF, retrieveSkillTimeSlots);

  yield takeEvery(types.UPDATE_USER_SETTINGS_NOTIFICATION, updateUserSettingsNotification);

  yield fork(accumulateFetch);
}

export default pageSaga;
