import React, { PureComponent } from 'react';
import { shape, arrayOf, func } from 'prop-types';
import DeferredAutocomplete from 'oc-core-components/src/DeferredAutocomplete';
import { isFunction } from 'helpers/utils';
import TextbookSelectOption from './components/TextbookSelectOption';
import { getTextbooks } from './utils';

const defaultRenderItem = (item, _, { highlighted } = {}) => (
  <TextbookSelectOption
    highlighted={highlighted}
    {...item}
  />
);


class TextbookAutocomplete extends PureComponent {
  state = {
    suggestions: [],
  }

  inputChangeHandler = (val) => {
    const {
      request,
      onSuggestionsLoad,
    } = this.props;

    const searchQuery = val;

    return getTextbooks(searchQuery, { request })
      .then(({ data }) => {
        const {
          hits: { hits: rawHits } = {},
        } = data || {};

        const result = rawHits?.map(({
          _source: source,
        }) => {
          const {
            id,
            name,
            edition,
            author,
            isbn,
            show_url: showUrl,
            url,
            thumb_url: thumb,
            thumbnail_2x: thumb2,
          } = source;

          return ({
            type: 'book',
            label: name,
            value: id.toString(),
            url: showUrl || url,
            edition,
            author,
            isbn,
            thumb,
            thumb2,
          });
        });

        // TODO: TEMO FIX TEXTBOOK
        // if (searchQuery.length) {
        //   result = [...result, { label: searchQuery, value: searchQuery }];
        // }

        if (isFunction(onSuggestionsLoad)) {
          onSuggestionsLoad({ items: result, val });
        }

        return result;
      });
  };

  render() {
    const {
      suggestions,
    } = this.state;

    const {
      items: customItems,
      ...restProps
    } = this.props;

    return (
      <DeferredAutocomplete
        promise={this.inputChangeHandler}
        items={suggestions}
        disableFiltering
        {...restProps}
      />
    );
  }
}

TextbookAutocomplete.defaultProps = {
  renderItem: defaultRenderItem,
};

TextbookAutocomplete.propTypes = {
  request: func,
  items: arrayOf(shape()),
  renderItem: func,
  onSuggestionsLoad: func,
};

export default TextbookAutocomplete;
