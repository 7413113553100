// TODO: Refactor

import React from 'react';
import { useAmp } from 'next/amp';

import {
  bool, arrayOf, shape, element,
} from 'prop-types';
import { LinkListType } from 'types';
import Container from 'oc-core-components/src/Container';
import Icon from 'oc-core-components/src/Icon';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import Link from 'oc-core-components/src/Link';
import cn from 'classnames';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { convertLinksToPwa } from 'helpers/pwa';

import {
  SOCIAL_LINKS,
} from 'helpers/constants';

import withAmpStyles from 'helpers/withAmpStyles';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Footer.module.scss';
import ampStylesObj from './Footer.module.scss?amp&type=resolve';

const s = stylesProxy(stylesObj, 'Footer');


const renderSimpleItem = ({
  label, url, className, styles, onClick,
}) => (
  <li key={`${label}-${url}`} className={className || styles.nav__item}>
    <Link href={url} rel={url && 'nofollow'} className={url ? styles.link : ''} onClick={url ? onClick : undefined}>{label}</Link>
  </li>
);

const renderMenu = ({
  name, items, styles, onClick,
}) => {
  const pwaItems = convertLinksToPwa(items, 'url');

  return (
    <li key={name} className={styles.nav__item}>
      <div className={styles.title}>{name}</div>
      <ul className={styles.list}>
        {
          pwaItems.map(({ label, url }) => (
            <li key={`${label}-${url}`} className={styles.list__item}>
              <Link href={url} rel={url && 'nofollow'} className={url ? styles.link : ''} onClick={url ? onClick : undefined}>{label}</Link>
            </li>
          ))
        }
      </ul>
    </li>
  );
};

const Address = ({
  className,
  address,
  email,
  styles,
}) => (
  <Row>
    <Col className={className}>
      {address}
      {' · '}
      <Link
        href={`mailto:${email}`}
      >
        {email}
      </Link>
    </Col>
  </Row>
);

const DesktopFooter = ({
  smallNav,
  fullNav,
  bottom,
  full,
  social,
  className,
  address,
  email,
  banner,
  styles,
  onClick,
}) => (
  <Col className={className}>
    <ul className={cn(styles.nav, { [styles.full]: full })}>
      {
          full
            ? (
              <>
                {banner ? <li className="u-align-self-center">{banner}</li> : null}
                {fullNav.map(item => renderMenu({ ...item, styles, onClick }))}
              </>
            )
            : smallNav.map(item => renderSimpleItem({ ...item, styles, onClick }))
        }
    </ul>
    {
        full ? (

          <div className={styles.bottom}>
            <Row className="u-justify-content-between">
              <Col>
                {
                  bottom.length > 0 && (
                    <ul className={cn(styles.nav, styles['nav--bottom'])}>
                      {
                        bottom.map(item => renderSimpleItem({ ...item, styles, onClick }))
                      }
                    </ul>
                  )
                }
              </Col>

              <Col xs="auto">
                <Row noGutters className="u-align-items-center">
                  {
                    social.map(({ url, ariaLabel, icon }) => (
                      <Col xs="auto" key={url}>
                        <Link
                          aria-label={ariaLabel}
                          href={url}
                          rel="nofollow noopener"
                          target="_blank"
                          className={styles.social__item}
                        >
                          <Icon
                            icon={icon}
                            size="3xs"
                          />
                        </Link>
                      </Col>
                    ))
                  }

                  <Col xs="auto">
                    <div
                      data-font="arial"
                      data-href={SOCIAL_LINKS.facebook}
                      data-layout="button_count"
                      data-send="false"
                      data-show-faces="false"
                      data-width="50"
                      className={cn(styles.social__item, 'fb-like')}
                    />

                  </Col>
                </Row>
              </Col>
            </Row>

            <Address
              address={address}
              email={email}
              className="u-text-left"
              styles={styles}
            />
          </div>
        ) : (
          <Address
            address={address}
            email={email}
            className="u-text-center u-mt-s"
            styles={styles}
          />
        )
      }
  </Col>
);

const MobileFooter = ({
  mobile, bottom, className, styles, onClick,
}) => {
  const privacy = bottom[0];
  const terms = bottom[1];
  const copyright = bottom[2];

  return (
    <Col className={className}>
      <ul className={styles.mobile}>
        {mobile.map(({ label, url }) => (

          <li key={`${label}-${url}`} className={styles.mobile__item}>
            <Link href={url} rel="nofollow" className={styles.link} onClick={url ? onClick : undefined}>{label}</Link>
          </li>
        ))}
      </ul>

      <div className={styles['mobile-bottom']}>
        <div>
          <span className={styles['mobile-bottom__item']}>
            <Link href={privacy?.url} className={styles.link} onClick={privacy?.url ? onClick : undefined}>{privacy?.label}</Link>
          </span>

          <span className={styles['mobile-bottom__item']}>
            <Link href={terms?.url} className={styles.link} onClick={terms?.url ? onClick : undefined}>{terms?.label}</Link>
          </span>
        </div>

        <div>
          <span>{copyright.label}</span>
        </div>
      </div>
    </Col>

  );
};


const Footer = ({
  smallNav,
  fullNav,
  bottom,
  full,
  social,
  mobile,
  className,
  address,
  email,
  banner,
  styles,
  defaultLinkClick,
  ...restProps
}) => {
  const bottomItems = convertLinksToPwa(bottom, 'url');
  const isMobile = useMobile();
  const isAmp = useAmp();


  return (
    <footer className={cn(styles.container, className)} {...restProps}>
      {((isMobile || isAmp) && banner) ? <div className={styles['mobile-banner']}>{banner}</div> : null}
      <Container>

        <Row className="u-align-items-center">

          {/* Show both versions on amp to keep styles after purge css */}
          {
            (isMobile || isAmp) && (
              <MobileFooter
                className={styles.mobile}
                mobile={mobile}
                bottom={bottomItems}
                address={address}
                email={email}
                styles={styles}
                onClick={defaultLinkClick}
              />
            )
          }

          {
            (!isMobile || isAmp) && (
              <DesktopFooter
                className={styles.desktop}
                smallNav={smallNav}
                fullNav={fullNav}
                bottom={bottomItems}
                full={full}
                social={social}
                address={address}
                email={email}
                banner={banner}
                styles={styles}
                onClick={defaultLinkClick}
              />
            )
          }
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  fullNav: LinkListType,
  smallNav: LinkListType,
  bottom: LinkListType,
  mobile: LinkListType,
  full: bool,
  social: arrayOf(shape()),
  banner: element,
};

Footer.defaultProps = {
  fullNav: [],
  smallNav: [],
  bottom: [],
  social: [],
  address: '1902-365 Bloor St East, Toronto, ON M4W 3L4',
  email: 'info@oneclass.com',
};

export default withAmpStyles(Footer, s, stylesObj, ampStylesObj);
