import { handleModelActions } from 'v2/redux/utils/handleActions';
// import { logErrorDev } from 'helpers/utils';
// import types from './types';

import { BOOSTER_CLASS_EXERCISE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const boosterClassExerciseReducer = handleModelActions(
  BOOSTER_CLASS_EXERCISE,
  {

  },
  initialState,
);

export { initialState };

export default boosterClassExerciseReducer;
