const RECEIVE_DOWNLOAD_TOKEN_DATA = 'common/downloadToken/RECEIVE_DOWNLOAD_TOKEN_DATA';
const POST_PURCHASE_DOWNLOAD_TOKEN = 'common/downloadToken/POST_PURCHASE_DOWNLOAD_TOKEN';
const POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS = 'common/downloadToken/POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS';
const POST_PURCHASE_DOWNLOAD_TOKEN_ERROR = 'common/downloadToken/POST_PURCHASE_DOWNLOAD_TOKEN_ERROR';
const RESET_PURCHASE_ERROR = 'common/downloadToken/RESET_PURCHASE_ERROR';

export {
  RECEIVE_DOWNLOAD_TOKEN_DATA,
  POST_PURCHASE_DOWNLOAD_TOKEN,
  POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS,
  POST_PURCHASE_DOWNLOAD_TOKEN_ERROR,
  RESET_PURCHASE_ERROR,
};
