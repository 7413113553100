import React, { PureComponent, Fragment } from 'react';
import {
  string, number, node, func, arrayOf, shape, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { isFunction, createMarkup } from 'helpers/utils';
// import lozad from 'lozad';
import LazyLoad from 'react-lazyload';

import { selectIsGoogleBot } from 'common/page/selectors';
import { getV2IsIE11 } from 'v2/redux/meta/selectors';


import Placeholder from './Placeholder';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './LazyImage.module.scss';

const styles = stylesProxy(stylesObj, 'LazyImage');


class LazyImage extends PureComponent {
  constructor(props) {
    super(props);
    this.img = React.createRef();

    this.state = {
      loaded: this.props.vanillaLazy || this.props.disableLazy,
      error: false,
    };
  }

  onLoad = () => {
    const {
      onLoad,
    } = this.props;

    this.setState({
      loaded: true,
      error: false,
    });

    if (isFunction(onLoad)) {
      onLoad();
    }
  };

  onError = () => {
    const {
      onError,
    } = this.props;

    this.setState({
      loaded: false,
      error: true,
    });

    if (isFunction(onError)) {
      onError();
    }
  };

  render() {
    const {
      alt,
      src,
      imgClassName,
      placeholderClassName,
      disablePlaceholderAnimation,
      placeholderProps,
      errorClassName,
      className,
      width,
      height,
      htmlWidth,
      htmlHeight,
      onLoad,
      onError,
      sources,
      vanillaLazy: customVanillaLazy,
      responsiveWidth,
      lazyPlaceholder,
      disableLazy,
      disableLoading,
      isGoogleBot,
      dispatch,
      isIE11,
      ampProps,
      children,
      ...restProps
    } = this.props;

    if (!alt) {
      console.error("Missing alt tag: ", src);
    }

    const vanillaLazy = isGoogleBot || customVanillaLazy;
    const { loaded, error } = this.state;

    const ratio = (!Number.isNaN(width) && !Number.isNaN(height) && width > 0 && height > 0)
      ? height / width : null;

    const imgClasses = cn(
      imgClassName,
      styles.img,
      {
        lazyload: vanillaLazy,
        [styles.loading]: !loaded,
        [errorClassName]: !!errorClassName && error,
        [styles.responsive]: responsiveWidth,
      },
    );

    const placeholderClasses = cn(
      className,
      placeholderClassName,
      {
        [errorClassName]: !!errorClassName && error,
      },
      styles.placeholder,
    );

    const wrapperStyles = {};

    if (ratio) {
      // wrapperStyles.paddingTop = `${ratio * 100}%`;
      // wrapperStyles.width = '100%';
      if (responsiveWidth) {
        wrapperStyles.paddingTop = `${ratio * 100}%`;
        wrapperStyles.width = '100%';
      } else {
        wrapperStyles.width = width;
        wrapperStyles.height = height;
      }
    }

    const {
      className: phClassName,
      ...placeholderPropsObj
    } = placeholderProps;

    let innerComponent = null;

    if (src) {
      innerComponent = (
        (sources && (sources.length > 0)) ? (
          <picture
            ref={this.img}
            className={className}
            onLoad={this.onLoad}
            onError={this.onError}
            {...restProps}
          >
            {sources.map(({ srcSet, ...sourceProps }, indx) => (
              <source
                key={`${srcSet}_${indx}`}
                data-srcset={vanillaLazy ? srcSet : null}
                srcSet={!vanillaLazy ? srcSet : null}
                {...sourceProps}
              />
            ))}
            <img
              src={!vanillaLazy ? src : null}
              data-src={vanillaLazy ? src : null}
              alt={alt}
              ref={this.img}
              onLoad={this.onLoad}
              onError={this.onError}
              className={imgClasses}
              width={htmlWidth}
              height={htmlHeight}
            />
          </picture>
        ) : (
          <img
            src={!vanillaLazy ? src : null}
            data-src={vanillaLazy ? src : null}
            alt={alt}
            className={cn(className, imgClasses)}
            ref={this.img}
            onLoad={this.onLoad}
            onError={this.onError}
            width={htmlWidth}
            height={htmlHeight}
            {...restProps}
          />
        )
      );
    }

    return (
      <Fragment>
        {
          !disableLoading && ((vanillaLazy || disableLazy) ? innerComponent : (
            <LazyLoad
              height={height}
              // TODO: temp: move Placeholder here
              placeholder={lazyPlaceholder || <div />}
            >
              {innerComponent}
            </LazyLoad>
          ))
        }

        {
          (src && !disableLazy) && (
            <noscript dangerouslySetInnerHTML={createMarkup(`<img src="${src}" alt="${alt}" className="${cn(className, imgClasses)}" />`)} />

          )
        }

        {
          ((!loaded || error || !src || disableLoading) && !disableLazy)
            && (
              <Placeholder
                style={wrapperStyles}
                className={placeholderClasses}
                disableAnimation={error || disablePlaceholderAnimation}
                {...placeholderPropsObj}
              />
            )
        }
      </Fragment>
    );
  }
}

LazyImage.propTypes = {
  src: string,
  alt: string,
  imgClassName: string,
  width: number,
  height: number,
  responsiveWidth: bool,
  placeholderClassName: string,
  errorClassName: string,
  disablePlaceholderAnimation: bool,
  onLoad: func,
  onError: func,
  sources: arrayOf(shape()),
  placeholderProps: shape(),
  disableLazy: bool,
  vanillaLazy: bool,
  lazyPlaceholder: node,
  disableLoading: bool,
  isGoogleBot: bool,
  dispatch: func,
};

LazyImage.defaultProps = {
  placeholderProps: {},
};

const mapStateToProps = (state, props) => ({
  isGoogleBot: selectIsGoogleBot(state, props),
  isIE11: getV2IsIE11(state),
  get disableLazy() { return props.disableLazy || this.isIE11; },
});

export default connect(
  mapStateToProps,
  null,
)(LazyImage);
export { LazyImage as PureLazyImage };
