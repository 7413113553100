import React, { useCallback, useMemo, useEffect } from 'react';
import {
  retrieveNotificationsCount,
} from 'common/notification/actions';
import {
  selectNotificationsCount,
} from 'common/notification/selectors';
import getAuthLink from 'helpers/utils/getAuthLink';
import { useRouter } from 'oc-core-components/src/RouterProvider';
import DefaultNavbarBannerContainer from 'oc-core-components/src/DefaultNavbarBannerContainer';
import { bool, func, node, oneOfType, shape, string } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { isFunction } from 'helpers/utils';

import {
  getV2CurrentUserAvatar,
  getV2CurrentUserDisplayName,
  getV2CurrentUserId,
  getV2CurrentUserNickname,
  selectV2StreamActive,
  getV2IsLoggedIn,
} from 'v2/redux/meta/selectors';
import Navbar, { NavbarRoutes, NavbarTypes } from '../Navbar';

const defaultNavbarBeforeContent = () => <DefaultNavbarBannerContainer />;

const NavbarContainer = ({
  authParams,
  buttonLoginClassName,
  buttonSignupClassName,
  loading,
  navbarAfterContent,
  navbarBeforeContent,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const { router: { query, asPath } = {} } = useRouter();
  const streamActive = useSelector(selectV2StreamActive);
  const unreadNotificationsCount = useSelector(selectNotificationsCount);
  const userAvatar = useSelector(getV2CurrentUserAvatar);
  const userDisplayName = useSelector(getV2CurrentUserDisplayName);
  const isLoggedIn = useSelector(getV2IsLoggedIn);
  const userId = useSelector(getV2CurrentUserId);
  const userNickname = useSelector(getV2CurrentUserNickname);

  const loginUrl = getAuthLink({
    intent: false,
    login: true,
    ...authParams,
  });
  const signupUrl = getAuthLink({
    intent: false,
    ...authParams,
  });


  useEffect(() => {
    if(isLoggedIn) {
      dispatch(retrieveNotificationsCount());
    }
  }, [isLoggedIn]);

  const type = useMemo(() => {

    // TODO: Consider a better implementation after the Rails views are already obsolete.
    // TODO: This could be improved after we remove support for older browsers.
    // This is here instead of inside NavBar in order to easily reuse NavBar in Rails.
    let res = NavbarTypes.default;

    NavbarRoutes.forEach(({ routes, type: currentType }) => {
      if (routes.some((path) => asPath.startsWith(path))) {
        if(isFunction(currentType)) {
          res = currentType({ asPath, query })
        } else {
          res = currentType;
        }
      }
    });

    return res;
  }, [asPath, query]);


  return (
    <Navbar
      afterContent={navbarAfterContent}
      beforeContent={navbarBeforeContent}
      buttonLoginClassName={buttonLoginClassName}
      buttonSignupClassName={buttonSignupClassName}
      loading={loading}
      loginUrl={loginUrl}
      signupUrl={signupUrl}
      streamActive={streamActive}
      type={type}
      unreadNotificationsCount={unreadNotificationsCount}
      userAvatar={userAvatar}
      userDisplayName={userDisplayName}
      userId={userId}
      userNickname={userNickname}
      {...restProps}
    />
  );
};

NavbarContainer.defaultProps = {
  authParams: {},
  navbarBeforeContent: defaultNavbarBeforeContent,
};

NavbarContainer.propTypes = {
  authParams: shape(),
  buttonLoginClassName: string,
  buttonSignupClassName: string,
  isAmp: bool,
  loading: bool,
  navbarAfterContent: oneOfType([func, node]),
  navbarBeforeContent: oneOfType([func, node]),
  navbarProps: shape(),
};

export default NavbarContainer;
