import { createAction } from 'redux-actions';
import { normalizeData } from 'helpers/utils';
import { postData } from '../api';
import {
  RECEIVE_DOWNLOAD_TOKEN_DATA,
  POST_PURCHASE_DOWNLOAD_TOKEN,
  POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS,
  POST_PURCHASE_DOWNLOAD_TOKEN_ERROR,
  RESET_PURCHASE_ERROR,
} from './constants';

const receiveDownloadTokenData = createAction(RECEIVE_DOWNLOAD_TOKEN_DATA, payload => payload);
const postPurchaseDownloadToken = createAction(POST_PURCHASE_DOWNLOAD_TOKEN, payload => payload);
const postPurchaseDownloadTokenSuccess = createAction(POST_PURCHASE_DOWNLOAD_TOKEN_SUCCESS, (params, res) => ({ params, res }));
const postPurchaseDownloadTokenError = createAction(POST_PURCHASE_DOWNLOAD_TOKEN_ERROR, (params, error) => ({ params, error }));
const resetPurchaseError = createAction(RESET_PURCHASE_ERROR);

function purchaseDownloadToken(tokenId, documentId) {
  return function action(dispatch) {
    dispatch(postPurchaseDownloadToken({ tokenId, documentId }));
    const request = postData(`/download_tokens/${tokenId}/purchase`, { doc_id: documentId });
    return request.then(
      ({ data }) => {
        if (!data?.success) {
          return dispatch(postPurchaseDownloadTokenError({ tokenId, documentId },
            { error: 'An error occured in POST_PURCHASE_DOWNLOAD_TOKEN' }));
        }

        return dispatch(postPurchaseDownloadTokenSuccess({ tokenId, documentId }, data));
      },
      () => dispatch(postPurchaseDownloadTokenError({ tokenId, documentId },
        { error: 'An error occured in POST_PURCHASE_DOWNLOAD_TOKEN' })),
    );
  };
}

const getDownloadTokensById = (downloadTokenIds, initialData) => {
  const downloadTokens = [];
  let tokens = {};
  initialData.forEach((o) => {
    const normalizedData = normalizeData(o);
    const dataKeys = Object.keys(normalizedData);
    dataKeys.forEach((key) => {
      if (key === 'downloadToken') {
        tokens = Object.assign({}, tokens, normalizedData[key]);
      }
    });
  });
  downloadTokenIds.forEach((id) => {
    if (tokens[id] && tokens[id].attributes) {
      downloadTokens.push(tokens[id].attributes);
    }
  });
  return tokens;
};

export {
  receiveDownloadTokenData,
  purchaseDownloadToken,
  resetPurchaseError,
  getDownloadTokensById,
};
