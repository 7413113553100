import React from 'react';
import Icon from 'oc-core-components/src/Icon';

import Badge from 'hw_components/src/_HWv2/Badge';
import cn from 'classnames';

import { oneOf } from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './LvlBadge.module.scss';

const styles = stylesProxy(stylesObj, 'PointsBadge');

const SIZES = {
  xs: 'xs',
  // sm: 'sm',
  // md: 'md',
};
const SIZES_ARRAY = Object.keys(SIZES);

const COLORS = {
  orange: 'orange',
  grey: 'grey',
  purple: 'purple',
  blue: 'blue',
};
// const COLORS_ARRAY = Object.keys(COLORS);

const getSizeProps = ({ size }) => ({
  [SIZES.xs]: {
    className: styles[`container--${size}`],
    iconSize: 10,
  },
  // [SIZES.sm]: {
  //   className: styles[`container--${size}`],
  //   iconSize: 10,
  // },
})[size];

const LVL_ARRAY = Array(10).fill().map((_, i) => i + 1);


// i'm using Map here to make it easier to extend this component if we need non-numeric grade levels
const LVL_GRADES = {
  1: 1,
  2: 2,
  5: 5,
  10: 10,
};
const REVERSED_LVL_GRADES_ARRAY = Object.keys(LVL_GRADES).map(k => parseInt(k, 10)).sort((a, b) => a - b).reverse();

const getColorProps = ({ lvl }) => {
  const currentGrade = REVERSED_LVL_GRADES_ARRAY.find(g => g <= lvl);

  return ({
    [LVL_GRADES[1]]: {
      className: styles[`container--${COLORS.grey}`],
      icon: 'oc:coin-grey',
    },
    [LVL_GRADES[2]]: {
      className: styles[`container--${COLORS.blue}`],
      icon: 'oc:coin-blue',
    },
    [LVL_GRADES[5]]: {
      className: styles[`container--${COLORS.purple}`],
      icon: 'oc:coin-purple',
    },
    [LVL_GRADES[10]]: {
      className: styles[`container--${COLORS.orange}`],
      icon: 'oc:coin-orange',
    },
  })[currentGrade];
};

const LvlBadge = ({
  lvl: initialLvl,
  size,

  icon,
  iconSize,
  iconClassName,
  iconStroke,
  className,
  // pointsCount,
}) => {
  let lvl = initialLvl;
  if (lvl === 0) {
    lvl = 1;
  }

  const sizeProps = getSizeProps({ size });
  const colorProps = getColorProps({ lvl });
  // TODO: i18n?
  const defaultText = `Lv${lvl}`;

  return (
    <Badge
      className={cn(styles.container, sizeProps?.className, colorProps?.className, className)}
    >
      <>
        <Icon
          className={iconClassName}
          icon={icon || colorProps?.icon}
          size={iconSize || sizeProps.iconSize}
          stroke={iconStroke}
        />

        <span className={styles.badge_text}>{defaultText}</span>
      </>
    </Badge>
  );
};

LvlBadge.propTypes = {
  lvl: oneOf([0, ...LVL_ARRAY]),
  size: oneOf(SIZES_ARRAY),
};

LvlBadge.defaultProps = {
  lvl: LVL_ARRAY[0],
  size: SIZES.xs,
  iconStroke: 'none',
};

export default LvlBadge;
