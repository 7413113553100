import React, { PureComponent } from 'react';
import {
  number, string, bool, func, oneOfType,
} from 'prop-types';
import Icon from 'oc-core-components/src/Icon';
import cn from 'classnames';
import FormField from 'oc-core-components/src/FormField';


// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Rating.module.scss';

const styles = stylesProxy(stylesObj, 'Rating');

@FormField()

class Rating extends PureComponent {
  state = {
    focused: 0,
  }

  onMouseEnterHandler = (indx) => {
    this.setState({ focused: indx });
  }

  onMouseLeaveHandler = () => {
    this.setState({ focused: 0 });
  }

  isDisabled = (indx, value) => {
    const positionValue = indx + 1;
    const { focused } = this.state;
    return ((positionValue > value && indx > focused) || (value === 0 && focused === 0));
  };

  handleClick = (e) => {
    if (typeof this.props.onClick === 'function') {
      e.stopPropagation();
      const newValue = this.state.focused + 1;
      this.props.onClick(newValue);
    }
  }

  render() {
    /* eslint-disable prefer-const, react/prop-types */
    let {
      className,
      error,
      success,
      total,
      value,
      hover,
      cssSize,
      disabled,
      readonly,
      onClick,
      id,
      ...restProps
    } = this.props;
    /* eslint-enable prefer-const, react/prop-types */

    if (!total) total = value;
    if (value > total) value = total;

    const Tag = this.props.onClick ? 'button' : 'span';

    return (
      <div className={cn(className, styles.container)} id={id} {...restProps}>
        {
          Array(total).fill().map((i, indx) => (
            <Tag
              id={id && `${id}-${indx}`}
              key={`rating-${indx}`} // eslint-disable-line react/no-array-index-key
              onMouseEnter={() => hover && this.onMouseEnterHandler(indx)}
              onMouseLeave={() => hover && this.onMouseLeaveHandler(indx)}
              className={cn(styles.item, { [styles.hover]: hover })}
              onClick={this.handleClick}
              type={onClick && 'button'}
            >
              <Icon
                icon="oc:star"
                className={cn({ [styles['is-disabled']]: this.isDisabled(indx, value) })}
                fill="primary-alt"
                stroke="none"
              />
            </Tag>

          ))
        }
      </div>
    );
  }
}

Rating.propTypes = {
  id: string,
  value: number.isRequired,
  total: number,
  onClick: func,
  className: string,
  error: oneOfType([string, bool]),
  label: string,
  success: bool,
  hover: bool,
};

Rating.defaultProps = {
  hover: false,
};

export default Rating;
