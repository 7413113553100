import React from 'react';
import cn from 'classnames';
import Dropdown from 'oc-core-components/src/Dropdown';

import MenuButtonV2 from '../MenuButtonV2';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './NavbarDropdownV2.module.scss';

const styles = stylesProxy(stylesObj, 'NavbarDropdownV2');

const NavbarDropdownV2 = ({
  className,
  icon,
  iconStroke,
  buttonClassName,
  toggleCount,
  toggleLabel,
  children,
  menuClassName,
  ...restProps
}) => (
  <Dropdown
    trigger="hover"
    closeDelay={20}
    position="right"
    menuClassName={cn(styles.menu, menuClassName)}
    className={cn(styles.container, className)}
    toggle={(
      <MenuButtonV2
        count={toggleCount}
        icon={icon}
        iconStroke={iconStroke}
        className={buttonClassName}
      >
        {toggleLabel}
      </MenuButtonV2>
      )}
    {...restProps}
  >
    {children}
  </Dropdown>
);

export default NavbarDropdownV2;
