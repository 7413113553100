import React from 'react';
import { bool } from 'prop-types';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import { useMobile } from 'oc-core-components/src/MediaQuery';

import { Form } from 'formik';
import {
  Field,
  FormGroup,
  Submit,
} from 'shared/oc-core-components/src/Formik';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ChangeEmailForm.module.scss';

const styles = stylesProxy(stylesObj, 'ChangeEmailForm');

const ChangeEmailForm = ({
  isSubmitting,
}) => {
  const isMobile = useMobile();

  return (
    <Form className={styles.container}>
      <Row xsGap="xxs">
        <Col>
          <Field
            type="email"
            name="email"
            placeholder="Enter new email"
            inputClassName={styles.input}
            className="u-px-0"
          />
        </Col>
        <Col xs={isMobile ? '12' : 'auto'}>
          <Submit
            fullWidth
            loading={isSubmitting}
            className={styles.submit}
          >
            Send
          </Submit>
        </Col>
      </Row>
    </Form>
  );
};

ChangeEmailForm.propTypes = {
  isSubmitting: bool,
};

export default ChangeEmailForm;
