import React from 'react';
import withPortal from 'oc-core-components/src/WithPortal';


const EmptyModalWrapper = ({
  children,
}) => (
  <>{children}</>
);

export default withPortal({ root: 'modal-root' })(EmptyModalWrapper);
