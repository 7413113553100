import { isBrowser } from 'helpers/utils';
import buildUrl from 'helpers/utils/buildUrl';
import { ROUTES } from 'helpers/constants';

export const selectionExists = () => {
  if(!isBrowser) return false;

  const selection = window.getSelection();

  return (
    selection
    && selection.rangeCount > 0
    && selection.getRangeAt(0)
    && !selection.getRangeAt(0).collapsed
    && selection.getRangeAt(0).getBoundingClientRect().width > 0
    && selection.getRangeAt(0).getBoundingClientRect().height > 0
  );
}

export const clearSelection = () => {
  if(!isBrowser) return;

  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
}

export const goToAskQuestionByAnnId = id => id && window.location.assign(buildUrl(ROUTES.askQuestion, { annotation_id: id }));

export default {};
