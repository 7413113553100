import { handleActions } from 'redux-actions';
import {
  POST_ONE_TIME_PURCHASE,
  POST_ONE_TIME_PURCHASE_SUCCESS,
  POST_ONE_TIME_PURCHASE_ERROR,
  RESET_ONE_TIME_PURCHASE_ERROR,
  POST_PURCHASE,
  POST_PURCHASE_SUCCESS,
  POST_PURCHASE_ERROR,
  RESET_PURCHASE_ERROR,
  POST_CREATE_DOWNLOAD,
  POST_CREATE_DOWNLOAD_SUCCESS,
  POST_CREATE_DOWNLOAD_ERROR,
  RESET_CREATE_DOWNLOAD_ERROR,
} from './constants';

const initialState = {
  oneTimePurchasing: false,
  oneTimePurchaseSuccess: false,
  oneTimePurchaseError: false,
  purchasing: false,
  purchaseSuccess: false,
  purchaseError: false,
  creatingDownload: false,
  createDownloadSuccess: false,
  createDownloadError: false,
};

const reducer = handleActions(
  {
    [POST_ONE_TIME_PURCHASE]: state => ({
      ...state,
      oneTimePurchasing: true,
      oneTimePurchaseSuccess: false,
    }),
    [POST_ONE_TIME_PURCHASE_SUCCESS]: (state, { payload }) => ({
      ...state,
      oneTimePurchasing: false,
      oneTimePurchaseSuccess: payload.res,
    }),
    [POST_ONE_TIME_PURCHASE_ERROR]: (state, { payload }) => ({
      ...state,
      oneTimePurchasing: false,
      oneTimePurchaseError: payload.error,
    }),
    [RESET_ONE_TIME_PURCHASE_ERROR]: state => ({
      ...state,
      oneTimePurchaseError: false,
    }),
    [POST_PURCHASE]: state => ({
      ...state,
      purchasing: true,
      purchaseSuccess: false,
    }),
    [POST_PURCHASE_SUCCESS]: (state, { payload }) => ({
      ...state,
      purchasing: false,
      purchaseSuccess: payload.res,
    }),
    [POST_PURCHASE_ERROR]: (state, { payload }) => ({
      ...state,
      purchasing: false,
      purchaseError: payload.error,
    }),
    [RESET_PURCHASE_ERROR]: state => ({
      ...state,
      purchaseError: false,
    }),
    [POST_CREATE_DOWNLOAD]: state => ({
      ...state,
      creatingDownload: true,
      createDownloadSuccess: false,
    }),
    [POST_CREATE_DOWNLOAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      creatingDownload: false,
      createDownloadSuccess: payload.res,
    }),
    [POST_CREATE_DOWNLOAD_ERROR]: (state, { payload }) => ({
      ...state,
      creatingDownload: false,
      createDownloadError: payload.error,
    }),
    [RESET_CREATE_DOWNLOAD_ERROR]: state => ({
      ...state,
      createDownloadError: false,
    }),
  },
  { ...initialState },
);

export default reducer;
