import { storeModelAttributes } from 'v2/redux/actions';
import createActions from 'v2/redux/utils/createActions';
import createAction from 'v2/redux/utils/createAction';
import types from 'v2/redux/question/types';

export const updateBookmark = createAction(types.UPDATE_BOOKMARK.SELF, (id, value) => ({ id, value }));

export const updateBookmarkActions = createActions(
  types.UPDATE_BOOKMARK, {
    SELF: [(id, value) => ({ id, value })],
  },
);

export const storeBookmark = ({ id, value }) => storeModelAttributes({
  id,
  modelType: 'question',
  updates: {
    isBookmarked: value,
  },
});

export default {};
