import cn from 'classnames';
import { useTranslation } from 'config/i18n';
import { stylesProxy } from 'helpers/css';
import getAuthLink from 'helpers/utils/getAuthLink';
import Button from 'oc-core-components/src/Button';
import LazyImage from 'oc-core-components/src/LazyImage';
import getImagePropsFactory from 'helpers/getImageProps';
import Col from 'oc-core-components/src/Col';
import Container from 'oc-core-components/src/Container';
import Icon from 'oc-core-components/src/Icon';
import RawContent from 'oc-core-components/src/RawContent';
import Row from 'oc-core-components/src/Row';
import Link from 'oc-core-components/src/Link';

import {
  arrayOf, bool, node, string, object,
} from 'prop-types';
import React from 'react';

import stylesObj from './ProductHomeHero.module.scss';

const styles = stylesProxy(stylesObj, 'ProductHomeHero');

const ProductHomeHero = ({
  afterContent,
  className,
  contentClassName,
  description,
  hint,
  imageContainerClassName,
  imageClassName,
  listDescription,
  showLogin,
  source,
  src,
  textAlign,
  title,
  simpleTitle,
  hintLink,
}) => {
  const { t } = useTranslation('common');

  const allowedSizes = ['1x', '2x'];
  const getImageProps = getImagePropsFactory(src.path);

  const imageProps = getImageProps({
    alt: src.filename,
    defaultExt: 'png',
    sourcesExt: src.sourcesExt || 'webp',
    filename: src.filename,
    allowedSizes,
  });

  const hasDescriptionList = Array.isArray(listDescription) && listDescription.length > 0;
  const signUpUrl = getAuthLink({ intent: false, source, withPwa: true });
  const loginUrl = getAuthLink({
    intent: false,
    source,
    login: true,
    withPwa: true,
  });

  const titleClassNames = cn(
    styles.content__title,
    `u-text-${textAlign} u-text-left-md`,
  );

  return (
    <div
      className={cn(
        styles.container,
        className,
        { [styles[`${source}-bg`]]: source === 'documents' || source === 'booster' },
      )}
    >
      <Container className={cn(styles.container__inner)}>
        <div className={cn(styles.content, contentClassName)}>

          {
          simpleTitle ? (
            <h1 className={titleClassNames}>
              {title}
            </h1>
          ) : (
            <RawContent
              className={titleClassNames}
              tag="h1"
            >
              {title}
            </RawContent>
          )
        }

          {(description || hasDescriptionList) && (
            <Row className={styles.description} noGutters>
              {description && (
                <Col xs="auto" xl="5">
                  <RawContent className={styles.description__text} tag="p">
                    {description}
                  </RawContent>
                </Col>
              )}

              {hasDescriptionList && (
                <Col xs="auto" xl="7">
                  <ul className="u-list-unstyled">
                    {listDescription.map((item, index) => (
                      <li className={styles.description__item} key={index}>
                        <Icon
                          icon="feather:plus"
                          size="3xs"
                          className="u-mt-3xs"
                          svgClassName={styles.description__item__icon}
                        />

                        <div className={styles.description__item__text}>
                          {item}
                        </div>
                      </li>
                    ))}
                  </ul>
                </Col>
              )}
            </Row>
          )}

          <Row
            className="u-align-items-center u-justify-content-center u-justify-content-md-start"
            noGutters
          >
            <Col xs={showLogin ? 'auto' : 12} md="auto">
              <Button
                className={cn(styles.content__signup, {
                  [styles['content__signup--has-login']]: showLogin,
                  [styles['content__signup--has-hint']]: hint,
                })}
                color="dark"
                href={signUpUrl}
                roundBorders
                size="lg"
              >
                {t('sign_up')}
              </Button>
            </Col>

            <Col xs={showLogin ? 'auto' : 12} md="auto">
              {showLogin && (
                <Button
                  className={styles.content__login}
                  color="light-lt"
                  href={loginUrl}
                  roundBorders
                  size="lg"
                >
                  {t('log_in')}
                </Button>
              )}

              {hint && (
                <div className={styles.hint}>
                  <div className={styles.hint__asterisk}>*</div>
                  <div>
                    <RawContent>{hint}</RawContent>
                    {
                      hintLink && (
                        <Link className={styles.hint__link} href={hintLink}>{t('learn_more')}</Link>
                      )
                    }
                  </div>
                </div>
              )}
            </Col>
          </Row>

          {afterContent && (
            <Row
              className="u-align-items-center u-justify-content-center u-justify-content-md-start"
              noGutters
            >
              {afterContent}
            </Row>
          )}
        </div>

        <div className={cn(styles.image__container, imageContainerClassName)}>
          <LazyImage {...imageProps} imgClassName={imageClassName} />
        </div>
      </Container>
    </div>
  );
};

export const ProductHomeHeroPropType = {
  afterContent: node,
  className: string,
  contentClassName: string,
  description: string,
  heroClassName: string,
  hint: string,
  imageContainerClassName: string,
  imageClassName: string,
  listDescription: arrayOf(string),
  showLogin: bool,
  source: string,
  src: object,
  textAlign: string,
  title: node.isRequired,
  simpleTitle: bool,
};

ProductHomeHero.propTypes = {
  ...ProductHomeHeroPropType,
};

ProductHomeHero.defaultProps = {
  listDescription: [],
  textAlign: 'left',
};

export default ProductHomeHero;
