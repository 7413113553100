import { cookiesKeys } from 'helpers/constants';
import { cookies, getUniversalCookies } from 'helpers/utils';

export default {};


// FP Marcil:
// 1) Its off by default
// 2) its on if I activate it from anywhere
// 3) the test feature turns it ON if its off (he means start_session event I guess)
// 4) the test feature turns it OFF ONLY if it was turn ON by the test, ie if I turned it on before, it stays on (edited)

export const setStudyModeCookie = ({
  isStudyModeOn,
  saveUserChoice = false,
  ignoreUserChoice,
}) => {
  // saveUserChoise is true only if the user checked StudyMode Toggle in navbar
  if (saveUserChoice) {
    cookies.set(cookiesKeys.STUDY_MODE_ON_USER_CHOICE, isStudyModeOn, { path: '/' });
    cookies.set(cookiesKeys.STUDY_MODE_ON, isStudyModeOn, { path: '/' });
  } else {
    let studyModeValue = isStudyModeOn;

    if (!ignoreUserChoice) {
      const userChoice = !!(cookies.get(cookiesKeys.STUDY_MODE_ON_USER_CHOICE) === 'true');
      studyModeValue = userChoice;
    }

    cookies.set(cookiesKeys.STUDY_MODE_ON, studyModeValue, { path: '/' });
  }
};

// returns Boolean [true/false]
export const getStudyModeStatus = (universalCookies = cookies) => {
  return (universalCookies.get(cookiesKeys.STUDY_MODE_ON) === 'true');
};

export const getStudyModePageAttrs = (serverCookies) => {
  const state = {};
  const universalCookies = getUniversalCookies(serverCookies);

  state.isStudyModeOn = getStudyModeStatus(universalCookies);
  // state.isStudyModeTooltipOpen = ...
  // ... other state related things
  return state;
};
