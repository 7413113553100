import { isPwa, toPwaUrl, isPwaLaunched } from './pwa';
import { isAmp, AMP_ROUTES_KEYS, toAmpUrl } from './amp';
import { getPathFromUrl } from './utils';

// TODO: remove react prefix
export const cookiesKeys = {
  ACCEPTED_COOKIES: 'accepted_cookies',
  CLASS_EMAIL: 'class_email',
  FB_OR_GMAIL: 'signup_fb_or_gmail',
  FROM_HW_LANDING: 'from_hw_landing',
  HW_PAYWALL_POPUP: 'hw_paywall',
  HW_ASK_QUESTION_POPUP: 'hw_ask_question',
  HW_DRAFT_QUESTION_ID: 'draft_homework_question_id',
  INTERCOM_LEAD_ID: 'intercom_lead_id',
  LOGIN_STEP1: 'login_user_email',
  NEW_USER_TEMP_PASSWORD: 'new_user_temp_password',
  RESET_STEP1: 'reset_step1',
  SHOW_INIT_RESET_FORM: 'show_init_reset_password_form',
  SHOW_RESET_FORM: 'show_reset_password_form',
  SHOW_SUBSCRIPTIONS_PRINT_INFO: 'show_subscriptions_print_info',
  SIGNUP_REFERRED_BY: 'signup_referred_by_data',
  SIGNUP_STEP_NUMBER: 'signup_step',
  SIGNUP_STEP1: 'signup_user_email',
  SIGNUP_STEP2: 'signup_step2',
  SIGNUP_STEP3: 'signup_step3',
  WECHAT_SOURCE_ID: 'wechat_source_id',
  DEFAULT_LOGIN: 'default_login',
  ADSENSE_ENABLED: 'adsense_enabled',
  LIVESTREAM_COLLAPSE: 'livestream_collapse',
  ASK_QUESTION_REDIRECT_URL: 'ask_question_redirect_url',
  SEARCH_HISTORIES: 'search_histories',
  STUDY_MODE_ON: 'study_mode_on',
  STUDY_MODE_ON_USER_CHOICE: 'STUDY_MODE_ON_USER_CHOICE',
  LEADERBOARD_TOGGLE: 'leaderboard_toggle',
  TOP_NOTES_CLOSED: 'top_notes_closed',
  PRODUCT_SHOW_UNLOCK_WIDGET: 'product_show_unlock_widget',
  SHOWED_PRODUCT_POPUP: 'showedProductPopup',
  IS_CYPRESS_TESTING_ENV: 'is_cypress_testing_env',
};

export const documentReferralReasons = {
  PLAYLIST_24HR: 'playlist_24hr',
  MORE_FROM_USER: 'more_from_user',
  CLASSMATES_ALSO_UNLOCKED: 'classmates_also_unlocked',
};

export const GA_DEV_ID = 'UA-114591408-1';
export const GA_PROD_ID = 'UA-17858131-2';


export const GTM_FEATURE_ID = '1';
export const GTM_PROD_ID = 'GTM-P8SJ2NW';

// adwords_subscribed_conversion_id
export const GA_SUBSCRIBED_PROD_ID = 'AW-982854327';
export const GA_SUBSCRIBED_DEV_ID = '1';

export const ADWORDS_CONVERSION_LABEL = '/NeT8CMTOsH8Qt9XU1AM';

// export const cookiesKeys = {
//   CLASS_EMAIL: 'react_class_email',
//   FB_OR_GMAIL: 'react_signup_fb_or_gmail',
//   LOGIN_STEP1: 'react_login_user_email',
//   RESET_STEP1: 'react_reset_step1',
//   SHOW_INIT_RESET_FORM: 'react_show_init_reset_password_form',
//   SHOW_RESET_FORM: 'react_show_reset_password_form',
//   SIGNUP_REFERRED_BY: 'react_signup_referred_by_data',
//   SIGNUP_STEP1: 'react_signup_user_email',
//   SIGNUP_STEP2: 'react_signup_step2',
//   SIGNUP_STEP3: 'react_signup_step3',
//   SIGNUP_STEP_NUMBER: 'react_signup_step',
// };

export const FILESTACK_ID = 'AX8w4gmQGRhqp29l84ehBz';

export const IOS_APP_URL = 'https://apps.apple.com/ca/app/oneclass/id1509374392';

export const DEFAULT_THUMBNAIL = '/static/assets/images/documents/thumbnail.png';

export const GOOGLE_OATH_URL = '/auth/google_oauth2';
export const GOOGLE_CLIENT_ID = '653704744881-6vk0dfd4up4144a2hs2kgg8tuotlf7u9.apps.googleusercontent.com';

// Static ID for the Header block. Can be used, for example, to calculate a top offset for the scrollToBlock utility
export const HEADER_ID = '_header_navbar_';
export const HEADER_BANNER_ID = '_header_navbar_banner_';

export const SUBS_CARDS_ID = '_subs_cards_section_';


// TODO: add language support
export const URL_SUFFIX = '.en.html';

export const PWA_ROUTES_KEYS = [
  'askQuestion',
  'homeworkHelp',
  'homeworkHelpChoosePlanSignup',
  'homeworkHelpChoosePlanPurchase',
  'homeworkHelpChoosePlanLogin',
  'hwGradePlus',
  'hwOneclass',
  'hwAllQuestions',
  'hwYourQuestions',
  'hwMyUnlocks',
  'hwBookmarks',
  'yourQuestions',
  'privacy',
  'settings',
  'terms',
];


export const HTML_ROUTES = {
  // hwAllQuestions: '/homework-help/all-questions.en.html',
  about: '/about.en.html',
  academicIntegrity: '/academic-integrity.en.html',
  account: '/settings/account.en.html',
  allMaterials: '/all-materials.en.html',
  askQuestion: '/homework-help/ask-question.en.html',
  askQuestionPaymentPage: '/purchases/hw-plus.en.html?ask-question=true',
  australia: '/all-materials/australia.en.html',
  badges: '/badges',
  blackFridayLanding: '/black-friday.en.html',
  blackFridayPurchaseUrl: '/purchases/hw-plus.en.html?selected_plan_id=hw_unlimited_monthly_jun_2020&source=bf',
  blog: '/blog.en.html',
  blogNewPage: 'https://oneclassblog.com/wp-admin/post-new.php',
  blogsBySchool: '/blog/blog-articles-by-schools.en.html',
  boosterClass: '/courses.en.html',
  boosterClassGroups: '/courses/booster-for-groups.en.html',
  boosterClassPayment: '/purchases/booster-class.en.html',
  boosterClassPersonalInfo: '/booster-classes/college-credit-course/enrol.en.html',
  boosterClassPricing: '/pricing/courses.en.html',
  canada: '/all-materials/canada.en.html',
  changePassword: '/change_password.en.html',
  classNotes: '/class-notes.en.html',
  creditCourseEnroll: '/booster-classes/college-credit-course/enrol.en.html',
  contact: '/about.en.html#contact',
  contributorChampionship: '/contributor-championship.en.html',
  courseFolder: '/course_folders',
  dashboard: '/dashboard',
  dataBreach: '/data-breach.en.html',
  disclaimer: '/faqs.en.html#faq16',
  docUpload: '/upload.en.html',
  editProfile: '/edit_profile.en.html',
  edu: '/edu.en.html',
  examPrep: '/exam-prep.en.html',
  faq: '/faqs.en.html',
  featuredBlog: '/blog/featured.en.html',
  freeTrialError: '/subscriptions/free_trial_error_msg',
  getCredits: '/getcredits',
  gradePlusUpgradeUrl: '/settings/manage-subscription/gradeplus-upgrade.en.html',
  homeworkHelp: '/homework-help.en.html',
  ocLogin: '/login.en.html?source=documents',
  ocSignup: '/signup.en.html?source=documents',
  hwSignup: '/signup.en.html?source=hw',
  homeworkHelpChoosePlanSignup: '/signup.en.html?source=hw',
  homeworkHelpChoosePlanLogin: '/login.en.html?source=hw',
  homeworkHelpChoosePlanPurchase: '/purchases/hw-plus.en.html',
  gradePlusDiscountBanner: '/discount_20_banner',
  gradePlusPurchase: '/purchases/grade-plus.en.html',
  classPlusPurchase: '/purchases/class-plus.en.html',
  gradePlusPurchaseSC: '/purchases/grade-plus.en.html?source=summer_challenge&selected_plan_id=study_guides_unlimited_1_oct_2020',
  gradePlusPricing: '/pricing/grade-plus.en.html',
  homeworkHelpPricing: '/pricing/homework-help.en.html',
  hwAnswers: '/homework-help/answers.en.html',
  hwAutocomplete: '/api/autocomplete',
  // used to handle autocomplete form submit on Enter key press
  hwAutocompleteSubmit: '/api/amp_autocomplete_submit',
  hwBookmarks: '/homework-help/bookmarked.en.html',
  hwGradePlus: '/homework-help/oneclass-grade+.en.html',
  hwYourQuestions: '/homework-help/your-questions.en.html',
  hwMyUnlocks: '/homework-help/my-unlocks.en.html',
  hwOneclass: '/homework-help/oneclass-homework-help.en.html',
  invite: '/invite',
  insights: '/insights.en.html',
  insightsEarnings: '/insights/earnings.en.html',
  jobs: '/jobs.en.html',
  livestream: '/livestream.en.html',
  login: '/login.en.html',
  logout: '/logout',
  logoutPwa: '/app/logout',
  manageSubscription: '/settings/manage-subscription.en.html',
  me: '/me',
  mobileNav: '/settings/mobile-nav.en.html',
  myCourses: '/my-courses',
  newZealand: '/all-materials/new-zealand.en.html',
  notesPricing: '/pricing/notes.en.html',
  notetaker: '/official-note-taker.en.html',
  notetakerProgram: '/my-note-taker-program',
  notifications: '/notifications',
  officialNoteTaker: '/official-note-taker.en.html',
  paymentCalendar: '/payment-calendar',
  payments: '/payments',
  paypal: '/paypal_subscription',
  people: '/people',
  perks: '/perks.en.html#giftcards',
  press: '/about.en.html#press',
  privacy: '/privacy.en.html',
  purchasesGradePlus: '/purchases/grade-plus.en.html',
  purchasesNotesPlus: '/purchases/notes-plus.en.html',
  redemption: '/redemption_history',
  referrals: '/referrals.en.html',
  registerSw: '/register_sw.en.html',
  revshare: 'https://oneclass.com/blog/featured/186410-revenue-sharing-program.en.html',
  rolloutProductBrowse: '/switch-version-endpoint',
  root: '/',
  schools: '/schools.en.html',
  search: '/search.en.html',
  settings: '/settings.en.html',
  settingsManageSubscription: '/settings/manage-subscription.en.html',
  shareLink: '/share_link.en.html',
  shop: 'https://store.oneclass.com',
  signup: '/signup.en.html',
  signupFreeTrial: '/signup-free-trial-redirect.en.html',
  skipAddCourses: '/users/signup/skip_course_step',
  solvit: '/solvit.en.html',
  stopImpersonatingUrl: '/admin/stop_impersonating',
  studyGuides: '/study-guides.en.html',
  studyGuidesPricing: '/pricing/study-guides.en.html',
  subscriptions: '/subscriptions.en.html',
  subscriptionsFreeTrial: schoolId => `/subscriptions.en.html?free_trial=true&school_id=${schoolId}`,
  subscriptionsMonthly: '/purchases/new?notes_plan=notes_unlimited_1_new_2016',
  subscriptionsQuarterly: '/purchases/new?notes_plan=notes_unlimited_3_new_2016',
  subscriptionsYearly: '/purchases/new?notes_plan=notes_unlimited_12_new_2018_04',
  support: '/support.en.html',
  team: '/team.en.html',
  terms: '/terms.en.html',
  textbookNotes: '/textbook-notes.en.html',
  textbooks: '/textbooks.en.html',
  tiktokStoreUrl: 'https://www.tiktok.com/@oneclass.store',
  transactions: '/transactions',
  tutors: '/tutors.en.html',
  unitedStates: '/all-materials/united-states.en.html',
  upload: '/upload.en.html',
  vouchers: '/vouchers.en.html',
  vouchersCreateAccount: '/vouchers.en.html?step=1',
  vouchersPaymentPage: '/vouchers.en.html?step=2',
  websitePrivacy: '/website-privacy.en.html',

};

// TODO: replace hardcoded routes from js
export const ROUTES = new Proxy(HTML_ROUTES, {
  get: (obj, prop) => {
    let newProp = prop;

    const pwa = isPwa();

    if (prop === 'root' && pwa) {
      newProp = 'homeworkHelp';
    }
    if (prop === 'logout' && pwa) {
      newProp = 'logoutPwa';
    }
    const val = obj[newProp];

    if (PWA_ROUTES_KEYS.includes(newProp)) {
      return toPwaUrl({ url: val });
    } if (AMP_ROUTES_KEYS.includes(newProp)) {
      return toAmpUrl({ url: val });
    }

    return val;
  },
});

export const ROUTES_BASE = new Proxy(ROUTES, {
  get: (obj, prop) => {
    const val = obj[prop];
    if (val) {
      const path = getPathFromUrl(val);

      return path.replace('.en.html', '').replace('.en.pwa', '');
    }
    return val;
  },
});

export const DYNAMIC_ROUTES = {
  bcPracticalSummary: ({ sessionId }) => (`/booster-classes/practical/${sessionId}/summary.en.html`),
  directory: (kind, type) => `/${kind}/${kind}-by-${type}.en.html`,
  contributorsAnswerShow: id => `/insights/answers/${id}.en.html`,
  contributorsNoteShow: id => `/insights/notes/${id}.en.html`,
};

const authUrlsMap = {
  hw: {
    source: 'hw',
    payment: ROUTES.homeworkHelpChoosePlanPurchase,
  },
  booster: {
    source: 'booster',
    payment: ROUTES.boosterClassPayment,
  },
  boosterCredit: {
    source: 'booster_credit',
    info: ROUTES.boosterClassPersonalInfo,
    payment: ROUTES.boosterClassPayment,
  },
  boosterAudit: {
    source: 'booster_audit',
    info: ROUTES.boosterClassPerfonalInfo,
    payment: ROUTES.boosterClassPayment,
  },
  doc: {
    source: 'documents',
    payment: ROUTES.gradePlusPurchase,
  },
};

export const authUrlsData = new Proxy(authUrlsMap, {
  get: (obj, prop) => {
    if (!obj[prop]) return obj.doc;

    return obj[prop];
  },
});

export const yearGraduateOptions = [
  {
    label: 'Already graduated',
    value: 'Graduated',
  },
  ...Array(7).fill().map((item, index) => {
    const year = (new Date().getFullYear() + index).toString();

    return {
      label: year,
      value: year,
    };
  }),
];

export const MATERIAL_TYPES = {
  allMaterials: 'all-materials',
  classNotes: 'class-notes',
  textbookNotes: 'textbook-notes',
  studyGuides: 'study-guides',
  examPrep: 'exam-prep',
};

export const NOTES_URL_PREFIXES = {
  allMaterials: {
    label: 'All Materials',
    url: ROUTES.allMaterials,
    prefix: MATERIAL_TYPES.allMaterials,
    kinds: [],
  },
  classNotes: {
    label: 'Class Notes',
    url: ROUTES.classNotes,
    bySchoolUrl: '/class-notes/class-notes-by-schools.en.html',
    prefix: MATERIAL_TYPES.classNotes,
    kinds: [1, 5],
  },
  textbookNotes: {
    label: 'Textbook Notes',
    url: ROUTES.textbookNotes,
    bySchoolUrl: '/textbook-notes/textbook-notes-by-schools.en.html',
    prefix: MATERIAL_TYPES.textbookNotes,
    kinds: [2, 8],
  },
  studyGuides: {
    label: 'Study Guides',
    url: ROUTES.studyGuides,
    bySchoolUrl: '/study-guides/study-guides-by-schools.en.html',
    prefix: MATERIAL_TYPES.studyGuides,
    kinds: [3, 4, 6, 7],
  },
  examPrep: {
    label: 'Exam Prep',
    url: ROUTES.examPrep,
    // bySchoolUrl: '/exam-prep/exam-prep-by-schools.en.html',
    prefix: MATERIAL_TYPES.examPrep,
  },
};

export const SIGNUP_REDIRECT_URL_PREFIXES = [
  'textbooks',
  'perks',
  'purchases',
  'blog',
  ...Object.values(NOTES_URL_PREFIXES).map(obj => obj.url.replace('/', '')),
];

export const COUNTRY_SUFFIXES = {
  ca: '/canada.en.html',
  us: '/united-states.en.html',
  au: '/australia.en.html',
  nz: '/new-zealand.en.html',
  in: '/india.en.html',
  ph: '/philippines.en.html',
};

export const COPYRIGHT = `© Notesolution Inc. - OneClass ${(new Date()).getFullYear()}`;

export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/OneClass',
  twitter: 'https://twitter.com/oneclass',
  youtube: 'https://www.youtube.com/user/Notesolution',
  instagram: 'https://www.instagram.com/myoneclass/',
  wiki: 'https://en.wikipedia.org/wiki/OneClass',
  tiktok: 'https://www.tiktok.com/@oneclass.store?',
};

export const DOCUMENT_TYPE = {
  defaultValue: '/all-materials',
  options: [
    {
      uid: 'all-materials',
      label: 'All Materials',
      value: '/all-materials',
    },
    {
      uid: 'class-notes',
      label: 'Class Notes',
      value: '/class-notes',
    },
    {
      uid: 'textbook-notes',
      label: 'Textbook Notes',
      value: '/textbook-notes',
    },
    {
      uid: 'study-guides',
      label: 'Study Guides',
      value: '/study-guides',
      tag: 'Popular',
    },
  ],
};

export const GoogleMapAPIKey = 'AIzaSyCIx-KKE1xwxSKN6pjtNG5MVENZtptB_gk';
export const DEFAULT_ZOOM = 11;

// Hotjar constants

export const HJID = 1067434;
export const HJSV = 6;
export const HEAP_ID = 377382514;

export const INTERCOM_DEV_APP_ID = 'g0a6thrb';
export const INTERCOM_PROD_APP_ID = 'ovc2qosq';

export const FACEBOOK_DEV_APP_ID = '370218410827336';
export const FACEBOOK_PROD_APP_ID = '460939294111578';

export const FACEBOOK_DEV_PIXEL_ID = '1';
// export const FACEBOOK_PROD_PIXEL_ID = '1325927980848465';
export const FACEBOOK_PROD_PIXEL_ID = '418311779002351';

// Recommendation engine encryption key
export const RECOMMENDATION_KEY = 'p4ssw0rd';

export const CRISP_WEBSITE_ID = '7da003dd-870a-470d-9d95-aa44e18fd979';
// export const CRISP_COOKIE_EXPIRE = 2592000; // 30 days;
export const CRISP_COOKIE_EXPIRE = 30; // 30 seconds

// Google reCaptcha

export const RECAPTCHA_SITE_KEY = '6LdLkb8UAAAAAO4r8s0DbRbIXv3KqwMM9dlq1T_3';

export const STATIC_PAGES_MENU_ITEMS = {
  about: {
    href: ROUTES.about,
    i18nKey: 'about_page_name',
  },
  jobs: {
    href: ROUTES.jobs,
    i18nKey: 'jobs_page_name',
  },
  press: {
    href: ROUTES.press,
    i18nKey: 'press_page_name',
  },
  contact: {
    href: ROUTES.contact,
    i18nKey: 'contact_page_name',
  },
  academicIntegrity: {
    href: ROUTES.academicIntegrity,
    i18nKey: 'academic_integrity_page_name',
  },
};

export const STATIC_PAGES_MENU_ITEMS_ARRAY = Object.values(STATIC_PAGES_MENU_ITEMS);


export const GOOGLE_OPTIMIZE_ID = 'OPT-WW2LMM4';


// images directories
export const ACADEMIC_INTEGRITY_IMAGES_PATH = '/pages/academic_integrity/';

export const DEFAULT_I18N = ['common', 'navbar', 'search', 'forms'];

export const EMPTY_SCHOOL_IDS = [919, 920, 921];

export const ENFORCE_HTML_PARAM = 'enforce_html';
export const ASK_QUESTION_PARAM = 'ask-question';

export const SUBSCRIPTIONS_LINKS = {
  studyguide: ROUTES.gradePlusPricing,
  hw: ROUTES.homeworkHelpPricing,
  notes: ROUTES.notesPricing,
};

export const SUB_TYPES = {
  studyguide: 'studyguide',
  notes: 'notes',
  hw: 'hw',

};

export const SUB_GRADE_LEVELS = {
  [SUB_TYPES.studyguide]: 2,
  [SUB_TYPES.notes]: 1,
  [SUB_TYPES.hw]: 0,
};


export default {};
