import router from 'next-routes';

const patchRouterInstance = ({ router: customRouter, routesConfig = {} }) => {
  const routerObj = customRouter ? router({
    Router: customRouter,
  }) : router();

  Object.entries(routesConfig).forEach(([name, { pattern, page, isStatic }]) => {
    routerObj.add({ name, pattern, page });

    if (isStatic) {
      routerObj.routes[routerObj.routes.length - 1].isStatic = isStatic;
    }
    // if (Array.isArray(pattern) && /\.amp/.test(pattern[0])) {
    //   routerObj.routes[routerObj.routes.length - 1].isAmp = true;
    // }
    if (Array.isArray(pattern) && /\.pwa/.test(pattern[0])) {
      routerObj.routes[routerObj.routes.length - 1].isAmp = true;
    }
  });

  return routerObj;
};

export default patchRouterInstance;
