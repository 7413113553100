import React, { PureComponent, Fragment } from 'react';
import { withGlobalProps } from 'components/pages/HomeworkHelp/GlobalPropsProvider';

import {
  string, node, bool, oneOfType, oneOf, func, number,
} from 'prop-types';
import cn from 'classnames';


import Icon from 'oc-core-components/src/Icon';

import { tagType } from 'types';

import { sizes, variants } from 'oc-core-components/src/Button/styleConfig';

const PREFIX = 'oc-btn';
export class Button extends PureComponent {
  static propTypes = {
    className: string,
    children: node,
    size: oneOf(sizes), // Button Size (e.g sm, lg, etc.)
    color: oneOf(variants), // Button Color (e.g. primary, alt, etc.)
    activeColor: oneOf(variants), // Active State Button Color (e.g. primary, alt, etc.)
    outline: bool, // transparent background + border
    active: bool, // active state
    disabled: bool, // disabled state (disabled attribute + standard disabled class)
    tag: tagType,
    innerRef: oneOfType([func, string]), // ref prop
    fullWidth: bool, // Full width button
    mobileFullWidth: bool, // Full width button
    shadowed: bool, // add shadow
    round: bool, // Round Button
    icon: string, // Icon name for the round button
    iconClassName: string,
    iconSize: oneOfType([number, string]), // Icon Size
    iconFill: string, // Icon Fill
    iconStroke: string, // Icon Stroke
    reverseIcon: bool, // render Icon after children
    onClick: func,
    social: bool, // social button, e.g. facebook, google (additional styles are required)
    roundBorders: bool, // add big border radius
    disableInactive: bool, // never add is-inactive class
  };

  static defaultProps = {
    color: variants[0],
    tag: 'button',
    iconSize: 'sm',
  }

  onClick = (e) => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    /* eslint-disable prefer-const */
    let {
      className,
      tag: Tag,
      active,
      disabled,
      color,
      activeColor,
      outline, // TODO: deprecated
      size,
      innerRef,
      fullWidth,
      round,
      shadowed,
      children,
      icon,
      iconClassName,
      iconSize,
      iconFill,
      iconColor,
      iconStroke,
      social,
      reverseIcon,
      roundBorders,
      // styles,
      disableInactive,
      mobileFullWidth,
      ...attributes
    } = this.props;
    /* eslint-enable prefer-const */

    if (attributes.href) {
      Tag = 'a';
    }

    const withHover = !!(attributes.href || attributes.onClick || attributes.on);

    const classNames = cn(
      className,
      `${PREFIX}__btn`,
      {
        // [`with-icon--${size}`]: (size && icon) && (!round && React.Children.count(children) > 0),
        [`${PREFIX}__reverse`]: reverseIcon,
        [`${PREFIX}__${color}`]: color && !round,
        [`${PREFIX}__${color}-outline`]: outline && color && !round,
        [`${PREFIX}__${size}`]: size && !round,
        [`${PREFIX}__social`]: social,
        [`${PREFIX}__round`]: round,
        [`${PREFIX}__full`]: fullWidth && !round,
        [`${PREFIX}__full-mobile`]: mobileFullWidth && !round,
        [`${PREFIX}__toggle--${activeColor}`]: activeColor && !round,
        [`${PREFIX}__shadowed`]: shadowed,
        [`${PREFIX}__round-borders`]: roundBorders,
        [`${PREFIX}__clickable`]: withHover && !disabled,
        'is-active': active,
        'is-inactive': !withHover && !disableInactive,
        'is-disabled': disabled,
      },
    );

    const ButtonIcon = () => (
      <Icon
        icon={icon}
        stroke={iconStroke}
        size={iconSize}
        fill={iconFill}
        color={iconColor}
        className={cn(`${PREFIX}__icon`, { [`${PREFIX}__reverse`]: reverseIcon }, iconClassName)}
      />
    );


    return (
      <Tag
        type={(Tag === 'button' && attributes.onClick) ? 'button' : undefined}
        {...attributes}
        className={classNames}
        ref={innerRef}
        onClick={this.onClick}
      >
        {
          (icon && !reverseIcon)
          && ButtonIcon()
        }
        <Fragment>
          {
            children
          }
        </Fragment>
        {
          (icon && reverseIcon)
          && ButtonIcon()
        }
      </Tag>
    );
  }
}

export {
  Button as RawButton,
};

export default Button;
