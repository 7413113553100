import React, { PureComponent } from 'react';
import { shape, arrayOf, func } from 'prop-types';
import DeferredAutocomplete from 'oc-core-components/src/DeferredAutocomplete';
import { numberString } from 'types';
import { isFunction } from 'helpers/utils';

import { getSchools } from './utils';


class SchoolAutocomplete extends PureComponent {
  state = {
    suggestions: [],
  }

  inputChangeHandler = (val) => {
    const {
      request,
      itemToLabel,
      onSuggestionsLoad,
      excludedItemIds,
    } = this.props;

    const searchQuery = val?.toUpperCase();

    return getSchools(searchQuery, this.props.countryId, { request })
      .then(({ data }) => {
        const {
          hits: { hits: rawHits } = {},
        } = data || {};

        let result = rawHits?.map(({
          _source: source,
        }) => {
          const {
            id,
            all_materials_url: url,
            shortened_name: tags,
            short_handle: shortHandle,
            state,
          } = source;

          return ({

            // some course codes are written in the following format:
            // {variation 1},{variation 2}
            // We need to show only the first variation
            type: 'school',
            label: itemToLabel(source),
            value: id.toString(),
            url,
            shortHandle,
            tagNames: typeof tags === 'string' ? tags.split(',') : [],
            state,
          });
        });

        if (excludedItemIds && Array.isArray(excludedItemIds)) {
          const excludedIds = excludedItemIds.map(id => `${id}`);

          result = result.filter(item => !excludedIds.includes(item?.value));
        }

        if (isFunction(onSuggestionsLoad)) {
          onSuggestionsLoad({ items: result, val });
        }

        return result;
      });
  };

  render() {
    const {
      suggestions,
    } = this.state;

    const {
      items: customItems,
      countryId,
      disabled,
      ...restProps
    } = this.props;

    return (
      <DeferredAutocomplete
        promise={this.inputChangeHandler}
        items={suggestions}
        disableFiltering
        disabled={disabled || !countryId}
        {...restProps}
      />
    );
  }
}

SchoolAutocomplete.defaultProps = {
  itemToLabel: ({ name } = {}) => name,
};

SchoolAutocomplete.propTypes = {
  countryId: numberString,
  request: func,
  items: arrayOf(shape()),
  itemToLabel: func,
  onSuggestionsLoad: func,
};

export default SchoolAutocomplete;
