import { getNotifications } from 'common/api';
import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';


import { loadingNotificationsRequest, storeV2Notifications, setV2NotificationsLoaded } from 'v2/redux/notification/actions';
import { selectNotificationLastPage } from 'v2/redux/notification/selectors';

import {
  getV2IsLoggedIn,
} from 'v2/redux/meta/selectors';

import types from 'v2/redux/notification/types';

function* fetchNotifications() {
  const lastPage = yield select(selectNotificationLastPage);
  const isLoggedIn = yield select(getV2IsLoggedIn);

  if (isLoggedIn) {
    yield put(loadingNotificationsRequest.start());

    const nextPage = lastPage + 1;

    const res = yield call(getNotifications, nextPage);


    const data = res?.data?.notifications;

    if (data) {
      if (data?.length > 0) {
        yield put(storeV2Notifications({ notifications: data, page: nextPage }));
      } else {
        yield put(setV2NotificationsLoaded(true));
      }
    }


    yield put(loadingNotificationsRequest.stop());
  }
}


function* notificationSaga() {
  yield takeLatest(types.FETCH_NOTIFICATIONS.SELF, fetchNotifications);
}

export default notificationSaga;
