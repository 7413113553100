import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getV2CurrentUser } from 'v2/redux/meta/selectors';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import { getUserProfileLink } from 'common/user/utils';
import TutorWelcomeModal from 'components/modals/TutorWelcomeModal';

import { dummyFunc } from 'shared/helpers/utils';

const useTutorWelcomePopup = () => {
  const currentUser = useSelector(getV2CurrentUser) || {};
  const { showTutorWelcomePopup } = currentUser;

  const [isTutorWelcomePopupOpen, setOpenState] = useState(showTutorWelcomePopup);
  // const [isTutorWelcomePopupOpen, setOpenState] = useState(true); // use it to debug tooltip
  const onTutorWelcomePopupClose = useCallback(() => { setOpenState(false); }, []);


  // if no currentUser or if announcement has been already shown
  return isTutorWelcomePopupOpen ? {
    isTutorWelcomePopupOpen,
    onTutorWelcomePopupClose,
  } : {
    isTutorWelcomePopupOpen: false,
    onTutorWelcomePopupClose: dummyFunc,
  };
};

const useLoadTutorWelcomePopup = () => {
  const currentUser = useSelector(getV2CurrentUser) || {};
  const { isTutorWelcomePopupOpen } = useTutorWelcomePopup();
  const isMobile = useMobile();
  const { inline } = useModal();
  const profileLink = getUserProfileLink(currentUser?.id);

  useEffect(() => {
    // if (!isMobile) return;
    if (!isTutorWelcomePopupOpen) return;

    if (isTutorWelcomePopupOpen) {
      inline({
        content: () => (
          <TutorWelcomeModal
            profileLink={profileLink}
          />
        ),
      });
    }
  }, [isTutorWelcomePopupOpen, isMobile, profileLink, inline]);
};

export default useTutorWelcomePopup;
export {
  useLoadTutorWelcomePopup,
};
