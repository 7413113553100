import { handleModelActions } from 'v2/redux/utils/handleActions';
// import { logErrorDev } from 'helpers/utils';
// import types from './types';

import { REFERRAL_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const referralReducer = handleModelActions(
  REFERRAL_MODEL_TYPE,
  {

  },
  initialState,
);

export { initialState };

export default referralReducer;
