// https://marmelab.com/blog/2016/10/18/using-redux-saga-to-deduplicate-and-group-actions.html
import {
  takeEvery, put, call, cancel, fork,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import { catchError } from 'v2/redux/actions';

import { getApiPathFromUrl, isEmpty } from 'helpers/utils';

import { getAPIData } from 'common/api';

import types from 'v2/redux/page/types';

// tasks = {
//   resourceId: {
//     task,
//     params: {
//       [key]: arrayOf(ids),
//     },
//     meta: {
//       url: '',
//       loadingAction,
//       callbackAction,
//       ...restMeta,
//     },
//   }
// }

const tasks = {};

function* fetchReference(resource) {
  const taskData = tasks[resource];
  try {
    // combined with cancel(), this debounces the calls
    yield delay(50);

    if (taskData) {
      const {
        meta: {
          url,
          actions: {
            callback: callbackAction,
            loading: loadingAction,
          } = {},
          ...restMeta
        },
        params,
      } = taskData;


      const requestParams = omitBy(mapValues(params, o => Object.keys(o).join(',')), el => !el);

      // if (!isEmpty(requestParams)) {

      // }

      if (callbackAction) {
        if (loadingAction?.start) {
          yield put(loadingAction.start());
        }

        const { data } = yield call(getAPIData, {
          url: getApiPathFromUrl(url),
          params: requestParams,
        });

        yield put(callbackAction({
          ...restMeta,
          data,
          stopLoading: loadingAction?.stop,
        }));
      }

      delete tasks[resource];

      // Object.entries(groupedByUrl).forEach(([url, urlGroup]) => {
      //   const groupedByPage = groupBy(urlGroup, 'page');

      //   Object.entries(groupedByPage).forEach(([page, pageGroup]) => {
      //     const ids = pageGroup.map(obj => obj.id);

      //     put(retrieveDataBySections({
      //       page,
      //       sections: ids,
      //       url,
      //       topSchoolIds: [],
      //     }));
      //   });
      // });
    }
  } catch (e) {
    // TODO: refactor
    const stopLoading = taskData?.meta?.actions?.loading?.stop;

    if (stopLoading) {
      yield put(stopLoading(e));
    }

    yield put(catchError(e));
  }
}

function* accumulate({ payload, meta }) {
  try {
    const {
      resource,
      ...restMeta
    } = meta;

    if (!tasks[resource]) {
      tasks[resource] = {
        task: null,
        params: {},
        meta: {},
      };
    }

    const taskData = tasks[resource];
    const { params } = taskData;

    Object.entries(payload).forEach(([key, id]) => {
      if (!params[key]) {
        params[key] = {};
      }

      if (id) {
        params[key][id] = true;
      }
    });

    if (taskData.task) {
      yield cancel(taskData.task);
    }

    taskData.task = yield fork(fetchReference, resource);
    taskData.meta = { ...taskData.meta, ...restMeta };
  } catch (e) {
    yield put(catchError(e));
  }
}

function* accumulateFetchSaga() {
  yield takeEvery(types.ACCUMULATE_FETCH, accumulate);
}

export default accumulateFetchSaga;
