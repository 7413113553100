/* eslint-disable global-require */
import { isBrowser } from 'helpers/utils';
// import repeatPolyfill from 'helpers/polyfills/repeat';
import loadCssPolyfill from '@dotsunited/load-css-polyfill';
// if (typeof Promise === 'undefined') {
//   // Rejection tracking prevents a common issue where React gets into an
//   // inconsistent state due to an error, but it gets swallowed by a Promise,
//   // and the user has no idea what causes React's erratic future behavior.
//   require('promise/lib/rejection-tracking').enable();
//   window.Promise = require('promise/lib/es6-extensions.js');
// }
// repeatPolyfill();

// require('@dotsunited/load-css-polyfill')();
// require('date-time-format-timezone');

if (isBrowser) {
  // require('whatwg-fetch');
  loadCssPolyfill();
  // require('nodelist-foreach-polyfill');
}

// loadCssPolyfill();
// require('nodelist-foreach-polyfill');

// // fetch() polyfill for making API calls.
// // require('whatwg-fetch');

// // Object.assign() is commonly used with React.
// // It will use the native implementation isf it's present and isn't buggy.
// Object.assign = require('object-assign');

// // Array.from polyfill https://www.npmjs.com/package/array-from
// if (!Array.from) Array.from = require('array-from');

// require('isomorphic-unfetch');

// require('es6-shim');
// require('@babel/polyfill');
// require('universal-url').shim();
// if (isBrowser) window.Stickyfill = require('stickyfilljs');

if (isBrowser) {
  // eslint-disable-next-line global-require
  require('intersection-observer'); // polyfill
  require('resize-observer-polyfill/dist/ResizeObserver.global'); // polyfill
}
