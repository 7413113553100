import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';
import { arrayOfShapes, LinkListType } from 'types';
import getAuthLink from 'helpers/utils/getAuthLink';
import {
  selectToolbarMenuItems,
  selectIsLoggedIn,
} from 'common/page/selectors';
import { withTranslation } from 'config/i18n';
import { getV2CurrentPage } from 'v2/redux/page/selectors';
import { goToHomeworkHelpWithCookie, goToAskQuestionPage } from 'components/pages/HomeworkHelp/utils';

import { withModal, modalContextPropTypes } from 'oc-core-components/src/Modal/ModalProvider';
import { trackAskQuestionButtonClick } from 'helpers/tracking';

import { UPLOAD_COURSE_MODAL, ADD_COURSES_MODAL } from 'oc-core-components/src/Modal/constants';
import { isFunction } from 'helpers/utils';
import Toolbar from './Toolbar';


// TODO: Refactor
const getHandlers = ({
  modal, isLoggedIn, currentPage, toolbarHandlers, authLinkParams, loginLinkParams, signupLinkParams,
}) => ({
  askQuestion: () => {
    trackAskQuestionButtonClick({
      trackId: 'mobile_nav_bar',
      url: window.location.href,
    });

    if (currentPage === 'homeworkHelp') {
      goToAskQuestionPage();
    } else {
      goToHomeworkHelpWithCookie();
    }
  },
  logIn: (e) => {
    e.preventDefault();
    window.location.assign(getAuthLink({ login: true, ...authLinkParams, ...loginLinkParams }));
  },
  signUp: (e) => {
    e.preventDefault();
    window.location.assign(getAuthLink({ ...authLinkParams, ...signupLinkParams }));
  },
  addCourse: isLoggedIn ? () => modal.open(ADD_COURSES_MODAL) : null,
  upload: () => modal.open(UPLOAD_COURSE_MODAL),
  bookmarks: (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      modal.openLogin();
    }
  },
  myQuestions: (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      modal.openLogin();
    }
  },
  myUnlocks: (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      modal.openLogin();
    }
  },
  ...toolbarHandlers,
});

class ToolbarContainer extends PureComponent {
  onItemClick = (uid, e) => {
    const handlers = getHandlers(this.props);
    const handler = handlers[uid];

    if (isFunction(handler)) {
      e?.preventDefault();
      handler(e);
    }
  }

  render() {
    const {
      items,
      isLoggedIn,
      renderBefore,
    } = this.props;

    return (
      <Toolbar
        items={items}
        isLoggedIn={isLoggedIn}
        onItemClick={this.onItemClick}
        renderBefore={renderBefore}
      />
    );
  }
}


const mapStateToProps = (state, props) => ({
  items: selectToolbarMenuItems(state, props),
  isLoggedIn: selectIsLoggedIn(state, props),
  currentPage: getV2CurrentPage(state),
});

ToolbarContainer.propTypes = {
  items: arrayOfShapes({
    label: string,
    url: string,
    items: LinkListType,
  }),
  modal: modalContextPropTypes,
  isLoggedIn: bool,
};

export default withTranslation()(connect(mapStateToProps, null)(withModal(ToolbarContainer)));
