import React from 'react';
import LazyImage from 'oc-core-components/src/LazyImage';
import Link from 'oc-core-components/src/Link';
import Avatar from 'oc-core-components/src/Avatar';
import cn from 'classnames';

import {
  string, node, arrayOf, shape, oneOfType, bool, array,
} from 'prop-types';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './RoundThumbCard.module.scss';

const styles = stylesProxy(stylesObj, 'RoundThumbCard');

const RoundThumbCard = ({
  src,
  href,
  title,
  subtitle,
  sources,
  alt,
  withAvatar,
  user,
  ...restProps
}) => {
  let altText = alt;
  if (!altText) {
    altText = 'Default user avatar';
  }

  return (
    <div {...restProps}>
      {
        withAvatar && (
          <Avatar
            src={src}
            alt={altText || title}
            className={cn(styles.img, { [styles['img--ambassador']]: user?.ambassador })}
            href={href}
            ambassador={user?.ambassador}
            ambassadorProps={{
              iconWrapperSize: 24,
              mobileIconWrapperSize: 20,
              iconSize: 20,
              mobileIconSize: 16,
            }}
          />
        )
      }

      {
        !withAvatar && src && (typeof src === 'string') && (
          <Link
            href={href}
            className={styles.img}
          >
            <LazyImage
              width={72}
              height={72}
              src={src}
              alt={alt || title}
              sources={sources}
            />
          </Link>
        )
      }
      {
        title && (
          <h5 className={styles.title}><Link className="u-inherit-link" title={title} href={href}>{title}</Link></h5>
        )
      }
      {
        subtitle && (
          <div className={styles.subtitle}>
            {subtitle}
          </div>
        )
      }
    </div>
  );
};

RoundThumbCard.propTypes = {
  src: oneOfType([string, array]),
  href: string,
  title: string,
  subtitle: node,
  alt: string,
  sources: arrayOf(shape()),
  withAvatar: bool,
};

export default RoundThumbCard;
