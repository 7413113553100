import handleActions from 'v2/redux/utils/handleActions';
import { SESSION_STATUS } from 'v2/redux/page/boosterClass/constants';

import {
  logErrorDev, camelizeKeys, isObject,
} from 'helpers/utils';
import combineMerge from 'helpers/utils/combineMerge';

import pullAll from 'lodash/pullAll';

import types from './types';

const initialState = {
  currentPage: '',
  // show search overlay on mobile
  // TODO: temp solution; ui states should not be stored in redux
  isSearchOverlayOpen: false,
  isStudyModeOn: false,
  isStudyModeTooltipOpen: false,
  tempAnswerDraft: null,
};

function insertArrayAt(array, index, arrayToInsert) {
  Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
  return array;
}

const pageReducer = handleActions(
  {
    [types.SHOW_ANSWER_FORM]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.showAnswerForm = payload;
    },
    [types.UPDATE_CHART_DATA]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.chartData = payload;
    },
    [types.UPDATE_PAYMENT_FORM]: (state, { payload }) => {
      const pageId = state.currentPage;

      const isValidPayload = (
        payload?.gradePlus?.paymentPlans?.length > 0
        || payload?.classPlus?.paymentPlans?.length > 0
        || payload?.homeworkHelp?.paymentPlans?.length > 0
      );

      if (!state[pageId].props.originalPaymentForm && isValidPayload) {
        // const obj = JSON.parse(JSON.stringify(state[pageId].props.paymentForm));
        // obj.classPlus.paymentPlans[0].totalPrice = 86;
        // state[pageId].props.originalPaymentForm = state[pageId].props.paymentForm;
        // TODO: replace with deep copy
        state[pageId].props.originalPaymentForm = JSON.parse(JSON.stringify(state[pageId].props.paymentForm));

        // state[pageId].props.paymentForm = obj;
        // Here we have to perform a granular update, because unfortunately backend returns only updated plans in payload
        // i.e. initially there was paymentForm { gradePlus, classPlus } plans on the page
        // after promo code applied backend returns { gradePlus } plans in payload
        // that's why we have to preserve original { classPlus } plans
        const {
          classPlus,
          gradePlus,
          homeworkHelp,
        } = payload;
        if (classPlus) {
          state[pageId].props.paymentForm.classPlus = JSON.parse(JSON.stringify(classPlus));
        }
        if (gradePlus) {
          state[pageId].props.paymentForm.gradePlus = JSON.parse(JSON.stringify(gradePlus));
        }
        if (homeworkHelp) {
          state[pageId].props.paymentForm.homeworkHelp = JSON.parse(JSON.stringify(homeworkHelp));
        }
        // state[pageId].props.paymentForm = JSON.parse(JSON.stringify(payload));
      }
    },
    [types.RESET_PAYMENT_FORM]: (state, { payload }) => {
      const pageId = state.currentPage;

      if (state[pageId].props.originalPaymentForm) {
        state[pageId].props.paymentForm = JSON.parse(JSON.stringify(state[pageId].props.originalPaymentForm));
        state[pageId].props.originalPaymentForm = undefined;
      }
    },
    [types.SET_SEARCH_OVERLAY]: (state, { payload }) => ({
      ...state,
      isSearchOverlayOpen:
        payload === undefined ? !state.isSearchOverlayOpen : payload,
    }),
    [types.SET_STUDY_MODE]: (state, { payload }) => ({
      ...state,
      isStudyModeOn:
        payload === undefined ? !state.isStudyModeOn : payload,
    }),
    [types.SET_STUDY_MODE_TOOLTIP]: (state, { payload }) => ({
      ...state,
      isStudyModeTooltipOpen:
        payload === undefined ? !state.isStudyModeTooltipOpen : payload,
    }),
    [types.SET_CURRENT_PAGE]: (state, { payload }) => {
      if (typeof payload !== 'string') {
        logErrorDev('Current Page State is incorrect', payload);
      }
      state.currentPage = payload;
    },

    [types.STORE_META]: (state, { payload, meta: { mergingStrategy } = {} }) => {
      state.meta = combineMerge(state.meta, camelizeKeys(payload?.meta), { strategy: mergingStrategy });
    },
    [types.UPDATE_AVATAR]: (state, { payload }) => {
      state.meta.currentUser.avatar = payload;
    },

    [types.STORE_PAGE_PROPS]: (state, { payload, meta: { mergingStrategy, pageId } = {} }) => {
      if (!state[pageId]) {
        state[pageId] = { props: {} };
      }
      state[pageId].props = combineMerge(state[pageId].props, camelizeKeys(payload?.pageProps), { strategy: mergingStrategy });
    },

    [types.REMOVE_IDS_FROM_PAGE_LIST_DATA]: (state, { payload }) => {
      const {
        ids,
        uid,
        deleteAll,
      } = payload;

      const pageId = state.currentPage;
      const pageList = state[pageId]?.props?.pageLists?.find(l => l.uid === uid);

      if (Array.isArray(pageList?.data?.ids)) {
        const idsArr = Array.isArray(ids) ? ids : [ids];

        // pullAll(pageList.data.ids, idsArr);
        let newIds;

        if (isObject(ids)) {
          newIds = pageList?.data?.ids?.filter(({ id }) => !idsArr.find(obj => obj?.id === id));
        } else {
          newIds = pageList?.data?.ids?.filter(i => !idsArr.includes(i));
        }

        pageList.data.ids = deleteAll ? [] : newIds;
      }
    },
    [types.INSERT_IDS_IN_PAGE_LIST_DATA]: (state, { payload }) => {
      const {
        ids,
        position,
        uid,
        // optional for second else (if pageList is missing)
        modelType,
      } = payload;

      const pageId = state.currentPage;
      const pageList = state[pageId]?.props?.pageLists?.find(l => l.uid === uid);

      if (Array.isArray(pageList?.data?.ids)) {
        const idsArr = Array.isArray(ids) ? ids : [ids];

        pageList.data.ids = insertArrayAt(pageList.data.ids, position, idsArr);
      } else if (ids && uid && !pageList && state[pageId]?.props?.pageLists) {
        const idsArr = Array.isArray(ids) ? ids : [ids];

        state[pageId].props.pageLists = [
          ...state[pageId]?.props?.pageLists,
          {
            uid,
            section_type: uid,
            data: {
              type: modelType,
              ids: idsArr,
            },
          },
        ];
      }
    },
    [types.UPDATE_IS_PURCHASED]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.isPurchased = payload;
    },

    [types.UPDATE_STREAM_VIDEO_SCHEDULE_ID]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.streamVideoScheduleId = payload;
    },

    [types.STORE_STREAM_ACTIVE]: (state, { payload }) => {
      state.meta.streamActive = payload;
    },

    [types.STORE_SKILL_TIME_SLOTS]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.skillTimeSlots = payload;
    },

    [types.SET_USER_FEEDBACK]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.userFeedback = payload;
    },

    [types.STORE_HOT_COURSES]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.hotCourses = payload?.map(obj => camelizeKeys(obj)) || [];
    },
    [types.STORE_SHOW_HOT_COURSES_BANNER]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.showHotCourseBanner = payload;
    },

    [types.STORE_BC_ANNOTATION]: (state, { payload }) => {
      const pageId = state.currentPage;

      if (!payload.id) {
        logErrorDev('annotation id is missing', payload);
      }

      if (state[pageId].props.annotations) {
        state[pageId].props.annotations[payload.id] = payload;
      }
    },

    [types.UPDATE_BC_ANNOTATION]: (state, { payload }) => {
      const pageId = state.currentPage;

      const { annotations } = state[pageId].props;
      const {
        id, // real or fake
        uid, // fake id
        draft,
        ...rest
      } = payload;

      const annId = uid || id;

      if (annotations && annotations[annId]) {
        state[pageId].props.annotations[annId] = {
          ...annotations[annId],
          id,
          draft: false,
          ...rest,
        };
      }
    },

    [types.REMOVE_BC_ANNOTATION]: (state, { payload = {} }) => {
      const pageId = state.currentPage;
      const {
        uid,
        content_only: contentOnly,
      } = payload;

      const { annotations } = state[pageId].props;

      if (annotations && annotations[uid]) {
        if (contentOnly) {
          delete state[pageId].props.annotations[uid].note;
        } else {
          delete state[pageId].props.annotations[uid];
        }
      }
    },

    [types.STORE_BC_COMPLETE]: (state, { payload }) => {
      const pageId = state.currentPage;

      const { sidebar } = state[pageId].props;

      const type = payload?.type;
      const completed = payload?.completed;

      if (type && sidebar && sidebar[type] && isObject(sidebar[type])) {
        sidebar[type].status = completed ? SESSION_STATUS.COMPLETED : SESSION_STATUS.NOT_STARTED;
      }

      state[pageId].props.completed = payload;
    },
    [types.HIGHLIGHT_UNLOCK_QUESTION_BTN_ON]: (state) => {
      const pageId = state.currentPage;

      state[pageId].props.highlightUnlockQuestionBtn = true;
    },
    [types.HIGHLIGHT_UNLOCK_QUESTION_BTN_OFF]: (state) => {
      const pageId = state.currentPage;

      state[pageId].props.highlightUnlockQuestionBtn = false;
    },
    // [types.UPDATE_USER_SETTINGS_NOTIFICATION]: (state, { payload }) => {
    //   const pageId = state.currentPage;

    //   const {
    //     group: groupName,
    //     values,
    //   } = payload;


    //   const group = state[pageId].props.notifications[groupName];

    //   if (group) {
    //     state[pageId].props.notifications[groupName] = { ...group, ...values };
    //   }
    // },
    [types.UPDATE_USER_SETTINGS_NOTIFICATION]: (state, { payload = {} }) => {
      const pageId = state.currentPage;
      const { notifications } = state[pageId].props;

      const newData = { ...notifications, ...camelizeKeys(payload) };

      state[pageId].props.notifications = newData;
    },

    [types.UPDATE_TOPIC_FOLLOW]: (state, { payload }) => {
      const pageId = state.currentPage;

      if (!isObject(payload)) {
        return;
      }

      Object.entries(payload).forEach(([id, value]) => {
        if (state[pageId].props.allTopics[id]) {
          state[pageId].props.allTopics[id].followed = value;
        }
      });
    },

    [types.STORE_TEMP_ANSWER_DRAFT]: (state, { payload }) => {
      state.tempAnswerDraft = payload;
    },

    [types.UPDATE_HAS_UNPUBLISHED_DOCS]: (state, { payload }) => {
      state.hasUnpublishedDocs = payload;
    },

    [types.UPDATE_WATCH]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.watched = {
        ...state[pageId].props.watched,
        ...payload,
      };
    },
    [types.SET_WAS_LIKED]: (state, { payload }) => {
      const pageId = state.currentPage;

      state[pageId].props.wasLiked = !!payload;
    },
    [types.REMOVE_LATEST_ENROLLMENT_BY_ID]: (state, { payload }) => {
      const pageId = state.currentPage;

      const latestEnrollmentIds = state[pageId]?.props?.latestEnrollmentIds;


      if (Array.isArray(latestEnrollmentIds)) {
        const ids = Array.isArray(payload) ? payload : [payload];
        pullAll(latestEnrollmentIds, ids);
      }
    },
    [types.SET_SECTION_CHAPTER]: (state, { payload }) => {
      const pageId = state.currentPage;
      const {
        sectionsList,
        problemsList,
      } = payload || {};

      if (sectionsList) {
        state[pageId].props.sectionsList = { ...state[pageId].props.sectionsList, ...sectionsList };
        // console.log('sectionsList', state[pageId].props.sectionsList);
      }


      if (problemsList) {
        state[pageId].props.problemsList = { ...state[pageId].props.problemsList, ...problemsList };
        // console.log('problemsList', state[pageId].props.problemsList);
      }
    },
  },
  initialState,
);

export { initialState };

export default pageReducer;
