import { handleActions } from 'redux-actions';
import mergeable from 'redux-merge-reducers';

import merge from 'lodash/merge';
import {
  RECEIVE_COUNTRY_DATA,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
} from './constants';

const initialState = {
  data: {},
  loadingCountries: false,
};

const reducer = handleActions(
  {
    [RECEIVE_COUNTRY_DATA]: (state, { payload }) => ({
      ...state,
      data: merge({}, state.data, { ...payload }),
    }),
    [GET_COUNTRIES]: state => ({ ...state, loadingCountries: true }),
    [GET_COUNTRIES_SUCCESS]: state => ({ ...state, loadingCountries: false }),
    [GET_COUNTRIES_ERROR]: state => ({ ...state, loadingCountries: false }),
  },
  { ...initialState },
);

export default mergeable(reducer);
