// import { createAction } from 'redux-actions';

import {
  getNotificationsCounter as getNotificationsCountRequest,
} from 'common/api';

import {
  RECEIVE_NOTIFICATION,
  CLEAR_NOTIFICATION,
  GET_NOTIFICATIONS_COUNT,
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_COUNT_ERROR,
  CLEAR_NOTIFICATIONS_COUNT,
} from './constants';

// const receiveNotification = createAction(RECEIVE_NOTIFICATION, res => res);
// const getNotifications = createAction(GET_NOTIFICATIONS);
// const getNotificationsSuccess = createAction(GET_NOTIFICATIONS_SUCCESS, res => res);
// const getNotificationsError = createAction(GET_NOTIFICATIONS_ERROR, res => res);
// const getNotificationsCount = createAction(GET_NOTIFICATIONS_COUNT);
// const getNotificationsCountSuccess = createAction(GET_NOTIFICATIONS_COUNT_SUCCESS, res => res);
// const getNotificationsCountError = createAction(GET_NOTIFICATIONS_COUNT_ERROR, res => res);
// const clearNotificationsCount = createAction(CLEAR_NOTIFICATIONS_COUNT);
// const clearNotification = createAction(CLEAR_NOTIFICATION);

const receiveNotification = res => ({ type: RECEIVE_NOTIFICATION, res });
const getNotificationsCount = () => ({ type: GET_NOTIFICATIONS_COUNT });
const getNotificationsCountSuccess = res => ({ type: GET_NOTIFICATIONS_COUNT_SUCCESS, res });
const getNotificationsCountError = res => ({ type: GET_NOTIFICATIONS_COUNT_ERROR, res });
const clearNotificationsCount = () => ({ type: CLEAR_NOTIFICATIONS_COUNT });
const clearNotification = () => ({ type: CLEAR_NOTIFICATION });


function retrieveNotificationsCount() {
  return function action(dispatch) {
    dispatch(getNotificationsCount());
    const request = getNotificationsCountRequest();
    return request.then(
      ({ data }) => dispatch(getNotificationsCountSuccess(data?.notifications_count)),
      () => dispatch(getNotificationsCountError({ error: 'retrieve notifications count error' })),
    );
  };
}

export {
  receiveNotification,
  retrieveNotificationsCount,
  clearNotification,
  clearNotificationsCount,
};
