import React from 'react';
import BCEmoji from 'shared/components/BCEmoji';
import Link from 'oc-core-components/src/Link';
import Icon from 'oc-core-components/src/Icon';
import { stylesProxy } from 'helpers/css';
import { useTranslation } from 'config/i18n';
import { ROUTES } from 'shared/helpers/constants';
import stylesObj from './InviteBanner.module.scss';

const styles = stylesProxy(stylesObj, 'InviteBanner');

const InviteBanner = ({}) => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <BCEmoji name="party" size={20} />
      <div className={styles.text}>{t('invite_banner_title')}</div>
      <Link
        href={ROUTES.tutors}
        className={styles.link}
        link
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('lets_start_now')}
        <Icon className={styles.arrow} icon="feather:arrow-right" size={16} />
      </Link>
    </div>
  );
};
export default InviteBanner;
