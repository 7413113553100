import React, { PureComponent } from 'react';
import { func, arrayOf, shape } from 'prop-types';
import isEqual from 'react-fast-compare';

import debounce from 'lodash/debounce';

import { isFunction } from 'helpers/utils';

import Autocomplete from 'oc-core-components/src/Autocomplete';

const DEBOUNCE_TIME = 100;

class CourseAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    const {
      defaultItems,
    } = this.props;


    this.state = {
      suggestions: defaultItems || [],
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.defaultItems, this.props.defaultItems)) {
      this.setState({
        suggestions: this.props.defaultItems,
      });
    }
  }

  handleInputChange = (value, downshiftProps) => {
    const {
      onInputValueChange,
      defaultItems,

      skipRequestOnSelect,
    } = this.props;

    let runChangeHandler = value.length;
    if (skipRequestOnSelect) {
      runChangeHandler = runChangeHandler && downshiftProps.isOpen;
    }

    if (runChangeHandler) {
      this.inputChangeHandler(value);
    } else {
      this.setState({
        suggestions: defaultItems || [],
      });
    }

    if (isFunction(onInputValueChange)) {
      onInputValueChange(value);
    }
  }

  handleStateChange = (changes, downshiftState) => {
    const {
      onStateChange,
    } = this.props;

    if (changes.selectedItem) {
      this.selectHandler(changes.selectedItem);
      // this.selectHandler(changes.selectedItem, downshiftState);
      return;
    }

    if (isFunction(onStateChange)) onStateChange(changes);
  }

  inputChangeHandler = debounce((val) => {
    const {
      promise,
    } = this.props;

    promise(val).then((suggestions = []) => {
      if (this.mounted) {
        this.setState({
          suggestions,
        });
      }
    });
  }, DEBOUNCE_TIME)

  selectHandler = (item) => {
    const {
      onSelect,
    } = this.props;

    if (this.mounted) {
      this.setState({
        suggestions: [],
      });
    }

    if (isFunction(onSelect)) onSelect(item);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      suggestions,
    } = this.state;

    const {
      items: customItems,
      onStateChange: customOnStateChange,
      onSelect: customSelectHandler,
      ...restProps
    } = this.props;

    return (
      <Autocomplete
        items={suggestions}
        hideNotFound
        onInputValueChange={this.handleInputChange}
        onStateChange={this.handleStateChange}
        {...restProps}
      />
    );
  }
}

CourseAutocomplete.propTypes = {
  onSelect: func,
  onStateChange: func,
  onInputValueChange: func,
  promise: func,
  items: arrayOf(shape()),
};

export default CourseAutocomplete;
