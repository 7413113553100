import React from 'react';
import cn from 'classnames';
import Icon from 'oc-core-components/src/Icon';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './PrimaryCheckbox.module.scss';

const styles = stylesProxy(stylesObj, 'PrimaryCheckbox');

const PrimaryCheckbox = ({
  className,
  wrapperProps,
  error,
  errorMessage,
  cssSize,
  ...restProps
}) => (
  <span className={cn(styles.input, className)} {...wrapperProps}>
    <input type="checkbox" {...restProps} />
    <span className={styles.control}>
      <Icon icon="feather:check" size={12} className={styles.icon} />
    </span>
  </span>
);

export default PrimaryCheckbox;
