import merge from 'lodash/merge';
import mergeable from 'redux-merge-reducers';

import {
  RECEIVE_CURRENT_USER_ID,
  RECEIVE_USER_DATA,
  POST_REQUEST_UNLOCK_LIMIT,
  POST_REQUEST_UNLOCK_LIMIT_SUCCESS,
  POST_REQUEST_UNLOCK_LIMIT_ERROR,
  RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR,
  GET_USER_COURSES_SUCCESS,
  GET_ENROLLED_USER_COURSES_SUCCESS,
} from './constants';

const initialState = {
  data: {},
  currentUserId: '',
  requestingUnlockLimit: false,
  requestUnlockLimitSuccess: false,
  requestUnlockLimitError: false,
  enrolledUserCourses: [],
  enrolledUserCoursesLoaded: false,
  userSchoolCourses: [],
  userSchoolCoursesLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_USER_ID:
      return Object.assign({}, state, {
        currentUserId: action.res,
      });
    case RECEIVE_USER_DATA:
      return merge({}, state, {
        data: action.res,
      });
    case POST_REQUEST_UNLOCK_LIMIT:
      return Object.assign({}, state, {
        requestingUnlockLimit: true,
        requestUnlockLimitSuccess: false,
      });
    case POST_REQUEST_UNLOCK_LIMIT_SUCCESS:
      return Object.assign({}, state, {
        requestingUnlockLimit: false,
        requestUnlockLimitSuccess: action.res,
      });
    case POST_REQUEST_UNLOCK_LIMIT_ERROR:
      return Object.assign({}, state, {
        requestingUnlockLimit: false,
        requestUnlockLimitError: action.res,
      });
    case RESET_POST_REQUEST_UNLOCK_LIMIT_ERROR:
      return Object.assign({}, state, {
        requestUnlockLimitError: false,
      });
    case GET_USER_COURSES_SUCCESS:
      return Object.assign({}, state, {
        userSchoolCourses: action.payload,
        userSchoolCoursesLoaded: true,
      });
    case GET_ENROLLED_USER_COURSES_SUCCESS:
      return Object.assign({}, state, {
        enrolledUserCourses: action.payload,
        enrolledUserCoursesLoaded: true,
      });
    default:
      return state;
  }
};

export default mergeable(reducer);
