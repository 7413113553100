import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadingById, getHasLoading } from 'v2/redux/loading/selectors';
import Loader from 'oc-core-components/src/Loader';
import Icon from 'oc-core-components/src/Icon';
import { useTranslation } from 'config/i18n';


import {
  fetchV2Notifications,
} from 'v2/redux/notification/actions';


import {
  selectNotifications,
  selectNotificationsAllLoaded,
} from 'v2/redux/notification/selectors';

import { string } from 'prop-types';

import NavbarMenuNotificationsItemV2 from '../NavbarMenuNotificationsItemV2';

const NotificationsListContainer = ({
  loadingId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('navbar');

  const notifications = useSelector(selectNotifications);
  const loading = useSelector(state => getLoadingById(state, loadingId));
  const hasLoading = useSelector(state => getHasLoading(state, loadingId));
  const allLoaded = useSelector(selectNotificationsAllLoaded);

  const { ref, inView } = useInView({
    threshold: 0,
    // triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      dispatch(fetchV2Notifications());
    }
  }, [inView]);

  // show spinner only on first request
  const showSpinner = (loading && !notifications?.length) || !hasLoading;

  return (
    <div>
      <Loader loaded={!showSpinner} svg iconClassName="u-my-s">
        {
          (notifications?.length > 0) ? notifications?.map((item, indx) => (
            <NavbarMenuNotificationsItemV2 key={item?.id || indx} notification={item} />
          )) : (
            <div className="u-px-m u-pb-xl u-pt-l u-text-center">
              <div><Icon color="dark-ltr" icon="feather:bell" size={48} /></div>
              <div className="u-mt-xs">{t('navbar.notifications.empty')}</div>
            </div>
          )
        }
      </Loader>
      {
        !allLoaded && (
          <div ref={ref} />
        )
      }
    </div>
  );
};

NotificationsListContainer.propTypes = {
  loadingId: string,
};

NotificationsListContainer.defaultProps = {
  loadingId: 'notificationRequest',
};


export default NotificationsListContainer;
