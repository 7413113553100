import React, {
  useMemo, useCallback, createContext, useContext,
} from 'react';

import {
  shape, func, string, bool,
} from 'prop-types';

import { withContext } from 'shared/helpers/hoc';

import { useRouter as useNextRouter } from 'next/router';

import { pushRoute } from 'shared/helpers/router_utils';
import { getV2CurrentPage } from 'shared/v2/redux/page/selectors';
import { getV2IsHumanUser } from 'shared/v2/redux/meta/selectors';
import { connect } from 'react-redux';

// Create context with default fields
export const RouterContext = createContext({
  pushRoute: () => {},
  router: {},
});

export const routerContextPropTypes = shape({
  pushRoute: func,
  router: shape(),
});

export const withRouter = withContext(RouterContext, 'router');

export const useRouter = () => useContext(RouterContext);

const RouterProvider = ({
  currentPage,
  isHumanUser,
  children,
  router: Router,
}) => {
  const nextRouterObj = useNextRouter();

  const routerObj = Router || nextRouterObj;
  const curriedPushRoute = useCallback(props => pushRoute({
    pageId: currentPage,
    isHumanUser,
    // TODO: remove FallbackRouter
    router: routerObj,
    routerInstance: Router,
    ...props,
  }), [currentPage, Router, routerObj, isHumanUser]);

  const value = useMemo(() => ({
    pushRoute: curriedPushRoute,
    router: routerObj,
  }), [curriedPushRoute, routerObj]);

  return (
    <RouterContext.Provider
      value={value}
    >
      {children}
    </RouterContext.Provider>
  );
};

const mapStateToProps = state => ({
  currentPage: getV2CurrentPage(state),
  isHumanUser: getV2IsHumanUser(state),
});

RouterProvider.propTypes = {
  currentPage: string,
  isHumanUser: bool,
};

export default connect(
  mapStateToProps,
)(RouterProvider);
