import { getAPIData, getRecommendations } from 'common/api';
import { getPathFromUrl, getDataFromResponse } from 'helpers/utils';
import { createAction } from 'redux-actions';
import { storeV2Meta, storeV2PageProps } from 'v2/redux/page/actions';
import { storeModels } from 'v2/redux/actions';

import urljoin from 'url-join';

import {
  RECEIVE_PAGE_META,
  RECEIVE_PAGE_DATA,
  RECEIVE_PAGE_LIST,
  RECEIVE_PAGE_PROPS,
  MERGE_PAGE_LISTS,
  RECEIVE_PAGE_RELATIONSHIPS,
  GET_PAGE_DATA,
  GET_PAGE_DATA_SUCCESS,
  GET_PAGE_DATA_ERROR,
  GET_DATA_BY_SECTION,
  GET_DATA_BY_SECTION_SUCCESS,
  GET_DATA_BY_SECTION_ERROR,
  RESET_LOADING_PAGE_DATA_ERROR,
  RECEIVE_SEARCH_QUERY,
  REMOVE_ENROLLMENT,

  START_PAGE_SECTIONS_LOADING,
  STOP_PAGE_SECTIONS_LOADING,
  CLEAR_PAGE_SECTIONS,
  SET_PAGE_SECTIONS_TO_LOAD,

} from './constants';


const removeEnrollment = res => ({
  type: REMOVE_ENROLLMENT,
  res,
});

const receivePageMeta = res => ({
  type: RECEIVE_PAGE_META,
  res,
});

const receivePageData = (pageSubState, res) => ({
  type: RECEIVE_PAGE_DATA,
  pageSubState,
  res,
});

const receivePageList = (params, res) => ({
  type: RECEIVE_PAGE_LIST,
  params,
  res,
});

const receivePageProps = (pageSubState, res) => ({
  type: RECEIVE_PAGE_PROPS,
  pageSubState,
  res,
});

const mergePageLists = (pageSubState, res) => ({
  type: MERGE_PAGE_LISTS,
  pageSubState,
  res,
});

const receivePageRelationships = (pageSubState, res) => ({
  type: RECEIVE_PAGE_RELATIONSHIPS,
  pageSubState,
  res,
});

const getDataBySection = params => ({ type: GET_DATA_BY_SECTION, params });

const getDataBySectionSuccess = params => ({
  type: GET_DATA_BY_SECTION_SUCCESS,
  params,
});

const getDataBySectionError = (params, res) => ({
  type: GET_DATA_BY_SECTION_ERROR,
  params,
  res,
});

const getPageData = params => ({ type: GET_PAGE_DATA, params });

const getPageDataSuccess = (params, res) => ({
  type: GET_PAGE_DATA_SUCCESS,
  params,
  res,
});

const getPageDataError = (params, res) => ({
  type: GET_PAGE_DATA_ERROR,
  params,
  res,
});

const resetLoadingPageDataError = () => ({
  type: RESET_LOADING_PAGE_DATA_ERROR,
});

const updateSearchQuery = query => ({
  type: RECEIVE_SEARCH_QUERY,
  query,
});

function retrievePageData(page, url, previousUrl) {
  return function action(dispatch) {
    const request = getAPIData({ url: `api${url}` });
    const params = { page, url, previousUrl };
    dispatch(getPageData(params));

    return request.then(
      ({ data }) => {
        const {
          meta,
          pages = {},
        } = getDataFromResponse(data);

        // console.log({ pages, request });
        const pageData = pages[page];


        if (meta) {
          // console.log('META:', meta);
          dispatch(storeV2Meta(meta, 'merge'));
        }

        if (pageData.models) {
          // console.log('models:', pageData.models);
          dispatch(storeModels(pageData.models));
        }

        if (pageData.pageProps) {
          // console.log('pageProps:', pageData.pageProps);
          dispatch(storeV2PageProps(page, pageData.pageProps, 'replace'));
        }


        // // TODO: Refactor: use the same code that is on the line 103
        // if (newPageProps.directory?.pageProps) {
        //   dispatch(receivePageProps('directory', newPageProps.directory.pageProps));
        // }
        // if (newPageProps.blogDirectory?.pageProps) {
        //   dispatch(receivePageProps('blogDirectory', newPageProps.blogDirectory.pageProps));
        // }
        return dispatch(getPageDataSuccess(params, pageData.pageProps));
      },
      () => dispatch(getPageDataError(params, { error: `An error occured retrievingPageData: api${url}` })),
    );
  };
}

/*
  Retreive data objects for specific page sections
*/
function retrieveDataBySections({
  page,
  sections = [],
  url,
  topSchoolIds = [],
}) {
  return function action(dispatch) {
    const sectionsToLoad = [...sections];
    const schoolIds = [...topSchoolIds];
    const params = {
      page,
      sectionsToLoad,
      url,
      schoolIds,
    };
    dispatch(getDataBySection(params));
    if (!url || (sectionsToLoad.length === 0) || !page) {
      return dispatch(getDataBySectionError(params, { error: 'retrieveDataBySection error' }));
    }

    const requestParams = { section: sectionsToLoad.join(',') };
    // Send top three school ids for homepage data retrieve
    if (schoolIds?.length) {
      requestParams.topSchools = schoolIds.slice(0, 3).join(',');
    }

    const request = getAPIData({
      url: urljoin('api', getPathFromUrl(url)),
      params: requestParams,
    });
    return request.then(
      ({ data }) => {
        let newData;
        let newPageProps;

        if (data?.pages[page]) {
          const pageData = data?.pages[page];
          if (pageData?.data && pageData?.pageProps) {
            newPageProps = pageData.pageProps;
            newData = pageData.data;
          }
        }
        if (newData && newPageProps) {
          dispatch(receivePageData(page, newData));
          dispatch(mergePageLists(page, newPageProps));
          return dispatch(getDataBySectionSuccess(params));
        }
        return dispatch(getDataBySectionError(params, { error: 'retrieveDataBySection error' }));
      },
      () => {
        dispatch(getDataBySectionError(params, { error: 'retrieveDataBySection error' }));
      },
    );
  };
}

/*
  Retreive data objects for specific page sections
*/
function retrieveRecommendations(params = {}) {
  return function action(dispatch) {
    try {
      const {
        page,
        userId,
        schoolId,
        coursesIds = [],
        ...restParams
      } = params;

      if (!page || !restParams || !userId) {
        return dispatch(getDataBySectionError(params, { error: 'retrieveRecommendations error' }));
      }

      dispatch(getDataBySection(params));

      const request = getRecommendations({
        format: 'array',
        user_id: userId,
        school_id: schoolId,
        courses_ids: coursesIds,
        ...restParams,
      });

      return request.then(({ data }) => {
        // data = { data, included, list }
        if (data?.data && data?.list) {
          const newPageProps = {
            pageLists: [data?.list],
          };
          const newData = [{
            data: data?.data,
            included: data?.included,
          }];
          dispatch(receivePageData(page, newData));
          dispatch(mergePageLists(page, newPageProps));
          return dispatch(getDataBySectionSuccess(params));
        }

        return dispatch(getDataBySectionError(params, { error: 'retrieveRecommendations error' }));
      },
      (e) => {
        dispatch(getDataBySectionError(params, { error: 'retrieveRecommendations error', erroMessage: e }));
      });
    } catch (e) {
      console.error(e);
      return dispatch(getDataBySectionError(params, { error: 'retrieveRecommendations error', erroMessage: e }));
    }
  };
}


const startSectionsLoading = createAction(START_PAGE_SECTIONS_LOADING);
const stopSectionsLoading = createAction(STOP_PAGE_SECTIONS_LOADING);
const clearSectionsToLoad = createAction(CLEAR_PAGE_SECTIONS);
const setSectionsToLoad = createAction(SET_PAGE_SECTIONS_TO_LOAD);


function fetchLazySections({
  page,
  sections,
  url,
  topSchoolIds,
}) {
  return function action(dispatch) {
    if (sections?.length > 0) {
      dispatch(startSectionsLoading());

      return dispatch(retrieveDataBySections({
        page,
        sections,
        url,
        topSchoolIds,
      })).then(() => {
        dispatch(clearSectionsToLoad());

        return dispatch(stopSectionsLoading());
      });
    }

    return null;
  };
}


export {
  receivePageMeta,
  receivePageData,
  receivePageList,
  receivePageProps,
  receivePageRelationships,
  retrievePageData,
  resetLoadingPageDataError,
  retrieveDataBySections,
  updateSearchQuery,
  retrieveRecommendations,
  removeEnrollment,
  fetchLazySections,
  startSectionsLoading,
  stopSectionsLoading,
  clearSectionsToLoad,
  setSectionsToLoad,
  mergePageLists,
};
