import React from 'react';

import Modal from 'oc-core-components/src/Modal';
import {
  INLINE_MODAL,
} from 'oc-core-components/src/Modal/constants';

import withPortal from 'oc-core-components/src/WithPortal';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
import BottomModalWrapper from 'oc-core-components/src/BottomModalWrapper';
import MobileFullScreenModalWrapper from 'shared/oc-core-components/src/MobileFullScreenModalWrapper';
import { renderNode } from 'helpers/utils';

const MobileModalPortal = withPortal({ root: 'modal-root' })(BottomModalWrapper);
const MobileFullScreenModalPortal = withPortal({ root: 'modal-root' })(MobileFullScreenModalWrapper);

const InlineModal = (props, ...rest) => {
  const isMobile = useMobile();
  const {
    modalProps,
    modalProps: {
      wrapperProps,
      mobileFullScreen,
      CustomModalPortal,
    },
    // close: onClose,
  } = useModal();

  return (
    <Modal
      component={
        CustomModalPortal
        || (isMobile ?
          (mobileFullScreen ? MobileFullScreenModalPortal : MobileModalPortal)
          : undefined)}
      name={INLINE_MODAL}
      {...props}
      {...wrapperProps}
    >
      {
        ({ modalProps: { content, ...restModalProps } = {} }) => renderNode(content, restModalProps)
      }
    </Modal>
  );
};


export default InlineModal;
