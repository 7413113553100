import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postUnfollowUser, postFollowUser } from 'common/api';

import { useAuth } from 'oc-core-components/src/AuthProvider';
import { useNotifications } from 'oc-core-components/src/NotificationProvider';
import getAuthLink from 'helpers/utils/getAuthLink';
import { storeModelAttributes } from 'v2/redux/actions';
import { selectV2ModelByIdProp } from 'v2/redux/selectors';

const FALLBACK = {
  follow: () => console.error('Bad request from useFollowUser hook'),
  isFollowing: undefined,
};

const useFollowUser = ({ modelType, modelId } = {}) => {
  const {
    addErrorNotification,
  } = useNotifications();
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();

  const externalUserId = modelId;

  const userData = useSelector(state => selectV2ModelByIdProp.model(state, { modelType, modelId: externalUserId }, 1));

  const {
    isFollowing,
    followersCount,
    id: internalUserId,
  } = userData || {};

  const follow = useCallback(async (e) => {
    e.preventDefault();

    if (!isLoggedIn) {
      window.location.assign(getAuthLink({ source: 'hw', intent: false, login: true }));
      return;
    }

    const request = isFollowing
      ? () => postUnfollowUser({ following_id: internalUserId })
      : () => postFollowUser({ following_id: internalUserId });

    const res = await request();

    const canBeUpdated = externalUserId && !!modelType;

    if (res?.data?.success && canBeUpdated) {
    // if (true) {
      const newIsFollowing = !isFollowing;
      const delta = newIsFollowing ? 1 : -1;

      const newFollowersCount = Math.max(0, (followersCount + delta) || 0);

      dispatch(storeModelAttributes({
        id: externalUserId,
        modelType,
        updates: {
          isFollowing: newIsFollowing,
          followersCount: newFollowersCount,
        },
      }));
      //  setTimeout(closeDropdown, 2000);
    } else {
      addErrorNotification(res?.data?.error || undefined);
    }
  }, [
    internalUserId,
    externalUserId,
    isLoggedIn,
    modelType,
    isFollowing,
    followersCount,
    addErrorNotification,
    dispatch,
  ]);

  if (!userData) {
    return FALLBACK;
  }

  return {
    follow,
    isFollowing,
  };
};

export default useFollowUser;
