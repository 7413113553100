import React, { useCallback } from 'react';
import { useModal } from 'oc-core-components/src/Modal/ModalProvider';
// import EmptyModalWrapper from 'components/pages/HomeworkHelp/_HWv2/EmptyModalWrapper';
// import SummerChallengeModal from 'oc_components/src/pages/HomeFeed/SummerChallengeModal';
// import { postTrackSCEvents } from 'shared/common/api';
import SignupDiscountModal from 'oc_components/src/modals/SignupDiscountModal/SignupDiscountModal';

const useSignupDiscountPopup = ({
  // className,
}) => {
  const { inline } = useModal();

  const onClick = useCallback(async () => {
    inline({
      content: <SignupDiscountModal />,
      mobileFullScreen: true,
      wrapperProps: {
        // className,
        disableOverlayClose: true,
        // disableXClose: true,
      },
    });
  }, [inline]);

  return onClick;
};


export default useSignupDiscountPopup;
