const IS_APP_PAGE = new RegExp(/^\/app/);
const PWA_EXT_REGEXP = new RegExp(/pwa$/);
export const PWA_SCOPE = '/app';
export const MAIL_TO_REGEXP = new RegExp(/mailto/);

// list of currentPage values
export const PAGES_WITH_PWA_SUPPORT = [
  'homeworkHelp',
  'skillsShow',
  'terms',
  'privacy',
  'settings',
  'editProfile',
  'changePassword',
  'manageSubscriptions',
];

const PWA_URLS_REGEXPS = [
  /homework/,
  /courses\/.*\/.*\//,
  /terms/,
  /privacy/,
  /edit_profile/,
  /settings/,
  /edit_profile/,
  /change_password/,
  /manage_subscriptions/,
];

const PWA_EXCEPTIONS = [
  /homework-help.*.pwa\?step=\d+/,
  /homework-help-get-started.*.pwa/,
  /homework-help-parents.*.pwa/,
  /signup.*.pwa/,
  /login.*.pwa/,
];

// Detect if user is within PWA app
export const isPwaLaunched = () => (
  (typeof navigator !== 'undefined')
    ? (navigator.standalone || matchMedia('(display-mode: standalone)').matches)
    : false
);

export const isPwa = (currentPath) => {
  const path = currentPath || (typeof window === 'undefined' ? global.INITIAL_PAGE_PATH : window.location.pathname);

  return (
    IS_APP_PAGE.test(path)
    || PWA_EXT_REGEXP.test(path)
    || isPwaLaunched()
  );
};


export const hasPwaAlternative = currentPage => PAGES_WITH_PWA_SUPPORT.includes(currentPage);
export const urlWithPwaSupport = url => !!(PWA_URLS_REGEXPS.find(regexp => regexp.test(url)));
export const isAmpException = url => !!(PWA_EXCEPTIONS.find(regexp => regexp.test(url)));

export const toPwaUrl = ({
  url,
  prefix = PWA_SCOPE,
  lng = 'en',
  format = 'html',
  currentPath,
  force,
}) => {
  if (!url) return url;
  // don't convert url to PWA url if it is already a PWA url
  if (IS_APP_PAGE.test(url)) return url;
  // don't convert mailto links
  if (MAIL_TO_REGEXP.test(url)) return url;
  // don't convert url if there's no PWA version for this url
  if (!urlWithPwaSupport(url)) return url;

  const f = force || isPwaLaunched();

  // if current page isn't PWA page return initial url (we convert links only for PWA pages)
  if (!isPwa(currentPath) && !f) return url;


  let protocolPlusHost = '';
  let newPath = url.slice();
  const match = url.match(/(^https?\:\/\/.+?)\//);
  if (match) {
    protocolPlusHost = match[1];
    newPath = newPath.replace(protocolPlusHost, '');
  }

  return `${protocolPlusHost}${prefix}${newPath}`.replace(`${lng}.${format}`, `${lng}.pwa`);
};

export const convertObjectToPwa = (i, key = 'url') => ({ ...i, [key]: toPwaUrl({ url: i[key] }) });
export const convertLinksToPwa = (array, key = 'url') => array.map(i => convertObjectToPwa(i, key));

export const patchRedirectTo = (currentUrl, redirectTo) => toPwaUrl({ url: redirectTo, currentPath: currentUrl });

export const registerSW = () => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
    // add event listeners to handle any of PWA lifecycle event
    // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
    window.workbox.addEventListener('installed', (event) => {
      // console.log(`Event ${event.type} is triggered.`);
      // console.log(event);
    });

    window.workbox.addEventListener('controlling', (event) => {
      // console.log(`Event ${event.type} is triggered.`);
      // console.log(event);
    });

    window.workbox.addEventListener('activated', (event) => {
      // console.log(`Event ${event.type} is triggered.`);
      // console.log(event);
    });

    // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
    // NOTE: set skipWaiting to false in next.config.js pwa object
    // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
    window.workbox.addEventListener('waiting', (event) => {
      // if (confirm('A new version is installed, reload to use the new version immediately?')) {
      window.workbox.addEventListener('controlling', (event) => {
        window.location.reload();
      });
      window.workbox.messageSW({ type: 'SKIP_WAITING' });
      // } else {
      //   // User rejected, new verion will be automatically load when user open the app next time.
      // }
    });

    // ISSUE - this is not working as expected, why?
    // I could only make message event listenser work when I manually add this listenser into sw.js file
    window.workbox.addEventListener('message', (event) => {
      // console.log(`Event ${event.type} is triggered.`);
      // console.log(event);
    });

    /*
    window.workbox.addEventListener('redundant', event => {
      console.log(`Event ${event.type} is triggered.`)
      console.log(event)
    })
    window.workbox.addEventListener('externalinstalled', event => {
      console.log(`Event ${event.type} is triggered.`)
      console.log(event)
    })
    window.workbox.addEventListener('externalactivated', event => {
      console.log(`Event ${event.type} is triggered.`)
      console.log(event)
    })
    window.workbox.addEventListener('externalwaiting', event => {
      console.log(`Event ${event.type} is triggered.`)
      console.log(event)
    })
    */

    //  const shellUrlsToCache = ['/'];
    //  const urlsToCache = [
    //       // ...staticUrlsToCache,
    //     shellUrlsToCache.map(url => `${url}?shell`),
    //   ];
    //   window.workbox.addEventListener('fetch', event => {
    //     console.log({ event });
    //     let request = event.request;

    //     const shellUrl = shellUrlsToCache.find(url => request.url.endsWith(url));
    //     if (shellUrl) {
    //       request = new Request(`${shellUrl}?shell`);
    //     }

    //     event.respondWith(
    //       caches.match(request).then(response => response || fetch(request))
    //     );
    //   });

    // never forget to call register as auto register is turned off in next.config.js
    window.workbox.register();
  }
};

export default {};
