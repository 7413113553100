import typesCreator from 'v2/redux/utils/typesCreator';

export default typesCreator('question', {
  postfix: {
    startWith: {
      UPDATE: ['SUCCESS', 'ERROR'],
    },
  },
})(
  'UPDATE_BOOKMARK',
);
