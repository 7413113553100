import React, { PureComponent } from 'react';
import { shape, arrayOf, func } from 'prop-types';
import DeferredAutocomplete from 'oc-core-components/src/DeferredAutocomplete';
import { uidType } from 'types';
import { isFunction } from 'helpers/utils';
import { getCourses } from './utils';

const defaultProcessResponse = data => {
  const {
    hits: { hits: rawHits } = {},
  } = data || {};

  return rawHits?.map(({
    _source: {
      code, id, all_materials_url: url, all_materials_count: count, nt_docs_count: ntDocsCount,
    },
  }) => ({
    label: code,
    value: id.toString(),
    url,
    count,
    ntDocsCount,
  }));
};
class CourseAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    const {
      defaultItems,
    } = this.props;


    this.state = {
      suggestions: defaultItems || [],
    };
  }

  inputChangeHandler = (val) => {
    const {
      request,
      onSuggestionsLoad,
      params,
      processRepsonse,
    } = this.props;

    return getCourses(val, this.props.schoolId, { request, params })
      .then(({ data }) => {
        const result = processRepsonse(data);


        if (isFunction(onSuggestionsLoad)) {
          onSuggestionsLoad({ items: result, val });
        }

        return result;
      });
  };

  render() {
    const {
      suggestions,
    } = this.state;

    const {
      items: customItems,
      schoolId,
      defaultItems,
      onSuggestionsLoad,
      ...restProps
    } = this.props;


    return (
      <DeferredAutocomplete
        promise={this.inputChangeHandler}
        items={suggestions}
        defaultItems={defaultItems}
        disableFiltering
        {...restProps}
      />
    );
  }
}

CourseAutocomplete.propTypes = {
  schoolId: uidType,
  items: arrayOf(shape()),
  processRepsonse: func,
};

CourseAutocomplete.defaultProps = {
  processRepsonse: defaultProcessResponse,
};

export default CourseAutocomplete;
