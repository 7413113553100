// TODO: Consider merging SimpleDropdown and TooltipPopper
import React, { PureComponent } from 'react';
import {
  node, string, oneOf, oneOfType, func, bool, number,
} from 'prop-types';
import withPortal from 'oc-core-components/src/WithPortal';

import cn from 'classnames';


import { isFunction, optimizedResize } from 'helpers/utils';

// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './Dropdown.module.scss';

const styles = stylesProxy(stylesObj, 'Dropdown');

const ModalPortal = withPortal({ root: 'modal-root' })(props => <div {...props} />);

class Dropdown extends PureComponent {
  state = {
    isOpen: false,
  }

  constructor(props) {
    super(props);
    this.optimizedResize = optimizedResize();
  }

  componentDidMount() {
    this.addTargetEvents();
  }

  componentWillUnmount() {
    this.removeTargetEvents();
  }

  // onEscKeyDown = (e) => {
  //   if (e.key === 'Escape') {
  //     this.close();
  //   }
  // }


  getChildren = () => {
    const { children } = this.props;

    return isFunction(children) ? children(this.dropdownProps()) : children;
  }

  addTargetEvents = () => {
    const { portal } = this.props;

    if (this.props.trigger) {
      const triggers = this.props.trigger.split(' ');

      if (triggers.indexOf('manual') === -1) {
        if (triggers.indexOf('click') > -1) {
          document.addEventListener('click', this.handleDocumentClick, true);
        }

        if (triggers.indexOf('hover') > -1) {
          this.container.addEventListener('mouseleave', this.closeWithDelay);
          this.toggle.addEventListener('mouseenter', this.open);
          document.addEventListener('touchstart', this.handleDocumentClick);
        }

        // if (triggers.indexOf('focus') > -1) {
        //   this.toggle.addEventListener('focusin', this.open, true);
        //   this.toggle.addEventListener('focusout', this.close, true);
        // }

        // this.toggle.addEventListener('keydown', this.onEscKeyDown, true);
      }

      if (portal) {
        document.addEventListener('scroll', this.setMenuPosition);
        this.optimizedResize.add(this.setMenuPosition);
      }
    }

    document.addEventListener('closeDropdowns', this.close);
  }

  removeTargetEvents = () => {
    // this._target.removeEventListener('mouseover', this.onMouseOverTooltip, true);
    // this._target.removeEventListener('mouseout', this.onMouseLeaveTooltip, true);
    // this._target.addEventListener('keydown', this.onEscKeyDown, true);
    // this._target.addEventListener('focusin', this.show, true);
    // this._target.addEventListener('focusout', this.hide, true);

    this.container.removeEventListener('mouseleave', this.close);
    this.toggle.removeEventListener('mouseleave', this.close);
    this.toggle.removeEventListener('mouseenter', this.open);

    this.toggle.removeEventListener('focusin', this.open);
    this.toggle.removeEventListener('focusout', this.close);
    // this.toggle.removeEventListener('keydown', this.onEscKeyDown);
    ['click', 'touchstart'].forEach(event => document.removeEventListener(event, this.handleDocumentClick));
    document.removeEventListener('scroll', this.setMenuPosition);
    document.removeEventListener('closeDropdowns', this.close);
    this.optimizedResize.clear();
  }

  open = () => {
    const { onOpen } = this.props;

    clearTimeout(this.closeTimeout);
    if (!this.props.disabled) {
      this.setState({
        isOpen: true,
      }, () => {
        if (this.popup) {
          this.popup.addEventListener('mouseenter', this.onPopupHover);
          this.popup.addEventListener('mouseleave', this.close);
        }

        if (this.props.portal) {
          this.setMenuPosition();
        }

        if (isFunction(onOpen)) {
          onOpen();
        }
      });
    }
  };

  close = () => {
    if (this.state.isOpen) {
      if (this.popup) {
        this.popup.removeEventListener('mouseenter', this.onPopupHover);
        this.popup.removeEventListener('mouseleave', this.close);
      }

      this.setState({ isOpen: false });
    }
  }

  closeWithDelay = () => {
    const { closeDelay } = this.props;
    clearTimeout(this.closeTimeout);

    if (closeDelay) {
      this.closeTimeout = setTimeout(this.close, closeDelay);
    } else {
      this.close();
    }
  }

  onPopupHover = () => {
    const { closeDelay } = this.props;
    if (closeDelay && this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }

  setMenuPosition = () => {
    const { position } = this.props;

    if (this.container) {
      const rect = this.container.getBoundingClientRect();
      // const padding = parseFloat(this.toggle.);
      if (rect) {
        const {
          top,
          left: newLeft,
          height,
          bottom,
        } = rect;

        const isOnTopPosition = position?.startsWith('top');

        const newTop = isOnTopPosition ? top : top + height;


        this.top = newTop;
        this.left = newLeft;

        if (this.popup) {
          this.popup.style.top = `${this.top}px`;

          if (position?.endsWith('right')) {
            this.popup.style.left = `${this.left + rect.width}px`;
            this.popup.style.right = 'auto';
          } else if (position?.endsWith('center')) {
            this.popup.style.left = `${this.left + rect.width / 2}px`;
          } else {
            this.popup.style.left = `${this.left}px`;
            this.popup.style.right = 'auto';
          }
        }
      }
    }
  };


  handleDocumentClick = (e) => {
    const { popup, toggle } = this;
    const {
      disabled, portal, onOpen, disableCloseOnMenuClick,
    } = this.props;
    const { isOpen } = this.state;
    // || container.contains(e.target)
    // e.stopPropagation();

    // https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js
    const path = e.path || (e.composedPath && e.composedPath());

    if (!disabled) {
      if (e.target === toggle || toggle?.contains(e.target)) {
        this.toggleHandler(portal ? this.setMenuPosition : undefined);
        if (isFunction(onOpen) && !isOpen) {
          onOpen();
        }
        return this.handleAnchorClick(e);
      } if (this.state.isOpen && !(popup?.contains(e.target) || path?.includes(popup))) {
        if (!disableCloseOnMenuClick) {
          this.close();
          return this.handleAnchorClick(e);
        }
      }
    }
  }

  toggleHandler = (cb) => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }), cb);
  }

  dropdownProps = () => ({
    open: this.open,
    close: this.close,
    isOpen: this.state.isOpen,
  })

  handleAnchorClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  render() {
    const {
      className,
      toggleClassName,
      menuClassName,
      disableMenuStyles,
      toggle,
      position,
      menuId,
      portal,
      openClassName,
    } = this.props;


    const { isOpen } = this.state;

    const menuStyles = {
      position: 'fixed',
      top: `${this.top}px`,
      left: `${this.left}px`,
    };

    const menu = (
      <div
        id={menuId}
        ref={(el) => { this.popup = el; }}
        className={cn({ [styles.menu]: !disableMenuStyles, [styles[position]]: position }, menuClassName)}
        style={(portal && !disableMenuStyles) ? menuStyles : null}
      >
        {this.getChildren()}
      </div>
    );

    return (
      <div
        className={cn(styles.container, className, isOpen && openClassName)}
        ref={(el) => { this.container = el; }}
      >
        <span
          className={cn(styles.toggle, toggleClassName)}
          aria-haspopup
          ref={(el) => { this.toggle = el; }}
          aria-controls={menuId}
          aria-expanded={isOpen}
        >
          {isFunction(toggle) ? toggle(this.dropdownProps()) : toggle}
        </span>

        {
          portal ? (
            <ModalPortal
              isDisplayed={isOpen}
            >
              {menu}
            </ModalPortal>
          ) : isOpen && menu
        }


      </div>
    );
  }
}

Dropdown.propTypes = {
  children: oneOfType([func, node]),
  className: string,
  toggleClassName: string,
  menuClassName: string,
  toggle: oneOfType([node, func]),
  position: oneOf(['center', 'right', 'top', 'top-right', 'top-center']),
  trigger: string,
  disabled: bool,
  menuId: string,
  portal: bool,
  closeDelay: number,
  onOpen: func,
};

Dropdown.defaultProps = {
  trigger: 'hover',
};


export default Dropdown;
