import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'config/i18n';
import { withModal, modalContextPropTypes } from 'oc-core-components/src/Modal/ModalProvider';
import buildUrl from 'helpers/utils/buildUrl';


import { isEmpty } from 'helpers/utils';
import { ROUTES } from 'helpers/constants';
import { ADD_COURSES_MODAL } from 'oc-core-components/src/Modal/constants';
import {
  arrayOf, shape, func, bool,
} from 'prop-types';

import {
  selectEnrolledUserCourses,
  selectEnrolledUserCoursesLoaded,
} from 'common/user/selectors';
import { retrieveEnrolledUserCourses } from 'common/user/actions';

import MyCoursesList from '../MyCoursesList';

class MyCoursesListContainer extends Component {
  componentDidMount() {
    const { courses } = this.props;

    if (isEmpty(courses)) {
      this.props.retrieveEnrolledUserCourses({ upload: false });
    }
  }

  render() {
    const {
      loaded,
      courses,
      modal,
      t,
    } = this.props;

    return (
      <MyCoursesList
        items={courses}
        myCoursesUrl={buildUrl(ROUTES.dashboard, { courses_modal: true })}
        linkText={t('view_all')}
        addCourseText={t('section.my_courses.add_course')}
        noCoursesText={t('section.my_courses.empty')}
        loaded={loaded}
        onClick={() => modal.open(ADD_COURSES_MODAL)}
      />
    );
  }
}

MyCoursesListContainer.propTypes = {
  courses: arrayOf(shape()),
  retrieveEnrolledUserCourses: func,
  loaded: bool,
  modal: modalContextPropTypes,
};

MyCoursesListContainer.defaultProps = {
  courses: [],
};

const mapStateToProps = (state, props) => ({
  courses: selectEnrolledUserCourses(state, props),
  loaded: selectEnrolledUserCoursesLoaded(state, props),
});

const mapDispatchToProps = {
  retrieveEnrolledUserCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common'])(withModal(MyCoursesListContainer)));
