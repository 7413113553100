import React, { Fragment } from 'react';
import { Field as FormikField, getIn } from 'formik';

import TextInput from 'oc-core-components/src/TextInput';
import Checkbox from 'oc-core-components/src/Checkbox';
import PrimaryCheckbox from 'oc-core-components/src/PrimaryCheckbox';
import DownshiftAutocomplete from 'oc-core-components/src/Autocomplete';
import CourseAutocomplete from 'oc-core-components/src/CourseAutocomplete';
import SchoolAutocomplete from 'oc-core-components/src/SchoolAutocomplete';
import TextbookAutocomplete from 'oc-core-components/src/TextbookAutocomplete';
import DepartmentAutocomplete from 'oc-core-components/src/DepartmentAutocomplete';
import ProfessorAutocomplete from 'oc-core-components/src/ProfessorAutocomplete';
import UserAutocomplete from 'oc-core-components/src/UserAutocomplete';
import Rating from 'oc-core-components/src/Rating';
import { RadioGroup, RadioButton } from 'oc-core-components/src/RadioGroup';
import { RadioGroupV2, RadioButtonV2 } from 'oc-core-components/src/RadioGroupV2';

import { withGlobalProps } from 'components/pages/HomeworkHelp/GlobalPropsProvider';
import { isFunction } from 'helpers/utils';

// import dynamic from 'next/dynamic';

// const dynamicProps = {
//   ssr: false,
//   loading: () => null,
// };
// const TextInput = dynamic(() => import(/* webpackChunkName: "TextInput" */ 'oc-core-components/src/TextInput'), dynamicProps);
// const Checkbox = dynamic(() => import(/* webpackChunkName: "Checkbox" */ 'oc-core-components/src/Checkbox'), dynamicProps);
// const DownshiftAutocomplete = dynamic(() => import(/* webpackChunkName: "Autocomplete" */ 'oc-core-components/src/Autocomplete'), dynamicProps);
// const CourseAutocomplete = dynamic(() => import(/* webpackChunkName: "CourseAutocomplete" */ 'oc-core-components/src/CourseAutocomplete'), dynamicProps);
// const SchoolAutocomplete = dynamic(() => import(/* webpackChunkName: "SchoolAutocomplete" */ 'oc-core-components/src/SchoolAutocomplete'), dynamicProps);
// const Rating = dynamic(() => import(/* webpackChunkName: "Rating" */ 'oc-core-components/src/Rating'), dynamicProps);

// TODO: Too big; split into separate components

/* eslint-disable react/prop-types */
const EnhancedTextInput = ({
  // error: customError,
  value: customValue, // remove value from props
  field, // { name, value, onChange, onBlur }
  form: {
    touched: touchedArr,
    errors,
    setFieldValue,
    // handleBlur,
    handleChange,
  }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  type,
  onChange,
  // onBlur,
  onSelect,
  onClick,
  component,
  ...props
}) => {
  let result = null;

  const eventHandler = callback => (value) => {
    // console.log(callback, type);
    setFieldValue(field.name, value);
    // check if event is react synthetic event
    if (typeof callback === 'function') {
      // console.log(callback);
      callback(value);
    }
  };

  const touched = getIn(touchedArr, field.name);
  const error = getIn(errors, field.name);

  const autocompleteProps = {
    error: touched && !!error,
    errorMessage: error,
    selectedItem: field.value,
    onSelect: eventHandler(onSelect),
    onChange: eventHandler(onChange),
    ...props,
  };

  switch (type) {
    case 'primaryCheckbox':
      result = (
        <PrimaryCheckbox
          error={touched && !!error}
          errorMessage={error}
          checked={field.value}
          {...field}
          {...props}

        />
      );
      break;
    case 'checkbox':
      result = (
        <Checkbox
          error={touched && !!error}
          errorMessage={error}
          checked={field.value}
          {...field}
          {...props}
        />
      );
      break;
    case 'autocomplete': {
      // let errorMessage = errors[field.name];
      // if (typeof errors[field.name] === 'object') {
      //   errorMessage = errors[field.name].value;
      // }
      result = (
        <DownshiftAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'courses': {
      result = (
        <CourseAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'schools': {
      result = (
        <SchoolAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'textbooks': {
      result = (
        <TextbookAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'department': {
      result = (
        <DepartmentAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'professor': {
      result = (
        <ProfessorAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'user': {
      result = (
        <UserAutocomplete
          {...autocompleteProps}
        />
      );
      break;
    }
    case 'rating': {
      result = (
        <Rating
          error={touched && !!error}
          errorMessage={error}
          onClick={eventHandler(onChange)}
          hover
          {...field}
          {...props}
        />
      );
      break;
    }
    case 'radioGroup': {
      result = (
        <RadioGroup
          selected={field.value}
          name={field.name}
          error={touched && !!error}
          errorMessage={error}
          onChange={eventHandler(onChange)}
          {...props.groupProps}
        >
          {props.options && props.options.map((item, key) => (
            <Fragment key={`${field.name}-${key}`}>
              <RadioButton
                id={`${field.name}-${item?.value}`}
                item={item}
                {...props.radioProps}
              >

                {
                  isFunction(props.renderItem) ? props.renderItem(item) : item?.label
                }
              </RadioButton>
            </Fragment>
          ))}
          {props.children}
        </RadioGroup>
      );
      break;
    }
    // TODO: refactor
    case 'radioGroupV2': {
      result = (
        <RadioGroupV2
          selected={props.selected || field.value}
          name={field.name}
          error={touched && !!error}
          errorMessage={error}
          onChange={eventHandler(onChange)}
          {...props.groupProps}
        >
          {props.options && props.options.map((item, key) => (
            <Fragment key={`${field.name}-${key}`}>
              <RadioButtonV2
                id={`${field.name}-${item?.value}`}
                item={item}
                disabled={item.disabled}
                {...props.radioProps}
              >
                {item?.label}
              </RadioButtonV2>
            </Fragment>
          ))}
          {props.children}
        </RadioGroupV2>
      );
      break;
    }
    default:
      result = (
        <TextInput
          error={touched && !!error}
          errorMessage={error}
          type={type}
          // onBlur={handleBlur}
          {...field}
          onChange={(e) => {
            field.onChange(e);

            if (isFunction(onChange)) {
              onChange(e);
            }
          }}
          {...props}
        />
      );
      break;
  }

  return result;
};
/* eslint-enable react/prop-types */


const Field = ({ ...props }) => (
  <FormikField
    component={EnhancedTextInput}
    {...props}
  />
);


export {
  Field as RawField,
};

export default withGlobalProps(Field, 'Field');
