import React, { useMemo, useCallback, useState } from 'react';
import cn from 'classnames';
import Link from 'oc-core-components/src/Link';
import LazyImage from 'oc-core-components/src/LazyImage';
import Icon from 'oc-core-components/src/Icon';
import Row from 'oc-core-components/src/Row';
import Col from 'oc-core-components/src/Col';
import { useMobile } from 'oc-core-components/src/MediaQuery';
import { postTrackShopProducts } from 'common/api';
// eslint-disable-next-line import/order
import { stylesProxy } from 'helpers/css';
import stylesObj from './ShopItem.module.scss';

const styles = stylesProxy(stylesObj, 'ShopItem');


const ShopItem = ({
  className,
  id,
  img,
  mobileImg,
  name,
  price,
  href,
  // minPrice,
  // maxPrice,
  ...restProps
}) => {
  const [isLiked, setIsLiked] = useState();
  const isMobile = useMobile();
  // const price = useMemo(() => [minPrice, maxPrice].filter(p => !isNil(p)).map(p => `$${p}`).join('-'), [minPrice, maxPrice]);
  const onClick = useCallback(() => {
    postTrackShopProducts(id, isLiked);
    setIsLiked(!isLiked);
  }, [id, isLiked]);

  const imgSrc = (isMobile && mobileImg) || img;

  return (
    <Link
      onClickTag="div"
      onClick={onClick}
      target={href ? '_blank' : undefined}
      className={cn(styles.container, className, { [styles.active]: isLiked })}
      href={href}
      {...restProps}
    >
      <div className={styles.thumb}>
        <LazyImage
          width={176}
          height={216}
          responsiveWidth
          className={styles.img}
          src={imgSrc}
          alt={name}
        />
      </div>

      <Row noGutters className="u-align-items-center">
        <Col>
          <h6 className={styles.title}>
            {name}
          </h6>
          <div className={styles.price}>
            {price}
          </div>
        </Col>
        {
          !href && (
            <Col xs="auto">
              <span className={styles.like}>
                <Icon icon="oc:heart" size={12} />
              </span>
            </Col>
          )
        }

      </Row>


    </Link>
  );
};

export default ShopItem;
