import { call, take } from 'redux-saga/effects';

export function* takexSaga(pattern) {
  let action;
  while (true) {
    action = yield take('*');
    // console.log('takex', action.type);
    if (pattern.test(action.type)) {
      // console.log('takex:match', action.type);
      break;
    }
    // console.log('takex:ignore', action.type);
  }
  return action;
}

export default function takex(pattern) {
  return call(takexSaga, pattern);
}
