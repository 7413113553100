import React from 'react';

import DynamicModal from 'oc-core-components/src/DynamicModal';
import {
  ADD_COURSES_MODAL,
} from 'oc-core-components/src/Modal/constants';
import { string } from 'prop-types';

const AddCoursesFormLoader = () => import(/* webpackChunkName: "AddCoursesForm" */ 'components/forms/AddCoursesForm');

const AddCoursesModal = ({ pageApiId }) => (
  <DynamicModal
    component={AddCoursesFormLoader}
    name={ADD_COURSES_MODAL}
    size="sm"
    render={AddCoursesForm => (
      <AddCoursesForm
        pageApiId={pageApiId}
      />
    )}
  />
);

AddCoursesModal.propTypes = {
  pageApiId: string,
};

export default AddCoursesModal;
