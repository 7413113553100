import { handleModelActions } from 'v2/redux/utils/handleActions';
// import { logErrorDev } from 'helpers/utils';
// import types from './types';

import { BOOSTER_CLASS_CHAPTER } from 'v2/redux/constants';

const initialState = {
  data: {},
};

const boosterClassChapterReducer = handleModelActions(
  BOOSTER_CLASS_CHAPTER,
  {

  },
  initialState,
);

export { initialState };

export default boosterClassChapterReducer;
