import { combineReducers } from 'redux';
// V2
import pageReducerV2 from 'v2/redux/page/reducer';
import blogArticleReducerV2 from 'v2/redux/blogArticle/reducer';
import blogCommentReducerV2 from 'v2/redux/blogComment/reducer';
import bookReducerV2 from 'v2/redux/book/reducer';
import answererReducerV2 from 'v2/redux/answerer/reducer';
import hwAnswerReducerV2 from 'v2/redux/hwAnswer/reducer';
import earningReducerV2 from 'v2/redux/earning/reducer';
import authorReducerV2 from 'v2/redux/author/reducer';
import courseReducerV2 from 'v2/redux/course/reducer';
import documentReducerV2 from 'v2/redux/document/reducer';
import schoolReducerV2 from 'v2/redux/school/reducer';
import countryReducerV2 from 'v2/redux/country/reducer';
import downloadTokenReducerV2 from 'v2/redux/downloadToken/reducer';
import professorReducerV2 from 'v2/redux/professor/reducer';
import userReducerV2 from 'v2/redux/user/reducer';
import departmentReducerV2 from 'v2/redux/department/reducer';
import questionReducerV2 from 'v2/redux/question/reducer';
import liveTutorialSessionReducerV2 from 'v2/redux/liveTutorialSession/reducer';
import liveTutorialSectionReducerV2 from 'v2/redux/liveTutorialSection/reducer';
import streamVideoScheduleReducerV2 from 'v2/redux/streamVideoSchedule/reducer';
import streamVideoReducerV2 from 'v2/redux/streamVideo/reducer';
import streamTutorReducerV2 from 'v2/redux/streamTutor/reducer';
import boosterClassReducerV2 from 'v2/redux/boosterClass/reducer';
import boosterClassSkillReducerV2 from 'v2/redux/boosterClassSkill/reducer';
import boosterClassChapterReducerV2 from 'v2/redux/boosterClassChapter/reducer';
import boosterClassTheoryReducerV2 from 'v2/redux/boosterClassTheory/reducer';
import boosterClassLectureReducerV2 from 'v2/redux/boosterClassLecture/reducer';
import boosterClassUserSessionReducerV2 from 'v2/redux/boosterClassUserSession/reducer';
import boosterClassExerciseReducerV2 from 'v2/redux/page/boosterClassExercise/reducer';
import boosterClassExerciseAnswerReducerV2 from 'v2/redux/boosterClassExerciseAnswer/reducer';
import boosterClassTestimonialReducerV2 from 'v2/redux/boosterClassTestimonial/reducer';
import testimonialReducerV2 from 'v2/redux/testimonial/reducer';
import boosterClassWhyLearnReducerV2 from 'v2/redux/boosterClassWhyLearn/reducer';
import redemptionReducerV2 from 'v2/redux/redemption/reducer';
import topicReducerV2 from 'v2/redux/topic/reducer';
import transactionReducerV2 from 'v2/redux/transaction/reducer';
import tutorReducerV2 from 'v2/redux/tutor/reducer';
import typerReducerV2 from 'v2/redux/typer/reducer';
import watcherReducerV2 from 'v2/redux/watcher/reducer';
import subscriptionReducerV2 from 'v2/redux/subscription/reducer';
import notificationReducerV2 from 'v2/redux/notification/reducer';
import pointsTaskReducerV2 from 'v2/redux/pointsTask/reducer';
import videoReducerV2 from 'v2/redux/video/reducer';
import videoSolutionReducerV2 from 'v2/redux/videoSolution/reducer';
import referralReducerV2 from 'v2/redux/referral/reducer';
import chapterReducerV2 from 'v2/redux/chapter/reducer';
import sectionReducerV2 from 'v2/redux/section/reducer';
import hotCourseReducerV2 from 'v2/redux/hotCourse/reducer';
import blackFridayCampaignReducerV2 from 'v2/redux/blackFridayCampaign/reducer';

import countryReducer from './country/reducer';
import courseReducer from './course/reducer';
import departmentReducer from './department/reducer';
import documentReducer from './document/reducer';
import downloadReducer from './download/reducer';
import downloadTokenReducer from './downloadToken/reducer';
import notificationReducer from './notification/reducer';
import pageReducer from './page/reducer';
import professorReducer from './professor/reducer';
import schoolReducer from './school/reducer';
import userReducer from './user/reducer';

const reducers = combineReducers({
  answerer: answererReducerV2,
  author: authorReducerV2,
  blackFridayCampaign: blackFridayCampaignReducerV2,
  blogArticle: blogArticleReducerV2,
  blogComment: blogCommentReducerV2,
  book: bookReducerV2,
  boosterClass: boosterClassReducerV2,
  boosterClassChapter: boosterClassChapterReducerV2,
  boosterClassExercise: boosterClassExerciseReducerV2,
  boosterClassExerciseAnswer: boosterClassExerciseAnswerReducerV2,
  boosterClassLecture: boosterClassLectureReducerV2,
  boosterClassSkill: boosterClassSkillReducerV2,
  boosterClassTestimonial: boosterClassTestimonialReducerV2,
  boosterClassTheory: boosterClassTheoryReducerV2,
  boosterClassUserSession: boosterClassUserSessionReducerV2,
  boosterClassWhyLearn: boosterClassWhyLearnReducerV2,
  chapter: chapterReducerV2,
  country: countryReducer.merge(countryReducerV2),
  course: courseReducer.merge(courseReducerV2),
  department: departmentReducer.merge(departmentReducerV2),
  document: documentReducer.merge(documentReducerV2),
  download: downloadReducer,
  downloadToken: downloadTokenReducer.merge(downloadTokenReducerV2),
  earning: earningReducerV2,
  hwAnswer: hwAnswerReducerV2,
  liveTutorialSection: liveTutorialSectionReducerV2,
  liveTutorialSession: liveTutorialSessionReducerV2,
  notification: notificationReducer.merge(notificationReducerV2),
  page: pageReducer.merge(pageReducerV2),
  pointsTask: pointsTaskReducerV2,
  professor: professorReducer.merge(professorReducerV2),
  question: questionReducerV2,
  redemption: redemptionReducerV2,
  referral: referralReducerV2,
  school: schoolReducer.merge(schoolReducerV2),
  section: sectionReducerV2,
  streamTutor: streamTutorReducerV2,
  streamVideo: streamVideoReducerV2,
  streamVideoSchedule: streamVideoScheduleReducerV2,
  subscription: subscriptionReducerV2,
  testimonial: testimonialReducerV2,
  topic: topicReducerV2,
  transaction: transactionReducerV2,
  tutor: tutorReducerV2,
  typer: typerReducerV2,
  user: userReducer.merge(userReducerV2),
  video: videoReducerV2,
  videoSolution: videoSolutionReducerV2,
  chapter: chapterReducerV2,
  section: sectionReducerV2,
  hotCourse: hotCourseReducerV2,
  watcher: watcherReducerV2,
});

export default reducers;
