import { handleModelActions } from 'v2/redux/utils/handleActions';
// import { logErrorDev } from 'helpers/utils';
import types from 'v2/redux/school/types';

import { SCHOOL_MODEL_TYPE } from 'v2/redux/constants';

const initialState = {
  data: {},
  defaultTerm: {},
};

const schoolReducer = handleModelActions(
  SCHOOL_MODEL_TYPE,
  {
    [types.STORE_DEFAULT_TERM]: (state, { payload }) => {
      if (payload) {
        const {
          schoolId,
          value,
        } = payload;

        state.defaultTerm[schoolId] = value;
      }
    },
  },
  initialState,
);

export { initialState };

export default schoolReducer;
